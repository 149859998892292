import React, { useState, useEffect } from "react";

import { Input, Button } from "reactstrap";
import styled from "styled-components";
import currency from "currency.js";
import Swal from "sweetalert2";

export default function Product({
  product,
  isAdmin,
  updateProduct,
  deleteProduct,
}) {
  const [newName, setNewName] = useState("");
  const [newUnitCost, setNewUnitCost] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setNewName(product.name);
    setNewUnitCost(product.unitCost);
  }, [product]);

  return (
    <Wrapper
      isAdmin={isAdmin}
      onClick={() => setIsUpdating((prevState) => !prevState)}
      className={`p-1 pl-2 ml-4 rounded ${isUpdating && "bg-secondary mb-2"}`}
    >
      {isUpdating || (
        <>
          <p className="w-50 m-0 p-0 text-dark">{product.name}</p>
          <p className="w-50 m-0 p-0 text-dark">
            {currency(product.unitCost, {
              symbol: "$",
              precision: 2,
            }).format()}
          </p>
        </>
      )}
      {isUpdating && isAdmin && (
        <form
          className="w-100 p-2"
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            updateProduct({
              id: product.id,
              name: newName,
              unitCost: newUnitCost,
            });
            setIsUpdating(false);
          }}
        >
          <div className="w-25 mr-2">
            <label className="text-dark font-weight-bold mb-0">
              *product name
            </label>
            <Input
              value={newName}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setNewName(e.target.value)}
              required
              type="text"
              className="w-100 text-dark"
              placeholder='(e.x. "OC Estate Gray")'
            />
          </div>
          <div className="w-25 mr-2">
            <label className="text-dark font-weight-bold mb-0">
              *unit cost
            </label>
            <Input
              value={newUnitCost}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setNewUnitCost(e.target.value)}
              required
              type="text"
              className="w-100 text-dark"
              placeholder="$ unit cost"
            />
          </div>
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            color="default"
            className=""
            type="submit"
          >
            update
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              Swal.fire({
                title: "Are you sure?",
                text:
                  "Are you sure you want to delete this product? You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#F3123E",
                cancelButtonColor: "#08080B",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteProduct(product.id);
                }
              });
            }}
            color="danger"
          >
            <i className="fa fa-trash"></i>
          </Button>
        </form>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isAdmin &&
    `
    :hover {
      background: #eff0f4;
      cursor: pointer;
    }
  `}
`;
