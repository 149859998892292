import React from "react";

import UploadedImage from "../../../../utils/UploadedImage.js";

export default function Image({ logoSrc }) {
  return (
    <UploadedImage
      src={logoSrc}
      alt="Company Logo"
      style={{
        marginTop: "-75px",
        position: "relative",
        textAlign: "center",
      }}
      background="white"
      width="125"
      height="125"
      display="fill"
      className="rounded border border-light shadow-sm"
    />
  );
}
