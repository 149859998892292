import React from "react";
import styled from "styled-components";
import Color from "color";

const LIGHTEN_AMT = 0.5;

export function renderJobEventContent(eventInfo) {
  const { color, status } = eventInfo.event.extendedProps;

  const borderColor =
    status !== "active"
      ? Color("#738394").lighten(LIGHTEN_AMT).hex()
      : Color(color).lighten(LIGHTEN_AMT).hex() ||
        Color("#738394").lighten(LIGHTEN_AMT).hex();
  const bgColor = "#f9f9f9";

  eventInfo.backgroundColor = bgColor;
  eventInfo.borderColor = "rgba(0,0,0,0)";

  return (
    <Card
      id={`CalendarPopoverItem-${eventInfo.event.extendedProps.id}`}
      borderColor={borderColor}
      status={status}
      color={color}
      className="shadow--hover rounded"
    >
      <p
        className="m-0 p-0 pl-1 pr-1 text-dark"
        style={{
          fontSize: "12px",
          fontWeight: "600",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        {eventInfo.event.title}
      </p>
      <p
        className="m-0 p-0 pl-1 pr-1 text-dark"
        style={{
          fontSize: "12px",
          fontWeight: "400",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        {eventInfo.event.extendedProps.address &&
          `${eventInfo.event.extendedProps.address}, `}
      </p>
      <p
        className="m-0 p-0 pl-1 pr-1 text-dark"
        style={{
          fontSize: "12px",
          fontWeight: "400",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        {`${eventInfo.event.extendedProps.city}, `}
        {`${eventInfo.event.extendedProps.state}`}{" "}
        {eventInfo.event.extendedProps.zip &&
          ` ${eventInfo.event.extendedProps.zip}`}
      </p>
      {(eventInfo.view.type === "dayGridWeek" ||
        eventInfo.view.type === "dayGridDay") && (
        <CardMoreDetailsWrapper>
          <CardFlexWrapper className="mb-2">
            <div className="mr-2">
              <p
                className="m-0 p-0 pl-1 pr-1 text-dark"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  overflow: "hidden",
                }}
              >
                Job #{eventInfo.event.extendedProps.jobNumber}
              </p>
              <p
                className="m-0 p-0 pl-1 pr-1 text-dark"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  overflow: "hidden",
                }}
              >
                subcontractor name:{" "}
                {eventInfo.event.extendedProps.isCustom
                  ? eventInfo.event.extendedProps.customSubcontractorName || "-"
                  : eventInfo.event.extendedProps?.companyInfo?.name
                  ? eventInfo.event.extendedProps.companyInfo.name
                  : "-"}
              </p>

              <p
                className="m-0 p-0 pl-1 pr-1 text-dark"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  overflow: "hidden",
                }}
              >
                assigned to:{" "}
                {eventInfo.event.extendedProps.isCustom
                  ? eventInfo.event.extendedProps.customCrewMemberName || "-"
                  : Object.keys(
                      eventInfo.event.extendedProps.assignedToUserInfo
                    ).length
                  ? `${eventInfo.event.extendedProps.assignedToUserInfo.firstName} ${eventInfo.event.extendedProps.assignedToUserInfo.lastName}`
                  : "-"}
              </p>
            </div>
            <div>
              {eventInfo.event.extendedProps.reports.map(
                ({ id, measurement, amount }) => {
                  return (
                    <p
                      key={id}
                      className="m-0 p-0 pl-1 pr-1"
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        overflow: "hidden",
                        color,
                      }}
                    >
                      {amount} {measurement}
                    </p>
                  );
                }
              )}
            </div>
          </CardFlexWrapper>
          <CardTagsWrapper>
            {eventInfo.event.extendedProps.tags.map(({ content, id }) => (
              <div
                key={id}
                style={{ fontSize: "10px", display: "block" }}
                className="badge badge-light ml-1 mb-1"
              >
                {content}
              </div>
            ))}
          </CardTagsWrapper>
        </CardMoreDetailsWrapper>
      )}
    </Card>
  );
}

export function renderAppointmentEventContent(eventInfo) {
  const {
    color,
    status,
    assignedToFirstName,
    assignedToLastName,
    customerFirstName,
    customerLastName,
    note,
  } = eventInfo.event.extendedProps;

  const borderColor =
    status !== "active"
      ? Color("#738394").lighten(LIGHTEN_AMT).hex()
      : Color(color).lighten(LIGHTEN_AMT).hex() ||
        Color("#738394").lighten(LIGHTEN_AMT).hex();
  const bgColor = "#f9f9f9";

  eventInfo.backgroundColor = bgColor;
  eventInfo.borderColor = "rgba(0,0,0,0)";

  return (
    <Card
      id={`AppointmentPopoverItem-${eventInfo.event.extendedProps.id}`}
      borderColor={borderColor}
      status={status}
      color={color}
      className="shadow--hover rounded bg-white"
    >
      <p
        className="m-0 p-0 pl-1 pr-1 text-dark"
        style={{
          fontSize: "12px",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        {assignedToFirstName} {assignedToLastName} is meeting with{" "}
        <span style={{ fontWeight: "600" }}>
          {customerFirstName} {customerLastName}
        </span>{" "}
        about:
      </p>

      <p
        className="m-0 mt-2 pl-1 pr-1 text-dark"
        style={{
          fontSize: "12px",
          fontWeight: "400",
          cursor: "pointer",
          overflow: "hidden",
          fontStyle: "italic",
        }}
      >
        {note}
      </p>
    </Card>
  );
}

const CardTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const CardMoreDetailsWrapper = styled.div``;

const CardFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const Card = styled.div`
  height: 100%;
  border: 2px solid ${(props) => props.borderColor};
  border-left: 7px solid
    ${(props) =>
      props.status !== "active" ? "#738394" : props.color || "#738394"};

  overflow: hidden;
  z-index: 500;
  position: relative;
`;
