import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import AssignSubcontractor from "./AssignSubcontractor";
import EditBasicJobDetails from "./EditBasicJobDetails";
import { withRouter } from "react-router-dom";
import MarkAsComplete from "./MarkAsComplete";
import { editJob } from "store/reducers/jobs";
import AssignSchedule from "./AssignSchedule";
import AssignCustomer from "./AssignCustomer";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function ContractorEdit({
  setIsCreatingCustomer,
  handleUpdateCustomer,
  jobCrewTableId,
  curStartDate,
  connections,
  curEndDate,
  companyId,
  isActive,
  history,
  editJob,
  jobId,
  job,
}) {
  const [selectedConnection, setSelectedConnection] = useState(0);
  const [isCustom, setIsCustom] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [crews, setCrews] = useState([]);
  const [customCrewId, setCustomCrewId] = useState(0);
  const [customCrewMemberId, setCustomCrewMemberId] = useState(0);

  useEffect(() => {
    if (curStartDate && curEndDate) {
      setStartDate(new Date(curStartDate));
      setEndDate(new Date(curEndDate));
    }
  }, [curStartDate, curEndDate]);

  useEffect(() => {
    setTitle(job.title);
    setAddress(job.address || "");
    setCity(job.city);
    setState(job.state);
    setZip(job.zip || "");
  }, [job]);

  useEffect(() => {
    fetchCrews();
  }, []);

  const updateSubcontractor = () => {
    if ((!isCustom && !selectedConnection) || (isCustom && !customCrewId)) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "You must choose a subcontractor from the list to assign them this job.",
      });
    }
    const subInfo = {
      customCrewId,
      customCrewMemberId: customCrewMemberId || null,
      selectedConnection,
      jobCrewTableId,
      isCustom,
    };
    axios
      .post(
        `/api/jobs/update/assign/${
          isCustom ? "custom" : "subcontractorAccount"
        }`,
        subInfo
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: `We've updated your job. ${
            !isCustom
              ? "We'll notify your subcontractor and let them know."
              : ""
          }`,
        });
        history.push("/app/jobs");
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an error updating this job. Please try again in a moment.",
        });
      });
  };

  const updateSchedule = () => {
    if (startDate > endDate) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date must come before end date.",
      });
    }
    const schedule = {
      startDate,
      endDate,
      jobId,
    };

    axios
      .post(`/api/jobs/update/schedule`, schedule)
      .then(() => {
        history.push("/app/jobs");
        Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: `We've updated your schedule.`,
        });
      })
      .catch(() =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an error updating this job. Please try again in a moment.",
        })
      );
  };

  const deleteJob = async () => {
    try {
      await axios.post("/api/jobs/delete", { jobId });
      history.push(
        window.location.pathname.includes("/app/jobs")
          ? "/app/jobs"
          : "/app/calendar"
      );
      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've deleted this job.`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error deleting this job. Please try again in a moment.",
      });
    }
  };

  const updateTitleAndAddress = async (e) => {
    e.preventDefault();
    const updates = {
      title,
      address,
      city,
      state,
      zip,
      jobId,
    };
    try {
      await axios.post("/api/jobs/update/nameAddress", updates);
      editJob(updates);
      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've updated this job.`,
      });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error updating this job. Please try again in a moment.",
      });
    }
  };

  const fetchCrews = async () => {
    try {
      const {
        data: { crews },
      } = await axios.get("/api/custom_crews/all");
      setCrews(crews);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to fetch your custom crews.",
      });
    }
  };

  return (
    <div className="p-2">
      {/* ---- Assign subcontractor ---- */}
      <AssignSubcontractor
        customCrewId={customCrewId}
        setCustomCrewId={setCustomCrewId}
        customCrewMemberId={customCrewMemberId}
        setCustomCrewMemberId={setCustomCrewMemberId}
        setSelectedConnection={setSelectedConnection}
        updateSubcontractor={updateSubcontractor}
        selectedConnection={selectedConnection}
        setIsCustom={setIsCustom}
        connections={connections}
        companyId={companyId}
        isCustom={isCustom}
        crews={crews}
      />
      {/* ---- Assign Customer ---- */}
      <AssignCustomer
        handleUpdateCustomer={handleUpdateCustomer}
        setIsCreatingCustomer={setIsCreatingCustomer}
        jobCustomer={job.jobCustomer || 0}
      />
      {/* Edit Name and Address */}
      <EditBasicJobDetails
        updateTitleAndAddress={updateTitleAndAddress}
        setAddress={setAddress}
        setTitle={setTitle}
        setState={setState}
        setCity={setCity}
        address={address}
        setZip={setZip}
        title={title}
        state={state}
        city={city}
        zip={zip}
      />
      {/* ---- Schedule Jobs ---- */}
      <AssignSchedule
        updateSchedule={updateSchedule}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
      />

      {/* Delete/Mark Complete Buttons */}
      <Button
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to delete this job?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#F3123E",
            cancelButtonColor: "#08080B",
            confirmButtonText: "Yes, delete it!",
          }).then((result) => {
            if (result.value) {
              deleteJob();
            }
          });
        }}
        className="mt-5 w-100"
        color="danger"
      >
        Delete
      </Button>
      <MarkAsComplete isActive={isActive} jobId={jobId} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
  connections: state.connections.connections,
});

export default connect(mapStateToProps, { editJob })(
  withRouter(ContractorEdit)
);
