import React from "react";

import { Cover, InnerWrapper } from "../StyledComponents";
import CreateCrewMember from "./CreateCrewMember";
import styled from "styled-components";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

export default function ViewSingleCrewDetails({
  crew,
  isAdmin,
  isViewingCrew,
  handleDeleteCrew,
  setIsViewingCrew,
  setIsEditingCrewMember,
  handleAddCrewMember,
  isCreatingCrewMember,
  setSelectedCrewMember,
  handleDeleteCrewMember,
  setIsCreatingCrewMember,
}) {
  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsViewingCrew(false);
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ display: isViewingCrew ? "block" : "none" }}
    >
      <InnerWrapper
        style={{ height: "85vh" }}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded p-4 bg-white"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="display-4 text-darker m-0 mb-2">{crew.name}</p>
          {isAdmin && (
            <Button
              onClick={() => handleDeleteCrew(crew.id)}
              color="danger"
              size="sm"
            >
              delete
            </Button>
          )}
        </div>

        {isAdmin && (
          <Button
            className="btn-icon shadow-none p-2 mb-1"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => setIsCreatingCrewMember((prevState) => !prevState)}
            color="default"
            size="sm"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-plus fa-lg" />
            </span>
            <span className="btn-inner--text">New Crew Member</span>
          </Button>
        )}
        {isCreatingCrewMember && (
          <CreateCrewMember
            handleAddCrewMember={handleAddCrewMember}
            crewId={crew.id}
          />
        )}
        <table className="table bg-white">
          <thead className="bg-white">
            <tr style={{ borderTop: "hidden" }}>
              <th className="text-darker" scope="col">
                Name
              </th>
              <th className="text-darker" scope="col">
                Email
              </th>
              <th className="text-darker" scope="col">
                Phone
              </th>
              <th className="text-darker" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {crew?.crewMembers?.map((crewMember) => {
              const { id, name, email, phone } = crewMember;
              return (
                <TableRow
                  key={id}
                  onClick={() => {
                    setIsEditingCrewMember(true);
                    setSelectedCrewMember(crewMember);
                  }}
                >
                  <th
                    scope="row"
                    className="text-dark"
                    style={{ fontWeight: 400 }}
                  >
                    {name}
                  </th>
                  <th
                    scope="row"
                    className="text-dark"
                    style={{ fontWeight: 400 }}
                  >
                    {email || "-"}
                  </th>
                  <th
                    scope="row"
                    className="text-dark"
                    style={{ fontWeight: 400 }}
                  >
                    {phone || "-"}
                  </th>
                  <th scope="row">
                    <Button
                      disabled={!isAdmin}
                      onClick={(e) => {
                        e.stopPropagation();
                        Swal.fire({
                          title: "Are you sure?",
                          text:
                            "Are you sure you want to delete this crew member? You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#F3123E",
                          cancelButtonColor: "#08080B",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleDeleteCrewMember(id, crew.id);
                          }
                        });
                      }}
                      color="danger"
                      size="sm"
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </th>
                </TableRow>
              );
            })}
          </tbody>
        </table>
        {crew?.crewMembers?.length ? (
          <></>
        ) : (
          <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
      </InnerWrapper>
    </Cover>
  );
}

const TableRow = styled.tr`
  cursor: pointer;
  :hover {
    background: #f3f3f3;
  }
`;
