import React, { useState, useEffect } from "react";

import { updateUserInfo } from "store/reducers/auth";
import { Button, Input } from "reactstrap";
import HelpModal from "utils/HelpModal";
import styled from "styled-components";
import { connect } from "react-redux";

function UserSettings({ updateUserInfo, user }) {
  const [isEditing, setIsEditing] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [location, setLocation] = useState("");
  const [language, setLanguage] = useState("");
  const [position, setPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setLocation(user.location);
    setLanguage(user.language);
    setPosition(user.position);
    setPhoneNumber(user.phoneNumber);
  }, [user]);

  const handleSubmit = async () => {
    try {
      await updateUserInfo({
        firstName,
        lastName,
        location,
        language,
        position,
        phoneNumber,
      });
      setIsEditing(false);
    } catch (error) {}
  };

  return (
    <div className="p-2" style={{ maxWidth: "700px", margin: "auto" }}>
      <TopBar>
        <p className="h3 m-0 text-dark" style={{ fontWeight: "600" }}>
          User Settings
        </p>
        <p
          onClick={() => setIsEditing((prevState) => !prevState)}
          className="text-default m-0"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {isEditing ? "-" : "+"} edit
        </p>
      </TopBar>
      <HelpModal shouldShowHelpText modalTitle="User Settings Help">
        <p className="text-dark m-0">
          To change your password, please log out of your account. When logging
          back you, click on the "forgot password?" button.
        </p>
      </HelpModal>
      <form className="p-2 mt-2 bg-white shadow-sm rounded">
        <UserSetting className="m-2">
          <p className="m-0 text-dark">first name</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.firstName}
            </p>
          )}
          {isEditing && (
            <Input
              required
              type="text"
              className="mb-2 text-dark border-light"
              placeholder="first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          )}
        </UserSetting>
        <UserSetting className="m-2">
          <p className="m-0 text-dark">last name</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.lastName}
            </p>
          )}
          {isEditing && (
            <Input
              required
              type="text"
              className="mb-2 text-dark border-light"
              placeholder="last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          )}
        </UserSetting>
        <UserSetting className="m-2">
          <p className="m-0 text-dark">location</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.location}
            </p>
          )}
          {isEditing && (
            <Input
              required
              type="text"
              className="mb-2 text-dark border-light"
              placeholder="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          )}
        </UserSetting>
        <UserSetting className="m-2">
          <p className="m-0 text-dark">language preference</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.language}
            </p>
          )}
          {isEditing && (
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              required
              className="mb-2 text-dark w-100 p-2 border-light rounded"
            >
              <option>english</option>
              <option>spanish</option>
            </select>
          )}
        </UserSetting>
        <UserSetting className="m-2">
          <p className="m-0 text-dark">position</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.position}
            </p>
          )}
          {isEditing && (
            <Input
              required
              type="text"
              className="mb-2 text-dark border-light"
              placeholder="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          )}
        </UserSetting>
        <UserSetting className="m-2">
          <p className="m-0 text-dark">phone number</p>
          {isEditing || (
            <p className="m-0 lead text-dark" style={{ fontWeight: "600" }}>
              {user.phoneNumber}
            </p>
          )}
          {isEditing && (
            <Input
              required
              type="text"
              className="mb-2 text-dark border-light"
              placeholder="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          )}
        </UserSetting>
        {isEditing && (
          <Button onClick={() => handleSubmit()} block color="default">
            update
          </Button>
        )}
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { updateUserInfo })(UserSettings);

const UserSetting = styled.div`
  border-bottom: 1px solid #adb5bd;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
