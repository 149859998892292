import React, { useState, useCallback } from "react";
import styled from "styled-components";

import CloseOnOutsideClick from "../../../utils/CloseOnOutsideClick.js";

export default function SelectUsers({ isOpen, close, onClick, userlist }) {
  return (
    <CloseOnOutsideClick close={close} isOpen={isOpen}>
      {isOpen && <Menu userlist={userlist} onClick={onClick} />}
    </CloseOnOutsideClick>
  );
}

function Menu({ userlist, onClick }) {
  const [search, setSearch] = useState("");
  const filterBySearch = useCallback(filterUsersByKeyword(search), [search]);
  const handleChange = useCallback((e) => setSearch(e.target.value), []);

  return (
    <TagUserContainer>
      <div>
        <div>
          <input
            role="searchbox"
            placeholder="Search for users"
            value={search}
            onChange={handleChange}
          />
        </div>
        <div>
          {userlist.filter(filterBySearch).map((u) => (
            <p key={u.id} onClick={onClick.bind(this, u)}>
              {u.firstName} {u.lastName}
            </p>
          ))}
        </div>
      </div>
    </TagUserContainer>
  );
}

const filterUsersByKeyword = (kw) => {
  const keyword = kw.toLowerCase();
  return (user) => {
    const fullName = user.firstName + " " + user.lastName;
    if (fullName.toLowerCase().includes(keyword)) {
      return true;
    }

    const userProperties = Object.keys(user);
    for (let i = 0; i < userProperties; i++) {
      const prop = userProperties[i];
      const value = user[prop];
      if (value.toLowerCase().includes(keyword)) {
        return true;
      }
    }

    return false;
  };
};

const TagUserContainer = styled.div`
  position: relative;

  & > div {
    position: absolute;
    z-index: 10;
    bottom: 15px;
    min-height: 235px;

    padding-left: 5px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;

    & > div:first-of-type {
      // Search input
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 5px;
    }

    & > div:last-of-type {
      // Scroll-able user list
      // border-top: 1px solid #d9d9d9;
      max-height: 185px;
      overflow-y: auto;

      p {
        min-width: 150px;
        margin: 8px 0;
        cursor: pointer;
        // Hide text that's too long behind ...
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          background-color: #f0f2f5;
        }
      }
    }
  }
`;
