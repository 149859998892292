import React from "react";

import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function Connections({ numConnections, history }) {
  return (
    <div style={{ marginLeft: "25px" }}>
      <p
        onClick={() => {
          history.push("/app/connections");
        }}
        className="mt-0 mb-0 text-dark statistic"
        style={{
          textAlign: "start",
          cursor: "pointer",
          textDecoration: "underline",
          display: "inline",
        }}
      >
        <span className="btn-inner--icon mr-2">
          <i className="fa fa-user fa-xs" />
        </span>
        <span style={{ fontWeight: "400" }}>
          {numConnections || 0} connections
        </span>
      </p>
    </div>
  );
}

export default withRouter(Connections);

Connections.propTypes = {
  numConnections: PropTypes.string,
};

Connections.defaultProps = {
  numConnections: "0",
};
