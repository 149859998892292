import React from "react";

import { FormGroup, Form, Input, Container, Button } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

const BasicAccountInfo = ({ formState, setFormState, handleSetStep }) => {
  return (
    <FormWrapper>
      <Container className="p-3 mb-5">
        <p className="display-4 font-weight-light text-darker p-0 m-0 text-left">
          We're excited you're here!
        </p>
        <p className="text-dark lead m-0 p-0 text-left">
          We just need a few more details to get you started.
        </p>
        <hr className="mt-3" />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSetStep(2);
          }}
        >
          <p className="text-dark text-left mb-0">*Email (provided)</p>
          <FormGroup>
            <Input
              className="text-dark"
              required
              type="email"
              name="email"
              disabled
              value={formState.email}
            />
          </FormGroup>
          <p className="text-dark text-left mb-0">*First Name</p>
          <FormGroup>
            <Input
              className="text-dark"
              autocomplete="given-name"
              required
              type="text"
              name="firstName"
              onChange={(e) => {
                const firstName = e.target.value;
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    firstName,
                  };
                });
              }}
              value={formState.firstName}
            />
          </FormGroup>
          <p className="text-dark text-left mb-0">*Last Name</p>
          <FormGroup>
            <Input
              className="text-dark"
              autocomplete="family-name"
              required
              type="text"
              name="lastName"
              onChange={(e) => {
                const lastName = e.target.value;
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    lastName,
                  };
                });
              }}
              value={formState.lastName}
            />
          </FormGroup>
          <p className="text-dark text-left mb-0">*Address</p>
          <FormGroup>
            <Input
              className="text-dark"
              required
              type="text"
              autocomplete="address-line1"
              name="location"
              onChange={(e) => {
                const location = e.target.value;
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    location,
                  };
                });
              }}
              value={formState.location}
            />
          </FormGroup>
          <p className="text-dark text-left mb-0">Phone Number</p>
          <FormGroup>
            <Input
              className="text-dark"
              type="tel"
              name="phoneNumber"
              onChange={(e) => {
                const phoneNumber = e.target.value;
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    phoneNumber,
                  };
                });
              }}
              value={formState.phoneNumber}
            />
          </FormGroup>
          <p className="text-dark text-left mb-0">
            Language Preference{" "}
            <i>(Crewly can help bridge language barriers)</i>
          </p>
          <FormGroup style={{ display: "flex", justifyContent: "flex-start" }}>
            <select
              value={formState.language}
              required
              onChange={(e) => {
                const language = e.target.value;
                setFormState((prevState) => {
                  return {
                    ...prevState,
                    language,
                  };
                });
              }}
              className="form-control text-dark form-control-sm"
            >
              <option>english</option>
              <option>spanish</option>
            </select>
          </FormGroup>

          <Button className="btn-icon mt-5" block color="default" type="submit">
            <span className="btn-inner--text">choose account type</span>
            <span className="btn-inner--icon">
              <i className="fa fa-arrow-circle-right fa-lg" />
            </span>
          </Button>
        </Form>
      </Container>
    </FormWrapper>
  );
};

export default BasicAccountInfo;

BasicAccountInfo.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  handleSetStep: PropTypes.func.isRequired,
};

const FormWrapper = styled.div`
  margin: auto;
`;
