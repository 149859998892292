import React, { useState } from "react";

import { inviteUserToCompany } from "store/reducers/company.js";
import { Input, Button } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";

export function InviteToCompany({ inviteUserToCompany, companyType }) {
  const [invitationSuccessful, setInvitationSuccessful] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [userType, setUserType] = useState("admin");
  const [email, setEmail] = useState("");

  return (
    <>
      <p
        className={`lead m-0 text-${
          invitationSuccessful ? "success" : "warning"
        }`}
        style={showMessage ? {} : { display: "none" }}
      >
        {responseMessage}
      </p>
      <div className="bg-white p-2 mb-2">
        <form>
          <label className="text-dark font-weight-bold mb-0">
            what e-mail should we send the invitation to?
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              className="text-dark mr-2"
              style={{
                width: "90%",
                minWidth: "250px",
              }}
              id="exampleFormControlInput1"
              placeholder="name@example.com"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              className="m-0"
              disabled={isInviting}
              color="default"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                setIsInviting(true);
                inviteUserToCompany(email, userType)
                  .then((res) => {
                    setInvitationSuccessful(res.success);
                    if (res.success) {
                      setResponseMessage(
                        res.isResendingInvitation
                          ? "This user has already been sent an invitation, we've resent it to them. If the existing invitation happens to be from another company, please ask them to delete their existing invitation by following the link in their email, then send them a new one."
                          : "Successfully sent your invitation."
                      );
                    } else {
                      setResponseMessage(res.message);
                    }
                  })
                  .catch(() => {
                    setInvitationSuccessful(false);
                    setResponseMessage(
                      "Hmm.... It seems there was an error on our end... Give us a moment and try again!"
                    );
                  })
                  .finally(() => {
                    setIsInviting(false);
                    setShowMessage(true);
                    setEmail("");
                  });
              }}
            >
              invite
            </Button>
          </div>
          <label className="text-dark font-weight-bold mb-0">
            account type
          </label>
          <select
            data-testid="selectAccountTypeForInvite"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            className="form-control text-dark form-control-sm mb-2"
          >
            <option>admin</option>
            <option>member</option>
            {companyType === Constants.CONTRACTOR && <option>salesman</option>}
            {companyType === Constants.CONTRACTOR && <option>foreman</option>}
          </select>
        </form>
        <HelpModal shouldShowHelpText modalTitle="Invitation Help">
          <p className="text-dark m-0">
            When inviting a new user to your company, the steps are fairly
            simple:
          </p>
          <ol>
            <li className="text-dark m-0">
              Type in the email of whoever you'd like to give access to your
              company.
            </li>
            <li className="text-dark m-0">
              Choose from the drop down which type level of access you'd like to
              give this user ("admin", "member", "salesman", "foreman"). Each
              account type has different features available which will be listed
              below.
            </li>
            <li className="text-dark m-0">
              Click on "INVITE". If this users email already belongs to another
              company, they will not be able to join yours. If they would like
              to, we suggest they either use a different email, or remove
              themselves from their existing company. If they've already been
              sent an invitation, they will be sent another (even if this
              invitation was from another company, in which case they can simply
              delete the existing invitation).
            </li>
          </ol>
          <p className="text-dark m-0 mt-2">
            Account types can be broken down into the following levels of
            access:
          </p>
          <ul>
            <li className="text-dark m-0">
              <span style={{ fontWeight: 600 }}>admin - </span>
              "Admin" users have full access to all features on your Crewly
              account.
            </li>
            <li className="text-dark m-0">
              <span style={{ fontWeight: 600 }}>member - </span>
              "Member" users can see all information on your Crewly account, but
              can not do things such as make edits to jobs, reschedule
              appointments, adjust company settings, etc. This can be seen
              essentially as a "read-only" account.
            </li>
            <li className="text-dark m-0">
              <span style={{ fontWeight: 600 }}>salesman/foreman - </span>
              "Salesman" and "foreman" users have the same levels of access as a
              "member" account, with the additional restriction of only being
              able to see jobs and appointments with which they are assigned.
            </li>
          </ul>
        </HelpModal>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
});

export default connect(mapStateToProps, { inviteUserToCompany })(
  InviteToCompany
);

InviteToCompany.propTypes = {
  inviteUserToCompany: PropTypes.func.isRequired,
};
