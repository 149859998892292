import React, { useState, useEffect } from "react";

import SingleQualification from "./SingleQualification";
import CreateQualification from "./CreateQualification";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import styled from "styled-components";
import Swal from "sweetalert2";
import axios from "axios";
import { connect } from "react-redux";

function Qualifications({ companyId, userType }) {
  const [qualifications, setQualifications] = useState([]);

  const fetchQualifications = async () => {
    try {
      const res = await axios.get(`/api/qualifications/find/${companyId}`);

      setQualifications(res.data.qualifications);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching some of your company details.",
      });
    }
  };

  useEffect(() => {
    companyId && fetchQualifications();
    // eslint-disable-next-line
  }, [companyId]);

  return (
    <Wrapper className="ml-2 mt-1 p-3 mb-0 bg-white shadow-sm rounded">
      <Header className="w-100 pb-2 border-bottom border-light">
        <p className="text-dark m-0 h4" style={{ fontWeight: "600" }}>
          Qualifications
        </p>
        <div style={{ display: "flex" }}>
          <HelpModal modalTitle="Qualifications Help">
            <p className="text-dark m-0">
              Qualifications are simply a way to communicate with your
              connections as to what type of work you are qualified for. By
              example, a roofing subcontractor may want contractors to know that
              they are qualified to work on metal roofs.
            </p>
            <p className="text-dark m-0 mt-3">
              These qualifications will show up on your public company page for
              others to see.
            </p>
          </HelpModal>
          {userType === Constants.ADMIN && (
            <div className="ml-2">
              <CreateQualification setQualifications={setQualifications} />
            </div>
          )}
        </div>
      </Header>
      <Content className="mt-2">
        {!qualifications.length && (
          <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
        {qualifications.map((qualification, idx) => {
          return (
            <SingleQualification
              key={idx}
              qualification={qualification}
              setQualifications={setQualifications}
            />
          );
        })}
      </Content>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
});

export default connect(mapStateToProps, null)(Qualifications);

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 500px;
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  align-content: start;
  overflow-x: hidden;
`;
