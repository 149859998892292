import React from "react";

import { CompanyCardWrapper, HeadTopLevel, Banner } from "./StyledComponents";
import Description from "./infoheadercomponents/Description";
import Connections from "./infoheadercomponents/Connections";
import EditButton from "./infoheadercomponents/EditButton";
import CompanyInfo from "../companyprofile/CompanyInfo";
import Location from "./infoheadercomponents/Location";
import Image from "./infoheadercomponents/Image";
import Constants from "utils/Constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function CompanyInfoHeader({ company, userType }) {
  return (
    <CompanyCardWrapper className="bg-white rounded shadow-sm mb-0 mt-0 pb-4">
      <Banner className="bg-darker" />
      <HeadTopLevel className="pt-0 pl-4 pr-4 pb-2">
        <Image
          logoSrc={
            company.logoUrl || "https://via.placeholder.com/200x200?text=logo"
          }
        />

        <p
          className="pt-2 pl-2 text-dark company-name"
          style={{
            textAlign: "start",
            lineHeight: "35px",
            margin: 0,
            fontWeight: "600",
            fontSize: "28px",
          }}
        >
          {company.name}
        </p>
      </HeadTopLevel>
      <Location company={company} />
      <Connections numConnections={company.numConnections} />
      {userType === Constants.ADMIN && <EditButton />}
      <div className="pl-4">
        <Description company={company} />
      </div>
    </CompanyCardWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
    userType: state.auth.user.userType,
  };
};

export default connect(mapStateToProps, {})(CompanyInfoHeader);

CompanyInfo.propTypes = {
  setURLToStore: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};
