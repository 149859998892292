import React, { useState, useEffect } from "react";
import useWindowSize from "components/providers/WindowSize.js";
import ResizeObserver from "react-resize-observer";
import styled from "styled-components";

/*
 * Renders an image inside of a div and forces a dynamic size to fit some constraints, meaning
 * this is most likely only useful for displaying a user-uploaded image (since we don't know the size)
 *
 * Required Props:
 *   src (string or file) - image source
 *   alt (string) - image alt text
 *
 * Optional Props:
 *   height (string or number) - assumed in pixels, the height of the div
 *   width (string or number) - assumed in pixels, the width of the div
 *   display (string: "fill" or "fit") - how the image should be rendered in the div.
 *         fill: the image should fill the entire div box
 *         fit: fit the entire image inside of the box
 *   background (string): How extra space in the div should be rendered (only applicable on fit)
 *         undefined or "none": Don't render any extra space (think "fit-content")
 *         [color] (string with name, rgba, hex, or any other valid color) - background color of the extra space
 *   style (object): Any styles to be placed on the container div
 *   imgStyle (object): Any styles to be placed on the image itself
 *   children (JSX): Any other jsx you want included inside of the container div, underneath the image
 */

function UploadedImage(
  {
    src,
    alt,
    display,
    width: mw,
    height: mh,
    style,
    imgStyle,
    background,
    children,
    ...otherProps
  },
  parentRef
) {
  // Refresh is me being lazy and forcing the component to reload.  In
  // reality, the logic I do in the component should be moved to a function
  // I can reuse.  But it works for now and I'm tired of this component
  // so I'm leaving it for the time being.
  const [refresh, setRefresh] = useState(false);
  const [size, setSize] = useState({ height: "", width: "" });
  const windowSize = useWindowSize();

  useEffect(() => {
    getImageSize(src)
      .then((imgSize) => {
        setSize(imgSize);
      })
      .catch((err) => console.error("ERROR: ", err));
    // eslint-disable-next-line
  }, [src]);

  const [maxWidth, widthUnits] = splitMeasurement(
    mw,
    parentRef,
    "width",
    windowSize
  );
  const [maxHeight, heightUnits] = splitMeasurement(
    mh,
    parentRef,
    "height",
    windowSize
  );

  if ((widthUnits === "%" || heightUnits === "%") && !parentRef) {
    throw new Error(
      "Cannot use a '%' measurement in UploadedImage without a ref to the parent container."
    );
  }

  const { height, width } =
    display && mw && mh
      ? getSizeForDisplayType(
          {
            display,
            width: maxWidth,
            height: maxHeight,
          },
          size
        )
      : size;

  return (
    <ImageContainer
      {...otherProps}
      style={style || {}}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      imgHeight={height}
      widthUnits={widthUnits}
      heightUnits={heightUnits}
      imgWidth={width}
      background={background}
    >
      <ResizeObserver onResize={setRefresh.bind(this, !refresh)} />
      {size.width && size.height && (
        <img src={src} alt={alt} style={imgStyle || {}} />
      )}
      {children}
    </ImageContainer>
  );
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ background }) => background !== "none" && background};
  overflow: hidden;
  max-height: ${({ maxHeight, heightUnits }) => maxHeight + heightUnits};
  max-width: 100%;
  min-height: ${({ background, maxHeight, heightUnits }) =>
    background && background !== "none" && maxHeight + heightUnits};
  min-width: ${({ background, maxWidth, widthUnits }) =>
    background && background !== "none" && maxWidth + widthUnits};
  img {
    height: ${({ imgHeight, heightUnits }) => imgHeight + heightUnits};
    width: ${({ imgWidth, widthUnits }) => imgWidth + widthUnits};
  }
`;

function splitMeasurement(str, parentRef, side, windowSize) {
  if (typeof str !== "string") {
    str = str.toString();
  }
  const value = parseInt(str, 10);
  const measurement = str.substring(value.toString().length).toLowerCase();
  if (!measurement) {
    // Just a number is given, assume pixels
    return [value, "px"];
  } else if ((!parentRef && measurement === "%") || (parentRef && !side)) {
    // No parent ref given but provided a percent (ie: someone messed up)
    return [null, "%"];
  } else if (measurement === "vh" || measurement === "vw") {
    const windowSide = measurement === "vh" ? "height" : "width";
    const valueInPx = windowSize[windowSide] * (value / 100);
    return [valueInPx, "px"];
  } else if (!parentRef) {
    // Standard measurements given
    return [value, measurement];
  }

  if (!parentRef.current) {
    // Parent given but it the ref hasn't been assigned yet
    return [0, "%"];
  }

  const parent = {
    width: parentRef.current.clientWidth,
    height: parentRef.current.clientHeight,
  };

  const percentToPixel = parent[side] * (value / 100);

  // Percentage given, convert it to a pixel value
  return [percentToPixel, "px"];
}

function getImageSize(src) {
  const img = new Image();
  img.src = src;
  img.alt = "temporary render";
  return new Promise((res, rej) => {
    img.onload = function () {
      res({ width: this.width, height: this.height });
    };
  });
}

function getSizeForDisplayType({ display, ...boxSize }, imageSize) {
  if (boxSize.height && boxSize.width && imageSize.width && imageSize.height) {
    const boxRatio = boxSize.height / boxSize.width;
    const imgRatio = imageSize.height / imageSize.width;
    const domSideLookup = {
      fit: (imgRatioIsGreater) => (imgRatioIsGreater ? "height" : "width"),
      fill: (imgRatioIsGreater) => (imgRatioIsGreater ? "width" : "height"),
    };

    const d = display.toLowerCase();
    const domSide = domSideLookup[d]
      ? domSideLookup[d](imgRatio >= boxRatio)
      : "height";

    if (!domSideLookup[d]) {
      console.error(d, "is not a valid image display");
    }

    const dominateSide = boxSize[domSide];
    const otherSide =
      domSide === "height" ? dominateSide / imgRatio : dominateSide * imgRatio;

    const calcHeight = domSide === "height" ? dominateSide : otherSide;
    const calcWidth = domSide === "width" ? dominateSide : otherSide;

    if (calcHeight > imageSize.height || calcWidth > imageSize.width) {
      // Do not scale the image above its natural size
      return imageSize;
    }

    return {
      height: calcHeight,
      width: calcWidth,
    };
  }

  return imageSize;
}

export default React.forwardRef(UploadedImage);
