import React from "react";

import styled from "styled-components";

export default function Qualifications({ qualifications }) {
  return (
    <Wrapper className="w-100 bg-white rounded shadow-sm p-2">
      <p
        className="text-dark m-0 h4 pb-1 mb-2 border-bottom border-light"
        style={{ fontWeight: "600" }}
      >
        Qualifications
      </p>
      {!qualifications.length && (
        <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
          nothing to show
        </p>
      )}
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {qualifications.map(({ id, qualification }, idx) => {
          return (
            <div key={id} className="w-100 mb-0">
              <Qualification
                className={`p-2 text-dark  ${idx % 2 && "evenNumberedQual"}`}
              >
                {qualification}
              </Qualification>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-left: 1rem;
  min-height: 300px;
  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 1rem;
  }
  .evenNumberedQual {
    background: #fcfcfc;
  }
`;

const Qualification = styled.p`
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  font-weight: 600;
  border-left: 5px solid #3e3e49;
`;
