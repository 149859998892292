import React from "react";

import moment from "moment";
import { Button } from "reactstrap";

export default function SingleNote({ note, handleDeleteNote, authorId }) {
  return (
    <div
      style={{ borderLeft: "5px solid black" }}
      className="w-100 p-2 bg-lighter mb-2"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="m-0 p-0 small" style={{ fontWeight: "600" }}>
          {note.firstName} {note.lastName}
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          {note.authorId === authorId ? (
            <Button
              onClick={() => {
                handleDeleteNote(note.id);
              }}
              color="danger"
              size="sm"
            >
              <i className="fa fa-trash"></i>
            </Button>
          ) : (
            <></>
          )}
          <p className="m-0 p-0 small">
            {moment(note.dateInserted).calendar()}
          </p>
        </div>
      </div>
      <p className="lead m-0 p-0">{note.content}</p>
    </div>
  );
}
