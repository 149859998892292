import React, { useEffect, useState } from "react";

import { fetchCompanyUsers } from "store/reducers/company.js";
import { withRouter } from "react-router-dom";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function SubcontractorEdit({
  fetchCompanyUsers,
  jobCrewTableId,
  employees,
  companyId,
  history,
  job,
}) {
  const { isAcceptedBySub, contractorCompanyName } = job;
  const [selectedEmployee, setSelectedEmployee] = useState(0);

  const accepteDecline = (didAccept) => {
    axios
      .post("/api/jobs/update/acceptDecline", {
        didAccept,
        jobCrewTableId: job.jobCrewTableId,
      })
      .then(() => {
        history.push("/app/jobs");
        Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: `We'll let the contractor know!`,
        });
      })
      .catch(() =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an error updating this job. Please try again in a moment.",
        })
      );
  };

  useEffect(() => {
    companyId && !employees.length && fetchCompanyUsers(companyId);
    // eslint-disable-next-line
  }, [employees, companyId]);

  const handleAssign = () => {
    axios
      .post("/api/jobs/update/assign/user", {
        assignedToUserId: selectedEmployee || null,
        jobCrewTableId,
      })
      .then(() => {
        history.push("/app/jobs");
        Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: `We've changed this jobs assignment.`,
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an issue making your assignment. Please try again in a moment.",
        });
      });
  };

  return (
    <div className="p-2">
      {isAcceptedBySub ? (
        <div>
          <label
            style={{ display: "block" }}
            className="text-dark font-weight-bold mb-2 mt-2"
          >
            Assigned to
          </label>
          <select
            onChange={(e) => {
              setSelectedEmployee(parseInt(e.target.value));
            }}
            value={selectedEmployee}
            className="w-100 mb-4"
          >
            <option value={0}></option>
            {employees.map((emp) => {
              return (
                <option value={emp.id} key={emp.id}>
                  {emp.firstName} {emp.lastName} - {emp.position}
                </option>
              );
            })}
          </select>
          <Button
            onClick={handleAssign}
            className="w-100 shadow-none"
            color="default"
          >
            Assign
          </Button>
        </div>
      ) : (
        <div>
          <p>{contractorCompanyName} has asked you to work on this job</p>
          <div style={{ display: "flex" }}>
            <Button
              onClick={() => accepteDecline(true)}
              className="w-50"
              color="default"
            >
              accept
            </Button>
            <Button
              onClick={() => accepteDecline(false)}
              className="w-50 shadow-none"
              color="white"
            >
              decline
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  employees: state.company.employees,
  companyId: state.auth.user.companyId,
});

export default connect(mapStateToProps, { fetchCompanyUsers })(
  withRouter(SubcontractorEdit)
);
