import React, { useState, useEffect } from "react";

import ViewSingleCrewDetails from "./ViewSingleCrewDetails";
import { Cover, InnerWrapper } from "../StyledComponents";
import CreateCustomCrew from "./CreateCustomCrew";
import { useHistory } from "react-router-dom";
import EditCrewMember from "./EditCrewMember";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function CustomCrews({ companyId, userType }) {
  const history = useHistory();

  const [selectedCrew, setSelectedCrew] = useState({ crewMembers: [] });
  const [selectedCrewMember, setSelectedCrewMember] = useState({});
  const [isCreatingCrewMember, setIsCreatingCrewMember] = useState(false);
  const [isCreatingCrew, setIsCreatingCrew] = useState(false);
  const [isViewingCrew, setIsViewingCrew] = useState(false);
  const [crews, setCrews] = useState([]);
  const [isEditingCrewMember, setIsEditingCrewMember] = useState(false);

  useEffect(() => {
    companyId && fetchCrews();
  }, [companyId]);

  const addCustomCrew = async (name) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can perform this action");
      }
      const {
        data: { crew },
      } = await axios.post("/api/custom_crews/create", { name });
      setIsCreatingCrew(false);
      setCrews((prevState) => [...prevState, { ...crew, crewMembers: [] }]);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to create your crew.",
      });
    }
  };

  const fetchCrews = async () => {
    try {
      const {
        data: { crews },
      } = await axios.get("/api/custom_crews/all");
      setCrews(crews);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to fetch your custom crews.",
      });
    }
  };

  const handleSelectCrew = (crew) => {
    setSelectedCrew(crew);
    setIsViewingCrew(true);
  };

  const handleDeleteCrew = async (crewId) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can perform this action");
      }
      await axios.delete(`/api/custom_crews/${crewId}`);
      setIsViewingCrew(false);
      setCrews((prevState) => prevState.filter((crew) => crew.id !== crewId));
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to delete this custom crews.",
      });
    }
  };

  const handleAddCrewMember = async (details) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can perform this action");
      }
      const {
        data: { crewMember },
      } = await axios.post("/api/custom_crews/create_crew_member", details);
      setIsCreatingCrewMember(false);
      setCrews((prevState) =>
        prevState.map((crew) =>
          crew.id !== details.crewId
            ? crew
            : { ...crew, crewMembers: [...crew.crewMembers, crewMember] }
        )
      );
      setSelectedCrew((prevState) => ({
        ...prevState,
        crewMembers: [...prevState.crewMembers, crewMember],
      }));
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to create this crew member.",
      });
    }
  };

  const handleUpdateCrewMember = async (details) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can perform this action");
      }
      await axios.put("/api/custom_crews/update_crew_member", details);
      setIsEditingCrewMember(false);
      setSelectedCrewMember({});
      setCrews((prevState) =>
        prevState.map((crew) =>
          crew.id !== details.crewId
            ? crew
            : {
                ...crew,
                crewMembers: crew.crewMembers.map((member) => {
                  return member.id !== details.id ? member : details;
                }),
              }
        )
      );
      setSelectedCrew((prevState) => ({
        ...prevState,
        crewMembers: prevState.crewMembers.map((member) =>
          member.id !== details.id ? member : details
        ),
      }));
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to update this crew member.",
      });
    }
  };

  const handleDeleteCrewMember = async (crewMemberId, crewId) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can perform this action");
      }
      await axios.delete(`/api/custom_crews/crew_members/${crewMemberId}`);
      setCrews((prevState) =>
        prevState.map((crew) =>
          crew.id !== crewId
            ? crew
            : {
                ...crew,
                crewMembers: crew.crewMembers.filter(
                  (member) => member.id !== crewMemberId
                ),
              }
        )
      );
      setSelectedCrew((prevState) => ({
        ...prevState,
        crewMembers: prevState.crewMembers.filter(
          (member) => member.id !== crewMemberId
        ),
      }));
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error while trying to create this crew member.",
      });
    }
  };

  return (
    <Cover
      onClick={() => {
        history.push("/app/jobs");
      }}
    >
      <EditCrewMember
        isAdmin={userType === Constants.ADMIN}
        handleUpdateCrewMember={handleUpdateCrewMember}
        setIsEditingCrewMember={setIsEditingCrewMember}
        isEditingCrewMember={isEditingCrewMember}
        selectedCrewMember={selectedCrewMember}
      />
      <ViewSingleCrewDetails
        isAdmin={userType === Constants.ADMIN}
        setIsCreatingCrewMember={setIsCreatingCrewMember}
        handleDeleteCrewMember={handleDeleteCrewMember}
        setIsEditingCrewMember={setIsEditingCrewMember}
        setSelectedCrewMember={setSelectedCrewMember}
        isCreatingCrewMember={isCreatingCrewMember}
        handleAddCrewMember={handleAddCrewMember}
        setIsViewingCrew={setIsViewingCrew}
        handleDeleteCrew={handleDeleteCrew}
        isViewingCrew={isViewingCrew}
        crew={selectedCrew}
      />
      <InnerWrapper
        style={{ height: "85vh" }}
        onClick={(e) => e.stopPropagation()}
        className="rounded p-4 bg-white"
      >
        <div
          className="mb-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className="display-4 text-darker m-0 mb-0">Custom Crews</p>
          <div
            className="mb-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <HelpModal modalTitle="Custom Crews Help">
              <p className="text-dark m-0">
                There are two type of "crews" to know about:
              </p>
              <ul>
                <li className="text-dark">Custom Crews</li>
                <li className="text-dark">Connected Crews</li>
              </ul>
              <p className="text-dark m-0">
                <span style={{ fontWeight: 600 }}>Custom crews</span> are crews
                which you've set up manually here. These "crews" are not linked
                to actual Crewly accounts. They can be assigned to jobs and
                reported on in the same way that "connected crews" can.
              </p>
              <p className="text-dark m-0 mt-3">
                <span style={{ fontWeight: 600 }}>Connected crews</span> refers
                to subcontractor accounts set up through Crewly. These are
                companies with which you've connected with via the "connections"
                tab in the navigation bar. Assigning jobs to these types of
                crews will place the job on their calendar and allow them to
                view relevant information such as location, work orders, tags
                and more (don't worry, we don't show them private information
                such as purchase orders and private customer information). It
                should also be noted that these crews can NOT edit job details,
                simply view them and add notes etc.
              </p>
            </HelpModal>
            {userType === Constants.ADMIN && (
              <Button
                className="btn-icon ml-2 shadow-none p-1"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => setIsCreatingCrew((prevState) => !prevState)}
                color="default"
                size="sm"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-plus" />
                </span>
                <span className="btn-inner--text">New Custom Crew</span>
              </Button>
            )}
          </div>
        </div>
        {isCreatingCrew && <CreateCustomCrew addCustomCrew={addCustomCrew} />}
        <p className="text-darker m-0 mb-2" style={{ fontSize: "0.8rem" }}>
          "Custom Crews" can be created by you and assigned to your jobs. These
          crews are only for your convenience and tracking purposes. If you'd
          like your crews to be able to see their jobs on Crewly, you can have
          them sign up for "subcontractor" accounts, then connect with them via
          the connections tab.
        </p>
        <table className="table bg-white">
          <thead className="bg-white">
            <tr style={{ borderTop: "hidden" }}>
              <th className="text-darker" scope="col">
                Name
              </th>
              <th className="text-darker" scope="col">
                Crew Count
              </th>
            </tr>
          </thead>
          <tbody>
            {crews.map((crew) => {
              const { id, name, crewMembers } = crew;
              return (
                <TableRow key={id} onClick={() => handleSelectCrew(crew)}>
                  <th
                    scope="row"
                    className="text-dark"
                    style={{ fontWeight: 400 }}
                  >
                    {name}
                  </th>
                  <th
                    scope="row"
                    className="text-dark"
                    style={{ fontWeight: 400 }}
                  >
                    {crewMembers.length}
                  </th>
                </TableRow>
              );
            })}
          </tbody>
        </table>
        {crews.length ? (
          <></>
        ) : (
          <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {})(CustomCrews);

const TableRow = styled.tr`
  cursor: pointer;
  :hover {
    background: #f3f3f3;
  }
`;
