import React, { useState } from "react";

import styled from "styled-components";
import Feedback from "./Feedback";
import Polls from "./Polls";
import News from "./News";

export default function PollsAndNews() {
  const [selectedTab, setSelectedTab] = useState("news");
  return (
    <div className="p-2">
      <Wrapper className="p-2">
        <TabSectionWrapper className="p-2 rounded bg-white shadow">
          <TabsWrapper>
            <Tab
              background={selectedTab === "news" ? "#275AE4" : "white"}
              color={selectedTab === "news" ? "white" : "black"}
              onClick={() => setSelectedTab("news")}
              className="p-2"
            >
              news
            </Tab>
            <Tab
              background={selectedTab === "polls" ? "#275AE4" : "white"}
              color={selectedTab === "polls" ? "white" : "black"}
              onClick={() => setSelectedTab("polls")}
              className="p-2"
            >
              polls
            </Tab>
            <Tab
              background={selectedTab === "feedback" ? "#275AE4" : "white"}
              color={selectedTab === "feedback" ? "white" : "black"}
              onClick={() => setSelectedTab("feedback")}
              className="p-2"
            >
              feedback
            </Tab>
          </TabsWrapper>
          <TabContentWrapper className="p-2 text-darker">
            {selectedTab === "news" && <News />}
            {selectedTab === "polls" && <Polls />}
            {selectedTab === "feedback" && <Feedback />}
          </TabContentWrapper>
        </TabSectionWrapper>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  max-width: 1300px;
  margin: auto;
`;

const TabSectionWrapper = styled.div`
  width: 100%;
`;
const TabsWrapper = styled.div`
  display: flex;
`;

const Tab = styled.div`
  width: 120px;
  font-weight: 600;
  text-align: center;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  margin: 5px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    background: ${(props) =>
      props.background === "white" ? "#EFF0F4" : props.background};
  }
`;

const TabContentWrapper = styled.div``;
