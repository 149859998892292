import React, { useState } from "react";

import { Input, Button } from "reactstrap";

export default function CreateProductForm({ addProduct, categoryId }) {
  const [name, setName] = useState("");
  const [unitCost, setUnitCost] = useState(0);
  return (
    <form
      className="mt-0 mb-2 rounded-bottom bg-lighter p-2"
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        addProduct(name, unitCost, categoryId);
        setUnitCost(0);
        setName("");
      }}
    >
      <div className="w-25 mr-2">
        <label className="text-dark font-weight-bold mb-0">*product name</label>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          type="text"
          className="w-100 text-dark"
          placeholder='(e.x. "OC Estate Gray")'
        />
      </div>
      <div className="w-25 mr-2">
        <label className="text-dark font-weight-bold mb-0">*unit cost</label>
        <Input
          value={unitCost}
          onChange={(e) => setUnitCost(e.target.value)}
          required
          type="text"
          className="w-100 text-dark"
          placeholder="$ unit cost"
        />
      </div>
      <Button color="default" className="" type="submit">
        <i className="fa fa-plus"></i> <span>add</span>
      </Button>
    </form>
  );
}
