import styled from "styled-components";

export const ScreenCover = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000000000;
  background: rgba(0, 0, 0, 0.8);
`;
