import React, { useRef, useEffect, useCallback } from "react";

import EmojiMenu from "./EmojiMenu.js";
import SelectUsers from "./SelectUsers.js";
import useMenuState from "./useMenuState.js";

import { BottomButtons } from "./styled.js";

function ChatButtons({
  attachments,
  dispatch,
  handleEmojiChange,
  addTag,
  userlist,
}) {
  const input = useRef();
  const [showEmojiMenu, closeEmojiMenu, toggleEmojiMenu] = useMenuState();
  const [showTagUserMenu, closeTagUserMenu, toggleTagUserMenu] = useMenuState();

  const handleEmojiClick = useCallback(
    ({ target: { value } }) => handleEmojiChange(value.native),
    [handleEmojiChange]
  );

  useEffect(() => {
    // Force input and attachments to be in sync
    if (!attachments.length && input.current) {
      input.current.value = null;
    } else if (input.current) {
      const dt = new DataTransfer();
      attachments.forEach(({ file }) => {
        dt.items.add(file);
      });
      input.current.files = dt.files;
    }
  }, [attachments]);

  const selectImages = (e) => {
    // Files were selected for upload
    const { files } = e.target;

    Array.from(files).forEach((f) => {
      // Loop over files and see if a file with that name already has been selected
      const isUniqueFile = !attachments.find(findMatchingFile(f));
      if (isUniqueFile) {
        dispatch({ type: "ADD_FILE", payload: f });
      } else {
        console.log("That file is already uploaded: ", f);
      }
    });
  };

  return (
    <BottomButtons>
      <EmojiMenu
        show={showEmojiMenu}
        onClick={handleEmojiClick}
        close={closeEmojiMenu}
      />
      <SelectUsers
        close={closeTagUserMenu}
        isOpen={showTagUserMenu}
        onClick={addTag}
        userlist={userlist}
      />
      <span className="btn-inner--icon" onClick={toggleEmojiMenu}>
        <i className="fa fa-smile-o fa-lg" />
      </span>
      <label className="btn-inner--icon ml-2">
        <i className="fa fa-file-image-o fa-lg" />
        <input
          ref={input}
          type="file"
          multiple
          style={{ display: "none" }}
          accept="image/png, image/jpeg, image/gif"
          onChange={selectImages}
        />
      </label>
      <span className="btn-inner--icon ml-2" onClick={toggleTagUserMenu}>
        <i className="fa fa-tag fa-lg" />
      </span>
      <span className="btn-inner--icon ml-2">
        <i className="fa fa-plus fa-lg" />
      </span>
    </BottomButtons>
  );
}

const findMatchingFile = (f) => ({ file }) => file.name === f.name;

export default ChatButtons;
