export default ((cache) => (arr) => {
  const cached = cache.get(arr);
  if (cached) {
    return cached;
  }

  cache.set(
    arr,
    arr.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {})
  );

  return cache.get(arr);
})(new Map());
