import React from "react";

import { Container, Button } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

const FinishAccountConfig = ({
  handleSetStep,
  handleCreateAccountAndCompany,
}) => {
  return (
    <FormWrapper>
      <Container className="container-sm p-3 mb-5">
        <p className="display-4 font-weight-light text-darker p-0 m-0 text-left">
          Perfect!
        </p>
        <p className="text-dark lead m-0 p-0 text-left">
          You're all set! Press {`"Finish"`} to complete your account sign up!
        </p>
        <p
          onClick={() => {
            handleSetStep(3);
          }}
          style={{ cursor: "pointer" }}
          className="text-default text-underline m-0 p-0 text-left"
        >
          go back a step
        </p>
        <hr className="mt-3" />
        <Button
          onClick={() => {
            handleSetStep(5);
            handleCreateAccountAndCompany();
          }}
          color="default"
          block
          type="button"
        >
          Finish
        </Button>
      </Container>
    </FormWrapper>
  );
};

export default FinishAccountConfig;

FinishAccountConfig.propTypes = {
  handleSetStep: PropTypes.func.isRequired,
  handleCreateAccountAndCompany: PropTypes.func.isRequired,
};

const FormWrapper = styled.div`
  max-width: 550px;
  margin: auto;
`;
