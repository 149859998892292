import React from "react";

import styled from "styled-components";

const EXAMPLE_POSTS = [
  {
    heading: "example 1",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    date: "10/10/2020",
  },
  {
    heading: "example 2",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    date: "10/8/2020",
  },
];

export default function News() {
  return (
    <div>
      <p className="h1 text-darker m-0 ml-2" style={{ fontWeight: 600 }}>
        Latest in Crewly
      </p>
      {EXAMPLE_POSTS.map((post, idx) => {
        return (
          <div className={`p-2 ${idx % 2 ? "bg-secondary" : "bg-white"}`}>
            <PostHeading className="h2 text-default m-0">
              {post.heading}
            </PostHeading>
            <p className="text-dark m-0">{post.content}</p>
          </div>
        );
      })}
    </div>
  );
}

const PostHeading = styled.p`
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
