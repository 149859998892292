import { useEffect } from "react";
import { connect } from "react-redux";

import { fetchAppointments } from "store/reducers/appointments.js";

function FetchAppointments({ fetchAppointments, companyId }) {
  useEffect(() => {
    companyId && fetchAppointments(companyId);
    // eslint-disable-next-line
  }, [companyId]);
  return null;
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
});

const mapDispatchToProps = { fetchAppointments };

export default connect(mapStateToProps, mapDispatchToProps)(FetchAppointments);
