import React from "react";

import { Container } from "reactstrap";

import styled from "styled-components";

const TranslationInfo = () => {
  return (
    <Container
      className="p-0 pt-4 pb-9 container-fluid bg-secondary"
      style={{ position: "relative" }}
      fluid
    >
      <Container className="container-md pt-4">
        <InfoWrapper>
          <p className="lead text-gray mb-0">Whatever it takes.</p>
          <p
            style={{ position: "relative", zIndex: 1 }}
            className="display-2 text-dark"
          >
            We'll make life{" "}
            <span className="text-default" style={{ fontWeight: "600" }}>
              easier.
            </span>{" "}
          </p>
        </InfoWrapper>
      </Container>
      <InfoPannel className="bg-white p-5 shadow">
        <p
          style={{ textAlign: "left", maxWidth: "600px" }}
          className="text-dark display-3"
        >
          Crewly helps bridge language barriers.
        </p>
        <p
          style={{ textAlign: "left", fontWeight: 600 }}
          className="text-dark p-0 m-0"
        >
          We recognize that the construction industry is filled with people from
          all walks of life. That's why we've added real time chat and listing
          translations so that everyone is on the same page from day one.
        </p>
        <CTAButton>Try it Out</CTAButton>
      </InfoPannel>
    </Container>
  );
};

export default TranslationInfo;

const InfoWrapper = styled.div`
  width: 50%;
  text-align: left;
  @media (max-width: 800px) {
    width: 90%;
    margin: auto;
  }
`;

const InfoPannel = styled.div`
  width: 40%;
  max-width: 400px;
  min-width: 350px;
  position: absolute;
  right: 0;
  top: 50px;
  border-top: 5px solid #829ff2;
  text-align: left;
  @media (max-width: 800px) {
    position: relative;
    top: 25px;
    width: 90%;
    max-width: 90%;
    margin: auto;
  }
`;

const CTAButton = styled.button`
  margin: 25px 0;
  padding: 10px 15px;
  transition: 0.2s;
  font-weight: 800;
  color: white;
  background: #275ae4;
  border: none;
  :hover {
    background: #0042ea;
  }
`;
