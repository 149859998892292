import React from "react";

import { useHistory } from "react-router-dom";
import SelectVendor from "./SelectVendor";
import Constants from "utils/Constants";
import styled from "styled-components";
import { Button } from "reactstrap";
import currency from "currency.js";

export default function PurchaseOrderTable({
  purchaseOrder,
  permissionScope,
  setPurchaseOrders,
  deletePurchaseOrder,
  setIsManagingVendors,
  setIsAssigningProducts,
  setSelectedPurchaseOrder,
}) {
  const history = useHistory();
  return (
    <div>
      <SelectVendor
        setIsManagingVendors={setIsManagingVendors}
        setPurchaseOrders={setPurchaseOrders}
        purchaseOrderId={purchaseOrder.id}
        permissionScope={permissionScope}
        vendorId={purchaseOrder.vendorId}
      />
      <TitlesWrapper className="p-2 border-bottom border-light ">
        <p className="m-0 p-0" style={{ width: "45%" }}>
          Item Name
        </p>
        <p className="m-0 p-0" style={{ width: "10%" }}>
          Qty.
        </p>
        <p className="m-0 p-0" style={{ width: "20%" }}>
          Unit Cost
        </p>

        <p className="m-0 p-0" style={{ width: "25%" }}>
          Total
        </p>
      </TitlesWrapper>
      <div className="w-100">
        {!purchaseOrder?.items?.length && (
          <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
        {purchaseOrder?.items?.map((item, idx) => {
          const { id, quantity, unitCost, productName } = item;
          return (
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                backgroundColor: idx % 2 ? "#fcfcfc" : "white",
              }}
              className="p-1 rounded"
            >
              <div style={{ width: "45%" }}>
                <p className="m-0 p-0">{productName}</p>
              </div>
              <p className="m-0 p-0" style={{ width: "10%" }}>
                {quantity}
              </p>
              <p className="m-0 p-0" style={{ width: "20%" }}>
                {currency(unitCost, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
              <p className="m-0 p-0">
                {currency(unitCost * quantity, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
            </div>
          );
        })}
        <div
          className="mt-2"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {permissionScope !== Constants.ADMIN_CONTRACTOR || (
            <>
              <Button
                onClick={() => {
                  setSelectedPurchaseOrder(purchaseOrder);
                  setIsAssigningProducts(true);
                }}
                color="default"
              >
                <i className="fa fa-plus"></i> <span>add/remove</span>
              </Button>
              <Button
                onClick={() => deletePurchaseOrder(purchaseOrder.id)}
                color="danger"
              >
                <i className="fa fa-trash"></i> <span>delete</span>
              </Button>
            </>
          )}
          <Button
            onClick={() =>
              history.push(`/app/purchase_order/${purchaseOrder.id}`)
            }
            className="shadow-none"
            color="secondary"
          >
            <i className="fa fa-share-square"></i> <span>send/print</span>
          </Button>
        </div>
      </div>
      <div style={{ display: "flex" }} className="mt-2">
        <div style={{ width: "45%" }}></div>
        <div style={{ width: "10%" }}></div>
        <p className="m-0 p-0" style={{ width: "20%", fontWeight: "600" }}>
          Total
        </p>

        <p
          className="m-0 p-0 text-success"
          style={{ width: "25%", fontWeight: "600" }}
        >
          {currency(
            purchaseOrder?.items?.reduce(
              (acc, cv) => (acc += cv.unitCost * cv.quantity),
              0.0
            ),
            { symbol: "$", precision: 2 }
          ).format()}
        </p>
      </div>
    </div>
  );
}

const TitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  background: white;
  p {
    font-weight: 600;
  }
`;
