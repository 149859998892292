import React, { useState } from "react";

import { Button, Modal } from "reactstrap";

export default function HelpModal({
  children,
  color,
  shouldShowHelpText,
  modalTitle,
  helpText,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button
        onClick={() => setIsModalOpen((prevState) => !prevState)}
        className="btn-icon btn-3"
        size="sm"
        color={color || "info"}
        type="button"
      >
        <span className="btn-inner--icon">
          <i className="fa fa-question-circle fa-2x" />
        </span>
        {shouldShowHelpText && (
          <span className="btn-inner--text">{helpText || "help"}</span>
        )}
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title text-dark" id="exampleModalLabel">
            {modalTitle}
          </h4>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsModalOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <Button
            color="default"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
