import React, { useState } from "react";

import UploadedImage from "../../../utils/UploadedImage";
import ShowMoreButton from "../shared/ShowMoreButton";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  HeadTopLevel,
  Banner,
  ImageWrapper,
} from "../companytab/StyledComponents";

export default function CompanyInfo({
  shouldShowPendingMessage,
  handleOpenConnectWindow,
  connectDisabled,
  isOwnCompany,
  company,
}) {
  const [substringMax, setSubstringMax] = useState(100);
  const [isShowing, setIsShowingFullString] = useState(false);
  const handleShowMoreClick = () => {
    setSubstringMax(substringMax === 100 ? 500 : 100);
    setIsShowingFullString(!isShowing);
  };

  const isLoading = !company.name;
  return (
    <CompanyCardWrapper className="bg-white border shadow-sm mb-3 mt-0 pb-4">
      <Banner className="bg-darker" />
      <HeadTopLevel className="pt-0 pl-4 pr-4 pb-0">
        <div>
          <ImageWrapper className="rounded border border-light shadow-sm">
            <UploadedImage
              src={company.logoUrl}
              alt="Company Logo"
              style={{
                width: "100%",
                height: "auto",
              }}
              background="white"
              width="125"
              height="125"
              display="fill"
            />
          </ImageWrapper>
          {isOwnCompany || company.connectionAccepted || (
            <Button
              onClick={() => {
                handleOpenConnectWindow();
              }}
              disabled={isLoading || connectDisabled}
              style={{ width: "125px" }}
              className="m-0 mt-2 img-box-size"
              size="sm"
              color="default"
            >
              {isLoading
                ? "Loading..."
                : shouldShowPendingMessage
                ? "Pending"
                : "Connect"}
            </Button>
          )}
        </div>

        <p
          className="pt-2 ml-2 text-dark company-name"
          style={{
            textAlign: "start",
            lineHeight: "35px",
            fontWeight: "600",
            fontSize: "28px",
            margin: 0,
          }}
        >
          {company.name}
        </p>
      </HeadTopLevel>
      <div className="pl-4">
        <p
          className="mt-2 mb-0 text-dark statistic"
          style={{ textAlign: "start" }}
        >
          <span className="btn-inner--icon mr-2">
            <i className="fa fa-map-marker fa-lg" />
          </span>
          <span style={{ fontWeight: "400" }}>
            {company.location || "none configured"}
          </span>
        </p>
        <p
          className="mt-1 mb-0 text-dark statistic"
          style={{ textAlign: "start" }}
        >
          <span className="btn-inner--icon mr-2">
            <i className="fa fa-user fa-lg" />
          </span>
          <span style={{ fontWeight: "400" }}>
            {company.companyType || "none configured"}
          </span>
        </p>
      </div>
      <div className="pl-4">
        <p className="mt-2 mb-0" style={{ textAlign: "start" }}>
          <span style={{ fontWeight: "600" }}>description: </span>
        </p>
        <div
          className="mt-0"
          style={{ textAlign: "start", position: "relative" }}
        >
          <>
            {company?.description
              ?.substring(0, substringMax)
              ?.split("\n")
              ?.map((s) => (
                <p key={s} className="mb-0">
                  {s}
                </p>
              )) || <p>none configured</p>}
          </>
          {company?.description?.length > 100 && (
            <ShowMoreButton
              isShowing={isShowing}
              handleShowMoreClick={handleShowMoreClick}
            />
          )}
        </div>
      </div>
    </CompanyCardWrapper>
  );
}

const CompanyCardWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0px;
`;
