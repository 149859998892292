import React, { useEffect, useCallback, useState } from "react";

import { fetchCompanyUsers, removeEmployee } from "store/reducers/company.js";
import usePermissionScopes from "utils/usePermissionScopes";
import InviteToCompany from "./InviteToCompany";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import axios from "axios";

export function CompanyEmployeesList({
  fetchCompanyUsers,
  removeEmployee,
  companyType,
  companyId,
  employees,
  userType,
}) {
  const [shouldShowInvite, setShouldShowInvite] = useState(false);
  const permissionScope = usePermissionScopes(companyType, userType);

  const fetchUsersCallback = useCallback(() => {
    if (companyId) fetchCompanyUsers(companyId);
  }, [companyId, fetchCompanyUsers]);

  useEffect(() => {
    fetchUsersCallback();
  }, [fetchUsersCallback]);

  const handleDeleteUser = async (id, name) => {
    const confirmed = await Swal.fire({
      title: "Are you sure you want to remove this user?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F3123E",
      cancelButtonColor: "#08080B",
      confirmButtonText: `Yes, remove ${name}!`,
    });
    if (confirmed.value) {
      try {
        await axios.post("/api/company/removeUser", { userId: id });
        removeEmployee(id);
      } catch (err) {
        return Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <Wrapper className="mt-2 mb-2">
      {[Constants.ADMIN_CONTRACTOR, Constants.ADMIN_SUBCONTRACTOR].includes(
        permissionScope
      ) && (
        <Button
          size="md"
          data-testid="openInviteUsersButton"
          onClick={() => {
            setShouldShowInvite(!shouldShowInvite);
          }}
          className="btn-icon btn-2 mt-2 mb-2"
          color="default"
          type="button"
        >
          <span className="btn-inner--icon">
            <i className="fa fa-plus fa-lg" />
          </span>
        </Button>
      )}
      {[Constants.ADMIN_CONTRACTOR, Constants.ADMIN_SUBCONTRACTOR].includes(
        permissionScope
      ) &&
        shouldShowInvite && <InviteToCompany />}
      <TableWrapper className="rounded shadow-sm">
        <table style={{ marginBottom: 0 }} className="table bg-white">
          <thead>
            <tr>
              <th className="text-dark" scope="col">
                name
              </th>
              <th className="text-dark" scope="col">
                position
              </th>
              <th className="text-dark" scope="col">
                user type
              </th>
              {[
                Constants.ADMIN_CONTRACTOR,
                Constants.ADMIN_SUBCONTRACTOR,
              ].includes(permissionScope) && (
                <th className="text-gray" scope="col"></th>
              )}
            </tr>
          </thead>
          <tbody>
            {employees.map((emp) => {
              return (
                <tr key={emp.id}>
                  <th
                    style={{ fontWeight: "400" }}
                    scope="row"
                    className="text-default"
                  >
                    {emp.firstName} {emp.lastName}
                  </th>
                  <th style={{ fontWeight: "400" }} scope="row">
                    {emp.position}
                  </th>
                  <th style={{ fontWeight: "400" }} scope="row">
                    {emp.userType}
                  </th>
                  {[
                    Constants.ADMIN_CONTRACTOR,
                    Constants.ADMIN_SUBCONTRACTOR,
                  ].includes(permissionScope) && (
                    <th scope="row">
                      <Button
                        onClick={() => {
                          handleDeleteUser(
                            emp.id,
                            emp.firstName + " " + emp.lastName
                          );
                        }}
                        size="sm"
                        color="danger"
                      >
                        x
                      </Button>
                    </th>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.user.companyId,
    employees: state.company.employees,
    companyType: state.company.company.companyType,
    userType: state.auth.user.userType,
  };
};

export default connect(mapStateToProps, { fetchCompanyUsers, removeEmployee })(
  CompanyEmployeesList
);

CompanyEmployeesList.propTypes = {
  fetchCompanyUsers: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  companyId: PropTypes.number,
};

CompanyEmployeesList.defaultProps = {
  companyId: 0,
};

const TableWrapper = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  width: 95%;
  margin: auto;
`;
