import React, { useState } from "react";

import { Input, Button } from "reactstrap";

export default function CreateCrewMember({ crewId, handleAddCrewMember }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleAddCrewMember({ name, email, phone, crewId });
      }}
    >
      <Input
        required
        onChange={(e) => setName(e.target.value)}
        placeholder="*name"
        className="text-dark mt-2"
        value={name}
      />
      <Input
        onChange={(e) => setEmail(e.target.value)}
        placeholder="email"
        className="text-dark mt-2"
        value={email}
      />
      <Input
        onChange={(e) => setPhone(e.target.value)}
        placeholder="phone"
        className="text-dark mt-2"
        value={phone}
      />
      <Button type="submit" color="default" className="mt-2">
        submit
      </Button>
    </form>
  );
}
