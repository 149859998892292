import React from "react";

import { Container, Card, Button, CardTitle, CardText } from "reactstrap";
import Constants from "../../../utils/Constants";
import styled from "styled-components";
import PropTypes from "prop-types";

const ChooseAccountType = ({ handleSetStep, setFormState }) => {
  return (
    <FormWrapper>
      <Container className="container-sm p-3 mb-5">
        <p className="display-4 font-weight-light text-darker p-0 m-0 text-left">
          Great!
        </p>
        <p className="text-dark lead m-0 p-0 text-left">
          What type of account are you looking for?
        </p>
        <p
          onClick={() => {
            handleSetStep(1);
          }}
          style={{ cursor: "pointer" }}
          className="text-default text-underline m-0 p-0 text-left"
        >
          go back a step
        </p>
        <hr className="mt-3" />
        <Card body className="bg-dark">
          <CardTitle className="display-3 mb-2">Subcontractor</CardTitle>
          <CardText>
            View schedules, take pictures of your work, communicate, and{" "}
            <span style={{ fontWeight: "600" }}>get paid.</span>
          </CardText>
          <Button
            onClick={() => {
              handleSetStep(3);
              setFormState((prevState) => {
                return {
                  ...prevState,
                  account_type: Constants.SUBCONTRACTOR,
                };
              });
            }}
          >
            choose "subcontractor"
          </Button>
        </Card>
        <Card body className="bg-default mt-2">
          <CardTitle className="display-3 mb-2">Contractor</CardTitle>
          <CardText>
            Track work across all of your crews, send work and purchase orders,
            schedule jobs and appointments, receive reporting and{" "}
            <span style={{ fontWeight: "600" }}>save time.</span>
          </CardText>
          <Button
            onClick={() => {
              handleSetStep(3);
              setFormState((prevState) => {
                return {
                  ...prevState,
                  account_type: Constants.CONTRACTOR,
                };
              });
            }}
          >
            choose "contractor"
          </Button>
        </Card>
      </Container>
    </FormWrapper>
  );
};

export default ChooseAccountType;

ChooseAccountType.propTypes = {
  handleSetStep: PropTypes.func,
  formState: PropTypes.object,
};

const FormWrapper = styled.div`
  margin: auto;
`;
