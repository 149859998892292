import styled from "styled-components";

export const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  cursor: pointer;
`;

export const InnerWrapper = styled.div`
  width: 95%;
  max-width: 900px;
  max-height: 85vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 55px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;
