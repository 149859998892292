import React, { useState, useEffect } from "react";

import usePermissionScopes from "utils/usePermissionScopes";
import { addTag, deleteTag } from "store/reducers/jobs";
import { Input, Button } from "reactstrap";
import HelpModal from "utils/HelpModal";
import Constants from "utils/Constants";
import { connect } from "react-redux";

function Tags({ tags, companyType, userType, addTag, jobId, deleteTag }) {
  const permissionScope = usePermissionScopes(companyType, userType);

  const [isShowing, setIsShowing] = useState(false);
  const [content, setContent] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  // ---- only reason im doing this is because where this component is being used in
  // "JobEditPopup", its passing in tags that are filtered from all jobs and not directly
  // tied to redux store. This way we can just update local tags on creation and deletion
  // to update the UI as opposed to relying on app state.
  const [localTags, setLocalTags] = useState([]);
  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  return (
    <div className="mb-2 p-2 bg-lighter rounded">
      {localTags.map(({ content, id }) => {
        return (
          <div
            key={id}
            style={{ fontSize: "10px" }}
            className="badge badge-light mr-1 mb-1"
          >
            {content}{" "}
            {permissionScope === Constants.ADMIN_CONTRACTOR && (
              <span
                onClick={() =>
                  deleteTag({
                    jobId,
                    tagId: id,
                  }).then(() => {
                    setLocalTags((prevState) =>
                      prevState.filter((t) => t.id !== id)
                    );
                  })
                }
                className=" ml-1 border-left border-light"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                x
              </span>
            )}
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {permissionScope === Constants.ADMIN_CONTRACTOR && (
          <span
            onClick={() => setIsShowing((prevState) => !prevState)}
            style={{
              display: "block",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "12px",
              textAlign: "center",
            }}
            className="text-default m-0 mr-2"
          >
            {isShowing ? "-" : "+"} add tag
          </span>
        )}
        <HelpModal modalTitle="Tags Help">
          <p className="text-dark m-0">
            Tags are simply a way to track simple information about a job. Some
            use cases may be for instance to track the slope of a roof or color
            of a shingle.
          </p>
        </HelpModal>
      </div>
      {isShowing && permissionScope === Constants.ADMIN_CONTRACTOR && (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              setIsLoading(true);
              const id = await addTag({
                content,
                jobId,
              });
              setIsShowing(false);
              setIsLoading(false);
              setContent("");
              setLocalTags([
                ...localTags,
                {
                  content,
                  jobId,
                  id,
                },
              ]);
            } catch (err) {}
          }}
          className="mt-2"
          style={{ display: "flex" }}
        >
          <Input
            className="text-dark w-75"
            maxLength="30"
            type="text"
            required
            style={{
              padding: "0px 4px",
              fontSize: "12px",
            }}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Button
            disabled={isLoading}
            className="ml-2 w-25 shadow-none"
            color="default"
            type="submit"
          >
            +
          </Button>
        </form>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, { addTag, deleteTag })(Tags);
