import React, { useState } from "react";

import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import Toggle from "react-toggle";

export default function SingleCompanyFile({
  handleToggleFilePublicStatus,
  handleDeleteFile,
  isAdmin,
  id,
  fileUrl,
  fileName,
  isPublic,
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div
      id={`Popover${id}`}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      className={`p-2 mt-1 ${popoverOpen ? "bg-secondary" : "bg-white"}`}
    >
      <p className="p-0 m-0 w-100 text-default" style={{ fontWeight: 600 }}>
        {fileName}
      </p>

      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={`Popover${id}`}
        toggle={() => setPopoverOpen((prevState) => !prevState)}
        style={{ minWidth: "250px" }}
      >
        <PopoverHeader
          className="pb-0"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {fileName}
        </PopoverHeader>
        <PopoverBody>
          {isAdmin && (
            <div
              className="mb-3"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Toggle
                value={isPublic ? "on" : "off"}
                checked={isPublic}
                className="bg-toggle m-0"
                icons={{
                  unchecked: null,
                }}
                onChange={() => handleToggleFilePublicStatus(id, !isPublic)}
              />
              <span
                className={`ml-2 ${isPublic ? "text-default" : "text-dark"}`}
                style={{ textSize: "0.875rem", fontWeight: 600 }}
              >
                {isPublic ? "public" : "private"}
              </span>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <p
              className="p-0 m-0 mr-2 text-underline text-center"
              style={{ fontWeight: 600 }}
            >
              <a href={fileUrl}>download</a>
            </p>
            {isAdmin && (
              <p
                className="p-0 m-0 text-danger text-underline text-center"
                style={{ fontWeight: 600, cursor: "pointer" }}
                onClick={() => handleDeleteFile(id)}
              >
                delete
              </p>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}
