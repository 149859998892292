import React, { useEffect, useState } from "react";

import CustomCrews from "./popups/customcrews/CustomCrews";
import ProductsPage from "./popups/products/ProductsPage";
import JobDetails from "./popups/jobdetails/JobDetails";
import Customers from "./popups/customers/Customers";
import { fetchJobs } from "store/reducers/jobs.js";
import NewJob from "./popups/newjob/NewJob";
import JobsHeaderBar from "./JobsHeaderBar";
import { Route } from "react-router-dom";
import Constants from "utils/Constants";
import JobsContent from "./JobsContent";
import styled from "styled-components";
import { connect } from "react-redux";
import Help from "./popups/Help";

function JobsTab({ fetchJobs, jobs, companyType, userType }) {
  const [curTab, setCurTab] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    companyType && fetchJobs(companyType).finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [companyType]);

  const getFilterFunc = (tab) => {
    switch (tab) {
      case "all":
        return () => true;
      case "active":
        return (j) => j.status === "active";
      case "complete":
        return (j) => j.status === "complete";
      case "unscheduled":
        return (j) => !j.startDate;
      default:
        return () => false;
    }
  };

  return (
    <>
      <Route path="/app/jobs/help" render={(props) => <Help {...props} />} />
      <Route path="/app/jobs/customers" component={Customers} />
      <Route path="/app/jobs/custom_crews" component={CustomCrews} />
      <Route
        path="/app/jobs/new"
        render={(props) => (
          <NewJob
            {...props}
            routeTo="/app/jobs/details"
            isContractor={
              !companyType ? true : companyType === Constants.CONTRACTOR
            }
          />
        )}
      />
      <Route
        path="/app/jobs/details/:id"
        render={(props) => <JobDetails jobs={jobs} {...props} />}
      />
      <Route path="/app/jobs/products" component={ProductsPage} />
      <Wrapper className="bg-white">
        <JobsHeaderBar
          shouldShowBtns={companyType === Constants.CONTRACTOR}
          isAdmin={userType === Constants.ADMIN}
          curTab={curTab}
          setCurTab={setCurTab}
        />
        <JobsContent
          loading={loading}
          jobs={jobs.filter(getFilterFunc(curTab))}
        />
      </Wrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    companyType: state.company.company.companyType,
    jobs: state.jobs.jobs,
    userType: state.auth.user.userType,
  };
};

export default connect(mapStateToProps, { fetchJobs })(JobsTab);

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;
