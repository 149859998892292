const subscriptions = {};
const getID = ((id) => () => id++)(0);

export function closeOpenWindows() {
  Object.values(subscriptions).forEach((cb) => cb());
}

export default function subscribe(callback) {
  // Give the default export a callback function, it will return a function to
  // unsubscribe.  In the meantime, if there's a click, it'll run your callback with the
  // event object
  const id = getID();
  subscriptions[id] = callback;
  return () => delete subscriptions[id];
}
