import axios from "axios";

// Defaults all outgoing Axios calls to this URL
axios.defaults.baseURL =
  process.env.REACT_APP_DB_ENDPOINT || "http://localhost:5000";

// Pauses outgoing requests to perform some actions
axios.interceptors.request.use((req) => {
  req.headers.Authorization = "Bearer " + (localStorage.getItem("token") || "");
  return autoStaggerRequests(req);
});

// Stops returning calls to automatically do something on success or failure
// Could resolve a loading state in success or handle errors automatically in the second function
// The second function, if well written, can handle all .catch instances so they are no longer
// Needed by the rest of the app
axios.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject(error)
);

const autoStaggerRequests = ((
  requestCache,
  forgetRequest = deleteFromCache(requestCache)
) => (req) => {
  // The goal of this function is to keep track of outgoing requests.
  // If multiples of the same type come through, stagger them out to prevent
  // self-DDoSing.  If a limit is reached, deny the outgoing request and log an error.
  const { method, url } = req;
  const request = method + url;
  const delay = process.env.REACT_APP_DUPLICATE_CALL_STAGGER_DELAY || 500;
  const forget = forgetRequest.bind({}, request);

  if (!requestCache[request]) {
    // New request, keep track of its count and set a 'forget' timer
    // to remove it from the cache.
    const timeout = setTimeout(forget, delay);
    requestCache[request] = { count: 1, timeout };
    return req;
  } else {
    // Multiple of a request has come in.  Clear the previous timeout so it isn't
    // forgotten the increment the count
    clearTimeout(requestCache[request].timeout);
    requestCache[request].count++;

    // Set a new forget timer based on the count
    const { count } = requestCache[request];
    requestCache[request].timeout = setTimeout(forget, (count + 1) * delay);

    return new Promise((res, reject) => {
      // Return 'req' after a delay
      setTimeout(res.bind({}, req), count * delay);
      const limit = process.env.REACT_APP_DUPLICATE_CALL_LIMIT || 10;
      if (limit.toString().toLowerCase() !== "infinity" && count > limit) {
        reject(`Duplicate call limit reached.  Check .${method}(${url})`);
      }
    });
  }
})({});

function deleteFromCache(cache) {
  return (key) => delete cache[key];
}
