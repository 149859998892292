import React, { useState, useEffect } from "react";

import { updateCompany, setURLToStore } from "store/reducers/company.js";
import UploadedImage from "../../../utils/UploadedImage";
import { FormGroup, Input, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function CompanySettingsPage({
  companyId,
  updateCompany,
  setURLToStore,
  company,
  userType,
}) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");
  const [pic, setPic] = useState({});
  const history = useHistory();

  useEffect(() => {
    setDescription(company.description || "");
    setLocation(company.location || "");
    setName(company.name || "");
    // eslint-disable-next-line
  }, [company]);

  useEffect(() => {
    if (userType && userType !== Constants.ADMIN) {
      history.push("/app/company");
    }
    // eslint-disable-next-line
  }, [userType, Constants]);

  const onUpload = () => {
    if (!pic) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "please choose an image.",
      });
      return;
    }
    setIsUploading(true);
    let data = new FormData();
    data.append("image", pic, pic.name);
    axios
      .post(`/api/company/uploadImage`, data)
      .then((res) => setURLToStore(res.data.url))
      .catch((e) =>
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There was an error while updating your logo.",
        })
      )
      .finally(() => {
        setPreviewSrc("");
        setPic({});
        setIsUploading(false);
      });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const newImage = e.target.files[0];
      setPic(newImage);
      const reader = new FileReader();
      reader.onload = (e) => setPreviewSrc(e.target.result);
      reader.readAsDataURL(newImage);
    }
  };

  return (
    <Cover
      onDoubleClick={() => {
        history.push("/app/company");
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <p className="h3 border-bottom border-light text-dark">
          Company Settings
        </p>
        <div className="mb-2 mt-2">
          <ImageWrapper className="rounded border border-light shadow-sm">
            <UploadedImage
              src={
                previewSrc ||
                company.logoUrl ||
                "https://via.placeholder.com/200x200?text=logo"
              }
              alt="Company Logo"
              style={{
                width: "100%",
                height: "auto",
              }}
              background="white"
              width="125"
              height="125"
              display="fill"
            />
          </ImageWrapper>
          <div
            className=" mt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <label
                disabled={isUploading}
                htmlFor="file"
                className="btn btn-sm btn-white shadow-none m-0 p-2 mr-1"
                style={{ width: "100px" }}
              >
                choose
              </label>
              <input
                disabled={isUploading}
                style={{ display: "none" }}
                id="file"
                type="file"
                accept="image/png, image/jpeg"
                name="upload logo"
                onChange={handleChange}
              />
            </div>
            <div>
              <button
                disabled={isUploading || !previewSrc}
                style={{ width: "100px" }}
                className="btn btn-sm btn-default m-0 ml-1 p-2"
                onClick={onUpload}
              >
                {isUploading ? (
                  <div
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    className="spinner-border"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "upload"
                )}
              </button>
            </div>
          </div>
        </div>

        <div>
          <FormGroup>
            <label className="mb-0">name</label>
            <Input
              className="text-dark"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
            />
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <label className="mb-0">location</label>
            <Input
              className="text-dark"
              required
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              type="text"
              name="location"
            />
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <label className="mb-0">description</label>
            <Input
              className="text-dark"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="textarea"
              name="description"
            />
            <p
              className={
                description.length <= 500
                  ? "text-success text-right"
                  : "text-warning text-right"
              }
            >
              {description.length}/500
            </p>
          </FormGroup>
        </div>
        <Button
          disabled={isDisabled}
          color="default"
          block
          className="shadow-none"
          onClick={() => {
            if (description.length > 500) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Maximum description length is 500 characters.",
              });
              return;
            }
            setIsDisabled(true);
            updateCompany({ description, location, name }, companyId)
              .then(() => {
                Swal.fire({
                  icon: "success",
                  title: "Sweet!",
                });
              })
              .catch((e) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "There was an issue while updating your description.",
                });
              })
              .finally(() => setIsDisabled(false));
          }}
        >
          save
        </Button>
        {userType === Constants.ADMIN && (
          <div className="mt-4">(WIP manage subscription stuff)</div>
        )}
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.user.companyId,
    company: state.company.company,
    userType: state.auth.user.userType,
  };
};

export default connect(mapStateToProps, { updateCompany, setURLToStore })(
  CompanySettingsPage
);

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const InnerWrapper = styled.div`
  width: 95%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 75px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;

const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  background: white;
  width: 125px;
  height: 125px;
  overflow: hidden;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
  }
`;
