import React, { useState, useEffect } from "react";

import { Cover, InnerWrapper } from "../StyledComponents";
import { Input, Button } from "reactstrap";

export default function EditCrewMember({
  isAdmin,
  selectedCrewMember,
  isEditingCrewMember,
  handleUpdateCrewMember,
  setIsEditingCrewMember,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    setName(selectedCrewMember.name || "");
    setEmail(selectedCrewMember.email || "");
    setPhone(selectedCrewMember.phone || "");
  }, [selectedCrewMember]);

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsEditingCrewMember(false);
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ display: isEditingCrewMember ? "block" : "none", zIndex: 501 }}
    >
      <InnerWrapper
        style={{ height: "85vh" }}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded p-4 bg-white"
      >
        <p className="display-4 text-darker m-0 mb-0">Edit Crew Member</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateCrewMember({
              name,
              email,
              phone,
              crewId: selectedCrewMember.crewId,
              id: selectedCrewMember.id,
            });
          }}
        >
          <label className="text-dark font-weight-bold mb-0">*name</label>
          <Input
            disabled={!isAdmin}
            required
            onChange={(e) => setName(e.target.value)}
            placeholder="*name"
            className="text-dark mt-2"
            value={name}
          />
          <label className="text-dark font-weight-bold mb-0">email</label>
          <Input
            disabled={!isAdmin}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
            className="text-dark mt-2"
            value={email}
          />
          <label className="text-dark font-weight-bold mb-0">phone</label>
          <Input
            disabled={!isAdmin}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="phone"
            className="text-dark mt-2"
            value={phone}
          />
          <Button
            disabled={!isAdmin}
            type="submit"
            color="default"
            className="mt-2"
          >
            submit
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}
