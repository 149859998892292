import React from "react";

import DotTexture from "../../../../imgs/dotbgtexture.png";
import { Container } from "reactstrap";
import styled from "styled-components";

export default function HowItWorks() {
  return (
    <Container
      className="p-4 pt-6 pb-8 container-fluid bg-secondary"
      style={{ position: "relative" }}
      fluid
    >
      <DotBackgroundDiv
        style={{
          backgroundImage: `url(${DotTexture})`,
        }}
      />
      <Wrapper>
        <p style={{ textAlign: "left" }} className="display-1 text-default">
          It's pretty straight forward.
        </p>
      </Wrapper>
      <Wrapper>
        <LeftWrapper className="rounded shadow">
          <div
            style={{ display: "flex", alignItems: "flex-end" }}
            className="mb-4"
          >
            <div style={{ width: "75px" }}>
              <h3 className="display-2 text-gray border-bottom border-dark">
                01
              </h3>
            </div>
            <p className="lead m-0 ml-4 text-dark">assign</p>
          </div>
          <p style={{ textAlign: "left" }}>
            Dolore dolore ea voluptate commodo cillum sint dolore quis commodo
            esse reprehenderit dolore in velit. Ut eu nostrud dolor est
            voluptate incididunt.
          </p>
          <div
            style={{ display: "flex", alignItems: "flex-end" }}
            className="mb-4"
          >
            <div style={{ width: "75px" }}>
              <h3 className="display-2 text-dark border-bottom border-dark">
                02
              </h3>
            </div>
            <p className="lead m-0 ml-4 text-dark">build</p>
          </div>
          <p style={{ textAlign: "left" }}>
            Labore Lorem consequat duis Lorem reprehenderit anim aliqua tempor
            ad labore ullamco proident non. Consectetur sint ea consectetur
            laborum culpa tempor sint nisi commodo minim duis nulla Lorem
            pariatur.
          </p>
          <div
            style={{ display: "flex", alignItems: "flex-end" }}
            className="mb-4"
          >
            <div style={{ width: "75px" }}>
              <h3 className="display-2 text-default border-bottom border-dark">
                03
              </h3>
            </div>
            <p className="lead m-0 ml-4 text-dark">complete</p>
          </div>
          <p style={{ textAlign: "left" }}>
            In aliquip ullamco ad veniam consectetur nulla velit officia.
            Aliquip anim commodo ea non culpa ex tempor irure voluptate officia.
            Minim sunt officia aute ullamco ipsum eiusmod ad officia sunt do ad
            magna.
          </p>
        </LeftWrapper>
        <RightWrapper>
          <p className="text-dark mb-0">So how exactly does it work?</p>
          <div className="mb-4">
            <h3 className="display-2 text-dark">
              Owning time has never been simpler.
            </h3>
          </div>
        </RightWrapper>
      </Wrapper>
    </Container>
  );
}

const Wrapper = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  display: flex;
  margin: auto;
  margin-bottom: 25px;
  @media (max-width: 800px) {
    margin-bottom: 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

const LeftWrapper = styled.div`
  min-width: 300px;
  max-width: 650px;
  width: 60%;
  padding: 25px;
  background: white;
  margin-right: 25px;
  z-index: 100;
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0;
  }
`;

const RightWrapper = styled.div`
  width: 400px;
  text-align: right;
  @media (max-width: 800px) {
    text-align: left;
    width: 100%;
    h3 {
      font-size: 24px;
    }
  }
`;

const DotBackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(19% 26%, 59% 34%, 100% 100%, 0 100%, 0 0);
`;
