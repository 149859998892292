import React from "react";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import axios from "axios";

export default function SingleQualification({
  qualification: { qualification, id },
  setQualifications,
}) {
  const deleteQualification = async () => {
    try {
      await axios.delete(`/api/qualifications/delete/${id}`);

      setQualifications((prevState) => prevState.filter((q) => q.id !== id));
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue deleting your qualification.",
      });
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure you want to delete this qualification?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F3123E",
      cancelButtonColor: "#08080B",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteQualification();
      }
    });
  };
  return (
    <div className="w-100">
      <UncontrolledDropdown className="w-100">
        <DropdownToggle
          color="white"
          className="shadow-none w-100 text-justify"
        >
          {qualification}
        </DropdownToggle>
        <DropdownMenu>
          <DeleteItem
            className="text-danger"
            onClick={(e) => {
              e.preventDefault();
              handleDelete();
            }}
          >
            Delete
          </DeleteItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

const DeleteItem = styled(DropdownItem)`
  :hover {
    background: #ffe5e5;
  }
`;
