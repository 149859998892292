import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Button } from "reactstrap";

function AssignCustomer({
  setIsCreatingCustomer,
  handleUpdateCustomer,
  jobCustomer,
  customers,
}) {
  const [customer, setCustomer] = useState(0);
  useEffect(() => {
    setCustomer(jobCustomer);
  }, [jobCustomer]);
  return (
    <>
      <div>
        <label className="text-dark font-weight-bold mb-0 mt-5">
          Assign Customer
        </label>
        <br />
        <p
          className="m-0 p-0"
          style={{
            fontSize: "12px",
          }}
        >
          Select customer to be assigned to this job, or create one by selecting
          the option below.
        </p>
        <select
          value={customer}
          onChange={(e) => setCustomer(e.target.value)}
          className="w-100 mt-2"
        >
          <option value={0}></option>
          {customers.map(({ id, firstName, lastName, company }) => {
            const title = `${firstName} ${lastName} ${
              company ? `- ${company}` : ""
            }`;
            return (
              <option key={id} value={id}>
                {title}
              </option>
            );
          })}
        </select>
        <div className="mt-3" style={{ display: "flex" }}>
          <Button
            onClick={() => {
              handleUpdateCustomer(customer);
            }}
            className="w-50"
            color="default"
          >
            Update Customer
          </Button>
          <Button
            onClick={() => setIsCreatingCustomer(true)}
            className="w-50"
            color="white shadow-none"
          >
            Create New Customer
          </Button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
});

export default connect(mapStateToProps, {})(AssignCustomer);
