import React from "react";

import styled from "styled-components";
import { Button } from "reactstrap";
import currency from "currency.js";

export default function OrderItem({
  setSelectedWorkOrderId,
  handleDeleteOrderItem,
  setIsEditingWorkItem,
  isAdminContractor,
  setSelectedItem,
  orderId,
  item,
  idx,
}) {
  const { description, title, quantity, unitCost, id } = item;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: idx % 2 ? "#fcfcfc" : "white",
      }}
      className="p-1 rounded"
    >
      <div style={{ width: "45%" }}>
        <Title
          onClick={() => {
            if (isAdminContractor) {
              setIsEditingWorkItem(true);
              setSelectedItem(item);
              setSelectedWorkOrderId(orderId);
            }
          }}
          className="m-0 p-0 text-default"
          isAdminContractor={isAdminContractor}
        >
          {title}
        </Title>
        <p className="m-0 p-0" style={{ fontSize: "0.8rem" }}>
          {description}
        </p>
      </div>
      <p className="m-0 p-0" style={{ width: "10%" }}>
        {quantity}
      </p>
      <p className="m-0 p-0" style={{ width: "20%" }}>
        {currency(unitCost, {
          symbol: "$",
          precision: 2,
        }).format()}
      </p>
      <div
        style={{
          width: "25%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="m-0 p-0">
          {currency(unitCost * quantity, {
            symbol: "$",
            precision: 2,
          }).format()}
        </p>
        {isAdminContractor && (
          <Button
            onClick={() => {
              handleDeleteOrderItem(id, orderId);
            }}
            color="danger"
            size="sm"
          >
            <i className="fa fa-trash"></i>
          </Button>
        )}
      </div>
    </div>
  );
}

const Title = styled.p`
  font-weight: 600;
  :hover {
    ${(props) => props.isAdminContractor && `cursor: pointer;`}
    ${(props) => props.isAdminContractor && `text-decoration: underline;`}
  }
`;
