import React from "react";

import { ScreenCover } from "../../utils/ScreenCover";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

export default function ConnectWindow({
  setShouldShowPopup,
  handleConnect,
  setMessage,
  message,
}) {
  return (
    <ScreenCover onClick={() => setShouldShowPopup(false)} className="p-4">
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "relative",
          margin: "100px auto",
          maxWidth: "600px",
        }}
        className="p-4 bg-white rounded"
      >
        <p className="p-0 m-0 mb-2">Message (optional)</p>
        <Input
          className="text-dark mb-2"
          type="textarea"
          placeholder="why would you like to connect?..."
          required
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            handleConnect();
            setShouldShowPopup(false);
          }}
          color="default"
        >
          Connect
        </Button>
      </Wrapper>
    </ScreenCover>
  );
}

const Wrapper = styled.div`
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;

ConnectWindow.propTypes = {
  setShouldShowPopup: PropTypes.func.isRequired,
  handleConnect: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
