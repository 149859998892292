import React, { useState } from "react";

import { Button, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Wrapper, Cover } from "../StyledComponents";

function CreateWorkOrderItem({
  setSelectedWorkOrderId,
  setIsCreatingWorkItem,
  selectedWorkOrderId,
  isCreatingWorkItem,
  handleAddItem,
}) {
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const [title, setTitle] = useState("");

  const handleAddWorkOrderItem = (e) => {
    e.preventDefault();
    const newWorkOrderItem = {
      workOrderId: selectedWorkOrderId,
      description,
      quantity,
      unitCost,
      title,
    };
    handleAddItem(newWorkOrderItem);
    setTitle("");
    setDescription("");
    setQuantity(0);
    setUnitCost(0);
  };

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsCreatingWorkItem(false);
        setSelectedWorkOrderId(null);
      }}
      onClick={(e) => e.stopPropagation()}
      display={isCreatingWorkItem ? "block" : "none"}
    >
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded p-4 bg-white"
      >
        <p className="display-4 text-darker m-0 mb-2">Add Work Order Item</p>
        <hr className="m-0 p-0" />
        <label className="text-dark font-weight-bold mb-0 mt-2">Title</label>
        <form onSubmit={handleAddWorkOrderItem}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            description
          </label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-dark border border-light mt-2"
            type="textarea"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            Quantity
          </label>
          <Input
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="number"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            $ Unit Cost
          </label>
          <Input
            value={unitCost}
            onChange={(e) => setUnitCost(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="number"
          />
          <Button className="mt-4" color="default" type="submit" block>
            add
          </Button>
        </form>
      </Wrapper>
    </Cover>
  );
}

export default withRouter(CreateWorkOrderItem);
