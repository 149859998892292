import React, { useState } from "react";

import { Button, Modal, Input } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function CreateQualification({ setQualifications }) {
  const [isOpen, setIsOpen] = useState(false);
  const [qualification, setQualification] = useState("");

  const handleSubmit = async () => {
    if (!qualification.length) {
      return;
    }
    try {
      const {
        data: { newQualification },
      } = await axios.post("/api/qualifications/new", {
        qualification,
      });
      setQualifications((prevState) => [...prevState, newQualification]);
      setIsOpen(false);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue creating your qualification.",
      });
    }
  };

  return (
    <>
      <Button
        color="default"
        size="sm"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        + add
      </Button>
      {/* Modal */}
      <Modal
        toggle={() => setIsOpen((ps) => !ps)}
        className="modal-dialog-centered"
        isOpen={isOpen}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add a Qualification
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Input
            placeholder='e.g. "Metal Roofing"'
            className="text-dark"
            value={qualification}
            maxLength="40"
            type="text"
            onChange={(e) => {
              setQualification(e.target.value);
            }}
          />
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Close
          </Button>
          <Button onClick={handleSubmit} color="default" type="button">
            Save changes
          </Button>
        </div>
      </Modal>
    </>
  );
}
