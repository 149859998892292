import React, { useState, useEffect, useCallback } from "react";

import { Cover, InnerWrapper } from "../jobstab/popups/StyledComponents";
import { fetchCompanyUsers } from "store/reducers/company.js";
import { addAppointment } from "store/reducers/appointments";
import { useHistory } from "react-router-dom";
import { Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import moment from "moment";

function NewAppointment({
  customers,
  employees,
  companyId,
  fetchCompanyUsers,
  addAppointment,
}) {
  const history = useHistory();

  const [date, setDate] = useState(new Date());
  const [length, setLength] = useState(30);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [note, setNote] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const fetchUsersCallback = useCallback(() => {
    if (companyId) fetchCompanyUsers(companyId);
  }, [companyId, fetchCompanyUsers]);

  useEffect(() => {
    fetchUsersCallback();
  }, [fetchUsersCallback]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newAppointment = {
      start: moment(date).format(),
      end: moment(date).add(length, "minutes").format(),
      customerId: parseInt(selectedCustomer),
      assignedToId: parseInt(selectedEmployee),
      companyId,
      address,
      state,
      note,
      city,
      zip,
    };

    addAppointment(newAppointment).then(() => {
      history.push("/app/calendar");
    });
  };

  return (
    <Cover
      onDoubleClick={() => {
        history.push("/app/calendar");
      }}
    >
      <InnerWrapper
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
        style={{ height: "80vh" }}
      >
        <p className="display-4 text-darker m-0">Create New Appointment</p>
        <hr className="mt-2 mb-2" />
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label className="text-dark font-weight-bold">*Start Time</label>
          <DatePicker
            selected={date}
            onChange={(time) => setDate(time)}
            showTimeSelect
            dateFormat="Pp"
            className="w-100 p-2"
            required
          />
          <label className="text-dark font-weight-bold mt-4">
            *Meeting length (minutes)
          </label>
          <Input
            onChange={(e) => setLength(e.target.value)}
            className="text-dark border border-dark"
            value={length}
            type="number"
            min={5}
            required
          />
          <label
            className="text-dark font-weight-bold mt-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            *Customer{" "}
            <span
              className="text-default ml-2"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "0.8rem",
                fontWeight: 400,
              }}
              onClick={() => history.push("/app/jobs/customers")}
            >
              create new
            </span>
          </label>
          <select
            onChange={(e) => {
              setSelectedCustomer(e.target.value);
            }}
            value={selectedCustomer}
            className="w-100"
            required
          >
            <option value=""></option>
            {customers.map((c) => {
              return (
                <option key={c.id} value={c.id}>
                  {c.firstName} {c.lastName} {c.company && `- ${c.company}`}
                </option>
              );
            })}
          </select>
          <label className="text-dark font-weight-bold mt-4">*Employee</label>
          <select
            onChange={(e) => {
              setSelectedEmployee(e.target.value);
            }}
            value={selectedEmployee}
            className="w-100"
            required
          >
            <option value=""></option>
            {employees.map((e) => {
              return (
                <option key={e.id} value={e.id}>
                  {e.firstName} {e.lastName} - {e.position}
                </option>
              );
            })}
          </select>
          <label className="text-dark font-weight-bold mb-0 mt-4">
            address
          </label>
          <Input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">city</label>
          <Input
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">state</label>
          <Input
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">zip</label>
          <Input
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mt-4">
            Note (whats this meeting for?)
          </label>
          <Input
            onChange={(e) => setNote(e.target.value)}
            className="text-dark border border-dark"
            value={note}
            type="textarea"
            maxLength="500"
          />

          <Button className="mt-4" color="default" type="submit">
            Create Appointment
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
  employees: state.company.employees,
  companyId: state.auth.user.companyId,
});

export default connect(mapStateToProps, { fetchCompanyUsers, addAppointment })(
  NewAppointment
);
