import { createReducer, createAction } from "@reduxjs/toolkit";

const initialState = {
  userLocation: {
    latitude: 0.0,
    longitude: 0.0,
  },
};

export default createReducer(initialState, {
  setUserGeolocation: (state, { payload }) => {
    state.userLocation = payload;
  },
});

export const setUserGeolocation = createAction("setUserGeolocation");
