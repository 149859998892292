import React from "react";
import { connect } from "react-redux";

import userlistToLookup from "utils/arrayToLookupByID";
import { TagList } from "./styled.js";

function Tagged({ tags, userlist }) {
  const list = tags && Object.values(tags);
  if (!tags || !list.length) {
    return null;
  }

  const userLookup = userlistToLookup(userlist);
  return (
    <TagList>
      <div>
        <span>Tagged:</span>
        {list.map(({ id, userId }, i) => {
          const user = userLookup[userId];
          return (
            <p key={id}>
              {user.firstName + " " + user.lastName}
              {i < list.length - 1 ? ", " : ""}
            </p>
          );
        })}
      </div>
    </TagList>
  );
}

const mapStateToProps = (state) => ({
  userlist: state.chat.rooms[state.chat.activeRoom].userlist,
});

export default connect(mapStateToProps, {})(Tagged);
