import store from "store/";

export const getAuthor = (message, lastMessage) => {
  // Message is from user -> no author
  // Previous message was from user -> no author
  if (!message) {
    return null;
  }

  const userId = store.getState().auth.user.id;
  const isUser = message.userId === userId;
  const lastAuthor = (lastMessage && lastMessage.author) || {};
  const curAuthor = (message && message.author) || {};
  const isConsecutiveFromUser = lastMessage && lastAuthor.id === curAuthor.id;

  const { firstName, lastName } = message.author || {};
  const authorName = firstName && lastName ? firstName + " " + lastName : "";
  return !isUser && !isConsecutiveFromUser ? authorName : null;
};

export function sortChatRoomsByLastMessage(rooms) {
  if (!rooms || !Object.keys(rooms).length) {
    return [];
  }

  return Object.values(rooms).sort((a, b) => {
    const aLastAction =
      a.messages && a.messages.length
        ? a.messages[0].dateInserted
        : a.dateInserted || 0;
    const bLastAction =
      b.messages && b.messages.length
        ? b.messages[0].dateInserted
        : b.dateInserted || 0;

    if (a.companyId) {
      // a is a company chat
      return -1;
    }

    if (b.companyId) {
      // b is a company chat
      return 1;
    }

    return aLastAction < bLastAction ? 1 : -1;
  });
}
