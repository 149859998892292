import { createReducer, createAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import axios from "axios";

const initialState = {
  customers: [],
};

export default createReducer(initialState, {
  fetchCustomers: (state, { payload }) => {
    state.customers = payload;
  },
  setNewCustomer: (state, { payload }) => {
    state.customers = [...state.customers, payload];
  },
  updateCustomer: (state, { payload }) => {
    state.customers = state.customers.map((c) =>
      c.id !== payload.customerId ? c : { ...c, ...payload.customer }
    );
  },
  deleteCustomer: (state, { payload }) => {
    state.customers = state.customers.filter((c) => c.id !== payload);
  },
});

export const fetchCustomers = () => (dispatch) => {
  axios
    .get(`/api/customers/find`)
    .then(({ data: { customers } }) => {
      dispatch({
        type: "fetchCustomers",
        payload: customers,
      });
    })
    .catch(() => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error fetching your customers.",
      });
    });
};

export const setNewCustomer = createAction("setNewCustomer");
export const updateCustomer = createAction("updateCustomer");
export const deleteCustomer = createAction("deleteCustomer");
