import React, { useState } from "react";

import { Button, Input } from "reactstrap";
import styled from "styled-components";
import Toggle from "react-toggle";
import Swal from "sweetalert2";
import axios from "axios";

export default function SendEmailForm({
  purchaseOrder,
  saveCanvas,
  company,
  vendor,
}) {
  const [cc, setCc] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSetCc = (id) => {
    if (cc.includes(id)) {
      setCc((prevState) => prevState.filter((c) => c !== id));
    } else {
      setCc((prevState) => [...prevState, id]);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!cc.length) {
        return Swal.fire({
          icon: "warning",
          title: "Oops...",
          text:
            "You must choose at least one contact to send this purchase order to.",
        });
      } else {
        setIsLoading(true);
        const contactEmails = cc.map(
          (c) => vendor.contacts.find((con) => c === con.id).email
        );

        const canvas = await saveCanvas();
        const pdf = canvas.output("blob");
        const data = new FormData();
        const fileName = `${vendor.name && `${vendor.name} - `}${
          purchaseOrder.title
        }.pdf`;
        data.append("purchaseOrderPDF", pdf, fileName);
        data.append("message", message);
        data.append("contactEmails", contactEmails);
        data.append("fileName", fileName);
        data.append(
          "subject",
          `${company.name} Purchase Order ${purchaseOrder.title}`
        );
        data.append("companyName", company.name);

        await axios.post("api/purchase_orders/sendToVendors", data);
        setMessage("");
        setCc([]);
        return Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: "Sent PO to: " + contactEmails.join(", "),
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaperWrapper className="p-2 bg-white shadow">
      <p className="text-dark lead m-0" style={{ fontWeight: 600 }}>
        Send To
      </p>
      {vendor?.contacts?.map((contact) => {
        return (
          <div
            key={contact.id}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Toggle
              value={cc.includes(contact.id) ? "on" : "off"}
              checked={cc.includes(contact.id)}
              className="bg-toggle m-0 mr-2"
              icons={{
                unchecked: null,
              }}
              onChange={() => handleSetCc(contact.id)}
            />
            <p className="m-0 text-dark">
              <span style={{ fontWeight: 600 }}>{contact.name}</span> -{" "}
              {contact.email}
            </p>
          </div>
        );
      })}
      <p className="text-dark lead m-0 mt-2" style={{ fontWeight: 600 }}>
        Optional Message
      </p>
      <Input
        type="textarea"
        placeholder="include a message..."
        maxLength="500"
        value={message}
        className="text-dark"
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        disabled={isLoading}
        onClick={handleSubmit}
        block
        color="default"
        className="m-0 mt-4"
      >
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <div
              style={{ width: "1.5rem", height: "1.5rem" }}
              className="spinner-border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <i className="fa fa-envelope"></i> <span>send</span>
          </>
        )}
      </Button>
      {isLoading && (
        <p className="text-gray m-0" style={{ fontStyle: "italic" }}>
          This may take a minute or two...
        </p>
      )}
    </PaperWrapper>
  );
}

const PaperWrapper = styled.div`
  width: 850px;
  margin: 15px auto;
`;
