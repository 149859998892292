import React from "react";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

/**
 * This component was originally used with the old listings features.
 * left it here because it can be easily refactored to be a gneeric map component,
 * which I assume wel want,
 */

export default class MapComponent extends React.Component {
  mapRef = React.createRef();

  state = {
    map: null,
  };

  componentDidMount() {
    // Container to put React generated content in.
    const { latitude, longitude } = this.props.center;

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [longitude, latitude],
      zoom: 8,
    });

    this.setState({
      map,
    });
  }

  getColor(job) {
    if (job.status === "active") {
      return job.color;
    }
    return "#738394";
  }

  componentDidUpdate() {
    const { jobs } = this.props;

    // remove existing nodes
    document
      .querySelectorAll(".fa-map-marker")
      .forEach((el) => el.parentNode.removeChild(el));

    // add new nodes
    for (let i = 0; i < jobs.length; i++) {
      let curJob = jobs[i];
      let el = document.createElement("i");
      el.className = "fa fa-map-marker fa-4x";
      el.style = `color: ${this.getColor(curJob)}; cursor: pointer;`;
      el.onclick = () => {
        // TODO: Should probably trigger some kind of nav bar/tool bar
        // that lets users schedule/recolor without leaving this page.
        this.props.history.push(`/app/calendar/event_options/${curJob.id}`);
      };

      new mapboxgl.Marker(el)
        .setLngLat([curJob.longitude, curJob.latitude])
        .addTo(this.state.map);
    }
  }

  componentWillUnmount() {
    document.getElementById("map-selector").remove();
  }

  render() {
    return (
      <section
        style={{
          position: "relative",
          width: "100%",
          height: "calc(100vh - 46px)",
        }}
      >
        <div
          ref={this.mapRef}
          id="map-selector"
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            width: "100%",
          }}
        />
      </section>
    );
  }
}
