import React from "react";
import { Spinner } from "reactstrap";

export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "150px",
      }}
    >
      <Spinner
        style={{ width: "6rem", height: "6rem" }}
        color="primary"
        className="mb-lg"
      />
      <div>
        <p>
          Not loading?{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            Click here
          </span>{" "}
          to manually reload this page.
        </p>
      </div>
    </div>
  );
}
