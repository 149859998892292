import React from "react";

import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

function EditButton() {
  const history = useHistory();
  return (
    <div className="mt-2" style={{ marginLeft: "25px" }}>
      <Button
        onClick={() => history.push("/app/company/settings")}
        className="shadow-none"
        color="light"
        size="sm"
      >
        edit company
      </Button>
    </div>
  );
}

export default EditButton;
