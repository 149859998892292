import React from "react";

import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import currency from "currency.js";
import Color from "color";
const LIGHTEN_AMT = 0.5;

const PARAGRAPH_STYLES = {
  fontSize: "12px",
  fontWeight: "400",
  overflow: "hidden",
};

export default function PrintableJobCard({ selectedJob }) {
  const history = useHistory();
  const borderColor =
    selectedJob.status !== "active"
      ? Color("#738394").lighten(LIGHTEN_AMT).hex()
      : Color(selectedJob.color).lighten(LIGHTEN_AMT).hex() ||
        Color("#738394").lighten(LIGHTEN_AMT).hex();
  const bgColor = "#f9f9f9";
  return (
    <>
      <div
        className="w-100"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          onClick={() => history.push(`/app/card/${selectedJob.id}`)}
          className="shadow-none mb-2 text-dark"
          color="white"
          size="sm"
        >
          view printable details card
        </Button>
      </div>
      <div
        style={{
          border: `1px solid ${borderColor}`,
          borderLeft: `7px solid ${
            selectedJob.status !== "active"
              ? "#738394"
              : selectedJob.color || "#738394"
          }`,
          background: bgColor,
        }}
        className="rounded"
      >
        <p
          className="m-0 p-0 pl-1 pr-1 text-dark"
          style={{ ...PARAGRAPH_STYLES, fontWeight: "600" }}
        >
          {selectedJob.title}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          {selectedJob.address && `${selectedJob.address}, `}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          {`${selectedJob.city}, `}
          {`${selectedJob.state}`} {selectedJob.zip && ` ${selectedJob.zip}`}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          Job #{selectedJob.jobNumber}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          subcontractor name:{" "}
          {selectedJob.isCustom
            ? selectedJob.customSubcontractorName || "-"
            : selectedJob?.companyInfo?.name
            ? selectedJob.companyInfo.name
            : "-"}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          assigned to:{" "}
          {selectedJob.isCustom
            ? selectedJob.customCrewMemberName || "-"
            : Object.keys(selectedJob.assignedToUserInfo).length
            ? `${selectedJob.assignedToUserInfo.firstName} ${selectedJob.assignedToUserInfo.lastName}`
            : "-"}
        </p>
        <p className="m-0 p-0 pl-1 pr-1 text-dark" style={PARAGRAPH_STYLES}>
          contract amount:{" "}
          {currency(selectedJob.contractAmount, {
            symbol: "$",
            precision: 2,
          }).format()}
        </p>

        {selectedJob.reports.map(({ id, measurement, amount }) => {
          return (
            <p
              key={id}
              className="m-0 p-0 pl-1 pr-1"
              style={{
                fontSize: "12px",
                fontWeight: "600",
                overflow: "hidden",
                color: selectedJob.color,
              }}
            >
              {amount} {measurement}
            </p>
          );
        })}
      </div>
    </>
  );
}
