import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

export default function authenticate(Component) {
  const Authenticate = () => {
    const { isAuthenticated } = useAuth0();
    return <>{isAuthenticated ? <Component /> : <Redirect to="/" />}</>;
  };
  return Authenticate;
}
