import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
import PurchaseOrder from "./PurchaseOrder";
import HelpModal from "utils/HelpModal";
import Constants from "utils/Constants";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function PurchaseOrders({
  setSelectedPurchaseOrder,
  setIsAssigningProducts,
  setIsManagingVendors,
  fetchPurchaseOrders,
  setPurchaseOrders,
  permissionScope,
  purchaseOrders,
  jobNumber,
  jobId,
}) {
  const history = useHistory();

  useEffect(() => {
    jobId && fetchPurchaseOrders(jobId);
    // eslint-disable-next-line
  }, [jobId]);

  const getDefaultPurchaseOrderNumber = () => {
    let endTag = purchaseOrders.length + 1;
    const allCurrentOrderNumbers = purchaseOrders.map((o) => o.title);
    let purchaseOrderNumber = `#${jobNumber}-${endTag}`;
    while (allCurrentOrderNumbers.includes(purchaseOrderNumber)) {
      ++endTag;
      purchaseOrderNumber = `#${jobNumber}-${endTag}`;
    }

    return purchaseOrderNumber;
  };

  const deletePurchaseOrder = async (orderId) => {
    if (permissionScope !== Constants.ADMIN_CONTRACTOR) {
      return alert("Unauthorized");
    }

    const confirmed = await Swal.fire({
      title: `Are you sure you want to remove purchase order: ${
        purchaseOrders.find((o) => o.id === orderId)?.title || ""
      }?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F3123E",
      cancelButtonColor: "#08080B",
      confirmButtonText: `Yes, remove it!`,
    });
    if (confirmed.value) {
      try {
        await axios.post("/api/purchase_orders/delete", { orderId });
        setPurchaseOrders((prevState) =>
          prevState.filter((o) => o.id !== orderId)
        );
      } catch (error) {}
    }
  };

  return (
    <div>
      {!purchaseOrders.length && (
        <p className="m-0 mt-2 ml-2 p-0" style={{ fontStyle: "italic" }}>
          nothing to show
        </p>
      )}
      <div className="mb-2">
        {purchaseOrders.map((po) => {
          return (
            <PurchaseOrder
              setSelectedPurchaseOrder={setSelectedPurchaseOrder}
              setIsAssigningProducts={setIsAssigningProducts}
              setIsManagingVendors={setIsManagingVendors}
              deletePurchaseOrder={deletePurchaseOrder}
              setPurchaseOrders={setPurchaseOrders}
              permissionScope={permissionScope}
              purchaseOrder={po}
              key={po.id}
            />
          );
        })}
      </div>
      {permissionScope !== Constants.ADMIN_CONTRACTOR || (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              Swal.fire({
                title: "Purchase Order Number",
                input: "text",
                showCancelButton: true,
                confirmButtonText: "create",
                showLoaderOnConfirm: true,
                inputValue: getDefaultPurchaseOrderNumber(),
                preConfirm: async (title) => {
                  try {
                    if (!title) {
                      return Swal.showValidationMessage(`Must provide a title`);
                    }
                    const {
                      data: { newPurchaseOrder },
                    } = await axios.post(`/api/purchase_orders/create`, {
                      title,
                      jobId,
                    });
                    setPurchaseOrders((prevState) => [
                      ...prevState,
                      newPurchaseOrder,
                    ]);
                  } catch (error) {
                    Swal.showValidationMessage(`Request failed: ${error}`);
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              });
            }}
            color="default"
            block
            className="mt-2 w-50"
          >
            new purchase order
          </Button>
          <Button
            onClick={() => history.push("/app/jobs/products")}
            className="mt-2 w-50 shadow-none"
            color="white"
            block
          >
            Manage Products
          </Button>
        </div>
      )}
      <div className="mt-3">
        <HelpModal shouldShowHelpText modalTitle="Purchase Orders Help">
          <p className="text-dark m-0">
            Purchase orders can be created in this view, assigned to vendors,
            then emailed to them directly via the "send/print" button. To
            add/edit products, simply click on the "manage products" button.
          </p>
        </HelpModal>
      </div>
    </div>
  );
}
