import React from "react";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import PropTypes from "prop-types";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

/**
 * This component was originally used with the old listings features.
 * left it here because it can be easily refactored to be a gneeric map component,
 * which I assume wel want,
 */

export default class DetailsMap extends React.Component {
  mapRef = React.createRef();

  state = {
    map: null,
  };

  componentDidMount() {
    // Container to put React generated content in.
    const { latitude, longitude } = this.props;

    const map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: "mapbox://styles/mapbox/streets-v9",
      center: [longitude, latitude],
      zoom: 12.5,
    });

    this.setState(
      {
        map,
      },
      () => {
        let el = document.createElement("i");
        el.className = "fa fa-map-marker fa-4x text-default";
        el.id = "latestMap";

        new mapboxgl.Marker(el)
          .setLngLat([longitude, latitude])
          .addTo(this.state.map);
      }
    );
  }

  componentDidUpdate() {
    // Container to put React generated content in.
    const { latitude, longitude } = this.props;
    this.state.map.flyTo({ center: [longitude, latitude] });

    let el = document.createElement("i");
    el.className = "fa fa-map-marker fa-4x text-default";
    el.id = "latestMap";

    new mapboxgl.Marker(el)
      .setLngLat([longitude, latitude])
      .addTo(this.state.map);
  }

  render() {
    return (
      <>
        <div
          ref={this.mapRef}
          style={{
            width: "100%",
            margin: "auto",
            height: "200px",
          }}
          className="mt-4"
        />
      </>
    );
  }
}

DetailsMap.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

DetailsMap.defaultProps = {
  location: { latitude: 0.0, longitude: 0.0 },
};
