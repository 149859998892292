import React, { useState } from "react";

import WorkOrderTotal from "./WorkOrderTotal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import OrderItem from "./OrderItem";
import { Button } from "reactstrap";

export default function WorkOrder({
  handleSignUnSignWorkOrder,
  setSelectedWorkOrderId,
  handleDeleteWorkOrder,
  setIsCreatingWorkItem,
  handleDeleteOrderItem,
  setIsEditingWorkItem,
  isAdminContractor,
  setSelectedItem,
  workOrder,
  userId,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  return (
    <div className="w-100 p-2 mt-2 border-bottom border-light">
      <p
        className="m-0 p-0 text-dark h5"
        style={{ fontWeight: 600, cursor: "pointer" }}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <span className="mr-2">
          <i className={`fa fa-caret-${isOpen ? "down" : "right"}`}></i>
        </span>
        <span>{workOrder.title}</span>
      </p>
      <div style={{ display: isOpen ? "block" : "none" }}>
        {/* ---- */}
        {/* ---- */}
        {/* ---- titles ---- */}
        {/* ---- */}
        {/* ---- */}
        <TitlesWrapper className="pt-2 border-bottom border-light pl-1 pr-1">
          <p className="m-0 p-0" style={{ width: "45%" }}>
            Description
          </p>
          <p className="m-0 p-0" style={{ width: "10%" }}>
            Qty.
          </p>
          <p className="m-0 p-0" style={{ width: "20%" }}>
            Unit Cost
          </p>

          <p className="m-0 p-0" style={{ width: "25%" }}>
            Total
          </p>
        </TitlesWrapper>
        {/* ---- */}
        {/* ---- */}
        {/* ---- items ---- */}
        {/* ---- */}
        {/* ---- */}
        <ItemsWrapper id="itemsWrapper" className="pb-2 mt-3 mb-2 rounded">
          {workOrder.orderItems.map((item, idx) => (
            <OrderItem
              setSelectedWorkOrderId={setSelectedWorkOrderId}
              handleDeleteOrderItem={handleDeleteOrderItem}
              setIsEditingWorkItem={setIsEditingWorkItem}
              setSelectedItem={setSelectedItem}
              isAdminContractor={isAdminContractor}
              orderId={workOrder.id}
              key={item.id}
              item={item}
              idx={idx}
            />
          ))}
        </ItemsWrapper>
        {/* ---- */}
        {/* ---- */}
        {/* ---- total ---- */}
        {/* ---- */}
        {/* ---- */}
        <WorkOrderTotal orderItems={workOrder.orderItems} />
        {/* ---- */}
        {/* ---- */}
        {/* ---- bottom btns ---- */}
        {/* ---- */}
        {/* ---- */}

        <div style={{ display: "flex", alignItems: "center" }} className="mt-4">
          {isAdminContractor && (
            <>
              <Button
                onClick={() => {
                  setIsCreatingWorkItem(true);
                  setSelectedWorkOrderId(workOrder.id);
                }}
                color="default"
              >
                <i className="fa fa-plus"></i> <span>add item</span>
              </Button>
              <Button
                onClick={() => {
                  handleDeleteWorkOrder(workOrder.id);
                }}
                color="danger"
              >
                <i className="fa fa-trash"></i> <span>delete</span>
              </Button>
              <Button color="success" disabled>
                <i className="fa fa-money"></i> <span>pay</span>
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              history.push(`/app/work_order/${workOrder.id}`);
            }}
            className="shadow-none"
            color="secondary"
          >
            <i className="fa fa-share-square"></i> <span>send/print</span>
          </Button>
        </div>

        {/* ---- */}
        {/* ---- */}
        {/* ---- sign offs ---- */}
        {/* ---- */}
        {/* ---- */}
        <p className="m-0 p-0 mt-1">
          <span
            className="text-default"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              handleSignUnSignWorkOrder(workOrder.id);
            }}
          >
            Click here
          </span>{" "}
          {workOrder.signOffs.filter((so) => so.userId === userId).length
            ? "to remove your signature from this work order."
            : "to sign off on this work order."}
        </p>
        {workOrder.signOffs.map((signOff) => (
          <span
            key={signOff.id}
            className="m-0 mr-2 badge badge-pill badge-success"
          >
            {signOff.firstName} {signOff.lastName}
          </span>
        ))}
      </div>
    </div>
  );
}

const TitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  position: sticky;
  background: white;
  top: -25px;
  p {
    font-weight: 600;
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
`;
