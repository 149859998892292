import React from "react";

import HouseImage from "../../../../imgs/House background .jpg";
import ByRoofersForRoofers from "./ByRoofersForRoofers";
import TranslationInfo from "./TranslationInfo";
import OverviewInfo from "./OverviewInfo";
import LandingHero from "./LandingHero";
import HowItWorks from "./HowItWorks";
import NavBar from "../../NavBar";
import Footer from "./Footer";

const MainPage = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <NavBar />
      <LandingHero />
      <OverviewInfo />
      <HowItWorks />
      <ByRoofersForRoofers />
      <TranslationInfo />
      <img src={HouseImage} style={{ width: "100%", height: "auto" }} alt="" />
      <Footer />
    </div>
  );
};

export default MainPage;
