import React, { useEffect } from "react";

import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import { connect } from "react-redux";
import WorkOrder from "./WorkOrder";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function WorkOrders({
  handleSignUnSignWorkOrder,
  setSelectedWorkOrderId,
  handleDeleteWorkOrder,
  setIsCreatingWorkItem,
  handleDeleteOrderItem,
  setIsEditingWorkItem,
  setSelectedItem,
  fetchWorkOrders,
  setWorkOrders,
  isContractor,
  workOrders,
  jobNumber,
  userType,
  userId,
  jobId,
}) {
  useEffect(() => {
    jobId && fetchWorkOrders(jobId);
    // eslint-disable-next-line
  }, [jobId]);

  const getDefaultWorkOrderNumber = () => {
    let endTag = workOrders.length + 1;
    const allCurrentOrderNumbers = workOrders.map((o) => o.title);
    let workOrderNumber = `#${jobNumber}-${endTag}`;
    while (allCurrentOrderNumbers.includes(workOrderNumber)) {
      ++endTag;
      workOrderNumber = `#${jobNumber}-${endTag}`;
    }

    return workOrderNumber;
  };

  const createNewWorkOrder = () => {
    if (userType !== Constants.ADMIN) {
      return alert("Must be an admin user to perform this action.");
    }
    Swal.fire({
      title: "Work Order Number",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "create",
      showLoaderOnConfirm: true,
      inputValue: getDefaultWorkOrderNumber(),
      preConfirm: async (title) => {
        try {
          if (!title) {
            return Swal.showValidationMessage(`Must provide a title`);
          }
          const {
            data: { newWorkOrder },
          } = await axios.post(`/api/work_orders/create`, {
            title,
            jobId,
          });
          setWorkOrders((prevState) => [...prevState, newWorkOrder]);
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <div>
      {!workOrders.length && (
        <p className="m-0 mt-2 ml-2 p-0" style={{ fontStyle: "italic" }}>
          nothing to show
        </p>
      )}
      {workOrders.map((order) => {
        return (
          <WorkOrder
            isAdminContractor={isContractor && userType === Constants.ADMIN}
            handleSignUnSignWorkOrder={handleSignUnSignWorkOrder}
            setSelectedWorkOrderId={setSelectedWorkOrderId}
            setIsCreatingWorkItem={setIsCreatingWorkItem}
            handleDeleteOrderItem={handleDeleteOrderItem}
            handleDeleteWorkOrder={handleDeleteWorkOrder}
            setIsEditingWorkItem={setIsEditingWorkItem}
            setSelectedItem={setSelectedItem}
            workOrder={order}
            userId={userId}
            key={order.id}
          />
        );
      })}

      {isContractor && (
        <Button
          onClick={createNewWorkOrder}
          color="default"
          block
          className="mt-2 w-100"
        >
          new work order
        </Button>
      )}
      <div className="mt-2">
        <HelpModal shouldShowHelpText modalTitle="Work Orders Help">
          <p className="text-dark m-0">
            A work order is simply a list of tasks assigned to a given crew.
            Each work order consists of a title and a list of
            tasks/descriptions, along with the cost of a single execution of
            that task, and a quantity of execution.
          </p>
          <p className="text-dark m-0 mt-3">
            A simple example of one of these task items could be read as
            "Install shingles 1 time for $500.00".
          </p>
          <p className="text-dark m-0 mt-3">
            It should be noted that if a subcontractor is assigned to a given
            job, all work orders belonging to that job are viewable by that
            subcontractor. This being said, you can simply press the
            "send/print" button and forward a work order to any other crew you
            wish.
          </p>
          <p className="text-dark m-0 mt-3">
            Finally, you'll notice text which says "Click here to sign off on
            this work order". Clicking that "Click here" text will add your name
            to a list of "sign offs" for that given work order. This is meant to
            indicate your acknowledgement of the completion of a work order.
          </p>
        </HelpModal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {})(WorkOrders);
