import React, { Component } from "react";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import styled from "styled-components";
import { Button } from "reactstrap";
import { editFilterStartEnd } from "store/reducers/calendars";
import { connect } from "react-redux";
import moment from "moment";

class DatePickerRangeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  componentDidMount() {
    this.setState({
      startDate:
        this.props.filters.startDate && moment(this.props.filters.startDate),
      endDate: this.props.filters.endDate && moment(this.props.filters.endDate),
    });
  }

  render() {
    return (
      <div>
        <hr className="m-0 mb-2 mt-2 border-light" />
        <Wrapper className="rounded">
          <DateRangePicker
            small
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel
            noBorder
            startDatePlaceholderText="start date"
            endDatePlaceholderText="end date"
            isOutsideRange={() => false}
            startDate={this.state.startDate}
            startDateId="start_date_picker_range_calendar_tab"
            endDate={this.state.endDate}
            endDateId="end_date_picker_range_calendar_tab"
            numberOfMonths={
              window.innerWidth < 900
                ? 1
                : window.innerWidth > 900 && window.innerWidth < 1200
                ? 2
                : 3
            }
            onDatesChange={({ startDate, endDate }) =>
              this.setState({ startDate, endDate })
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
              if (!focusedInput) {
                this.props.editFilterStartEnd({
                  startDate:
                    this.state.startDate && this.state.startDate.format(),
                  endDate: this.state.endDate && this.state.endDate.format(),
                });
              }
              this.setState({ focusedInput });
            }}
          />
        </Wrapper>
        <Button
          onClick={() => {
            this.props.editFilterStartEnd({
              startDate: null,
              endDate: null,
            });
            this.setState({
              startDate: null,
              endDate: null,
              focusedInput: null,
            });
          }}
          size="sm"
          block
          className="mt-2"
          color="default"
        >
          reset
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.calendars.filters,
});

export default connect(mapStateToProps, { editFilterStartEnd })(
  DatePickerRangeFilter
);

const Wrapper = styled.div`
  width: 100%;

  .DateRangePicker_picker {
    z-index: 10000;
  }

  .DateInput_input__focused {
    border-bottom: 2px solid #3966ea;
  }

  .CalendarDay__selected_span {
    background: #3966ea;
    color: white;
    border: 1px solid #3966ea;
  }

  .CalendarDay__selected {
    background: #2450c9;
    color: white;
    border: 1px solid #3966ea;
  }

  .CalendarDay__selected:hover {
    background: #2450c9;
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #3966ea;
    color: white;
    border: 1px solid #3966ea;
  }
`;
