import React from "react";

import { editJob } from "store/reducers/jobs";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function MarkAsComplete({ jobId, isActive, editJob }) {
  const toggleCompleteStatus = async () => {
    try {
      await axios.post("/api/jobs/complete", {
        jobId,
        isActive,
      });
      editJob({ jobId, status: isActive ? "complete" : "active" });
      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `Job updated!`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error updating this job. Please try again in a moment.",
      });
    }
  };

  return (
    <Button
      onClick={toggleCompleteStatus}
      className="mt-2 w-100"
      color="success"
    >
      {isActive ? "Mark Complete" : "Mark Incomplete"}
    </Button>
  );
}

export default connect(null, { editJob })(MarkAsComplete);
