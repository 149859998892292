import React from "react";
import { Router } from "react-router-dom";
import "./index.css";
import "./utils/interceptors.js";

// bootstrap/argon imports
import "./argon/vendor/nucleo/css/nucleo.css";
import "./argon/vendor/font-awesome/css/font-awesome.min.css";
import "./argon/scss/argon-design-system-react.scss";

// auth0
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

// redux
import { Provider } from "react-redux";
import store from "store/";

// context
import { WindowSizeProvider } from "components/providers/WindowSize.js";
import MainPage from "./components/marketing/landingpage/mainpage/MainPage";
import InviteRedirect from "./components/app/invite/InviteRedirect";
import authenticate from "./components/utils/authenticate";
import AppEntrance from "./components/app/AppEntrance";
import Invite from "./components/app/invite/Invite";
import Loading from "./components/utils/Loading";
import { useAuth0 } from "./react-auth0-spa";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";

import { closeOpenWindows } from "./utils/clickTracker.js";
import "./App.css";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

function MountApp() {
  return (
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <WindowSizeProvider>
          <Router history={history}>
            <App />
          </Router>
        </WindowSizeProvider>
      </Provider>
    </Auth0Provider>
  );
}

const App = withRouter(() => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="App" onClick={closeOpenWindows}>
      <Route path="/" exact render={(props) => <MainPage {...props} />} />
      {/* /app is main app root, check there for more routes */}
      <Route path="/app" component={authenticate(AppEntrance)} />
      <Route path="/invite/:invitationId" component={Invite} />
      <Route path="/inviteredirect" component={InviteRedirect} />
    </div>
  );
});

export default MountApp;
