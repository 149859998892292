import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function CreateCalendar({ history, companyId }) {
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.post("/api/calendar/new", {
        title,
        companyId,
      });
      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've created your new calendar.`,
      });
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error creating your calendar. Please try again in a moment.",
      });
    } finally {
      setIsLoading(false);
      history.push("/app/calendar");
    }
  };
  return (
    <Cover
      onClick={() => {
        history.push("/app/calendar");
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <p className="display-4 text-darker m-0">Create New Calendar</p>
        <hr className="mt-2 mb-2" />
        <form onSubmit={handleSubmit}>
          <label className="text-dark font-weight-bold">*Title</label>
          <Input
            onChange={(e) => setTitle(e.target.value)}
            className="text-dark border border-light"
            value={title}
            type="text"
            required
          />
          <Button
            disabled={isLoading}
            className="mt-4"
            color="default"
            type="submit"
            block
          >
            Submit
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.user.companyId,
  };
};

export default connect(mapStateToProps, {})(withRouter(CreateCalendar));

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;

const InnerWrapper = styled.div`
  width: 95%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 75px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;
