import React from "react";

export default function ShowMoreButton({ handleShowMoreClick, isShowing }) {
  return (
    <>
      {!isShowing ? (
        <p
          onClick={handleShowMoreClick}
          className="text-primary m-0"
          style={{
            cursor: "pointer",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <small>
            <b>
              <u>show more</u>
            </b>
          </small>
          <span
            className="btn-inner--icon m-0 ml-1 p-0"
            style={{ width: "10px", height: "30px" }}
          >
            <i className="fa fa-sort-down fa-sm"></i>{" "}
          </span>
        </p>
      ) : (
        <p
          onClick={handleShowMoreClick}
          className="text-primary  m-0"
          style={{
            cursor: "pointer",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <small>
            <b>
              <u>show less</u>
            </b>
          </small>
          <span
            className="btn-inner--icon m-0 ml-1 p-0"
            style={{ width: "10px", height: "20px" }}
          >
            <i className="fa fa-sort-up fa-sm"></i>{" "}
          </span>
        </p>
      )}
    </>
  );
}
