import React, { useState, useEffect } from "react";

import { fetchJobs } from "store/reducers/jobs";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { Button } from "reactstrap";
import currency from "currency.js";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import jsPDF from "jspdf";

function WorkOrderPrintPage({ match, user, company, fetchJobs }) {
  const [workOrder, setWorkOrder] = useState({});
  const [job, setJob] = useState({});
  const history = useHistory();

  useEffect(() => {
    company.companyType &&
      fetchJobs(company.companyType).then((allJobs) => {
        fetchWorkOrder(parseInt(match.params.workOrderId), allJobs);
      });
    // eslint-disable-next-line
  }, [match.params.workOrderId, company]);

  const fetchWorkOrder = async (orderId, allJobs) => {
    try {
      const {
        data: { order },
      } = await axios.get(`/api/work_orders/single/${orderId}`);
      const singleJob = allJobs.find(
        (singleJob) => singleJob.id === order.jobId
      );
      if (singleJob) {
        setJob(singleJob);
      } else {
        return history.push("/app/jobs");
      }
      setWorkOrder(order);
      return order;
    } catch (err) {
      history.push("/app/jobs");
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching work order.",
      });
    }
  };

  const printCanvas = () => {
    saveCanvas().then((doc) => {
      doc.save(`work_order-${workOrder.title}.pdf`);
    });
  };

  const saveCanvas = () => {
    window.scrollTo(0, 0);
    return html2canvas(document.getElementById("workOrderCapture")).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF("p", "mm");
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        return doc;
      }
    );
  };

  const { startDate, endDate } = job;
  let crew;
  if (job.isCustom) {
    crew = job.customSubcontractorName;
  } else {
    crew = job?.companyInfo?.name;
  }
  return (
    <div>
      <div style={{ maxWidth: "850px", margin: "auto" }}>
        <Button onClick={printCanvas} color="default" className="m-0">
          <i className="fa fa-print"></i> <span>save/print</span>
        </Button>
      </div>
      <PaperWrapper className="p-3 bg-white shadow" id="workOrderCapture">
        <div
          className="mb-4"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="h1 m-0 text-dark" style={{ fontWeight: 600 }}>
            Work Order
          </p>
          <p className="m-0 text-dark">{moment().format("MM/DD/YYYY")}</p>
        </div>
        <TopSectionWrapper>
          <TopLeftInfoSection>
            <p className="m-0 text-dark">
              Order title:{" "}
              <span style={{ fontWeight: 600 }}>{workOrder?.title}</span>
            </p>
            <p className="m-0 text-dark">crew: {crew || "-"}</p>
            <p className="m-0 text-dark">
              start: {startDate ? moment(startDate).format("MMM Do YYYY") : "-"}
            </p>
            <p className="m-0 text-dark">
              end: {endDate ? moment(endDate).format("MMM Do YYYY") : "-"}
            </p>
          </TopLeftInfoSection>
          <TopRightInfoSection>
            <p className="h3 m-0 text-dark" style={{ fontWeight: 600 }}>
              {company.name}
            </p>
            <p className="m-0 text-dark">{company.location}</p>
            <p className="m-0 text-dark">
              {user.firstName} {user.lastName} - {user.position}
            </p>
            <p className="m-0 text-dark">{user.email}</p>
            <p className="m-0 text-dark">{user.phoneNumber}</p>
          </TopRightInfoSection>
        </TopSectionWrapper>
        <TitlesWrapper className="p-2 mt-2 border-bottom border-light ">
          <p className="m-0 p-0 text-dark" style={{ width: "45%" }}>
            Item Name
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "10%" }}>
            Qty.
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "20%" }}>
            Unit Cost
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "25%" }}>
            Total
          </p>
        </TitlesWrapper>

        {workOrder?.orderItems?.map((item, idx) => {
          const { id, quantity, unitCost, title, description } = item;
          return (
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                backgroundColor: idx % 2 ? "#fcfcfc" : "white",
              }}
              className="p-1 rounded"
            >
              <div style={{ width: "45%" }}>
                <p className="m-0 text-default" style={{ fontWeight: 600 }}>
                  {title}
                </p>
                <p className="m-0 text-dark">{description}</p>
              </div>
              <p className="m-0 text-dark" style={{ width: "10%" }}>
                {quantity}
              </p>
              <p className="m-0 text-dark" style={{ width: "20%" }}>
                {currency(unitCost, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
              <p className="m-0 text-dark">
                {currency(unitCost * quantity, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
            </div>
          );
        })}

        {/* total */}
        <div style={{ display: "flex" }} className="mt-2">
          <div style={{ width: "45%" }}></div>
          <div style={{ width: "10%" }}></div>
          <p
            className="m-0 text0dark"
            style={{ width: "20%", fontWeight: "600" }}
          >
            Total
          </p>

          <p
            className="m-0 text-success"
            style={{ width: "25%", fontWeight: "600" }}
          >
            {currency(
              workOrder?.orderItems?.reduce(
                (acc, cv) => (acc += cv.unitCost * cv.quantity),
                0.0
              ),
              { symbol: "$", precision: 2 }
            ).format()}
          </p>
        </div>
      </PaperWrapper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  company: state.company.company,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, { fetchJobs })(WorkOrderPrintPage)
);

const PaperWrapper = styled.div`
  width: 850px;
  min-height: 1100px;
  margin: 15px auto;
  cursor: initial;
  position: sticky;
  top: 0;
  @media print {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
`;

const TopLeftInfoSection = styled.div`
  width: 50%;
`;

const TopRightInfoSection = styled.div`
  width: 50%;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start:
  justify-content: center;
`;

const TitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  background: white;
  p {
    font-weight: 600;
  }
`;
