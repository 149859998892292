import { useEffect } from "react";

import { fetchProducts, fetchCategories } from "store/reducers/products";
import { connect } from "react-redux";

function ProductsAndCategories({ fetchCategories, fetchProducts, companyId }) {
  useEffect(() => {
    companyId && fetchCategories(companyId);
    companyId && fetchProducts(companyId);
    // eslint-disable-next-line
  }, [companyId]);
  return null;
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
});

export default connect(mapStateToProps, { fetchProducts, fetchCategories })(
  ProductsAndCategories
);
