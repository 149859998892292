import React, { useState } from "react";

import { Button, Input } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

export default function MeasurementReporting({
  jobId,
  isContractor,
  measurementReports,
  setMeasurementReports,
}) {
  const [measurement, setMeasurement] = useState("square");
  const [amount, setAmount] = useState(0);

  const handleAdd = async () => {
    try {
      const {
        data: { report },
      } = await axios.post("/api/jobs/addMeasurementReport", {
        jobId,
        measurement,
        amount,
      });

      setMeasurementReports((prevState) => [...prevState, report]);

      setAmount(0);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error adding you report. Please try again in a moment.",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/jobs/deleteMeasurementReport/${id}`);
      setMeasurementReports((prevState) =>
        prevState.filter((report) => report.id !== id)
      );
    } catch (err) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You was an issue when deleting your measurement report.",
      });
    }
  };
  return (
    <div>
      <p
        className="m-0 p-0 mb-2 mt-2"
        style={{ fontSize: "0.8rem", fontStyle: "italic" }}
      >
        Report on primary measurements for your jobs.
      </p>
      <div className="mt-2">
        {measurementReports.map((report, idx) => {
          return (
            <div
              key={report.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className={`p-2 ${
                measurementReports.length === 1
                  ? "bg-lighter"
                  : idx % 2 && "bg-lighter"
              }`}
            >
              <p className="m-0 p-0 lead text-dark" style={{ fontWeight: 600 }}>
                {report.amount} {report.measurement}
              </p>
              {isContractor && (
                <Button
                  onClick={() => {
                    handleDelete(report.id);
                  }}
                  size="sm"
                  color="danger"
                >
                  x
                </Button>
              )}
            </div>
          );
        })}
      </div>
      {isContractor && (
        <div
          className="mt-2"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <div className="w-100">
            <label
              style={{
                display: "block",
                fontSize: "0.875rem",
                fontStyle: "italic",
              }}
              className="text-dark mb-0"
            >
              measurement
            </label>
            <select
              value={measurement}
              onChange={(e) => setMeasurement(e.target.value)}
              className="rounded w-100 border-dark text-dark"
              style={{ padding: "10px" }}
            >
              <option value="bundle">bundle</option>
              <option value="board foot">board foot</option>
              <option value="bag">bag</option>
              <option value="bucket">bucket</option>
              <option value="box">box</option>
              <option value="each">each</option>
              <option value="foot">foot</option>
              <option value="square foot">square foot</option>
              <option value="cubic foot">cubic foot</option>
              <option value="US gallon">US gallon</option>
              <option value="inch">inch</option>
              <option value="pound">pound</option>
              <option value="linear foot">linear foot</option>
              <option value="ounce">ounce</option>
              <option value="pallet">pallet</option>
              <option value="roll">roll</option>
              <option value="square">square</option>
              <option value="sheet">sheet</option>
              <option value="square yard">square yard</option>
              <option value="cubic yard">cubic yard</option>
              <option value="yard">yard</option>
            </select>
          </div>
          <div className="ml-2 mr-2 ">
            <label
              style={{
                display: "block",
                fontSize: "0.875rem",
                fontStyle: "italic",
              }}
              className="text-dark mb-0"
            >
              amount
            </label>
            <Input
              type="number"
              min={0}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="border-dark text-dark"
              style={{ width: "100px" }}
            />
          </div>
          <Button onClick={handleAdd} size="md" color="default">
            +
          </Button>
        </div>
      )}
    </div>
  );
}
