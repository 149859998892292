import React, { useState } from "react";

import ShowMoreButton from "../../shared/ShowMoreButton";

function Description({ company }) {
  const [substringMax, setSubstringMax] = useState(100);
  const [isShowing, setIsShowingFullString] = useState(false);

  const handleShowMoreClick = () => {
    setSubstringMax(substringMax === 100 ? 500 : 100);
    setIsShowingFullString(!isShowing);
  };

  return (
    <>
      <p className="mt-2 mb-0" style={{ textAlign: "start" }}>
        <span style={{ fontWeight: "600" }}>description: </span>
      </p>
      <div
        className="mt-0"
        style={{ textAlign: "start", position: "relative" }}
      >
        <>
          {company?.description
            ?.substring(0, substringMax)
            ?.split("\n")
            ?.map((s) => (
              <p key={s} className="mb-0">
                {s}
              </p>
            )) || <p>none configured</p>}
        </>
        {company?.description?.length > 100 && (
          <ShowMoreButton
            isShowing={isShowing}
            handleShowMoreClick={handleShowMoreClick}
          />
        )}
      </div>
    </>
  );
}

export default Description;
