import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

export default function EmployeesTab({ company }) {
  const getLgSize = () => {
    return company?.employees?.length <= 2 ? "49%" : "32%";
  };

  const getXlSize = () => {
    return company?.employees?.length <= 2
      ? "49%"
      : company?.employees?.length === 3
      ? "32%"
      : "24%";
  };

  return (
    <EmployeesWrapper>
      {company?.employees?.map((employee) => {
        const { firstName, lastName, id, location, position } = employee;
        return (
          <Employee
            className="p-2 mb-3 bg-white rounded shadow-sm"
            xl={() => getXlSize()}
            lg={() => getLgSize()}
            md="49%"
            key={id}
          >
            <div>
              <p
                style={{ fontSize: "18px", fontWeight: 600 }}
                className="m-0 p-0 text-dark"
              >
                {firstName} {lastName}
              </p>
              <p
                style={{ fontSize: "14px", fontWeight: 600 }}
                className="m-0 p-0 text-dark"
              >
                {position}
              </p>
              <p
                style={{ fontSize: "14px", fontWeight: 400 }}
                className="m-0 p-0 text-dark"
              >
                {location}
              </p>
            </div>
          </Employee>
        );
      })}
    </EmployeesWrapper>
  );
}

const EmployeesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  @media (max-width: 1150px) {
    grid-template-columns: 33% 33% 33%;
  }
  @media (max-width: 950px) {
    grid-template-columns: 50% 50%;
  }
  @media (max-width: 800px) {
    margin-top: 15px;
    grid-template-columns: 100%;
  }
`;

const Employee = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 150px;
  p {
    overflow-wrap: break-word;
    text-align: start !important;
  }
  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

EmployeesTab.propTypes = {
  company: PropTypes.object.isRequired,
};
