import { createReducer } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  vendors: [],
  vendorContacts: [],
};

export default createReducer(initialState, {
  fetchContractorVendors: (state, { payload }) => {
    state.vendors = payload.vendors;
    state.vendorContacts = payload.vendorContacts;
  },
  updatePurchaseOrderVendor: (state, { payload }) => {
    state.vendors = state.vendors.map((v) =>
      v.id !== payload.vendorId
        ? v
        : { ...v, purchaseOrderId: payload.purchaseOrderId }
    );
  },
  createVendor: (state, { payload }) => {
    state.vendors = [...state.vendors, payload.vendor];
  },
  deleteVendor: (state, { payload }) => {
    state.vendors = state.vendors.filter((v) => v.id !== payload.vendorId);
  },
  createVendorContact: (state, { payload }) => {
    state.vendorContacts = [...state.vendorContacts, payload.vendorContact];
  },
  updateVendorDetails: (state, { payload }) => {
    state.vendors = state.vendors.map((v) =>
      v.id !== payload.vendorId
        ? v
        : { ...v, name: payload.name, accountNumber: payload.accountNumber }
    );
  },
  updateVendorContact: (state, { payload }) => {
    state.vendorContacts = state.vendorContacts.map((v) =>
      v.id !== payload.vendorContactId
        ? v
        : { ...v, name: payload.name, email: payload.email }
    );
  },
  deleteVendorContact: (state, { payload }) => {
    state.vendorContacts = state.vendorContacts.filter((v) => v.id !== payload);
  },
});

export const fetchContractorVendors = () => async (dispatch) => {
  try {
    const {
      data: { vendors, vendorContacts },
    } = await axios.get(`/api/contractor_vendors`);

    dispatch({
      type: "fetchContractorVendors",
      payload: { vendors, vendorContacts },
    });
  } catch (error) {}
};

export const updatePurchaseOrderVendor = (purchaseOrderId, vendorId) => async (
  dispatch
) => {
  try {
    await axios.put(`/api/purchase_orders/update/vendor/${purchaseOrderId}`, {
      vendorId,
    });

    dispatch({
      type: "updatePurchaseOrderVendor",
      payload: { purchaseOrderId, vendorId },
    });
  } catch (error) {}
};

export const updateVendorDetails = (name, accountNumber, vendorId) => async (
  dispatch
) => {
  try {
    await axios.put(`/api/contractor_vendors/details`, {
      vendorId,
      accountNumber,
      name,
    });

    dispatch({
      type: "updateVendorDetails",
      payload: { name, vendorId, accountNumber },
    });
  } catch (error) {}
};

export const createVendor = (name, accountNumber) => async (dispatch) => {
  try {
    const {
      data: { vendor },
    } = await axios.post("/api/contractor_vendors", {
      name,
      accountNumber,
    });

    dispatch({
      type: "createVendor",
      payload: { vendor },
    });
  } catch (error) {}
};

export const deleteVendor = (vendorId) => async (dispatch) => {
  try {
    await axios.delete(`/api/contractor_vendors/${vendorId}`);

    dispatch({
      type: "deleteVendor",
      payload: { vendorId },
    });
  } catch (error) {}
};

export const createVendorContact = (name, email, vendorId) => async (
  dispatch
) => {
  try {
    const {
      data: { vendorContact },
    } = await axios.post("/api/contractor_vendors/contacts", {
      name,
      email,
      vendorId,
    });

    dispatch({
      type: "createVendorContact",
      payload: { vendorContact },
    });
  } catch (error) {}
};

export const updateVendorContact = (details) => async (dispatch) => {
  try {
    await axios.put(`/api/contractor_vendors/contacts`, details);
    dispatch({ type: "updateVendorContact", payload: details });
  } catch (error) {}
};

export const deleteVendorContact = (vendorContactId) => async (dispatch) => {
  try {
    await axios.delete(`/api/contractor_vendors/contacts/${vendorContactId}`);
    dispatch({ type: "deleteVendorContact", payload: vendorContactId });
  } catch (error) {}
};
