import React, { useState, useEffect } from "react";

import { updatePurchaseOrderVendor } from "store/reducers/contractorVendors";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";

function SelectVendor({
  updatePurchaseOrderVendor,
  setIsManagingVendors,
  setPurchaseOrders,
  permissionScope,
  purchaseOrderId,
  vendorId,
  vendors,
}) {
  const [vendor, setVendor] = useState(0);
  const [isShowingVendorSelection, setIsShowingVendorSelection] = useState(
    false
  );

  useEffect(() => {
    if (vendorId) {
      setVendor(vendorId);
    } else {
      setVendor(0);
    }
  }, [vendorId]);

  return (
    <div>
      <VisibleSection>
        <p className="m-0 ml-2 mr-3 p-0 text-dark">
          <span>vendor: </span>
          <span style={{ fontWeight: 600 }}>
            {parseInt(vendor)
              ? `${vendors?.find((v) => v.id === parseInt(vendor))?.name} ${
                  vendors?.find((v) => v.id === parseInt(vendor))?.accountNumber
                }`
              : "none selected"}
          </span>
        </p>
        {permissionScope !== Constants.ADMIN_CONTRACTOR || (
          <span
            onClick={() =>
              setIsShowingVendorSelection((prevState) => !prevState)
            }
            style={{
              textDecoration: "underline",
              fontSize: "0.8rem",
              cursor: "pointer",
              fontWeight: 400,
            }}
            className="text-default"
          >
            {isShowingVendorSelection ? "-" : "+"} edit
          </span>
        )}
      </VisibleSection>
      {permissionScope !== Constants.ADMIN_CONTRACTOR || (
        <SelectForm
          display={isShowingVendorSelection ? "flex" : "none"}
          className="m-2"
        >
          <select
            value={vendor}
            required
            onChange={(e) => {
              const vendorId = parseInt(e.target.value) || null;
              updatePurchaseOrderVendor(purchaseOrderId, vendorId)
                .then(() => {
                  setVendor(vendorId || 0);
                  setPurchaseOrders((prevState) =>
                    prevState.map((po) =>
                      po.id === purchaseOrderId ? { ...po, vendorId } : po
                    )
                  );
                })
                .catch(() => {
                  Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text: "There was an issue selecting your vendor",
                  });
                });
            }}
            className="form-control text-dark form-control-sm"
          >
            <option value={0}></option>

            {vendors.map(({ id, name, accountNumber }) => {
              return (
                <option key={id} value={id}>
                  {name} {accountNumber && `- ${accountNumber}`}
                </option>
              );
            })}
          </select>
          <Button
            onClick={() => setIsManagingVendors(true)}
            color="dark"
            className="mt-2"
            size="sm"
          >
            edit vendors
          </Button>
        </SelectForm>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  vendors: state.contractorVendors.vendors,
});

export default connect(mapStateToProps, { updatePurchaseOrderVendor })(
  SelectVendor
);

const VisibleSection = styled.div`
  display: flex;
  align-items: center;
`;

const SelectForm = styled.div`
  display: ${(props) => props.display};
  flex-direction: column;
  align-items: flex-end;
`;
