import React from "react";

import styled from "styled-components";

export default function ExtraSettings({ history, companyId }) {
  return (
    <ExtraSettingsWrapper className="pb-0 pl-2 pt-1">
      <div className="mt-0 pt-0 pb-0">
        <p
          onClick={() => {
            history.push(`/app/view/company/${companyId}`);
          }}
          className="m-0 p-0 text-dark"
          style={{ fontWeight: "800", cursor: "pointer" }}
        >
          <span className="btn-inner--icon mr-1 ml-3">
            <i className="fa fa-arrow-circle-right" />
          </span>
          view public page
        </p>
      </div>

      <div>
        <p
          onClick={() => {
            history.push(`/app/help`);
          }}
          className="m-0 p-0 text-start text-dark"
          style={{ fontWeight: "800", cursor: "pointer" }}
        >
          <span className="btn-inner--icon mr-1 ml-3">
            <i className="fa fa-question-circle" />
          </span>
          get help
        </p>
      </div>
    </ExtraSettingsWrapper>
  );
}

const ExtraSettingsWrapper = styled.div`
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    font-size: 14px;
    white-space: nowrap;
  }
`;
