import React, { useEffect, useState, useCallback } from "react";

import UploadedImage from "../../../utils/UploadedImage";
import { fetchJob } from "store/reducers/jobs.js";
import { withRouter } from "react-router-dom";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import JobCard from "./JobCard";
import Swal from "sweetalert2";
import axios from "axios";

function PrintableJobCard({ match, history, vendors, fetchJob, companyType }) {
  const [job, setJob] = useState({
    address: "",
    assignedToCompanyId: null,
    customCrewMemberName: null,
    assignedToUserId: null,
    assignedToUserInfo: {},
    calendarAssignments: [],
    city: "",
    color: "black",
    companyId: null,
    companyInfo: {},
    contractAmount: null,
    contractorCompanyName: "",
    contractorId: null,
    crewId: null,
    customSubcontractorName: null,
    dateInserted: "",
    endDate: "",
    id: null,
    isAcceptedBySub: false,
    inspectionNote: "",
    isCustom: true,
    jobCrewTableId: null,
    jobCustomer: null,
    jobNumber: "",
    latitude: null,
    longitude: null,
    reports: [],
    startDate: null,
    state: null,
    status: null,
    tags: [],
    title: null,
    zip: "",
  });
  const [jobImages, setJobImgs] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [showingImages, setShowingImages] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");

  const fetchJobDetails = useCallback(
    async (id) => {
      try {
        const jobDetails = await fetchJob(id);

        await fetchJobImages(id);
        await fetchWorkOrders(id);
        companyType === Constants.CONTRACTOR && (await fetchPurchaseOrders(id));

        if (jobDetails) {
          setJob(jobDetails);
        } else {
          history.push("/app/jobs");
        }
      } catch (error) {
        history.push("/app/jobs");
      }
    },
    [fetchJob, history, companyType]
  );

  useEffect(() => {
    companyType !== "" && fetchJobDetails(parseInt(match.params.id));
  }, [match.params.id, fetchJobDetails, companyType]);

  const fetchJobImages = async (jobId) => {
    try {
      const {
        data: { imgs },
      } = await axios.get(`/api/jobs/fetchImgs/${jobId}`);

      setJobImgs(imgs);
    } catch (err) {}
  };

  const fetchWorkOrders = async (jobId) => {
    try {
      const {
        data: { orders },
      } = await axios.get(`/api/work_orders/job/${jobId}`);

      setWorkOrders(orders);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching work orders",
      });
    }
  };

  const fetchPurchaseOrders = async (jobId) => {
    try {
      const {
        data: { orders },
      } = await axios.get(`/api/purchase_orders/job/${jobId}`);

      setPurchaseOrders(orders);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching purchase orders",
      });
    }
  };

  return (
    <Cover className="bg-lighter p-4">
      <ToolBarWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            className="mr-2"
            size="sm"
            color="default"
            onClick={(e) => {
              e.stopPropagation();
              window.print();
            }}
          >
            save & print
          </Button>
          <p
            onClick={() => setShowingImages((prevState) => !prevState)}
            className="image-picker-button text-default text-underline"
          >
            choose image
          </p>
        </div>
        <ImagesWrapper display={showingImages ? "flex" : "none"}>
          <ChooseNone
            style={{ width: "150px", height: "150px", cursor: "pointer" }}
            className="mb-2 mr-2 shadow--hover bg-white"
            onClick={() => setPreviewSrc("")}
            value=""
          >
            <div className="cross text-danger">x</div>
          </ChooseNone>
          {jobImages.map((image) => {
            return (
              <UploadedImage
                alt="image which is representative of job"
                className="mb-2 mr-2 shadow--hover"
                onClick={(e) => {
                  setPreviewSrc(image.imgUrl);
                }}
                value={image.imgUrl}
                src={image.imgUrl}
                background="white"
                key={image.id}
                display="fill"
                height="150"
                width="150"
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            );
          })}
        </ImagesWrapper>
      </ToolBarWrapper>
      <JobCard
        isContractor={companyType === Constants.CONTRACTOR}
        purchaseOrders={purchaseOrders}
        workOrders={workOrders}
        previewSrc={previewSrc}
        customer={job.customer}
        vendors={vendors}
        job={job}
      />
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
  vendors: state.contractorVendors.vendors,
});

export default connect(mapStateToProps, { fetchJob })(
  withRouter(PrintableJobCard)
);

const Cover = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: scroll;

  @media print {
    @page {
      size: A4;
      margin: 0mm;
    }
  }
`;

const ToolBarWrapper = styled.div`
  width: 1069px;
  margin: 15px auto;

  .image-picker-button {
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  @media print {
    display: none;
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const ImagesWrapper = styled.div`
  display: ${(props) => props.display};
  flex-wrap: wrap;
`;

const ChooseNone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .cross {
    font-size: 100px;
    font-weight: 600;
  }
`;
