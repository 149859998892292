import React, { useEffect } from "react";

import subscribe from "utils/clickTracker.js";

/*
 * Wrapper component.  Place around a modal or popup to close if a click occurs outside of the
 * wrapper.
 *
 * Required Props:
 *   close (function) - function to run to close the modal/popup
 *   isOpen (boolean) - boolean used to determine if the modal/popup is displayed
 *   children (JSX) - wrapped JSX
 */

let unsub;
export default function CloseOnOutsideClick({ close, isOpen, children }) {
  useEffect(() => {
    if (unsub) {
      unsub();
    }

    if (isOpen) {
      unsub = subscribe(close);
      return unsub;
    }
  }, [close, isOpen]);

  return <div onClick={stopPropagation}>{children}</div>;
}

const stopPropagation = (e) => e.stopPropagation();
