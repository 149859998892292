import React, { useState, useEffect } from "react";

import {
  deleteVendor,
  updateVendorDetails,
} from "store/reducers/contractorVendors";
import CreateVendorContactForm from "./CreateVendorContactForm";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Input, Button } from "reactstrap";
import VendorContact from "./VendorContact";
import Constants from "utils/Constants";

function Vendor({
  vendor,
  userType,
  deleteVendor,
  vendorContacts,
  setPurchaseOrders,
  updateVendorDetails,
}) {
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [isEditingVendorName, setIsEditingVendorName] = useState(false);
  const [newName, setNewName] = useState("");
  const [newAccountNumber, setNewAccountNumber] = useState("");

  useEffect(() => {
    setNewName(vendor?.name || "");
    setNewAccountNumber(vendor?.accountNumber || "");
  }, [vendor]);

  return (
    <div className="mb-2">
      <HeaderBar className="p-2 rounded-top bg-lighter">
        {isEditingVendorName || (
          <p className="m-0 text-darker" style={{ fontWeight: 600 }}>
            {vendor.name} {vendor.accountNumber && `- ${vendor.accountNumber}`}
          </p>
        )}
        {isEditingVendorName && (
          <form
            onSubmit={() => {
              setIsEditingVendorName(false);
              updateVendorDetails(newName, newAccountNumber, vendor.id);
            }}
            className="update-product-category"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Input
              value={newName}
              className="mr-2 text-dark"
              placeholder="new name"
              onChange={(e) => setNewName(e.target.value)}
              type="text"
              required
            />
            <Input
              value={newAccountNumber}
              className="mr-2 text-dark"
              placeholder="new account number"
              onChange={(e) => setNewAccountNumber(e.target.value)}
              type="text"
            />
            <Button color="default" type="submit">
              update
            </Button>
          </form>
        )}
        {userType === Constants.ADMIN && (
          <div className="action-btns">
            <p
              onClick={() => setIsAddingContact((prevState) => !prevState)}
              className="m-0 p-0 text-success mr-2 action-btn"
            >
              {isAddingContact ? "-" : "+"} add contact
            </p>

            <p
              onClick={() => setIsEditingVendorName((prevState) => !prevState)}
              className="m-0 p-0 text-default mr-2 action-btn"
            >
              edit
            </p>
            <p
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text:
                    "Deleting this vendor will delete all contacts within it as well. You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#F3123E",
                  cancelButtonColor: "#08080B",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteVendor(vendor.id);
                    setPurchaseOrders((prevState) =>
                      prevState.map((po) => {
                        return po.vendorId === vendor.id
                          ? { ...po, vendorId: null }
                          : po;
                      })
                    );
                  }
                });
              }}
              className="m-0 p-0 text-danger action-btn"
            >
              delete
            </p>
          </div>
        )}
      </HeaderBar>
      {isAddingContact && <CreateVendorContactForm vendorId={vendor.id} />}
      <div
        className="p-1 pl-2 mt-4 mb-2 border-bottom border-light"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="w-50 m-0 p-0 text-dark font-weight-bold">Name</p>
        <p className="w-50 m-0 p-0 text-dark font-weight-bold">Email</p>
      </div>
      {vendorContacts.map((contact) => {
        return (
          <VendorContact
            key={contact.id}
            contact={contact}
            userType={userType}
          />
        );
      })}
      {!vendorContacts.length && (
        <p className="m-0 ml-2 text-darker" style={{ fontStyle: "italic" }}>
          nothing to show
        </p>
      )}
    </div>
  );
}

export default connect(null, { deleteVendor, updateVendorDetails })(Vendor);

const HeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .action-btns {
    display: flex;
    align-item: center;
    .action-btn {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
