import React, { useEffect, useState } from "react";

import { fetchCalendars, setSelectedCalendar } from "store/reducers/calendars";
import JobDetails from "../jobstab/popups/jobdetails/JobDetails";
import { fetchJobs, fetchJob } from "store/reducers/jobs.js";
import NewJob from "../jobstab/popups/newjob/NewJob";
import { Route, withRouter } from "react-router-dom";
import AppointmentPopup from "./AppointmentPopup";
import NewAppointment from "./NewAppointment";
import DeleteCalendar from "./DeleteCalendar";
import EditCalendar from "./CreateCalendar";
import JobEditPopup from "./JobEditPopup";
import Constants from "utils/Constants";
import { connect } from "react-redux";
import Calendar from "./Calendar";
import moment from "moment";

const CalendarWrapper = ({
  setSelectedCalendar,
  selectedCalendar,
  fetchCalendars,
  filterLocation,
  filterStatus,
  companyType,
  fetchJobs,
  calendars,
  companyId,
  startDate,
  fetchJob,
  endDate,
  userId,
  jobs,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let fetch = true;
    if (fetch && companyType) {
      setLoading(true);
      fetchJobs(companyType).finally(() => setLoading(false));
      fetchCalendars(companyId);
    }

    return () => (fetch = false);
    // eslint-disable-next-line
  }, [companyType, userId, companyId]);

  const filterByStatus = (item) => {
    if (filterStatus === "all") {
      return true;
    } else if (filterStatus === "active") {
      if (item.status === "active") {
        return true;
      } else if (item?.extendedProps?.status === "active") {
        return true;
      }
    } else if (filterStatus === "completed") {
      if (item.status === "complete") {
        return true;
      } else if (item?.extendedProps?.status === "complete") {
        return true;
      }
    }
    return false;
  };

  const filterByLocation = (job) => {
    if (filterLocation === "all locations") {
      return true;
    } else if (job?.city?.toLowerCase() === filterLocation) {
      return true;
    } else if (job?.extendedProps?.city?.toLowerCase() === filterLocation) {
      return true;
    }
    return false;
  };

  const filterByStartDate = (job) => {
    const diffInDays = parseInt(
      moment(job?.extendedProps?.startDate || job.startDate).diff(
        startDate,
        "days"
      )
    );
    if (startDate) {
      return diffInDays >= 0;
    }
    return true;
  };

  const filterByEndDate = (job) => {
    const jobStart = moment(job?.extendedProps?.startDate || job.startDate)
      .subtract(1, "days")
      .format();
    const filterEnd = endDate;
    const diffInDays = parseInt(moment(jobStart).diff(filterEnd, "days"));
    if (endDate) {
      return diffInDays < 0;
    }
    return true;
  };

  return (
    <>
      <Route
        path="/app/calendar/newJob"
        render={(props) => (
          <NewJob
            {...props}
            routeTo="/app/calendar"
            isContractor={
              !companyType ? true : companyType === Constants.CONTRACTOR
            }
          />
        )}
      />
      <Route
        path="/app/calendar/new_appointment"
        render={(props) => (
          <NewAppointment
            {...props}
            isContractor={
              !companyType ? true : companyType === Constants.CONTRACTOR
            }
          />
        )}
      />
      <Route
        path="/app/calendar/job/details/:id"
        render={(props) => <JobDetails jobs={jobs} {...props} />}
      />
      <div className="" style={{ margin: "auto" }}>
        <Route path="/app/calendar/edit" component={EditCalendar} />
        <Route
          path="/app/calendar/event_options/:id"
          render={(props) => <JobEditPopup {...props} calendars={calendars} />}
        />
        <Route
          path="/app/calendar/appointment_options/:id"
          render={(props) => (
            <AppointmentPopup {...props} calendars={calendars} />
          )}
        />
        <>
          {deleting ? (
            <DeleteCalendar
              setSelectedCalendar={setSelectedCalendar}
              selectedCalendar={selectedCalendar}
              setDeleting={setDeleting}
              calendars={calendars}
            />
          ) : (
            ""
          )}
        </>
        <Calendar
          validRange={{ start: startDate, end: endDate }}
          shouldShowBtn={companyType === Constants.CONTRACTOR}
          setSelectedCalendar={setSelectedCalendar}
          filterByEndDate={filterByEndDate}
          filterByStartDate={filterByStartDate}
          filterByLocation={filterByLocation}
          selectedCalendar={selectedCalendar}
          filterByStatus={filterByStatus}
          setDeleting={setDeleting}
          setLoading={setLoading}
          calendarsList={calendars}
          fetchJob={fetchJob}
          loading={loading}
          jobs={jobs}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyType: state.company.company.companyType,
    userId: state.auth.user.id,
    companyId: state.auth.user.companyId,
    calendars: state.calendars.calendars,
    selectedCalendar: state.calendars.selectedCalendar,
    filterLocation: state.jobs.filters.filterLocation,
    filterStatus: state.jobs.filters.filterStatus,
    jobs: state.jobs.jobs,
    startDate: state.calendars.filters.startDate,
    endDate: state.calendars.filters.endDate,
  };
};

export default connect(mapStateToProps, {
  fetchJobs,
  fetchJob,
  fetchCalendars,
  setSelectedCalendar,
})(withRouter(CalendarWrapper));
