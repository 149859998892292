import React from "react";

import { Cover } from "./StyledComponents";
import { withRouter } from "react-router-dom";

function Help({ history }) {
  return (
    <Cover
      onClick={() => {
        history.push("/app/jobs");
      }}
    ></Cover>
  );
}

export default withRouter(Help);
