import React, { useState, useEffect } from "react";

import { FormGroup, Input, Container, Button, Form } from "reactstrap";
import Constants from "../../../utils/Constants";
import styled from "styled-components";
import PropTypes from "prop-types";

const BasicCompanyInfo = ({ formState, setFormState, handleSetStep }) => {
  const [defaultCompanyName, setDefaultCompanyName] = useState("");

  useEffect(() => {
    // default company name is optionally used for crews without a company name
    setDefaultCompanyName(
      `${formState.firstName} ${formState.lastName} - ${formState.location}`
    );
  }, [formState]);

  return (
    <>
      <FormWrapper>
        <Container className="container-sm p-3 mb-5">
          <p className="display-4 font-weight-light text-darker p-0 m-0 text-left">
            Fantastic!
          </p>
          <p className="text-dark lead m-0 p-0 text-left">
            Give us a bit of info on your company.
          </p>
          <p
            onClick={() => {
              handleSetStep(2);
            }}
            style={{ cursor: "pointer" }}
            className="text-default text-underline m-0 p-0 text-left"
          >
            go back a step
          </p>
          <hr className="mt-3" />
          <p className="text-dark text-left mb-0">
            *Company Name (viewable by{" "}
            {formState.account_type === "contractor" ? "crews" : "contractors"})
          </p>
          {formState.account_type === Constants.SUBCONTRACTOR && (
            <p className="text-dark text-left mb-2">
              <small>
                <i>
                  dont have a company name? click{" "}
                  <DefaultNameBtn
                    onClick={() => {
                      const company_name = defaultCompanyName;
                      setFormState((prevState) => {
                        return {
                          ...prevState,
                          company_name,
                        };
                      });
                    }}
                    className="text-default"
                  >
                    here
                  </DefaultNameBtn>
                </i>
              </small>
            </p>
          )}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSetStep(4);
            }}
          >
            <FormGroup>
              <Input
                className="text-dark"
                required
                type="text"
                value={formState.company_name}
                onChange={(e) => {
                  const company_name = e.target.value;
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      company_name,
                    };
                  });
                }}
              />
            </FormGroup>
            <p className="text-dark text-left mb-0">*Your Position</p>
            <FormGroup>
              <Input
                className="text-dark"
                required
                type="text"
                value={formState.position}
                onChange={(e) => {
                  const position = e.target.value;
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      position,
                    };
                  });
                }}
              />
            </FormGroup>
            <Button
              className="btn-icon mt-5"
              block
              color="default"
              size="md"
              type="submit"
            >
              <span className="btn-inner--text">confirm company info</span>
              <span className="btn-inner--icon">
                <i className="fa fa-arrow-circle-right fa-lg" />
              </span>
            </Button>
          </Form>
        </Container>
      </FormWrapper>
    </>
  );
};

export default BasicCompanyInfo;

BasicCompanyInfo.propTypes = {
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  handleSetStep: PropTypes.func.isRequired,
};

const FormWrapper = styled.div`
  margin: auto;
`;

const DefaultNameBtn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;
