import React, { useState } from "react";

import { Button, Input } from "reactstrap";

export default function CreateCustomCrew({ addCustomCrew }) {
  const [name, setName] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addCustomCrew(name);
      }}
      className="mb-2"
    >
      <Input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="crew name"
        className="text-dark mb-2"
        maxLength="255"
        required
      />
      <Button type="submit" color="default">
        submit
      </Button>
    </form>
  );
}
