import React from "react";

import CornerClipPathEmbelishment from "./CornerClipPathEmbelishment";
import DashboardPic from "../../../../imgs/jobstab.png";
import WorkerPhoto from "../../../../imgs/workerphoto1.jpeg";
import SubPhoto from "../../../../imgs/subphoto.jpeg";
import VendorPhoto from "../../../../imgs/vendorphoto.jpeg";
import DotTexture from "../../../../imgs/dotbgtexture.png";
import { Container } from "reactstrap";
import styled from "styled-components";

export default function OverviewInfo() {
  return (
    <Container
      className="p-4 pt-6 pb-8 container-fluid"
      style={{
        position: "relative",
        backgroundColor: "#ffffff",
      }}
      fluid
    >
      <DotBackgroundDiv
        style={{
          backgroundImage: `url(${DotTexture})`,
        }}
      />
      <CornerClipPathEmbelishment />
      <AbsolutePositionedImg
        src={DashboardPic}
        alt=""
        className="rounded shadow"
      />
      <FlexWrapper>
        <LeftInfoWrapper>
          <p className="lead text-primary mb-0" style={{ fontWeight: "600" }}>
            So we're on the same page...
          </p>
          <div className="border-bottom border-dark mb-4">
            <h3 className="display-2 text-dark">
              Why is scheduling work so difficult?
            </h3>
          </div>
          <UserTypeWrapper>
            <ImgPlaceholder className="bg-purple" src={SubPhoto} alt="" />
            <div className="ml-4">
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "800", fontSize: "30px" }}
              >
                Contractors
              </p>
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "400", fontSize: "22px" }}
              >
                All of your jobs and crew communication in one spot
              </p>
            </div>
          </UserTypeWrapper>
          <UserTypeWrapper>
            <ImgPlaceholder className="bg-default" src={WorkerPhoto} alt="" />
            <div className="ml-4">
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "800", fontSize: "30px" }}
              >
                Subcontractors
              </p>
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "400", fontSize: "22px" }}
              >
                No more phone tag... Get to work, get paid, that's it
              </p>
            </div>
          </UserTypeWrapper>
          <UserTypeWrapper>
            <ImgPlaceholder className="bg-green" src={VendorPhoto} alt="" />
            <div className="ml-4">
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "800", fontSize: "30px" }}
              >
                Vendors
              </p>
              <p
                className="text-dark m-0 p-0"
                style={{ fontWeight: "400", fontSize: "22px" }}
              >
                What are your contractors ordering a month from now? Find out
              </p>
            </div>
          </UserTypeWrapper>
        </LeftInfoWrapper>
        <RightInfoWrapper>
          <img src={DashboardPic} className="w-100 rounded shadow" alt="" />
        </RightInfoWrapper>
      </FlexWrapper>
    </Container>
  );
}

const LeftInfoWrapper = styled.div`
  width: 500px;
  text-align: left;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const RightInfoWrapper = styled.div`
  text-align: left;
  padding: 15px;
  margin-top: 24px;
  width: 400px;
  height: 100%;
  display: none;
  z-index: 10;
  @media (max-width: 900px) {
    width: 100%;
    display: block;
  }
`;

const FlexWrapper = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  display: flex;
  margin: auto;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ImgPlaceholder = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
`;

const UserTypeWrapper = styled.div`
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AbsolutePositionedImg = styled.img`
  width: 650px;
  position: absolute;
  bottom: 200px;
  right: -150px;
  @media (max-width: 1100px) {
    right: -300px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const DotBackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
`;
