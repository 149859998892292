import React, { useState } from "react";
import styled from "styled-components";
import Color from "color";

export default function Unscheduled({
  shouldBeDraggable,
  handleEventClick,
  inactiveJobs,
  history,
}) {
  const [isShowing, setIsShowing] = useState(true);

  return (
    <Wrapper
      className={`${
        isShowing ? "bg-white" : "bg-lighter"
      } p-2 mr-3 border-bottom border-lighter`}
      id="external-events"
    >
      <div
        className="m-0 ml-2 p-0 text-darker lead"
        style={{
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
        }}
      >
        <span className={`${isShowing ? "text-dark" : "text-gray"}`}>
          Unscheduled
        </span>
        <span
          onClick={() => setIsShowing((prevState) => !prevState)}
          className="text-default ml-3"
          style={{
            fontSize: "12px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {isShowing ? "hide" : "show"}
        </span>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {isShowing &&
          inactiveJobs.map((j, i) => {
            const LIGHTEN_AMT = 0.5;

            const borderColor =
              j.extendedProps.status !== "active"
                ? Color("#738394").lighten(LIGHTEN_AMT).hex()
                : Color(j.extendedProps.color).lighten(LIGHTEN_AMT).hex() ||
                  Color("#738394").lighten(LIGHTEN_AMT).hex();
            const bgColor = "#f9f9f9";
            return (
              <div key={j.id} id={`CalendarPopoverItem-${j.id}`}>
                <div
                  className={`${
                    shouldBeDraggable && "draggable"
                  } text-dark rounded p-1 m-1 shadow--hover`}
                  onClick={() => {
                    handleEventClick(j.extendedProps.id);
                  }}
                  style={{
                    display: "block",
                    width: "150px",
                    fontSize: "12px",
                    fontWeight: "600",
                    cursor: "grab",
                    background: bgColor,
                    border: `1px solid ${borderColor}`,
                    borderLeft: `7px solid ${
                      j.extendedProps.status !== "active"
                        ? "#738394"
                        : j.extendedProps.color || "#738394"
                    }`,
                  }}
                  title={j.title}
                  data={j.id}
                >
                  <p
                    className="m-0 p-0 pl-1 pr-1 text-dark"
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      cursor: "pointer",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {j.title}
                  </p>

                  <p
                    className="m-0 p-0 pl-1 pr-1 text-dark"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      cursor: "pointer",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${j.extendedProps.city}, `}
                    {`${j.extendedProps.state}`}{" "}
                  </p>
                </div>
                <div className="mb-2 buffer" />
              </div>
            );
          })}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
  position: sticky;
  top: 0px;
  z-index: 400;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 700px) {
    .buffer {
      display: none;
    }
  }
`;
