import React, { useState, useEffect } from "react";

import Highcharts from "highcharts";
import highchartsVariablePie from "highcharts/modules/variable-pie";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import moment from "moment";

highchartsVariablePie(Highcharts);

const colors = [
  "rgb(39, 90, 228)",
  "#08080B",
  "#5603ad",
  "#8898AA",
  "#fb6340",
  "#738394",
];

export default function Statistics({ jobs }) {
  // "Total Jobs By Location" chart data
  const [varpieOptions, setVarpieOptions] = useState({
    chart: {
      type: "variablepie",
    },
    colors,
    credits: {
      enabled: false,
    },
    title: {
      text: "Total Jobs By Location",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "Total Jobs: <b>{point.y}</b><br/>" +
        "Jobs Active: <b>{point.z}</b><br/>",
    },
    series: [
      {
        minPointSize: 15,
        innerSize: "50%",
        zMin: 0,
        name: "countries",
        data: [
          {
            name: "Nothing to Show",
            y: 1,
            z: 1,
          },
        ],
      },
    ],
  });

  // "Todays Jobs" chart data
  const [todaysJobsOptions, setTodaysJobsOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
    },
    colors,
    credits: {
      enabled: false,
    },
    title: {
      text: "Todays<br />Jobs",
      align: "center",
      verticalAlign: "middle",
      y: 60,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "110%",
      },
    },
    series: [
      {
        type: "pie",
        name: "Jobs Today",
        innerSize: "50%",
        data: [["none", 1]],
      },
    ],
  });

  /**
   * Parses job data to be used in charts
   */
  useEffect(() => {
    if (!jobs.length) {
      return;
    }
    const jobsByCity = {};

    // separate jobs by city
    for (let i = 0; i < jobs.length; i++) {
      const curJob = jobs[i];
      const { city } = curJob;
      jobsByCity[city.toLowerCase()] = jobsByCity[city.toLowerCase()]
        ? [...jobsByCity[city.toLowerCase()], curJob]
        : [curJob];
    }

    // parses data for total jobs by location chart
    let bars = Object.keys(jobsByCity).map((city) => {
      let curJobs = jobsByCity[city];
      let bar = curJobs.reduce(
        (acc, cv) => {
          acc.name = city;
          acc.y++;
          if (cv.status === "active") {
            acc.z++;
          }
          return acc;
        },
        {
          name: "",
          y: 0,
          z: 0,
        }
      );
      return bar;
    });

    // parses jobs to figure out which jobs are active today
    const now = moment(Date.now()).format();
    let jobsCountTodayByCity = Object.keys(jobsByCity)
      .map((city) => {
        let curJobs = jobsByCity[city];
        let num = curJobs.reduce((acc, cv) => {
          const { startDate, endDate } = cv;
          if (
            now >= moment(startDate).format() &&
            now <= moment(endDate).add(23, "hours").format()
          ) {
            return acc + 1;
          }
          return acc;
        }, 0);
        return [city, num];
      })
      .filter((j) => j[1] > 0);

    // set parsed data to state
    setTodaysJobsOptions((prevState) => ({
      ...prevState,
      series: [
        {
          ...prevState.series[0],
          data: jobsCountTodayByCity.length
            ? jobsCountTodayByCity
            : [["none", 1]],
        },
      ],
    }));
    setVarpieOptions((prevState) => ({
      ...prevState,
      series: [
        {
          ...prevState.series,
          data: bars,
        },
      ],
    }));
    // eslint-disable-next-line
  }, [jobs]);

  return (
    <Wrapper className="mt-1 p-3 mb-2 bg-white shadow-sm rounded">
      <div className="m-2 stat">
        <HighchartsReact highcharts={Highcharts} options={varpieOptions} />
      </div>
      <div className="m-2 stat">
        <HighchartsReact highcharts={Highcharts} options={todaysJobsOptions} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  .stat {
    width: 45%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    .stat {
      width: 100%;
    }
  }
`;
