import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  company: { companyType: "" },
  employees: [],
};

export default createReducer(initialState, {
  setURLToStore: (state, { payload }) => {
    state.company.logoUrl = payload;
  },
  updateCompanySuccess: (state, { payload }) => {
    state.company = { ...state.company, ...payload };
  },
  setCompanyInfoToStore: (state, { payload }) => {
    state.company = payload;
  },
  fetchEmployeesSuccess: (state, { payload }) => {
    state.employees = payload;
  },
  removeEmployee: (state, { payload }) => {
    state.employees = state.employees.filter((emp) => emp.id !== payload);
  },
});

export const setURLToStore = createAction("setURLToStore");
export const updateCompanySuccess = createAction("updateCompanySuccess");
export const setCompanyInfoToStore = createAction("setCompanyInfoToStore");
export const fetchEmployeesSuccess = createAction("fetchEmployeesSuccess");
export const removeEmployee = createAction("removeEmployee");

export const inviteUserToCompany = (email, userType) => async (dispatch) => {
  try {
    const response = await axios.post("/api/company/invite", {
      email,
      userType,
    });
    dispatch({
      type: "INVITE_USER_TO_COMPANY_SUCCESS",
      payload: response.data,
    });
    return { success: true, ...response.data };
  } catch (e) {
    return {
      success: false,
      message: "A user with this email already exists.",
      isResendingInvitation: false,
    };
  }
};

export const fetchCompanyUsers = (companyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/company/users/${companyId}`);
    dispatch(fetchEmployeesSuccess(data));
  } catch (e) {
    dispatch({ type: "", payload: e });
  }
};

export const updateCompany = (details, id) => async (dispatch) => {
  try {
    await axios.put(`/api/company/update/${id}`, details);
    // Would suggest having the API send something back to use as payload
    // Just to avoid multiple sources of truth
    dispatch(updateCompanySuccess(details));
  } catch (e) {
    dispatch({ type: "UPDATE_COMPANY_FAIL" });
  }
};
