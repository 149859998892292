import React, { useState, useEffect } from "react";

import {
  updateVendorContact,
  deleteVendorContact,
} from "store/reducers/contractorVendors";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Constants from "utils/Constants";

function VendorContact({
  contact,
  updateVendorContact,
  deleteVendorContact,
  userType,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setName(contact.name);
    setEmail(contact.email);
  }, [contact]);

  return (
    <Wrapper
      userType={userType}
      onClick={() =>
        userType === Constants.ADMIN && setIsUpdating((prevState) => !prevState)
      }
      className={`rounded p-1 pl-2 ${isUpdating && "bg-secondary mb-2"}`}
    >
      {isUpdating || (
        <>
          <p className="m-0 text-darker w-50">{contact.name}</p>
          <p className="m-0 text-darker w-50">{contact.email}</p>
        </>
      )}
      {isUpdating && (
        <form
          className="w-100 p-2"
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            updateVendorContact({
              vendorContactId: contact.id,
              name,
              email,
            });
            setIsUpdating(false);
          }}
        >
          <div className="w-25 mr-2">
            <label className="text-dark font-weight-bold mb-0">*name</label>
            <Input
              value={name}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setName(e.target.value)}
              required
              type="text"
              className="w-100 text-dark"
              placeholder="name"
            />
          </div>
          <div className="w-25 mr-2">
            <label className="text-dark font-weight-bold mb-0">*email</label>
            <Input
              value={email}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
              className="w-100 text-dark"
              placeholder="email"
            />
          </div>
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            color="default"
            className=""
            type="submit"
          >
            update
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              Swal.fire({
                title: "Are you sure?",
                text:
                  "Are you sure you want to delete this contact? You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#F3123E",
                cancelButtonColor: "#08080B",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteVendorContact(contact.id);
                }
              });
            }}
            color="danger"
          >
            <i className="fa fa-trash"></i>
          </Button>
        </form>
      )}
    </Wrapper>
  );
}

export default connect(null, { updateVendorContact, deleteVendorContact })(
  VendorContact
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.userType === Constants.ADMIN &&
    `
    :hover {
      background: #eff0f4;
      cursor: pointer;
    }
  `}
`;
