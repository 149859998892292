import { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchChatRooms } from "store/reducers/chat.js";
import { sortChatRoomsByLastMessage } from "utils/chat.js";
import sockets from "sockets/";

function FetchChatRooms({ hasFetched, rooms, fetchChatRooms, roomId }) {
  const history = useHistory();
  useEffect(() => {
    const roomIds = Object.keys(rooms);
    if (!hasFetched) {
      fetchChatRooms();
    } else if (roomIds.length) {
      roomIds.forEach((id) => {
        if (!rooms[id].hasFetchedRoom) {
          // If a new room was added and we haven't fetched it yet, get it
          fetchChatRooms(id);
        }
        sockets.listenForChat(id);
      });
    }
  }, [hasFetched, rooms, fetchChatRooms]);

  useEffect(() => {
    if (!roomId) {
      // If user navigates to app/messages, this will fire,
      // find the room with the most recent activity,
      // and then route the user to that chat channel
      const sortedRooms = sortChatRoomsByLastMessage(rooms);
      if (sortedRooms.length) {
        const newestRoomId = sortedRooms[0].roomId;
        if (newestRoomId) {
          history.push("/app/messages/" + newestRoomId);
        }
      }
    }

    if (roomId && !rooms[roomId]) {
      fetchChatRooms(roomId);
    }
  }, [history, roomId, rooms, fetchChatRooms]);

  return null;
}

const mapStateToProps = (state) => ({
  rooms: state.chat.rooms,
  roomId: state.chat.activeRoom,
  hasFetched: state.chat.hasFetched,
});

const mapDispatchToProps = { fetchChatRooms };

export default connect(mapStateToProps, mapDispatchToProps)(FetchChatRooms);
