import React from "react";

import DetailsMap from "components/app/utils/DetailsMap";
import InspectionNotes from "./InspectionNotes";
import { withRouter } from "react-router";
import Tags from "../../../calendar/Tags";
import moment from "moment";

function DetailsList({ job, history }) {
  return (
    <div className="p-2 mt-2">
      <Tags jobId={job.id} tags={job.tags} />
      <InspectionNotes jobId={job.id} inspectionNote={job.inspectionNote} />
      <div className="border-bottom border-light mb-2">
        <label className="m-0 p-0" style={{ fontSize: "12px" }}>
          start date
        </label>
        <p
          className="m-0 mb-1 p-0"
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {job.startDate ? moment(job.startDate).format("MMM Do YYYY") : "-"}
        </p>
      </div>
      <div className="border-bottom border-light mb-2">
        <label className="m-0 p-0" style={{ fontSize: "12px" }}>
          end date
        </label>
        <p
          className="m-0 mb-1 p-0"
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {job.endDate ? moment(job.endDate).format("MMM Do YYYY") : "-"}
        </p>
      </div>
      <div className="border-bottom border-light mb-2">
        <label className="m-0 p-0" style={{ fontSize: "12px" }}>
          contractor name
        </label>
        <p
          className="m-0 mb-1 p-0 text-primary text-underline"
          style={{ fontSize: "18px", fontWeight: "600" }}
          onClick={() => {
            if (!job.isCustom) {
              history.push(`/app/view/company/${job.contractorId}`);
            }
          }}
        >
          {job.contractorCompanyName}
        </p>
      </div>
      <div className="border-bottom border-light mb-2">
        <label className="m-0 p-0" style={{ fontSize: "12px" }}>
          subcontractor name
        </label>
        <p
          className={`m-0 mb-1 p-0 ${
            !job.isCustom && "text-primary text-underline"
          }`}
          onClick={() => {
            if (!job.isCustom) {
              history.push(`/app/view/company/${job.assignedToCompanyId}`);
            }
          }}
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {job.isCustom
            ? job.customSubcontractorName || "-"
            : job?.companyInfo?.name
            ? job.companyInfo.name
            : "-"}{" "}
          {!job.isCustom && job.isAcceptedBySub
            ? "(accepted)"
            : !job.isCustom && !job.isAcceptedBySub
            ? "(pending acceptance)"
            : ""}
        </p>
      </div>
      <div className="border-bottom border-light mb-4">
        <label className="m-0 p-0" style={{ fontSize: "12px" }}>
          assigned to
        </label>
        <p
          className="m-0 mb-1 p-0"
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {job.isCustom
            ? job.customCrewMemberName || "-"
            : Object.keys(job.assignedToUserInfo).length
            ? `${job.assignedToUserInfo.firstName} ${job.assignedToUserInfo.lastName}`
            : "-"}
        </p>
      </div>
      <DetailsMap
        latitude={job.latitude || 0.0}
        longitude={job.longitude || 0.0}
      />
    </div>
  );
}

export default withRouter(DetailsList);
