import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const leftWidth = "225px";
const leftWidthMobile = "100px";
const headerHeight = "47px";
export const Left = styled.div`
  height: calc(100vh - ${headerHeight});
  width: ${leftWidth};
  h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  @media (max-width: 700px) {
    width: ${leftWidthMobile};
  }
`;

export const Right = styled.div`
  width: calc(100vw - ${leftWidth});
  height: calc(100vh - ${headerHeight});
  display: flex;
  flex-direction: column;
  @media (max-width: 700px) {
    width: calc(100vw - ${leftWidthMobile});
  }
`;

export const ChatLog = styled.div`
  width: calc(100vw - ${leftWidth});
  height: calc(100vh - 110px - ${headerHeight});
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: 700px) {
    width: calc(100vw - ${leftWidthMobile});
  }
`;

export const UserMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
  position: relative;
  & > div {
    border-left: ${(props) => getChatBorder(props)};
    cursor: ${({ status }) => (status === "error" ? "pointer" : null)};
    max-width: 50%;
    width: fit-content;
    overflow-wrap: break-word;
    opacity: ${({ isUser, status }) =>
      isUser && (status === "sending" || status === "error") ? 0.5 : 1};
    .author {
      display: ${({ isUser }) => (isUser ? "none" : "block")};
      margin: 0;
      position: absolute;
      top: -23px;
      left: -5px;
      padding: 1px 6px;
      border-radius: 0.25rem;
      p {
        font-size: 12px;
        font-style: italic;
        margin: 0;
        position: relative;
        z-index: 2;
      }
      div {
        display: none;
        position: absolute;
        z-index: 1;
        top: 14px;
        left: 3px;
        width: 93%;
        height: 13px;
        padding: 0;
        margin: 0;
        border-radius: 0;
        background-color: rgb(219, 211, 247);
      }
    }
    .contents {
      margin: 0;
    }
  }
`;

export const SystemMessage = styled.div`
  div {
    border-top: 2px solid black;
    height: 2px;
    position: relative;
    top: 24px;
    z-index: 1;
  }
  p {
    width: fit-content;
    margin: 0.5rem auto calc(0.5rem - 8px);
    background-color: #eff0f4;
    position: relative;
    z-index: 2;
    padding: 0 5px;
  }
`;

export const TextBox = styled.div`
  width: calc(100vw - ${leftWidth});
  @media (max-width: 700px) {
    width: calc(100vw - ${leftWidthMobile});
  }
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  form {
    display: flex;
    justify-content: flex-start;
    textarea {
      width: calc(100% - 100px);
      fontsize: 14px;
      resize: none;
    }
    button {
      cursor: pointer;
      width: 100px;
    }
  }
`;

export const BottomButtons = styled.div`
  display: flex;
  span,
  label {
    cursor: pointer;
    :hover {
      color: #3966ea;
    }
  }
`;

export const ChatIcon = styled.div`
  background-color: ${({ active }) => (active ? "#F0F2F5" : "#FFF")};
  cursor: ${({ active }) => (active ? null : "pointer")};
  padding: 5px;
  display: flex;
  img {
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
  div {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 50px);
  }
  h2 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: ${leftWidth} - 50px;
    width: 100%;
  }
  p {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    font-weight: 0;
    padding: 0px;
    text-align: left;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: ${leftWidth} - 50px;
    width: 100%;
  }
`;

export const TextLimit = styled.p`
  position: absolute;
  font-size: 9px;
  right: 125px;
`;

export const AttachmentsContainer = styled.div`
  min-height: 75px;
  margin: 5px 0;
  padding: 0 19px;
  max-height: 75px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`;

export const Attachment = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  background-color: black;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  img {
    ${(props) => getImageDimensions(props)}
  }
  button {
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
    border: 1px solid black;
    background-color: rgba(255, 255, 255, 0.5);
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    padding: 0;
    padding-top: 2px;
  }
`;

export const TagList = styled.div`
  position: relative;
  & > div {
    position: absolute;
    top: -12px;
    background-color: #fff;
    border-radius: 5px;
    padding: 2px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    p,
    span {
      font-size: 8px;
      margin: 0;
    }
    span {
      margin-right: 1px;
    }
  }
`;

function getChatBorder({ isCompany, isUser, status, isTagged }) {
  const black = "5px solid #08080b";
  const red = "5px solid red";
  const blue = "5px solid #4073fd";
  const grey = "5px solid grey";
  const yellow = "5px solid rgb(245, 170, 40)";

  if (isUser) {
    return status === "error"
      ? red
      : isTagged
      ? yellow
      : status === "sending"
      ? black
      : status === "sent"
      ? grey
      : blue;
  } else {
    return isCompany ? blue : black;
  }
}

function getImageDimensions(props) {
  const bigger = props.height > props.width ? "height" : "width";
  const smaller = props.height > props.width ? "width" : "height";
  const ratio = props[bigger] / props[smaller];

  return `${bigger}: ${75 * ratio}px;\n${smaller}: 75px;`;
}
