import React, { useState } from "react";

import { Input, Button } from "reactstrap";
import Swal from "sweetalert2";

const responses = {
  "feature request":
    "We will absolutely take your feature requests into consideration.",
  complaint:
    "We take complaints very seriously and do our best to resolve your issues. If applicable, we will get back to you as soon as we can.",
  "found a bug":
    "We will do our best to patch any and all bugs as quickly as we possibly can, and we absolutely appreciate you reporting them!",
  other: "We appreciate every bit of feedback we can get!",
};

export default function Feedback() {
  const [feedbackType, setFeedbackType] = useState("feature request");
  const [subject, setSubject] = useState("");
  const [details, setDetails] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    //   ...send details to a shared email
    Swal.fire({
      icon: "success",
      title: "Thank you!",
      text: responses[feedbackType],
    });
    setFeedbackType("feature request");
    setSubject("");
    setDetails("");
  };

  return (
    <div>
      <p className="text-dark h3 m-0" style={{ fontWeight: 600 }}>
        We would love to hear from you!
      </p>
      <p className="text-dark m-0 mb-2">
        What can we do better? What features do you wish we had? Find a bug with
        the app? Anything at all, please feel free to let us know!
      </p>
      <form onSubmit={handleSubmit}>
        <label>*subject</label>
        <Input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
          type="text"
          className="text-dark"
        />
        <label>*feedback type</label>
        <select
          value={feedbackType}
          onChange={(e) => setFeedbackType(e.target.value)}
          required
          className="mb-2 text-dark w-100 p-2 border-light rounded"
        >
          <option>feature request</option>
          <option>complaint</option>
          <option>found a bug</option>
          <option>other</option>
        </select>
        <label>*details</label>
        <Input
          value={details}
          onChange={(e) => setDetails(e.target.value)}
          required
          type="textarea"
          className="text-dark"
        />
        <Button block className="mt-2" color="default" type="submit">
          submit
        </Button>
      </form>
    </div>
  );
}
