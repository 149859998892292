/**
 * This Invite component handles the form and login of a new user whos been invited to a company.
 * A user will recieve and email with a link to this component to join a company, fill out this
 * form, sign in with redirect to auth0, then be forwarded to InviteRedirect.js. For more details,
 * see InviteRedirect.js doc comment
 *
 *
 */
import React, { useState, useEffect } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import { useAuth0 } from "../../../react-auth0-spa";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

export const Invite = (props) => {
  const { loginWithRedirect, user } = useAuth0();
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    location: "",
    position: "",
    language: "english",
  });
  const { invitationId } = props.match.params;
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [email, setEmail] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(true);

  useEffect(() => {
    if (user && shouldRedirect) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "you must sign out of your previous session before creating a new account.",
      });
      props.history.push("/app/company");
    }
  }, [user, shouldRedirect, props.history]);

  useEffect(() => {
    // fetch invite info
    axios
      .get(`/api/company/invite/${invitationId}`)
      .then((res) => {
        setCompanyName(res.data.companyName);
        setCompanyId(res.data.companyId);
        setEmail(res.data.email);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an issue loading this invite. This is likely due to this invitation being out of date or already used. If reloading dose not work, please have your employer send a new invitation, or contact us for help!",
        });
        props.history.push("/");
      });
  }, [invitationId, props.history]);

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      invitationId,
    });
    // eslint-disable-next-line
  }, [invitationId]);

  const handleDeleteInvitation = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "Are you sure you want to remove this invitation?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#F3123E",
        cancelButtonColor: "#08080B",
        confirmButtonText: `Delete Invitation`,
      });
      if (confirmed.value) {
        await axios.delete(`/api/company/invitation/${invitationId}`);
        await await Swal.fire({
          icon: "success",
          title: "Sweet!",
          text: `We've deleted this invitation.`,
        });
        props.history.push("/");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "It seems there was an issue while attempting to remove this invitation.",
      });
    }
  };

  return (
    <div className="p-5 pt-0" style={{ maxWidth: "800px", margin: "auto" }}>
      <p className="display-1 text-default pt-3 mb-2">Welcome to Crewly!</p>
      <p className="lead mt-2 mb-0 text-dark" style={{ fontWeight: 600 }}>
        We're very happy to have you. Simply give us a bit more info and we'll
        get you all set up.
      </p>
      <p className="mt-2 mb-2" style={{ fontStyle: "italic" }}>
        If you believe you've received this by mistake and you'd like to delete
        this invitation, click on the red button below.
      </p>
      <Button onClick={handleDeleteInvitation} size="sm" color="danger">
        delete this invitation
      </Button>
      <hr />
      <p className="text-primary lead">
        company: <strong>{companyName}</strong>
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setShouldRedirect(false);
          const newUserInvitationData = {
            ...userInfo,
            companyId,
            invitationId,
            email,
          };
          localStorage.setItem(
            "newUserSettings",
            JSON.stringify(newUserInvitationData)
          );
          loginWithRedirect({
            screen_hint: "signup",
            redirect_uri:
              process.env.REACT_APP_INVITE_USER_REDIRECT_URL ||
              "http://localhost:3000/inviteredirect",
          });
        }}
      >
        <p className="text-dark  mb-0">*First Name</p>
        <FormGroup>
          <Input
            className="text-dark"
            placeholder="first name"
            required
            type="text"
            name="firsName"
            onChange={(e) => {
              const firstName = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  firstName,
                };
              });
            }}
            value={userInfo.firstName}
          />
        </FormGroup>
        <p className="text-dark  mb-0">*Last Name</p>
        <FormGroup>
          <Input
            className="text-dark"
            placeholder="last name"
            required
            type="text"
            name="lasName"
            onChange={(e) => {
              const lastName = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  lastName,
                };
              });
            }}
            value={userInfo.lastName}
          />
        </FormGroup>
        <p className="text-dark  mb-0">*Position</p>
        <FormGroup>
          <Input
            className="text-dark"
            placeholder="position"
            required
            type="text"
            name="position"
            onChange={(e) => {
              const position = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  position,
                };
              });
            }}
            value={userInfo.position}
          />
        </FormGroup>
        <p className="text-dark  mb-0">*Your Location</p>
        <FormGroup>
          <Input
            className="text-dark"
            placeholder="your location"
            required
            type="text"
            name="location"
            onChange={(e) => {
              const location = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  location,
                };
              });
            }}
            value={userInfo.location}
          />
        </FormGroup>
        <p className="text-dark  mb-0">Phone Number</p>
        <FormGroup>
          <Input
            className="text-dark"
            placeholder="000-000-0000"
            type="tel"
            name="phoneNumber"
            onChange={(e) => {
              const phoneNumber = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  phoneNumber,
                };
              });
            }}
            value={userInfo.phoneNumber}
          />
        </FormGroup>
        <p className="text-dark  mb-0">
          Language Preference <i>(Crewly can help bridge language barriers)</i>
        </p>
        <FormGroup style={{ display: "flex", justifyContent: "flex-start" }}>
          <select
            value={userInfo.language}
            required
            onChange={(e) => {
              const language = e.target.value;
              setUserInfo((prevState) => {
                return {
                  ...prevState,
                  language,
                };
              });
            }}
            className="form-control form-control-sm text-dark"
          >
            <option>english</option>
            <option>spanish</option>
          </select>
        </FormGroup>
        <p className="mt-2 mb-2" style={{ fontStyle: "italic" }}>
          After pressing "sign up", you will be forwarded and asked to create a
          username and password. You{" "}
          <span style={{ fontWeight: 600 }}>MUST</span> use the same email which
          this invitation was sent to.
        </p>
        <Button
          type="submit"
          className="mt-4"
          color="default"
          style={{ width: "150px" }}
        >
          sign up
        </Button>
      </form>
    </div>
  );
};

export default withRouter(Invite);
