import React from "react";

import MapComponent from "./MapComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function Map({ history, company, jobs, shouldMountMapCanvas }) {
  return (
    <>
      {shouldMountMapCanvas && (
        <MapComponent
          history={history}
          center={{
            latitude: company.latitude || 0.0,
            longitude: company.longitude || 0.0,
          }}
          jobs={jobs || []}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  company: state.company.company,
});

export default withRouter(connect(mapStateToProps, {})(Map));
