import React from "react";
import { Picker } from "emoji-mart";
import styled from "styled-components";

import CloseOnOutsideClick from "../../../utils/CloseOnOutsideClick";
import "emoji-mart/css/emoji-mart.css";

/*
 * Opens a floating emoji menu
 *
 * Required Props:
 *  show (boolean) - If the menu should render or not
 *  onClick (function) - Callback that accepts an event object (emoji at e.target.value.emoji)
 *
 * Optional Props
 *  close (function) - Function to call to close the menu (after a click)
 *  style (object) - Styles object to be put inline on the container
 */

export default function EmojiMenu({ show, onClick, close, style }) {
  const handleClick = (emoji) => {
    onClick && onClick({ target: { value: emoji } });
    close && close();
  };

  if (!show) {
    return null;
  }

  return (
    <CloseOnOutsideClick close={close} isOpen={show}>
      <EmojiContainer
        show={show}
        style={style || {}}
        data-testid="messagesEmojiContainer"
      >
        <div>
          <Picker
            title=""
            useButton={true}
            emoji=""
            native={true}
            onSelect={handleClick}
          />
        </div>
      </EmojiContainer>
    </CloseOnOutsideClick>
  );
}

const EmojiContainer = styled.div`
  position: relative;
  & > div {
    position: absolute;
    z-index: 10;
    bottom: 30px;
  }
`;
