import React from "react";

import { Button, Input } from "reactstrap";

export default function EditBasicJobDetails({
  updateTitleAndAddress,
  setAddress,
  setTitle,
  setState,
  setCity,
  address,
  setZip,
  title,
  state,
  city,
  zip,
}) {
  return (
    <form onSubmit={updateTitleAndAddress} className="mt-5">
      <label className="text-dark font-weight-bold mb-0 mt-2">Title</label>
      <Input
        value={title}
        required
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        className="text-dark border border-light mt-2"
        type="text"
      />
      <label className="text-dark font-weight-bold mb-0 mt-2">Address</label>
      <Input
        value={address}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
        className="text-dark border border-light mt-2"
        type="text"
      />
      <label className="text-dark font-weight-bold mb-0 mt-2">City</label>
      <Input
        value={city}
        required
        onChange={(e) => {
          setCity(e.target.value);
        }}
        className="text-dark border border-light mt-2"
        type="text"
      />
      <label className="text-dark font-weight-bold mb-0 mt-2">State</label>
      <Input
        value={state}
        required
        onChange={(e) => {
          setState(e.target.value);
        }}
        className="text-dark border border-light mt-2"
        type="text"
      />
      <label className="text-dark font-weight-bold mb-0 mt-2">Zip</label>
      <Input
        value={zip}
        onChange={(e) => {
          setZip(e.target.value);
        }}
        className="text-dark border border-light mt-2"
        type="text"
      />
      <Button type="submit" className="mt-3 w-100" color="default">
        Update
      </Button>
    </form>
  );
}
