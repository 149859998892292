import React, { useState } from "react";

import { Transition, animated } from "react-spring/renderprops";
import { useAuth0 } from "../../react-auth0-spa";
import MobileNavPopout from "./MobileNavPopout";
import { withRouter } from "react-router";
import { NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
// import LightBGLogo from "../../imgs/logos/PNG image file.png";
import LightBGLogo from "imgs/logos/forDarkBackground.png";

const NavBar = ({ history }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [subNavOpen, setSubNavOpen] = useState(false);

  return (
    <NavWrapper className="bg-darker">
      <InnerNavWrapper>
        <LeftWrapper>
          <NavbarBrand to="/" tag={Link}>
            <img
              className="mr-7"
              src={LightBGLogo}
              alt=""
              style={{ width: "100px", height: "auto" }}
            />
          </NavbarBrand>
          {/* <NavButtonWrapper>
            <p className="text-white">about</p>
            <p>pricing</p>
            <p>contact</p>
          </NavButtonWrapper> */}
        </LeftWrapper>
        <BtnsWrapper>
          {!isAuthenticated && (
            <SignInBtn
              onClick={() => {
                loginWithRedirect({
                  redirect_uri:
                    process.env.REACT_APP_LOGIN_URL ||
                    "http://localhost:3000/app/company",
                });
              }}
            >
              SIGN IN
            </SignInBtn>
          )}
          {!isAuthenticated && (
            <SignUpBtn
              onClick={() => {
                loginWithRedirect({
                  screen_hint: "signup",
                  redirect_uri:
                    process.env.REACT_APP_LOGIN_URL ||
                    "http://localhost:3000/app/company",
                });
              }}
            >
              SIGN UP
            </SignUpBtn>
          )}
          {isAuthenticated && (
            <SignUpBtn
              onClick={() => {
                history.push("/app/company");
              }}
            >
              account
            </SignUpBtn>
          )}
        </BtnsWrapper>
        <HamburgerIcon
          onClick={() => {
            setSubNavOpen(!subNavOpen);
          }}
        >
          <span className="btn-inner--icon">
            <i className="fa fa-bars fa-2x text-white" />
          </span>
        </HamburgerIcon>
      </InnerNavWrapper>
      <InnerNavWrapper>
        <Transition
          native
          items={subNavOpen}
          from={{ opacity: 0, marginTop: -300 }}
          enter={{ opacity: 1, marginTop: 0 }}
          leave={{ opacity: 0, marginTop: -400 }}
        >
          {(show) =>
            show &&
            ((props) => (
              <animated.div style={{ ...props, width: "100%" }}>
                <MobileNavPopout />
              </animated.div>
            ))
          }
        </Transition>
      </InnerNavWrapper>
    </NavWrapper>
  );
};

export default withRouter(NavBar);

NavBar.propTypes = {
  history: PropTypes.object.isRequired,
};

const NavWrapper = styled.header`
  width: 100%;
  padding: 15px;
  margin: 0px auto;
  z-index: 10;
`;

const InnerNavWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 1300px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BtnsWrapper = styled.div`
  @media (max-width: 850px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  i {
    color: #fff;
  }
  cursor: pointer;
  display: none;
  @media (max-width: 850px) {
    display: block;
  }
`;

// const NavButtonWrapper = styled.div`
//   @media (max-width: 850px) {
//     display: none;
//   }
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 15px;
//   p {
//     transition: 0.5s;
//     margin: 0;
//     padding: 0 15px;
//     cursor: pointer;
//     font-size: 20px;
//     font-weight: 800;
//     color: white;
//     background: transparent;
//     border: none;
//     border-bottom: 3px solid transparent;

//     :hover {
//       color: #fff;
//       border-bottom: 3px solid #fff;
//     }
//   }
// `;

export const SignUpBtn = styled.button`
  margin: 0 0 0 15px;
  width: 110px;
  padding: 10px 0;
  color: white;
  border-radius: 5px;
  transition: 0.2s;
  font-weight: 800;
  color: black;
  background: #fff;
  border: 1px solid white;

  :hover {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
  }
`;

export const SignInBtn = styled.button`
  width: 110px;
  padding: 10px 0;
  transition: 0.2s;
  font-weight: 800;

  color: #fff;
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;

  :hover {
    border-bottom: 3px solid #fff;
  }
`;
