import React from "react";

import SingleConnection from "./SingleConnection";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import {
  acceptConnection,
  declineConnection,
} from "store/reducers/connections.js";

function Connections({ connections, acceptConnection, declineConnection }) {
  const history = useHistory();

  return (
    <div className="p-2">
      <ConnectionsWrapper className="p-4 rounded bg-white shadow-sm">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            className="display-4 text-dark m-0 p-0"
            style={{ fontWeight: "600", textAlign: "center" }}
          >
            Your Connections {/* fuck you AJ */} {/* Fuck you, Tom. */}
          </p>
          <Button
            size="md"
            color="default"
            onClick={() => history.push("/app/search/companies")}
          >
            Search
          </Button>
        </div>
        <hr className="mb-4 mt-4 p-0" />
        {!connections.length && (
          <p className="p-0 m-0">No connections to show</p>
        )}

        {connections.map((conn) => (
          <SingleConnection
            declineConnection={declineConnection}
            acceptConnection={acceptConnection}
            key={conn.id}
            conn={conn}
          />
        ))}
      </ConnectionsWrapper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  connections: state.connections.connections,
});

const mapDispatchToProps = { acceptConnection, declineConnection };

export default connect(mapStateToProps, mapDispatchToProps)(Connections);

const ConnectionsWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  height: 80vh;
  overflow-y: scroll;
`;
