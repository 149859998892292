import React, { useState } from "react";

import { createVendorContact } from "store/reducers/contractorVendors";
import { Button, Input } from "reactstrap";
import { connect } from "react-redux";

function CreateVendorContactForm({ createVendorContact, vendorId }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  return (
    <form
      className="mt-0 mb-2 rounded-bottom bg-lighter p-2"
      style={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-start",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        createVendorContact(name, email, vendorId);
        setEmail("");
        setName("");
      }}
    >
      <div className="w-25 mr-2">
        <label className="text-dark font-weight-bold mb-0">*name</label>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          type="text"
          className="w-100 text-dark"
          placeholder="name"
        />
      </div>
      <div className="w-25 mr-2">
        <label className="text-dark font-weight-bold mb-0">*email</label>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          type="email"
          className="w-100 text-dark"
          placeholder="email"
        />
      </div>
      <Button color="default" className="" type="submit">
        <i className="fa fa-plus"></i> <span>add</span>
      </Button>
    </form>
  );
}

export default connect(null, { createVendorContact })(CreateVendorContactForm);
