import { useState, useEffect } from "react";
import Constants from "./Constants";

/**
 * Takes companyType and user
 */
function usePermissionScopes(companyType, userType = Constants.MEMBER) {
  const [scope, setScope] = useState("");
  useEffect(() => {
    if (companyType === Constants.CONTRACTOR) {
      if (userType === Constants.MEMBER) {
        setScope(Constants.MEMBER_CONTRACTOR);
      } else if (userType === Constants.ADMIN) {
        setScope(Constants.ADMIN_CONTRACTOR);
      } else if (userType === Constants.FOREMAN) {
        setScope(Constants.FOREMAN_CONTRACTOR);
      } else if (userType === Constants.SALESMAN) {
        setScope(Constants.SALESMAN_CONTRACTOR);
      }
    } else if (companyType === Constants.SUBCONTRACTOR) {
      if (userType === Constants.MEMBER) {
        setScope(Constants.MEMBER_SUBCONTRACTOR);
      } else if (userType === Constants.ADMIN) {
        setScope(Constants.ADMIN_SUBCONTRACTOR);
      } else if (userType === Constants.FOREMAN) {
        setScope(Constants.FOREMAN_SUBCONTRACTOR);
      } else if (userType === Constants.SALESMAN) {
        setScope(Constants.SALESMAN_SUBCONTRACTOR);
      }
    }
  }, [companyType, userType]);

  return scope;
}

export default usePermissionScopes;
