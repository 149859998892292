import { createReducer, createAction } from "@reduxjs/toolkit";

const initialState = {
  tab: null,
};

export default createReducer(initialState, {
  setSearchTab: (state, { payload }) => {
    state.tab = payload;
  },
});

export const setSearchTab = createAction("setSearchTab");
