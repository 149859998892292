import React from "react";

import styled from "styled-components";

export default function LoadingCover({ rem, display }) {
  return (
    <Cover display={display ? "flex" : "none"}>
      <div
        className="spinner-border text-dark"
        style={{ width: `${rem}rem`, height: `${rem}rem` }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </Cover>
  );
}

const Cover = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 250;
  display: ${(props) => props.display};
  justify-content: center;
  padding-top: 10%;
`;
