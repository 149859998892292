import React, { useState, useEffect, useCallback } from "react";

import CrewlyLogo from "imgs/logos/forDarkBackground.png";
import NotificationsDropdown from "./subnavs/NotificationsDropdown";
import AccountDropdown from "./subnavs/AccountDropdown";
import { withRouter /* Route */ } from "react-router-dom";
import NotificationWatcher from "../../utils/NotificationWatcher.js";
// import SearchSubNav from "./subnavs/SearchSubNav";
import { Redirect } from "react-router";
import styled from "styled-components";
import { connect } from "react-redux";
import NavBarIcon from "./NavBarIcon";
import PropTypes from "prop-types";
import { hasSeenNotifications } from "store/reducers/notifications.js";
import CloseOnOutsideClick from "../../../utils/CloseOnOutsideClick.js";

function NavBar({ history, user, hasFetchedUser, hasSeenNotifications }) {
  const [dropdown, setDropdown] = useState();
  const closeDropdown = useCallback(setDropdown.bind(this, ""), []);
  useEffect(() => {
    if (dropdown === "notifications") {
      hasSeenNotifications("bell");
    }
  }, [dropdown, hasSeenNotifications]);

  // handling this here because this component renders on all /app routes besides /app/configure
  const { accountCreationComplete } = user;
  if (hasFetchedUser && !accountCreationComplete) {
    return <Redirect to="/app/configure" />;
  }

  return (
    <>
      <CloseOnOutsideClick close={closeDropdown} isOpen={dropdown}>
        <div onMouseLeave={closeDropdown}>
          <AccountDropdown
            display={dropdown === "account" ? "block" : "none"}
          />
        </div>
        <div onMouseLeave={closeDropdown}>
          <NotificationsDropdown
            display={dropdown === "notifications" ? "block" : "none"}
          />
        </div>
      </CloseOnOutsideClick>
      <FullNavWrapper>
        <TopNavWrapper className="bg-darker">
          <TopNavLeftWrapper>
            <CrewlyIcon
              onClick={(e) => {
                e.preventDefault();
                history.push("/app/company");
              }}
              className="text-white"
              src={CrewlyLogo}
              style={{
                width: "75px",
                height: "auto",
              }}
              alt=""
            />
          </TopNavLeftWrapper>
          <OuterIconWrapper className="ml-4 mr-2">
            <NotificationWatcher watch="connections">
              <NavBarIcon routeTo="/app/connections" iconName="fa fa-users" />
            </NotificationWatcher>
            <NotificationWatcher watch="messages">
              <NavBarIcon routeTo="/app/messages" iconName="fa fa-comments" />
            </NotificationWatcher>
            <NavBarIcon routeTo="/app/calendar" iconName="fa fa-calendar" />
            <NavBarIcon routeTo="/app/jobs" iconName="fa fa-pencil" />
            <div className="mr-2">
              <NavBarIcon routeTo="/app/company" iconName="fa fa-building" />
            </div>
            <div
              style={{
                width: "1px",
                height: "24px",
                border: "1px solid white",
              }}
            ></div>
            <RemoveOnMobileWrapper className="ml-4 mr-2">
              <p className="m-0 text-white">
                <small>
                  <b>
                    {user.firstName} {user.lastName}
                  </b>
                </small>
              </p>
            </RemoveOnMobileWrapper>
            <NotificationWatcher watch="bell">
              <div onMouseEnter={setDropdown.bind(this, "notifications")}>
                <NavBarIcon iconName="fa fa-bell" />
              </div>
            </NotificationWatcher>
            <div onMouseEnter={setDropdown.bind(this, "account")}>
              <NavBarIcon iconName="fa fa-user-circle" />
            </div>
          </OuterIconWrapper>
        </TopNavWrapper>
        {/* <Route path="/app" exact component={SearchSubNav} /> */}
      </FullNavWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: { ...state.auth.user },
  hasFetchedUser: state.auth.hasFetchedUser,
});

export default withRouter(
  connect(mapStateToProps, { hasSeenNotifications })(NavBar)
);

NavBar.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasFetchedUser: PropTypes.bool.isRequired,
};

const FullNavWrapper = styled.div`
  width: 100%;
`;

const TopNavWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OuterIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CrewlyIcon = styled.img`
  padding: 0 0 0 15px;
  margin: 0;
  cursor: pointer;
`;

const TopNavLeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RemoveOnMobileWrapper = styled.div`
  @media (max-width: 750px) {
    display: none;
  }
`;
