import React, { useState, useEffect } from "react";

import CreateProductForm from "./CreateProductForm";
import { Input, Button } from "reactstrap";
import styled from "styled-components";
import Product from "./Product";
import Swal from "sweetalert2";

export default function ProductCategory({
  isAdmin,
  category,
  products,
  addProduct,
  updateProduct,
  deleteProduct,
  deleteCategory,
  updateCategoryTitle,
}) {
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    setNewTitle(category.title);
  }, [category]);

  return (
    <div className="mb-4">
      <Header className="rounded-top p-2 bg-lighter">
        {isEditingTitle || (
          <p
            className={`m-0 p-0 rounded text-dark h5`}
            style={{ fontWeight: 600 }}
          >
            {category.title}
          </p>
        )}
        {isEditingTitle && (
          <form
            onSubmit={() => {
              setIsEditingTitle(false);
              updateCategoryTitle(newTitle, category.id);
            }}
            className="update-product-category"
          >
            <Input
              value={newTitle}
              className="mr-2 text-dark"
              placeholder='(e.x. "shingles")'
              onChange={(e) => setNewTitle(e.target.value)}
              type="text"
              required
            />
            <Button color="default" type="submit">
              update
            </Button>
          </form>
        )}
        {isAdmin && (
          <div className="action-btns">
            <p
              className="m-0 p-0 text-success mr-2 action-btn"
              onClick={() => setIsAddingProduct((prevState) => !prevState)}
            >
              {isAddingProduct ? "-" : "+"} add product
            </p>
            <p
              className="m-0 p-0 text-default mr-2 action-btn"
              onClick={() => setIsEditingTitle((prevState) => !prevState)}
            >
              edit
            </p>
            <p
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text:
                    "Deleting this category will delete all products within it as well. You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#F3123E",
                  cancelButtonColor: "#08080B",
                  confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteCategory(category.id);
                  }
                });
              }}
              className="m-0 p-0 text-danger action-btn"
            >
              delete
            </p>
          </div>
        )}
      </Header>
      {isAddingProduct && (
        <CreateProductForm addProduct={addProduct} categoryId={category.id} />
      )}{" "}
      <div
        className="p-1 pl-2 ml-4 mt-2 mb-2 border-bottom border-light"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="w-50 m-0 p-0 text-dark font-weight-bold">Product Name</p>
        <p className="w-50 m-0 p-0 text-dark font-weight-bold">Unit Cost</p>
      </div>
      {!products?.length && (
        <p className="m-0 mt-0 ml-4 p-0" style={{ fontStyle: "italic" }}>
          no products in this category
        </p>
      )}
      {products?.map((p) => (
        <Product
          key={p.id}
          product={p}
          isAdmin={isAdmin}
          deleteProduct={deleteProduct}
          updateProduct={updateProduct}
        />
      ))}
    </div>
  );
}

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .action-btns {
    display: flex;
    align-item: center;
    .action-btn {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .update-product-category {
    display: flex;
    align-items: center;
  }
`;
