import React from "react";

import styled from "styled-components";
import {
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Button,
  Label,
  Input,
} from "reactstrap";

function SearchBar({ handleSearch, setLocation, location, setName, name }) {
  return (
    <div
      style={{ maxWidth: "900px", margin: "auto" }}
      className="w-100 shadow-sm mb-3 rounded border border-light"
    >
      <div className="p-4 w-100 shadow-sm bg-dark">
        <p
          className="lead m-0 mb-4 text-white"
          style={{ fontWeight: "600", textAlign: "center" }}
        >
          Search Companies
        </p>
        <SearchInputsWrapper className="mb-4">
          <FormGroup className="m-0 w-100 ml-1  text-white">
            <Label for="locationSelect" className="mt-0 mb-0">
              Name
            </Label>
            <InputGroup id="locationSelect">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="btn-inner--icon">
                    <i className="fa fa-user text-gray fa-lg" />
                  </span>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                onChange={(e) => setName(e.target.value)}
                className="text-dark"
                value={name}
                type="text"
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="m-0 w-100 ml-1  text-white">
            <Label for="locationSelect" className="mt-0 mb-0">
              *Location
            </Label>
            <InputGroup id="locationSelect">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="btn-inner--icon">
                    <i className="fa fa-map-marker text-gray fa-lg" />
                  </span>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="text-dark"
                type="text"
              />
            </InputGroup>
          </FormGroup>
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleSearch();
            }}
            className="m-0 ml-2"
            size="md"
            color="white"
          >
            Search
          </Button>
        </SearchInputsWrapper>
      </div>
    </div>
  );
}

export default SearchBar;

export const SearchInputsWrapper = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
