import React from "react";

import SingleCompanyFile from "./SingleCompanyFile";
import AddCompanyFile from "./AddCompanyFile";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import styled from "styled-components";
import Swal from "sweetalert2";
import axios from "axios";

export default function CompanyFiles({ files, setFiles, userType }) {
  const handleDeleteFile = async (id) => {
    try {
      await axios.delete(`/api/company/myFiles/${id}`);
      setFiles((prevState) => prevState.filter((f) => f.id !== id));
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error while deleting your file.",
      });
    }
  };

  const handleToggleFilePublicStatus = async (id, isPublic) => {
    try {
      setFiles((prevState) =>
        prevState.map((f) =>
          f.id !== id ? f : { ...f, isPublic: !f.isPublic }
        )
      );
      await axios.put(`/api/company/myFiles/${id}`, { isPublic });
    } catch (err) {
      // if errors, go back
      setFiles((prevState) =>
        prevState.map((f) =>
          f.id !== id ? f : { ...f, isPublic: !f.isPublic }
        )
      );
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error while changing your file status.",
      });
    }
  };

  return (
    <Wrapper className="mt-1 p-3 mb-2 bg-white shadow-sm rounded">
      <Header className="w-100 pb-2 border-bottom border-light">
        <p className="text-dark m-0 h4" style={{ fontWeight: "600" }}>
          Company Files
        </p>
        <div style={{ display: "flex" }}>
          <div className="mr-2">
            <HelpModal modalTitle="Company Files Help">
              <p className="text-dark m-0">
                You may save files (50 MB or smaller) and share them with your
                team and connected companies. If set to "public", the files will
                be available via your company public page and downloadable by
                companies with which you've connected.
              </p>
              <p className="text-dark mt-3">
                Some files which may be useful to save here are files which you
                consistently share with others, such as proof of insurance.
              </p>
            </HelpModal>
          </div>
          {userType === Constants.ADMIN && (
            <AddCompanyFile setFiles={setFiles} />
          )}
        </div>
      </Header>
      <div style={{ height: "100%", overflowY: "scroll" }}>
        {!files.length && (
          <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
        {files.map(({ id, isPublic, fileUrl, fileName }) => (
          <SingleCompanyFile
            isAdmin={userType === Constants.ADMIN}
            handleToggleFilePublicStatus={handleToggleFilePublicStatus}
            handleDeleteFile={handleDeleteFile}
            isPublic={isPublic}
            fileName={fileName}
            fileUrl={fileUrl}
            key={id}
            id={id}
          />
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 50%;
  height: 500px;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
