import React from "react";

import { ScreenCover } from "./ScreenCover.js";

export default function Modal(props) {
  if (!props.show) {
    return null;
  }

  return (
    <ScreenCover onClick={props.setShow.bind(this, !props.show)}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {props.children}
      </div>
    </ScreenCover>
  );
}
