import React, { useEffect, useState } from "react";

import Statistics from "./infoheadercomponents/Statistics";
import CompanyEmployeesList from "./CompanyEmployeesList";
import CompanyInfoHeader from "./CompanyInfoHeader";
import { fetchJobs } from "store/reducers/jobs.js";
import Qualifications from "./Qualifications";
import ExtraSettings from "./ExtraSettings";
import CompanyFiles from "./CompanyFiles";
import { withRouter } from "react-router";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "axios";

function CompanyTabHome({
  history,
  companyId,
  jobs,
  fetchJobs,
  companyType,
  userType,
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    companyType && fetchJobs(companyType);
    companyType && fetchFiles();
    // eslint-disable-next-line
  }, [companyType]);

  const fetchFiles = async () => {
    try {
      const { data } = await axios.get("/api/company/myFiles");
      setFiles(data.files);
    } catch (err) {}
  };
  return (
    <Wrapper>
      <BoxesWrapper>
        <CompanyInfoHeader />
      </BoxesWrapper>
      <ExtraSettings history={history} companyId={companyId} />
      <BoxesWrapper>
        <Statistics jobs={jobs} />
      </BoxesWrapper>
      <BoxesWrapper>
        <CompanyFiles files={files} setFiles={setFiles} userType={userType} />
        <Qualifications userType={userType} />
      </BoxesWrapper>
      <CompanyEmployeesList />
    </Wrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.user.companyId,
    companyType: state.company.company.companyType,
    userType: state.auth.user.userType,
    jobs: state.jobs.jobs,
  };
};

export default connect(mapStateToProps, { fetchJobs })(
  withRouter(CompanyTabHome)
);

const BoxesWrapper = styled.div`
  width: 95%;
  margin: auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
