import { useState, useCallback } from "react";

export default function useMenuState(initial) {
  const [show, setShow] = useState(initial || false);

  const closeMenu = useCallback(() => setShow(false), []);
  const toggleMenu = useCallback(() => setShow(!show), [show]);

  return [show, closeMenu, toggleMenu, setShow];
}
