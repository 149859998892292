import React, { useState } from "react";

import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { setCurrentView } from "store/reducers/calendars";
import CalendarSettingsBar from "./CalendarSettingsBar";
import CalendarSelect from "./CalendarSelect";
import { useHistory } from "react-router-dom";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";

function LeftNav({
  forceUpdateCalendarSize,
  setSelectedCalendar,
  selectedCalendar,
  setCurrentView,
  shouldShowBtn,
  printCalendar,
  currentView,
  setDeleting,
  calendars,
  userType,
  jobs,
}) {
  const [isSettingsBarOpen, setIsSettingsBarOpen] = useState(false);
  const [isCalendarsBarOpen, setIsCalendarsBarOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const history = useHistory();
  return (
    <>
      <Popover
        placement="right"
        isOpen={popoverOpen}
        target={`PopoverPrint`}
        toggle={() => {
          setPopoverOpen((prevState) => !prevState);
        }}
      >
        <PopoverHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>File Type</span>
          <span
            onClick={() => setPopoverOpen((prevState) => !prevState)}
            style={{ cursor: "pointer", fontWeight: "200" }}
          >
            x
          </span>
        </PopoverHeader>
        <PopoverBody>
          <Button
            color="default"
            onClick={() => {
              if (currentView === "map") {
                alert("Can only print in job/appointment views.");
                return;
              }
              setPopoverOpen((prevState) => !prevState);
              printCalendar("PDF");
            }}
          >
            <span>
              <i className="fa fa-file mr-1"></i>
            </span>
            <span>pdf</span>
          </Button>
          <Button
            color="default"
            onClick={() => {
              if (currentView === "map") {
                alert("Can only print in job/appointment views.");
                return;
              }
              setPopoverOpen((prevState) => !prevState);
              printCalendar("PNG");
            }}
          >
            <span>
              <i className="fa fa-image mr-1"></i>
            </span>
            <span>PNG</span>
          </Button>
        </PopoverBody>
      </Popover>
      <Wrapper className="bg-darker">
        <div className="iconsWrapper">
          <Icon
            isSettingsBarOpen={isSettingsBarOpen}
            onClick={() => {
              forceUpdateCalendarSize();
              setIsSettingsBarOpen((prevState) => !prevState);
              setIsCalendarsBarOpen(false);
            }}
          >
            <span>
              <i className="fa fa-cog fa-lg"></i>
            </span>
            <span className="mt-2 textTag">Filters</span>
          </Icon>
          <Icon
            isCalendarsBarOpen={isCalendarsBarOpen}
            onClick={() => {
              forceUpdateCalendarSize();
              setIsCalendarsBarOpen((prevState) => !prevState);
              setIsSettingsBarOpen(false);
            }}
          >
            <span>
              <i className="fa fa-calendar fa-lg"></i>
            </span>
            <span className="mt-2 textTag">Calendars</span>
          </Icon>
          <Icon
            id={`PopoverPrint`}
            onClick={() => {
              setPopoverOpen((prevState) => !prevState);
            }}
          >
            <span>
              <i className="fa fa-print fa-lg"></i>
            </span>
            <span className="mt-2 textTag">Print</span>
          </Icon>
          {shouldShowBtn && userType === Constants.ADMIN && (
            <Icon
              onClick={() => {
                if (currentView === "appointments") {
                  history.push("/app/calendar/new_appointment");
                } else {
                  history.push("/app/calendar/newJob");
                }
              }}
            >
              <span>
                <i className="fa fa-plus fa-lg" />
              </span>
              <span className="mt-2 textTag">
                {currentView === "appointments" ? "New Appt." : "New Job"}
              </span>
            </Icon>
          )}
          <ViewIcon
            onClick={() => {
              setCurrentView("jobs");
            }}
            isSelectedView={currentView === "jobs"}
          >
            <span>
              <i className="fa fa-pencil fa-lg"></i>
            </span>
            <span className="mt-2 textTag">Jobs</span>
          </ViewIcon>
          {shouldShowBtn && (
            <>
              <ViewIcon
                isSelectedView={currentView === "appointments"}
                onClick={() => {
                  setCurrentView("appointments");
                }}
              >
                <span>
                  <i className="fa fa-clock-o fa-lg"></i>
                </span>
                <span className="mt-2 textTag">Appointments</span>
              </ViewIcon>
            </>
          )}
          <ViewIcon
            isSelectedView={currentView === "map"}
            onClick={() => {
              setCurrentView("map");
            }}
          >
            <span>
              <i className="fa fa-map fa-lg"></i>
            </span>
            <span className="mt-2 textTag">Job Map</span>
          </ViewIcon>
        </div>
      </Wrapper>
      {isSettingsBarOpen && (
        <SettingsPopOut className="bg-white p-2 border-right border-lighter">
          <CalendarSettingsBar
            currentView={currentView}
            shouldShowBtn={shouldShowBtn}
            jobs={jobs}
          />
        </SettingsPopOut>
      )}
      {isCalendarsBarOpen && (
        <CalendarsPopOut className="bg-white p-2 border-right border-lighter">
          <CalendarSelect
            setSelectedCalendar={setSelectedCalendar}
            selectedCalendar={selectedCalendar}
            setDeleting={setDeleting}
            calendars={calendars}
            history={history}
          />
        </CalendarsPopOut>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentView: state.calendars.currentView,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {
  setCurrentView,
})(LeftNav);

const Wrapper = styled.div`
  min-height: 100vh;
  .iconsWrapper {
    position: sticky;
    top: 0;
  }
`;

const Icon = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  .fa {
    font-size: 1.2rem;
  }

  :hover {
    background: #494a5b;
  }

  @media (max-width: 700px) {
    .textTag {
      display: none;
    }
  }

  ${(props) =>
    (props.isSettingsBarOpen || props.isCalendarsBarOpen) &&
    `
    background: white;
    color: #3966EA;
    :hover {
        background: white;
        color: #2552ce;
    }
  `}
`;

const ViewIcon = styled.div`
  padding: 10px;
  color: white;
  background: #5d6a77;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;

  .fa {
    font-size: 1.2rem;
  }

  @media (max-width: 700px) {
    .textTag {
      display: none;
    }
  }
  ${(props) =>
    props.isSelectedView &&
    `
    background: #275AE4;
    color: white;
    
  `}
`;

const SettingsPopOut = styled.div`
  width: 200px;
`;

const CalendarsPopOut = styled.div`
  width: 200px;
`;
