import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";

const initialState = {
  jobs: [],
  filters: {
    filterStatus: "all",
    filterLocation: "all locations",
  },
};

export default createReducer(initialState, {
  fetchJobs: (state, { payload }) => {
    state.jobs = payload;
  },
  fetchJob: (state, { payload }) => {
    state.jobs = state.jobs.map((j) => (j.id === payload.id ? payload : j));
  },
  addJob: (state, { payload }) => {
    state.jobs.push(payload);
  },
  editJobs: (state, { payload }) => {
    state.jobs = payload;
  },
  editFilters: (state, { payload }) => {
    state.filters = payload;
  },
  editJob: (state, { payload }) => {
    state.jobs = state.jobs.map((j) => {
      if (j.id === payload.jobId) {
        return { ...j, ...payload };
      } else {
        return j;
      }
    });
  },
  routeFromEditPageAndUpdate: (state, { payload }) => {
    const newJob = { ...state.jobs.find((j) => j.id === payload.id) };
    const assignments = payload.calendarAssignmentIds.map((calendarId) => ({
      jobId: newJob.id,
      calendarId,
      id: Math.random(),
    }));
    newJob.calendarAssignments = assignments;
    if (payload.newColor) {
      newJob.color = payload.color;
    }
    state.jobs = state.jobs.map((j) => (j.id === payload.id ? newJob : j));
  },
  unscheduleJob: (state, { payload }) => {
    state.jobs = state.jobs.map((job) => {
      if (job.id !== payload) {
        return job;
      }
      return {
        ...job,
        startDate: null,
        endDate: null,
      };
    });
  },
  addTag: (state, { payload }) => {
    const { jobId } = payload;
    state.jobs = state.jobs.map((j) => {
      if (j.id !== jobId) {
        return j;
      }
      return {
        ...j,
        tags: [...j.tags, payload],
      };
    });
  },
  deleteTag: (state, { payload }) => {
    const { jobId, tagId } = payload;
    state.jobs = state.jobs.map((j) => {
      if (j.id !== jobId) {
        return j;
      }
      return {
        ...j,
        tags: j.tags.filter((t) => t.id !== tagId),
      };
    });
  },
});

export const addJob = createAction("addJob");
export const editJobs = createAction("editJobs");
export const editJob = createAction("editJob");
export const editFilters = createAction("editFilters");
export const routeFromEditPageAndUpdate = createAction(
  "routeFromEditPageAndUpdate"
);

export const fetchJobs = (companyType) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/jobs/myJobs/${companyType}`);
    dispatch({ type: "fetchJobs", payload: data.jobs });
    return data.jobs;
  } catch (error) {}
};

export const fetchJob = (jobId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/jobs/job/${jobId}`);
    dispatch({ type: "fetchJob", payload: data.info });
    return data.info;
  } catch (error) {}
};

export const unscheduleJob = (jobId) => async (dispatch) => {
  try {
    await axios.post("/api/jobs/unschedule", { jobId });

    dispatch({ type: "unscheduleJob", payload: jobId });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        "There was an error removing this job from your schedule. Please try again in a moment.",
    });
  }
};

export const addTag = (details) => async (dispatch) => {
  try {
    const { data } = await axios.post("/api/jobs/addTag", details);

    dispatch({ type: "addTag", payload: data.tag });
    return data.tag.id;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an error adding your tag.",
    });
  }
};

export const deleteTag = (details) => async (dispatch) => {
  try {
    await axios.post("/api/jobs/deleteTag", details);

    dispatch({ type: "deleteTag", payload: details });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an error deleting this tag.",
    });
  }
};
