import { useEffect } from "react";
import { connect } from "react-redux";

import { fetchNotifications } from "store/reducers/notifications.js";
import { hasSeenNotifications } from "store/reducers/notifications.js";

function FetchNotifications({ notifications, fetchNotifications }) {
  useEffect(() => {
    if (noNotifications(notifications) && !notifications.hasFetched) {
      fetchNotifications();
    }
  }, [notifications, fetchNotifications]);
  return null;
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});
const mapDispatchToProps = { fetchNotifications, hasSeenNotifications };
export default connect(mapStateToProps, mapDispatchToProps)(FetchNotifications);

function noNotifications(notifications) {
  for (let type in notifications) {
    if (Array.isArray(notifications[type]) && notifications[type].length) {
      return false;
    }
  }
  return true;
}
