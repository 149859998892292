import React, { useState, useEffect } from "react";

import ProfileNavTabs from "./ProfileNavTabs";
import Qualifications from "./Qualifications";
import ConnectWindow from "./ConnectWindow";
import { withRouter } from "react-router";
import EmployeesTab from "./EmployeesTab";
import CompanyFiles from "./CompanyFiles";
import CompanyInfo from "./CompanyInfo";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import axios from "axios";

function CompanyProfile(props) {
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [selected, setSelected] = useState("employees");
  const [company, setCompany] = useState({ files: [] });
  const [message, setMessage] = useState("");

  useEffect(() => {
    let mounted = true;
    fetchCompanyById(props.match.params.id).then((results) => {
      if (mounted) {
        setCompany(results);
      }
    });

    return () => (mounted = false);
    // eslint-disable-next-line
  }, []);

  const fetchCompanyById = async (id) => {
    try {
      const results = await axios.get(`/api/company/find/${id}`);
      const { data } = await axios.get(`/api/qualifications/find/${id}`);
      setQualifications(data.qualifications);
      return results.data.company;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue loading this company.",
      });
      props.history.push("/app/company");
    }
  };

  const handleConnect = async () => {
    try {
      const newConnection = {
        senderId: props.companyId,
        recipientId: company.id,
        message,
      };
      await axios.post("/api/connections/connect", newConnection);
      setCompany({ ...company, connectionExists: true });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue connecting with this company.",
      });
    }
  };

  const shouldAllowFilesTab = () => {
    const isOwnCompany = parseInt(props.match.params.id) === props.companyId;
    const connectionAccepted = company.connectionAccepted;
    const isFilesTabSelected = selected === "files";
    if (isOwnCompany && isFilesTabSelected) {
      return true;
    } else if (connectionAccepted && isFilesTabSelected) {
      return true;
    }
    return false;
  };

  return (
    <>
      <BoxesWrapper>
        <CompanyInfo
          isOwnCompany={parseInt(props.match.params.id) === props.companyId}
          shouldShowPendingMessage={
            parseInt(props.match.params.id) !== props.companyId &&
            company.connectionExists &&
            !company.connectionAccepted
          }
          handleOpenConnectWindow={() => setShouldShowPopup(true)}
          connectDisabled={
            parseInt(props.match.params.id) === props.companyId ||
            company.connectionExists
          }
          company={company}
        />
        <BottomSectionWrapper className="pb-7">
          <ProfileNavTabs
            selected={selected}
            setSelected={setSelected}
            shouldShowFilesTab={
              parseInt(props.match.params.id) === props.companyId ||
              company.connectionAccepted
            }
          />
          {selected === "employees" && <EmployeesTab company={company} />}
          {selected === "qualifications" && (
            <Qualifications qualifications={qualifications} />
          )}
          {shouldAllowFilesTab() && <CompanyFiles files={company.files} />}
        </BottomSectionWrapper>
      </BoxesWrapper>
      {shouldShowPopup && (
        <ConnectWindow
          setShouldShowPopup={setShouldShowPopup}
          handleConnect={handleConnect}
          setMessage={setMessage}
          message={message}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    companyId: state.auth.user.companyId,
    companyType: state.company.company.companyType,
  };
};

export default connect(mapStateToProps, {})(withRouter(CompanyProfile));

const BoxesWrapper = styled.div`
  width: 95%;
  margin: auto;
`;

const BottomSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

CompanyProfile.propTypes = {
  companyType: PropTypes.string.isRequired,
  companyId: PropTypes.number,
};
