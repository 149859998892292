import { useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchUserInfo } from "store/reducers/auth.js";
import sockets from "sockets/";

export function FetchUser({
  shouldLoadInitialUserState,
  hasFetchedUser,
  fetchUserInfo,
  user,
}) {
  const history = useHistory();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (shouldLoadInitialUserState && !hasFetchedUser) {
      fetchUserInfo(getTokenSilently, history);
    }
  }, [
    hasFetchedUser,
    fetchUserInfo,
    shouldLoadInitialUserState,
    getTokenSilently,
    history,
  ]);

  useEffect(() => {
    if (user.companyId && !sockets.online) {
      sockets
        .connect()
        .then((_) => sockets.listenForCompanyUpdates(user.companyId));
    }
  }, [user]);

  return null;
}

const mapStateToProps = (state) => ({
  shouldLoadInitialUserState: state.auth.shouldLoadInitialUserState,
  hasFetchedUser: state.auth.hasFetchedUser,
  user: state.auth.user,
});

const mapDispatchToProps = { fetchUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(FetchUser);
