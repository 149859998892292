import React from "react";

import { Container } from "reactstrap";
import styled from "styled-components";

export default function ByRoofersForRoofers() {
  return (
    <Container
      className="p-4 pt-6 pb-9 container-fluid bg-white"
      style={{ position: "relative" }}
      fluid
    >
      <Wrapper className="mb-6">
        <Left>
          <div style={{ position: "relative" }}>
            <h3 className="display-2 text-dark m-0 p-0">Trust us...</h3>
            <h3
              style={{ position: "relative", zIndex: 1 }}
              className="display-2 text-default"
            >
              We've been there.
            </h3>
            <div
              style={{
                position: "absolute",
                bottom: 5,
                right: -5,
                width: "130px",
                height: "35px",
                zIndex: 0,
                background: "#e8e8e8",
              }}
            />
          </div>
          <CTAButton>Join the Crew</CTAButton>
        </Left>
        <Right>
          <p className="text-dark" style={{ fontWeight: "600" }}>
            Reprehenderit in Lorem id consequat quis esse. Consequat aliquip
            labore anim magna irure cupidatat deserunt do. Excepteur ex sunt
            reprehenderit commodo duis sit eu commodo pariatur pariatur est est
            irure.
          </p>
          <p className="text-dark" style={{ fontWeight: "600" }}>
            Nostrud qui laborum cillum deserunt. In cupidatat esse tempor et
            nulla occaecat fugiat veniam amet exercitation ullamco non. Et
            consequat ut incididunt nulla veniam ullamco incididunt officia ex
            minim.
          </p>
        </Right>
      </Wrapper>
      <div
        className="bg-secondary"
        style={{
          width: "100%",
          height: "250px",
          clipPath: "polygon(30% 65%, 0% 100%, 100% 100%)",
          position: "absolute",
          bottom: -1,
          left: 0,
        }}
      />
    </Container>
  );
}

const Wrapper = styled.div`
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  display: flex;
  margin: auto;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  position: relative;
  text-align: right;
  @media (max-width: 800px) {
    margin-bottom: 25px;
    text-align: left;
  }
`;

const Right = styled.div`
  text-align: left;
  width: 50%;
  p:nth-child(2) {
    margin-top: 50px;
  }
  p {
    margin-left: 15px;
  }
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    p {
      margin-left: 0;
    }
    p:nth-child(1) {
      margin-right: 15px;
    }
    p:nth-child(2) {
      margin-left: 15px;
      margin-top: 0;
    }
  }
`;

const CTAButton = styled.button`
  margin: 25px 0;
  padding: 10px 15px;
  transition: 0.2s;
  font-weight: 800;
  color: white;
  background: #275ae4;
  border: none;
  :hover {
    background: #0042ea;
  }
`;
