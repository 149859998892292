import React, { useState } from "react";

import { createVendor } from "store/reducers/contractorVendors";
import { Button, Input } from "reactstrap";
import { connect } from "react-redux";

function AddVendorsForm({ createVendor }) {
  const [name, setName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createVendor(name, accountNumber);
        setName("");
        setAccountNumber("");
      }}
      className="mt-4 pt-2 border-top border-light"
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="w-50 mr-2">
          <label className="text-dark font-weight-bold mb-0">
            *vendor name
          </label>
          <Input
            type="text"
            className="text-dark"
            placeholder='(e.x. "ABC Roofing")'
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="w-50">
          <label className="text-dark font-weight-bold mb-0">
            account number
          </label>
          <Input
            type="text"
            className="text-dark"
            placeholder="your vendor number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </div>
      </div>
      <Button color="default" className="mt-2" type="submit">
        <i className="fa fa-plus"></i> <span>add vendor</span>
      </Button>
    </form>
  );
}

export default connect(null, { createVendor })(AddVendorsForm);
