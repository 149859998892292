import React, { useState } from "react";

import PurchaseOrderTable from "./PurchaseOrderTable";

export default function PurchaseOrder({
  purchaseOrder,
  permissionScope,
  setPurchaseOrders,
  deletePurchaseOrder,
  setIsManagingVendors,
  setIsAssigningProducts,
  setSelectedPurchaseOrder,
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-100 p-2 mt-2 border-bottom border-light">
      <p
        className="m-0 p-0 text-dark h5"
        style={{ fontWeight: 600, cursor: "pointer" }}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <span className="mr-2">
          <i className={`fa fa-caret-${isOpen ? "down" : "right"}`}></i>
        </span>
        <span>{purchaseOrder.title}</span>
      </p>
      {isOpen && (
        <div className="p-2 rounded">
          <PurchaseOrderTable
            setSelectedPurchaseOrder={setSelectedPurchaseOrder}
            setIsAssigningProducts={setIsAssigningProducts}
            setIsManagingVendors={setIsManagingVendors}
            deletePurchaseOrder={deletePurchaseOrder}
            setPurchaseOrders={setPurchaseOrders}
            permissionScope={permissionScope}
            purchaseOrder={purchaseOrder}
          />
        </div>
      )}
    </div>
  );
}
