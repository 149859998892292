import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import Datepicker from "react-datepicker";

export default function AssignSchedule({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateSchedule,
}) {
  return (
    <DatePickersWrapper>
      <label
        style={{ display: "block" }}
        className="text-dark font-weight-bold mb-2 mt-5"
      >
        Start Date
      </label>
      <div style={{ display: "flex" }}>
        <span className="btn-inner--icon m-0 mr-2 p-0">
          <i className="fa fa-calendar fa-lg"></i>{" "}
        </span>
        <Datepicker
          style={{ display: "block" }}
          className="rounded border border-light p-1 w-100"
          selected={startDate}
          onChange={setStartDate}
        />
      </div>

      <label
        style={{ display: "block" }}
        className="text-dark font-weight-bold mb-2 mt-2"
      >
        End Date
      </label>
      <div style={{ display: "flex" }}>
        <span className="btn-inner--icon m-0 mr-2 p-0">
          <i className="fa fa-calendar fa-lg"></i>{" "}
        </span>

        <Datepicker
          style={{ display: "block", width: "100% !important" }}
          className="rounded border border-light p-1 w-100"
          selected={endDate}
          onChange={setEndDate}
        />
      </div>
      <Button onClick={updateSchedule} className="mt-3 w-100" color="default">
        Update Schedule
      </Button>
      <p
        className="m-0 mt-1 p-0"
        style={{
          fontSize: "12px",
        }}
      >
        <i>You can also schedule jobs via the calendar view.</i>
      </p>
    </DatePickersWrapper>
  );
}

const DatePickersWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
