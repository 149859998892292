import React, { useState, useEffect } from "react";

import { filterCalendar } from "store/reducers/calendars";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function DeleteCalendar({
  setSelectedCalendar,
  selectedCalendar,
  filterCalendar,
  setDeleting,
  calendars,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [calendar, setCalendar] = useState({});

  useEffect(() => {
    setCalendar(calendars.find((c) => c.id === selectedCalendar));
  }, [calendars, selectedCalendar]);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.delete(`/api/calendar/delete/${selectedCalendar}`);
      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've deleted your calendar.`,
      });
      filterCalendar(selectedCalendar);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error deleting your calendar. Please try again in a moment.",
      });
    } finally {
      setIsLoading(false);
      setDeleting(false);
      setSelectedCalendar(0);
    }
  };
  return (
    <Cover
      onClick={() => {
        setDeleting(false);
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <p className="display-4 text-darker m-0">Delete Calendar</p>
        <hr className="mt-2 mb-2" />
        <p>
          Are you sure that you'd like to delete{" "}
          <span style={{ fontWeight: "600" }}>{calendar?.title}</span>? Deleting
          this calendar will not delete any of the jobs scheduled to it.
        </p>
        <form onSubmit={handleDelete}>
          <Button
            disabled={isLoading}
            className="mt-4"
            color="warning"
            type="submit"
            block
          >
            Delete
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}

export default connect(null, { filterCalendar })(DeleteCalendar);

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;

const InnerWrapper = styled.div`
  width: 95%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 75px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;
