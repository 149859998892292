import { configureStore } from "@reduxjs/toolkit";

import auth from "./reducers/auth.js";
import company from "./reducers/company.js";
import geolocation from "./reducers/geolocation.js";
import jobs from "./reducers/jobs.js";
import search from "./reducers/search.js";
import chat from "./reducers/chat.js";
import calendars from "./reducers/calendars";
import connections from "./reducers/connections.js";
import notifications from "./reducers/notifications.js";
import customers from "./reducers/customers.js";
import products from "./reducers/products.js";
import contractorVendors from "./reducers/contractorVendors.js";
import appointments from "./reducers/appointments.js";

export default configureStore({
  reducer: {
    auth,
    appointments,
    company,
    calendars,
    geolocation,
    jobs,
    search,
    chat,
    connections,
    notifications,
    customers,
    products,
    contractorVendors,
  },
});
