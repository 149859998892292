/**
 * This file is the main gateway to the application. All routes that require authentication
 * and regard the main application should come through here.
 */
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import PurchaseOrderPrintPage from "./dashboard/jobstab/popups/jobdetails/purchaseorders/printpage/PurchaseOrderPrintPage";
import WorkOrderPrintPage from "./dashboard/jobstab/popups/jobdetails/workorders/printpage/WorkOrderPrintPage";
import SearchCompanies from "./dashboard/searchtab/searchcompanies/SearchCompanies";
import CompanyTabWrapper from "./dashboard/companytab/CompanyTabWrapper";
import CompanyProfile from "./dashboard/companyprofile/CompanyProfile";
import ConfigureAccount from "./accountConfiguration/ConfigureAccount";
import PrintableJobCard from "./dashboard/jobcard/PrintableJobCard";
import UserSettings from "./dashboard/usersettings/UserSettings.js";
import CalendarWrapper from "./dashboard/calendar/CalendarWrapper";
import Connections from "./dashboard/connectionstab/Connections";
import { setUserGeolocation } from "store/reducers/geolocation.js";
import ProductsAndCategories from "../fetchers/ProductsAndCategories";
import PollsAndNews from "./dashboard/pollsandnews/PollsAndNews";
import FetchConnections from "../fetchers/Connections.js";
import FetchUser from "../fetchers/User.js";
import FetchNotifications from "../fetchers/Notifications.js";
import FetchChatRooms from "../fetchers/ChatRooms.js";
import FetchChatMessages from "../fetchers/ChatMessages.js";
import FetchCustomers from "../fetchers/Customers.js";
import ContractorVendors from "../fetchers/ContractorVendors.js";
import FetchAppointments from "../fetchers/Appointments.js";
import Chat from "./dashboard/messages/Chat.js";
import { usePosition } from "./utils/usePosition";
import JobsTab from "./dashboard/jobstab/JobsTab";
import { useAuth0 } from "../../react-auth0-spa";
import NavBar from "./dashboard/shared/NavBar";
import DailyAlert from "./utils/DailyAlert";
import Loading from "../utils/Loading";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

const AppEntrance = ({ setUserGeolocation, auth }) => {
  const { latitude, longitude } = usePosition();
  const { loading, user } = useAuth0();

  const [shouldShowDailyAlert, setShouldShowDailyAlert] = useState(false);

  useEffect(() => {
    const location = {
      latitude: latitude || 0.0,
      longitude: longitude || 0.0,
    };

    setUserGeolocation(location);
    // eslint-disable-next-line
  }, [latitude, longitude]);

  useEffect(() => {
    if (window.location.pathname.includes("/app/configure")) {
      return;
    }
    const lastTimeShownDailyAlert = localStorage.getItem(
      "lastTimeShownDailyAlert"
    );
    if (
      !lastTimeShownDailyAlert ||
      moment(lastTimeShownDailyAlert).format("YYYY-MM-DD") !==
        moment().format("YYYY-MM-DD")
    ) {
      setShouldShowDailyAlert(true);
      localStorage.setItem("lastTimeShownDailyAlert", moment().format());
    }
  }, []);

  if (loading || !user || auth.isFetchingUser) {
    // Prevent any more of the app from loading until we have user data
    return (
      <>
        <FetchUser />
        <Loading />
      </>
    );
  }

  return (
    <div
      style={{ minHeight: "100vh", position: "relative" }}
      className="bg-lighter"
    >
      {/* Fetch state based on app route */}
      <Route path="/app">
        <FetchUser />
        <FetchNotifications />
      </Route>
      {/* Fetchers */}
      <Route path="/app/connections" component={FetchConnections} />
      <Route path="/app/calendar/job/details" component={FetchConnections} />
      <Route path="/app/jobs/details" component={FetchConnections} />
      <Route path="/app/messages" component={FetchChatRooms} />
      <Route path="/app/messages/:roomId" component={FetchChatMessages} />
      <Route path="/app/jobs/details" component={FetchCustomers} />
      <Route path="/app/calendar/job" component={FetchCustomers} />
      <Route
        path="/app/calendar/appointment_options"
        component={FetchCustomers}
      />
      <Route path="/app/calendar/new_appointment" component={FetchCustomers} />
      <Route path="/app/jobs" component={ProductsAndCategories} />
      <Route path="/app/calendar" component={ProductsAndCategories} />
      <Route path="/app/jobs" component={ContractorVendors} />
      <Route path="/app/calendar" component={ContractorVendors} />
      <Route path="/app/card" component={ContractorVendors} />
      <Route path="/app/purchase_order" component={ContractorVendors} />
      <Route path="/app/calendar" component={FetchAppointments} />
      <Route path="/app/jobs/customers" component={FetchAppointments} />

      <Route path="/app/configure" exact render={() => <ConfigureAccount />} />
      {/* else they can move freely */}
      {window.location.pathname !== "/app/configure" && <NavBar />}
      <MaxWidthWrapper
        shouldUseMargin={
          !window.location.pathname.includes("/app/jobs") &&
          !window.location.pathname.includes("/app/messages") &&
          !window.location.pathname.includes("/app/calendar") &&
          !window.location.pathname.includes("/app/purchase_order")
        }
      >
        <Route path="/app/view/company/:id" render={() => <CompanyProfile />} />
        <Route path="/app/card/:id" component={PrintableJobCard} />
        <Route
          path="/app/purchase_order/:purchaseOrderId"
          component={PurchaseOrderPrintPage}
        />
        <Route
          path="/app/work_order/:workOrderId"
          component={WorkOrderPrintPage}
        />

        <Route path="/app/connections" render={() => <Connections />} />
        <Route path="/app/calendar" render={() => <CalendarWrapper />} />
        <Route path="/app/jobs" render={() => <JobsTab />} />
        <Route path="/app/company" render={() => <CompanyTabWrapper />} />
        <Route path="/app/messages" component={Chat} />
        <Route path="/app/user/settings" component={UserSettings} />
        <Route path="/app/latest" component={PollsAndNews} />

        <Route
          path="/app/search/companies"
          render={() => <SearchCompanies />}
        />
      </MaxWidthWrapper>
      {shouldShowDailyAlert && <DailyAlert />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setUserGeolocation,
})(AppEntrance);

AppEntrance.propTypes = {
  setUserGeolocation: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const MaxWidthWrapper = styled.div`
  width: 100%;
  margin: ${(props) => (props.shouldUseMargin ? "15px auto 0px" : "")};
  padding: 0;
`;
