import React from "react";

export default function CornerClipPathEmbelishment() {
  return (
    <>
      {/* <div
        className={color ? "" : "bg-default"}
        style={{
          background: color || "",
          clipPath: "polygon(0 0, 100% 100%, 100% 0)",
          width: "150px",
          height: "150px",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      ></div>
      <div
        className="bg-gray"
        style={{
          clipPath: "polygon(0 0, 100% 100%, 100% 0)",
          width: "200px",
          height: "100px",
          position: "absolute",
          top: 0,
          right: 0,
        }}
      ></div> */}
      <div
        className="bg-darker"
        style={{
          clipPath: "polygon(0 0, 100% 100%, 100% 0)",
          width: "250px",
          height: "75px",
          position: "absolute",
          top: -1,
          right: 0,
        }}
      ></div>
    </>
  );
}
