import React, { useState } from "react";

import { Button, Input } from "reactstrap";

export default function CreateCategoryForm({ createCategory }) {
  const [title, setTitle] = useState("");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createCategory(title);
        setTitle("");
      }}
      className="mt-4 pt-2 border-top border-light"
    >
      <label className="text-dark font-weight-bold mb-0">*category name</label>
      <Input
        type="text"
        className="text-dark"
        placeholder='(e.x. "shingles")'
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Button color="default" className="mt-2" type="submit">
        <i className="fa fa-plus"></i> <span>add product category</span>
      </Button>
    </form>
  );
}
