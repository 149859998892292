import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getAuthor, sortChatRoomsByLastMessage } from "utils/chat.js";

import { Left, ChatIcon } from "./styled.js";

function RoomList({ rooms, activeRoomId }) {
  const sortedRooms = sortChatRoomsByLastMessage(rooms);
  return (
    <Left className="bg-white shadow">
      <h2 className="m-0 p-0 text-darker mb-2">Companies</h2>
      {sortedRooms.map(({ roomId, company }) => {
        if (!company) {
          // If there is no company, the room is still being fetched
          return null;
        }

        const { messages } = rooms[roomId] || {};
        const displayMessage = messages && messages.length ? messages[0] : null;
        const author = getAuthor(displayMessage) || "You";
        const display =
          displayMessage && displayMessage.contents
            ? displayMessage.contents
            : displayMessage && displayMessage.attachment
            ? "sent an image"
            : null;
        return (
          <Link to={"/app/messages/" + roomId} key={roomId}>
            <ChatIcon active={Number(roomId) === Number(activeRoomId)}>
              <img src={company.logoUrl} alt="company icon" />
              <div>
                <h2>{company.name}</h2>
                <p>
                  {displayMessage
                    ? `${author}${
                        displayMessage.contents ? ":" : ""
                      } ${display}`
                    : "Start chatting!"}
                </p>
              </div>
            </ChatIcon>
          </Link>
        );
      })}
    </Left>
  );
}

const mapStateToProps = (state) => ({
  rooms: state.chat.rooms,
  activeRoomId: state.chat.activeRoom,
});

export default connect(mapStateToProps, {})(RoomList);
