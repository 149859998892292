import React, { useEffect, useState } from "react";

import {
  fetchCustomers,
  updateCustomer,
  deleteCustomer,
} from "store/reducers/customers";
import ViewSingleCustomerDetails from "./ViewSingleCustomerDetails";
import { setNewCustomer } from "store/reducers/customers";
import { Cover, InnerWrapper } from "../StyledComponents";
import CreateCustomer from "./CreateCustomer";
import { withRouter } from "react-router-dom";
import { Button, Input } from "reactstrap";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function Customers({
  setNewCustomer,
  fetchCustomers,
  updateCustomer,
  deleteCustomer,
  companyType,
  customers,
  companyId,
  userType,
  history,
}) {
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [isViewingCustomer, setIsViewingCustomer] = useState(false);
  const [chosenCustomer, setChosenCustomer] = useState({});
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    companyId && fetchCustomers();
    // eslint-disable-next-line
  }, [companyId]);

  useEffect(() => {
    if (companyType && companyType !== Constants.CONTRACTOR) {
      history.push("/app/jobs");
    }
    // eslint-disable-next-line
  }, [companyType]);

  const isValidPhoneNumber = (phone) => {
    if (
      phone.match("[0-9]{10}") ||
      phone.match("[0-9]{3}-[0-9]{3}-[0-9]{4}") ||
      phone.match("[0-9]{3} [0-9]{3} [0-9]{4}")
    ) {
      return true;
    }
    return false;
  };

  const handleCreateCustomer = async (customerDetails) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can create new customers");
      }
      if (!isValidPhoneNumber(customerDetails.phone)) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text:
            'Incorrect phone number formatting. Try something like "555-555-5555"',
        });
        return false;
      }
      const { data } = await axios.post("/api/customers/create", {
        ...customerDetails,
        companyId,
      });
      setNewCustomer(data.customer);
      setIsCreatingCustomer(false);
      return true;
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue creating your customer.",
      });
    }
  };

  const handleUpdateCustomer = async (customerId, customer) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can create new customers");
      }
      if (!customer.lastName || !customer.firstName || !customer.phone) {
        return Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Must include a first name, last name, and valid phone number",
        });
      }
      if (!isValidPhoneNumber(customer.phone)) {
        return Swal.fire({
          icon: "warning",
          title: "Oops...",
          text:
            'Incorrect phone number formatting. Try something like "555-555-5555"',
        });
      }

      await axios.post("/api/customers/update", {
        customer,
        customerId,
      });
      Swal.fire({
        icon: "success",
        title: "Success!",
      });
      updateCustomer({
        customer,
        customerId,
      });
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating your customer.",
      });
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      if (userType !== Constants.ADMIN) {
        return alert("Only admin accounts can create new customers");
      }
      await axios.delete(`/api/customers/delete/${customerId}`);
      deleteCustomer(customerId);
      setIsViewingCustomer(false);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating your customer.",
      });
    }
  };

  return (
    <Cover
      onClick={() => {
        history.push("/app/jobs");
      }}
    >
      <CreateCustomer
        setIsCreatingCustomer={setIsCreatingCustomer}
        handleCreateCustomer={handleCreateCustomer}
        isCreatingCustomer={isCreatingCustomer}
      />
      <ViewSingleCustomerDetails
        customer={chosenCustomer}
        isAdmin={userType === Constants.ADMIN}
        isViewingCustomer={isViewingCustomer}
        setIsViewingCustomer={setIsViewingCustomer}
        handleUpdateCustomer={handleUpdateCustomer}
        handleDeleteCustomer={handleDeleteCustomer}
      />
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        className="rounded shadow p-4 bg-white"
      >
        <div
          className="mb-2"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <p className="display-4 text-darker m-0 mb-0">Customers</p>
          <div
            className="mb-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="mr-2 ">
              <HelpModal modalTitle="Customers Help">
                <p className="text-dark m-0">
                  Customers can be assigned to jobs and appointments. You can
                  view a list of all jobs and appointments assigned to each
                  customer over time via this interface simply by clicking on
                  their name.
                </p>
              </HelpModal>
            </div>
            {userType === Constants.ADMIN && (
              <Button
                className="btn-icon shadow-none p-1"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => setIsCreatingCustomer(true)}
                color="default"
                size="sm"
              >
                <span className="btn-inner--icon">
                  <i className="fa fa-plus fa-lg" />
                </span>
                <span className="btn-inner--text">New Customer</span>
              </Button>
            )}
          </div>
        </div>
        <Input
          onChange={(e) => setFilterText(e.target.value)}
          className="text-dark"
          placeholder="search"
          value={filterText}
          type="text"
        />
        <table className="table bg-white">
          <thead className="bg-white">
            <tr style={{ borderTop: "hidden" }}>
              <th className="text-darker" scope="col">
                First Name
              </th>
              <th className="text-darker" scope="col">
                Last Name
              </th>
              <th className="text-darker" scope="col">
                Phone Number
              </th>
              <th className="text-darker" scope="col">
                Company
              </th>
            </tr>
          </thead>
          <tbody>
            {customers
              .filter((c) => {
                if (
                  c.address
                    ?.toLowerCase()
                    ?.includes(filterText.toLowerCase()) ||
                  c.city?.toLowerCase()?.includes(filterText.toLowerCase()) ||
                  c.state?.toLowerCase()?.includes(filterText.toLowerCase()) ||
                  c.zip?.toLowerCase()?.includes(filterText.toLowerCase()) ||
                  c?.firstName
                    ?.toLowerCase()
                    .includes(filterText.toLowerCase()) ||
                  c?.lastName
                    ?.toLowerCase()
                    .includes(filterText.toLowerCase()) ||
                  c.phone?.toLowerCase()?.includes(filterText.toLowerCase()) ||
                  c.company?.toLowerCase()?.includes(filterText.toLowerCase())
                ) {
                  return true;
                }
                return false;
              })
              .map((c) => {
                const { firstName, lastName, phone, company, id } = c;
                return (
                  <TableRow
                    key={id}
                    onClick={() => {
                      setChosenCustomer(c);
                      setIsViewingCustomer(true);
                    }}
                  >
                    <th
                      scope="row"
                      className="text-dark"
                      style={{ fontWeight: 400 }}
                    >
                      {firstName}
                    </th>
                    <th
                      scope="row"
                      className="text-dark"
                      style={{ fontWeight: 400 }}
                    >
                      {lastName}
                    </th>
                    <th
                      scope="row"
                      className="text-dark"
                      style={{ fontWeight: 400 }}
                    >
                      {phone || "-"}
                    </th>
                    <th
                      scope="row"
                      className="text-dark"
                      style={{ fontWeight: 400 }}
                    >
                      {company || "-"}
                    </th>
                  </TableRow>
                );
              })}
          </tbody>
        </table>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
  companyId: state.auth.user.companyId,
  companyType: state.company.company.companyType,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {
  fetchCustomers,
  setNewCustomer,
  updateCustomer,
  deleteCustomer,
})(withRouter(Customers));

const TableRow = styled.tr`
  cursor: pointer;
  :hover {
    background: #f3f3f3;
  }
`;
