import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

function NotificationWatcher(props) {
  const watchingState = props.notifications[props.watch].filter(
    ({ isNew }) => isNew
  );
  const [dot, setDot] = useState(!!watchingState.length);

  useEffect(() => {
    setDot(!!watchingState.length);
  }, [watchingState]);

  return (
    <Container>
      {dot && <RedDot />}
      {props.children}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});

export default connect(mapStateToProps, {})(NotificationWatcher);

const Container = styled.div`
  position: relative;
`;

const RedDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;

  position: absolute;
  top: 10px;
  right: 6px;
`;
