import React, { useEffect, useState } from "react";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ManageProductsForm from "./purchaseorders/ManageProductsForm";
import CreateWorkOrderItem from "./workorders/CreateWorkOrderItem";
import EditWorkOrderItem from "./workorders/EditWorkOrderItem";
import PurchaseOrders from "./purchaseorders/PurchaseOrders";
import usePermissionScopes from "utils/usePermissionScopes";
import ManageVendors from "./purchaseorders/ManageVendors";
import MeasurementReporting from "./MeasurementReporting";
import { Cover, InnerWrapper } from "../StyledComponents";
import { setNewCustomer } from "store/reducers/customers";
import CreateCustomer from "../customers/CreateCustomer";
import SubcontractorEdit from "./SubcontractorEdit";
import WorkOrders from "./workorders/WorkOrders";
import { withRouter } from "react-router-dom";
import ContractorEdit from "./ContractorEdit";
import ContractAmount from "./ContractAmount";
import Constants from "utils/Constants";
import DetailsList from "./DetailsList";
import styled from "styled-components";
import { connect } from "react-redux";
import JobNumber from "./JobNumber";
import Swal from "sweetalert2";
import Images from "./Images";
import Notes from "./Notes";
import axios from "axios";

const ADMIN_PERMISSIONS = [
  Constants.ADMIN_CONTRACTOR,
  Constants.ADMIN_SUBCONTRACTOR,
];

function JobDetails({
  setNewCustomer,
  companyType,
  companyId,
  userType,
  history,
  userId,
  match,
  jobs,
}) {
  const permissionScope = usePermissionScopes(companyType, userType);
  // --
  const [job, setJob] = useState({});
  // --
  const [activeTab, setActiveTab] = useState("tab1");
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  // --
  const [measurementReports, setMeasurementReports] = useState([]);
  const [jobImgs, setJobImgs] = useState([]);

  // Work Order Related
  const [workOrders, setWorkOrders] = useState([]);
  // const [isPrintingWorkOrder, setIsPrintingWorkOrder] = useState(false);
  const [isCreatingWorkItem, setIsCreatingWorkItem] = useState(false);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);
  const [isEditingWorkItem, setIsEditingWorkItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    description: "",
    quantity: "",
    unitCost: "",
    title: "",
    id: null,
  });

  // Purchase Order Related
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [isAssigningProducts, setIsAssigningProducts] = useState(false);
  const [isManagingVendors, setIsManagingVendors] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({});

  useEffect(() => {
    const curJob = jobs.find((j) => j.id === parseInt(match.params.id));
    if (curJob) {
      setJob(curJob);
    }
    // eslint-disable-next-line
  }, [jobs, match.params.id]);

  useEffect(() => {
    setMeasurementReports(job.reports || []);
  }, [job]);

  const fetchJobImages = async (jobId) => {
    try {
      const {
        data: { imgs },
      } = await axios.get(`/api/jobs/fetchImgs/${jobId}`);

      setJobImgs(imgs);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching job images",
      });
    }
  };

  const fetchPurchaseOrders = async (jobId) => {
    try {
      const {
        data: { orders },
      } = await axios.get(`/api/purchase_orders/job/${jobId}`);

      setPurchaseOrders(orders);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching purchase orders",
      });
    }
  };

  const fetchWorkOrders = async (jobId) => {
    try {
      const {
        data: { orders },
      } = await axios.get(`/api/work_orders/job/${jobId}`);

      setWorkOrders(orders);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching work orders",
      });
    }
  };

  const handleUpdatePurchaseOrderItems = async (poId, rows) => {
    try {
      const {
        data: { items },
      } = await axios.post(`/api/purchase_orders/updateOrderItems/${poId}`, {
        rows,
      });
      setPurchaseOrders((prevState) =>
        prevState.map((po) =>
          po.id !== poId
            ? po
            : {
                ...po,
                items,
              }
        )
      );
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating this purchase order.",
      });
    }
  };

  const handleAddItem = async (details) => {
    try {
      const {
        data: { item },
      } = await axios.post("/api/work_orders/addItem", details);
      setWorkOrders((prevState) =>
        prevState.map((o) => {
          if (o.id === details.workOrderId) {
            return {
              ...o,
              orderItems: [...o.orderItems, item],
            };
          }
          return o;
        })
      );
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue adding your new work order item.",
      });
    } finally {
      setIsCreatingWorkItem(false);
      setSelectedWorkOrderId(null);
    }
  };

  const handleUpdateItem = async (details) => {
    try {
      await axios.post("/api/work_orders/updateItem", details);

      setWorkOrders((prevState) =>
        prevState.map((o) => {
          if (o.id === selectedWorkOrderId) {
            return {
              ...o,
              orderItems: o.orderItems.map((item) => {
                if (item.id !== details.id) {
                  return item;
                }
                return { ...item, ...details };
              }),
            };
          }
          return o;
        })
      );
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue editing your new work order item.",
      });
    } finally {
      setIsEditingWorkItem(false);
      setSelectedWorkOrderId(null);
    }
  };

  const handleDeleteOrderItem = async (orderItemId, orderId) => {
    try {
      await axios.delete(`/api/work_orders/item/delete/${orderItemId}`);
      setWorkOrders((prevState) =>
        prevState.map((o) => {
          if (o.id === orderId) {
            return {
              ...o,
              orderItems: o.orderItems.filter(
                (item) => item.id !== orderItemId
              ),
            };
          }
          return o;
        })
      );
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue deleting this order item.",
      });
    }
  };

  const handleDeleteWorkOrder = async (orderId) => {
    const confirmed = await Swal.fire({
      title: `Are you sure you want to remove work order: ${
        workOrders.find((o) => o.id === orderId)?.title || ""
      }?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F3123E",
      cancelButtonColor: "#08080B",
      confirmButtonText: `Yes, remove it!`,
    });
    try {
      if (confirmed.value) {
        await axios.delete(`/api/work_orders/delete/${orderId}`);
        setWorkOrders((prevState) => prevState.filter((o) => o.id !== orderId));
      }
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue deleting this work order.",
      });
    }
  };

  const handleSignUnSignWorkOrder = async (orderId) => {
    try {
      const order = workOrders.find((o) => o.id === orderId);
      if (order.signOffs.filter((so) => so.userId === userId).length) {
        await axios.delete(`/api/work_orders/sign/${orderId}`);
        setWorkOrders((prevState) =>
          prevState.map((o) => {
            if (o.id === orderId) {
              return {
                ...o,
                signOffs: o.signOffs.filter((so) => so.userId !== userId),
              };
            }
            return o;
          })
        );
        return;
      }
      const {
        data: { newSignOff },
      } = await axios.post("/api/work_orders/sign", { orderId });
      setWorkOrders((prevState) =>
        prevState.map((o) => {
          if (o.id === orderId) {
            return {
              ...o,
              signOffs: [...o.signOffs, newSignOff],
            };
          }
          return o;
        })
      );
    } catch (e) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue signing off on this work order",
      });
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const isValidPhoneNumber = (phone) => {
    if (
      phone.match("[0-9]{10}") ||
      phone.match("[0-9]{3}-[0-9]{3}-[0-9]{4}") ||
      phone.match("[0-9]{3} [0-9]{3} [0-9]{4}")
    ) {
      return true;
    }
    return false;
  };

  const handleCreateCustomer = async (customerDetails) => {
    try {
      if (!isValidPhoneNumber(customerDetails.phone)) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text:
            'Incorrect phone number formatting. Try something like "555-555-5555"',
        });
        return false;
      }
      const { data } = await axios.post("/api/customers/create", {
        ...customerDetails,
        companyId,
      });
      setNewCustomer(data.customer);
      setIsCreatingCustomer(false);
      return true;
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue creating your customer.",
      });
    }
  };

  const handleUpdateCustomer = async (customerId) => {
    const jobCustomer = parseInt(customerId) || null;
    try {
      await axios.post("/api/jobs/update/customer", {
        jobCustomer,
        jobId: parseInt(match.params.id),
      });

      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've updated this job.`,
      });
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating this job.",
      });
    }
  };

  if (!Object.keys(job).length) {
    return (
      <Cover
        onClick={() => {
          history.push("/app/jobs");
        }}
      >
        <InnerWrapper
          onClick={(e) => e.stopPropagation()}
          className="rounded shadow p-4 bg-white"
        >
          <p className="p-0 m-0">No details found for this job</p>
        </InnerWrapper>
      </Cover>
    );
  }

  return (
    <Cover
      onClick={() => {
        history.push(
          window.location.pathname.includes("/app/jobs")
            ? "/app/jobs"
            : "/app/calendar"
        );
      }}
    >
      <ManageVendors
        setIsManagingVendors={setIsManagingVendors}
        isManagingVendors={isManagingVendors}
        setPurchaseOrders={setPurchaseOrders}
      />
      <ManageProductsForm
        purchaseOrder={selectedPurchaseOrder}
        isAssigningProducts={isAssigningProducts}
        setIsAssigningProducts={setIsAssigningProducts}
        handleUpdatePurchaseOrderItems={handleUpdatePurchaseOrderItems}
      />
      <CreateCustomer
        setIsCreatingCustomer={setIsCreatingCustomer}
        handleCreateCustomer={handleCreateCustomer}
        isCreatingCustomer={isCreatingCustomer}
      />
      <CreateWorkOrderItem
        setSelectedWorkOrderId={setSelectedWorkOrderId}
        setIsCreatingWorkItem={setIsCreatingWorkItem}
        selectedWorkOrderId={selectedWorkOrderId}
        isCreatingWorkItem={isCreatingWorkItem}
        handleAddItem={handleAddItem}
      />

      <EditWorkOrderItem
        setIsEditingWorkItem={setIsEditingWorkItem}
        isEditingWorkItem={isEditingWorkItem}
        handleUpdateItem={handleUpdateItem}
        selectedItem={selectedItem}
      />
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        className="rounded shadow p-4 bg-white"
      >
        <p className="display-4 text-darker m-0 mb-0">{job.title}</p>
        <p className="text-darker m-0" style={{ fontWeight: 600 }}>
          {job.address && `${job.address}, `}
          {`${job.city}, `}
          {`${job.state}`} {job.zip && ` ${job.zip}`}
        </p>
        <JobNumber
          jobId={job.id}
          jobNumber={job.jobNumber}
          companyType={companyType}
          userType={userType}
        />
        <ContractAmount jobId={job.id} contractAmount={job.contractAmount} />
        <NavWrapper tabs>
          <NavItem>
            <NavLink
              className={activeTab === "tab1" ? "active text-primary" : ""}
              onClick={() => {
                toggle("tab1");
              }}
            >
              details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "tab2" ? "active text-primary" : ""}
              onClick={() => {
                toggle("tab2");
              }}
            >
              work orders
            </NavLink>
          </NavItem>
          {companyType === Constants.CONTRACTOR && (
            <NavItem>
              <NavLink
                className={activeTab === "tab3" ? "active text-primary" : ""}
                onClick={() => {
                  toggle("tab3");
                }}
              >
                purchase orders
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={activeTab === "tab4" ? "active text-primary" : ""}
              onClick={() => {
                toggle("tab4");
              }}
            >
              images
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "tab5" ? "active text-primary" : ""}
              onClick={() => {
                toggle("tab5");
              }}
            >
              notes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "tab6" ? "active text-primary" : ""}
              onClick={() => {
                toggle("tab6");
              }}
            >
              measurement reporting
            </NavLink>
          </NavItem>
          {ADMIN_PERMISSIONS.includes(permissionScope) && (
            <NavItem>
              <NavLink
                className={
                  activeTab === "tab7"
                    ? "active text-primary ml-2"
                    : "bg-lighter ml-2"
                }
                onClick={() => {
                  toggle("tab7");
                }}
              >
                edit/assign
              </NavLink>
            </NavItem>
          )}
        </NavWrapper>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="tab1">
            <DetailsList job={job} />
          </TabPane>
          <TabPane tabId="tab2">
            <WorkOrders
              handleSignUnSignWorkOrder={handleSignUnSignWorkOrder}
              isContractor={companyType === Constants.CONTRACTOR}
              setSelectedWorkOrderId={setSelectedWorkOrderId}
              handleDeleteWorkOrder={handleDeleteWorkOrder}
              handleDeleteOrderItem={handleDeleteOrderItem}
              setIsCreatingWorkItem={setIsCreatingWorkItem}
              setIsEditingWorkItem={setIsEditingWorkItem}
              setSelectedItem={setSelectedItem}
              fetchWorkOrders={fetchWorkOrders}
              setWorkOrders={setWorkOrders}
              jobNumber={job.jobNumber}
              workOrders={workOrders}
              jobId={job.id}
            />
          </TabPane>
          <TabPane tabId="tab3">
            <PurchaseOrders
              setSelectedPurchaseOrder={setSelectedPurchaseOrder}
              setIsAssigningProducts={setIsAssigningProducts}
              setIsEditingWorkItem={setIsEditingWorkItem}
              setIsManagingVendors={setIsManagingVendors}
              fetchPurchaseOrders={fetchPurchaseOrders}
              setPurchaseOrders={setPurchaseOrders}
              setSelectedItem={setSelectedItem}
              permissionScope={permissionScope}
              purchaseOrders={purchaseOrders}
              jobNumber={job.jobNumber}
              jobId={job.id}
            />
          </TabPane>
          <TabPane tabId="tab4">
            <Images
              jobId={job.id}
              jobImgs={jobImgs}
              setJobImgs={setJobImgs}
              fetchJobImages={fetchJobImages}
            />
          </TabPane>
          <TabPane tabId="tab5">
            <Notes />
          </TabPane>
          <TabPane tabId="tab6">
            <MeasurementReporting
              isContractor={companyType === Constants.CONTRACTOR}
              setMeasurementReports={setMeasurementReports}
              measurementReports={measurementReports}
              jobId={job.id}
            />
          </TabPane>
          {ADMIN_PERMISSIONS.includes(permissionScope) && (
            <TabPane tabId="tab7">
              {companyType === Constants.CONTRACTOR ? (
                <ContractorEdit
                  isActive={job.status === "active"}
                  setIsCreatingCustomer={setIsCreatingCustomer}
                  handleUpdateCustomer={handleUpdateCustomer}
                  jobCrewTableId={job.jobCrewTableId}
                  curStartDate={job.startDate}
                  curEndDate={job.endDate}
                  jobId={job.id}
                  job={job}
                />
              ) : (
                <SubcontractorEdit
                  jobCrewTableId={job.jobCrewTableId}
                  job={job}
                />
              )}
            </TabPane>
          )}
        </TabContent>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
  userType: state.auth.user.userType,
  companyId: state.company.company.id,
  userId: state.auth.user.id,
});

export default connect(mapStateToProps, {
  setNewCustomer,
})(withRouter(JobDetails));

const NavWrapper = styled(Nav)`
  flex-wrap: nowrap;
  overflow-x: scroll;
  .nav-item {
    white-space: nowrap;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
