import React, { useState, useEffect } from "react";

import NothingToShow from "../../../utils/NothingToShow";
import SingleCompany from "./SingleCompany";
import { connect } from "react-redux";
import SearchBar from "./SearchBar";
import { Button } from "reactstrap";
import axios from "axios";

function SearchCompanies({ userLocation, companyType }) {
  const [pageNotLoaded, setPageNotLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [location, setLocation] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    setLocation(userLocation);
  }, [userLocation]);

  useEffect(() => {
    if (location && companyType && pageNotLoaded) {
      handleSearch(0);
      setPageNotLoaded(false);
    }
    // eslint-disable-next-line
  }, [location, companyType, pageNotLoaded]);

  const handleSearch = async (pag) => {
    try {
      setIsLoading(true);
      const searchQuery = {
        name,
        location,
        companyType,
        pagination: pag,
      };

      const results = await axios.post("/api/company/search", searchQuery);
      if (pag === 0) {
        setCompanies(results.data.companies);
        setPagination(pag + 25);
      } else {
        setCompanies([...companies, ...results.data.companies]);
        setPagination((prevState) => prevState + 25);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSearchButtonClick = () => {
    setPagination(0);
    handleSearch(0);
  };

  return (
    <div
      style={{
        maxWidth: "900px",
        margin: "auto",
      }}
    >
      <div className="bg-lighter">
        <div className="m-2 bg-lighter">
          <SearchBar
            handleSearch={handleSearchButtonClick}
            setLocation={setLocation}
            location={location}
            setName={setName}
            name={name}
          />
          {companies.length ? (
            <div className="pl-4 pr-4 pt-2 pb-2 bg-white border border-light rounded">
              {companies.map((c, idx) => {
                return <SingleCompany key={idx} company={c} />;
              })}
            </div>
          ) : !isLoading ? (
            <NothingToShow
              msg="Oops! No results!"
              subMsg="We're either lacking contractors in this area, or none of them match this query. You may find better results by excluding typing in the 'Name' field."
            />
          ) : (
            <div
              style={{ textAlign: "center" }}
              className="pl-4 pr-4 pt-2 pb-2 bg-white border border-light rounded"
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <Button
            disabled={isLoading}
            style={{
              margin: "25px 0",
            }}
            color="primary"
            onClick={() => handleSearch(pagination)}
          >
            load more
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userLocation: state.auth.user.location,
    companyType: state.company.company.companyType,
  };
};

export default connect(mapStateToProps, {})(SearchCompanies);
