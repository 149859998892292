import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import SendEmailForm from "./SendEmailForm";
import styled from "styled-components";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { Button } from "reactstrap";
import currency from "currency.js";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import jsPDF from "jspdf";
import Constants from "utils/Constants";

function PurchaseOrderPrintPage({
  match,
  userId,
  vendors,
  vendorContacts,
  company,
  user,
}) {
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    let mounted = true;

    mounted &&
      userId &&
      fetchPurchaseOrders(parseInt(match.params.purchaseOrderId));

    return () => (mounted = false);
  }, [userId, match.params.purchaseOrderId]);

  useEffect(() => {
    if (purchaseOrder?.vendorId) {
      let ven = vendors.find((v) => v.id === purchaseOrder.vendorId);
      const contacts = vendorContacts.filter((vc) => vc.vendorId === ven.id);

      setVendor({
        ...ven,
        contacts,
      });
    }
    // eslint-disable-next-line
  }, [vendors, vendorContacts, purchaseOrder]);

  const fetchPurchaseOrders = async (orderId) => {
    try {
      const {
        data: { order },
      } = await axios.get(`/api/purchase_orders/single/${orderId}`);

      setPurchaseOrder(order);
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue fetching purchase order.",
      });
    }
  };

  const printCanvas = () => {
    saveCanvas().then((doc) => {
      doc.save(
        `${vendor.name && `${vendor.name} - `}${purchaseOrder.title}.pdf`
      );
    });
  };

  const saveCanvas = () => {
    window.scrollTo(0, 0);
    return html2canvas(document.getElementById("purchaseOrderCapture")).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/png");
        let imgWidth = 210;
        let pageHeight = 295;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let doc = new jsPDF("p", "mm");
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position += heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        return doc;
      }
    );
  };

  return (
    <Wrapper className="p-4 bg-secondary">
      <div style={{ maxWidth: "850px", margin: "auto" }}>
        <Button onClick={printCanvas} color="default" className="m-0 ">
          <i className="fa fa-print"></i> <span>save/print</span>
        </Button>
      </div>
      {user.userType === Constants.ADMIN && (
        <SendEmailForm
          purchaseOrder={purchaseOrder}
          saveCanvas={saveCanvas}
          company={company}
          vendor={vendor}
        />
      )}
      <PaperWrapper className="p-3 bg-white shadow" id="purchaseOrderCapture">
        <div
          className="mb-4"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="h1 m-0 text-dark" style={{ fontWeight: 600 }}>
            Purchase Order
          </p>
          <p className="m-0 text-dark">{moment().format("MM/DD/YYYY")}</p>
        </div>
        <TopSectionWrapper>
          <TopLeftInfoSection>
            <p className="h3 m-0 text-dark" style={{ fontWeight: 600 }}>
              {vendor?.name}
            </p>

            <p className="m-0 text-dark">
              Account number:{" "}
              <span style={{ fontWeight: 600 }}>{vendor?.accountNumber}</span>
            </p>
            <p className="m-0 text-dark">
              PO number:{" "}
              <span style={{ fontWeight: 600 }}>{purchaseOrder?.title}</span>
            </p>
          </TopLeftInfoSection>
          <TopRightInfoSection>
            <p className="h3 m-0 text-dark" style={{ fontWeight: 600 }}>
              {company.name}
            </p>
            <p className="m-0 text-dark">{company.location}</p>
            <p className="m-0 text-dark">
              {user.firstName} {user.lastName} - {user.position}
            </p>
            <p className="m-0 text-dark">{user.email}</p>
            <p className="m-0 text-dark">{user.phoneNumber}</p>
          </TopRightInfoSection>
        </TopSectionWrapper>
        <TitlesWrapper className="p-2 mt-2 border-bottom border-light ">
          <p className="m-0 p-0 text-dark" style={{ width: "45%" }}>
            Item Name
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "10%" }}>
            Qty.
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "20%" }}>
            Unit Cost
          </p>
          <p className="m-0 p-0 text-dark" style={{ width: "25%" }}>
            Total
          </p>
        </TitlesWrapper>

        {purchaseOrder?.items?.map((item, idx) => {
          const { id, quantity, unitCost, productName } = item;
          return (
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "flex-start",
                backgroundColor: idx % 2 ? "#fcfcfc" : "white",
              }}
              className="p-1 rounded"
            >
              <div style={{ width: "45%" }}>
                <p className="m-0 text-default" style={{ fontWeight: 600 }}>
                  {productName}
                </p>
              </div>
              <p className="m-0 text-dark" style={{ width: "10%" }}>
                {quantity}
              </p>
              <p className="m-0 text-dark" style={{ width: "20%" }}>
                {currency(unitCost, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
              <p className="m-0 text-dark">
                {currency(unitCost * quantity, {
                  symbol: "$",
                  precision: 2,
                }).format()}
              </p>
            </div>
          );
        })}

        {/* total */}
        <div style={{ display: "flex" }} className="mt-2">
          <div style={{ width: "45%" }}></div>
          <div style={{ width: "10%" }}></div>
          <p
            className="m-0 text0dark"
            style={{ width: "20%", fontWeight: "600" }}
          >
            Total
          </p>

          <p
            className="m-0 text-success"
            style={{ width: "25%", fontWeight: "600" }}
          >
            {currency(
              purchaseOrder?.items?.reduce(
                (acc, cv) => (acc += cv.unitCost * cv.quantity),
                0.0
              ),
              { symbol: "$", precision: 2 }
            ).format()}
          </p>
        </div>
      </PaperWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  vendors: state.contractorVendors.vendors,
  vendorContacts: state.contractorVendors.vendorContacts,
  company: state.company.company,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, {})(PurchaseOrderPrintPage));

const Wrapper = styled.div``;

const PaperWrapper = styled.div`
  width: 850px;
  min-height: 1100px;
  margin: 15px auto;
  cursor: initial;
  position: sticky;
  top: 0;
  @media print {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
`;

const TopLeftInfoSection = styled.div`
  width: 50%;
`;

const TopRightInfoSection = styled.div`
  width: 50%;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start:
  justify-content: center;
`;

const TitlesWrapper = styled.div`
  width: 100%;
  display: flex;
  background: white;
  p {
    font-weight: 600;
  }
`;
