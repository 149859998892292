import React, { useState, useEffect } from "react";

import { Button, Input } from "reactstrap";
import SingleNote from "./SingleNote";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

const PLACEHOLDER_NOTE = {
  id: 0,
  content: "nothing to show",
  jobId: 0,
  authorId: 0,
  firstName: "crewly",
  lastName: "",
  dateInserted: Date.now(),
};

function Notes({ match, authorId, firstName, lastName }) {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line
  }, []);

  const fetchNotes = async () => {
    try {
      const jobId = parseInt(match.params.id);
      const {
        data: { notes },
      } = await axios.get(`/api/jobs/fetchNotes/${jobId}`);
      setNotes(notes);
    } catch (err) {}
  };

  const handleAddNote = async () => {
    setIsLoading(true);
    const jobId = parseInt(match.params.id);
    const newNote = {
      authorId,
      jobId,
      content,
    };
    try {
      const {
        data: { id },
      } = await axios.post("/api/jobs/addNote", newNote);
      setNotes((prevState) => [
        {
          ...newNote,
          firstName,
          lastName,
          dateInserted: Date.now(),
          id,
        },
        ...prevState,
      ]);
      setContent("");
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue adding this note.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.post("/api/jobs/notes/delete", { noteId });
      setNotes((prevState) => prevState.filter((n) => n.id !== noteId));
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue deleting this note.",
      });
    }
  };

  return (
    <div className="mt-4">
      <NotesWrapper>
        {!notes.length && (
          <SingleNote
            note={PLACEHOLDER_NOTE}
            handleDeleteNote={() => {}}
            authorId={-1}
          />
        )}
        {notes.map((note) => (
          <SingleNote
            key={note.id}
            note={note}
            handleDeleteNote={handleDeleteNote}
            authorId={authorId}
          />
        ))}
      </NotesWrapper>
      <div
        className="text-center mt-2"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Input
          className="text-dark border border-light mr-2"
          placeholder="add note"
          type="text"
          required
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Button disabled={isLoading} onClick={handleAddNote} color="default">
          <i className="fa fa-plus"></i>
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authorId: state.auth.user.id,
  firstName: state.auth.user.firstName,
  lastName: state.auth.user.lastName,
});

export default connect(mapStateToProps, {})(withRouter(Notes));

const NotesWrapper = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
`;
