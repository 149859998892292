import React from "react";

import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import PropTypes from "prop-types";

export default function SubmitModal({
  isModalOpen,
  modalBody,
  modalHeader,
  handleClick,
}) {
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader>
        <p className="display-3 m-0">{modalHeader}</p>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <Button
          className="btn-icon"
          block
          size="md"
          type="button"
          color="default"
          onClick={handleClick}
        >
          continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SubmitModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  modalBody: PropTypes.string.isRequired,
  modalHeader: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
