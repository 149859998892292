import { useEffect } from "react";
import { connect } from "react-redux";

import { fetchConnections } from "store/reducers/connections.js";
import { hasSeenNotifications } from "store/reducers/notifications.js";

function FetchConnections({
  hasFetched,
  connections,
  fetchConnections,
  hasSeenNotifications,
}) {
  useEffect(() => {
    if (!connections.length && !hasFetched) {
      fetchConnections();
    }
    hasSeenNotifications("connections");
  }, [hasFetched, connections, fetchConnections, hasSeenNotifications]);
  return null;
}

const mapStateToProps = (state) => ({
  connections: state.connections.connections,
  hasFetched: state.connections.hasFetched,
});

const mapDispatchToProps = { fetchConnections, hasSeenNotifications };

export default connect(mapStateToProps, mapDispatchToProps)(FetchConnections);
