import React from "react";

function Location({ company }) {
  return (
    <div style={{ marginLeft: "25px" }}>
      <p
        className="mt-0 mb-0 text-dark statistic"
        style={{ textAlign: "start" }}
      >
        <span className="btn-inner--icon mr-2">
          <i className="fa fa-map-marker fa-lg" />
        </span>
        <span style={{ fontWeight: "400" }}>
          {company.location || "none configured"}
        </span>
      </p>
    </div>
  );
}

export default Location;
