import React from "react";

import { Route } from "react-router-dom";
import CompanyTabHome from "./CompanyTabHome";
import CompanySettingsPage from "./CompanySettingsPage";

export default function CompanyTabWrapper() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <Route path="/app/company/settings" component={CompanySettingsPage} />
      <CompanyTabHome />
    </div>
  );
}
