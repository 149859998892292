import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import styled from "styled-components";

function EditWorkOrderItem({
  setIsEditingWorkItem,
  isEditingWorkItem,
  handleUpdateItem,
  selectedItem,
}) {
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setDescription(selectedItem.description);
    setQuantity(selectedItem.quantity);
    setUnitCost(selectedItem.unitCost);
    setTitle(selectedItem.title);
  }, [selectedItem]);

  const handleEditWorkOrderItem = (e) => {
    e.preventDefault();
    const updatedWorkOrderItem = {
      title,
      description,
      quantity,
      unitCost,
      id: selectedItem.id,
    };
    handleUpdateItem(updatedWorkOrderItem);
    setTitle("");
    setDescription("");
    setQuantity(0);
    setUnitCost(0);
  };

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsEditingWorkItem(false);
      }}
      onClick={(e) => e.stopPropagation()}
      display={isEditingWorkItem ? "block" : "none"}
    >
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        className="rounded p-4 bg-white"
      >
        <p className="display-4 text-darker m-0 mb-2">Edit Work Order Item</p>
        <hr className="m-0 p-0" />
        <label className="text-dark font-weight-bold mb-0 mt-2">Title</label>
        <form onSubmit={handleEditWorkOrderItem}>
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="text"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            description
          </label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-dark border border-light mt-2"
            type="textarea"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            Quantity
          </label>
          <Input
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="number"
          />
          <label className="text-dark font-weight-bold mb-0 mt-2">
            $ Unit Cost
          </label>
          <Input
            value={unitCost}
            onChange={(e) => setUnitCost(e.target.value)}
            required
            className="text-dark border border-light mt-2"
            type="number"
          />
          <Button className="mt-4" color="default" type="submit" block>
            update
          </Button>
        </form>
      </Wrapper>
    </Cover>
  );
}

export default EditWorkOrderItem;

const Wrapper = styled.div`
  width: 95%;
  max-width: 900px;
  height: 85vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 55px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;

const Cover = styled.div`
  display: ${(props) => props.display};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
`;
