import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import {
  updateAppointmentColor,
  completeAppointment,
  deleteAppointment,
  toggleAssignment,
} from "store/reducers/appointments";
import UpdateAppointmentDetails from "./UpdateAppointmentDetails";
import { withRouter } from "react-router-dom";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";

function AppointmentPopup({
  updateAppointmentColor,
  completeAppointment,
  deleteAppointment,
  toggleAssignment,
  appointments,
  calendars,
  history,
  match,
}) {
  const [selectedAppointment, setSelectedAppointment] = useState({
    appointmentCalendarAssignments: [],
  });
  const [calendarAssignmentIds, setCalendarAssignmentIds] = useState([]);

  useEffect(() => {
    if (appointments.length) {
      const a = appointments.find((j) => j.id === parseInt(match.params.id));
      if (a) {
        setSelectedAppointment(a);
      } else {
        history.push("/app/calendar");
      }
    }
  }, [match.params.id, appointments, history]);

  useEffect(() => {
    if (selectedAppointment) {
      setCalendarAssignmentIds(
        selectedAppointment?.appointmentCalendarAssignments?.map(
          ({ calendarId }) => calendarId
        )
      );
    }
  }, [selectedAppointment]);

  return (
    <Cover
      onClick={() => {
        history.push("app/calendar");
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => completeAppointment(selectedAppointment.id)}
            size="sm"
            color="default"
            disabled={selectedAppointment.status === "complete"}
            className="mb-2"
          >
            {selectedAppointment.status === "active" ? "complete" : "completed"}
          </Button>
          <Button
            onClick={() => {
              Swal.fire({
                title: "Are you sure?",
                text:
                  "Are you sure you'd like to delete this appointment? You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#F3123E",
                cancelButtonColor: "#08080B",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push("/app/calendar");
                  deleteAppointment(selectedAppointment.id);
                }
              });
            }}
            size="sm"
            color="danger"
            className="mb-2"
          >
            delete
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div>
            <p className="text-darker m-0">
              customer:{" "}
              <span style={{ fontWeight: "600" }}>
                {selectedAppointment.customerFirstName}{" "}
                {selectedAppointment.customerLastName}
              </span>
            </p>
            <p className="text-darker m-0 mb-3">
              employee:{" "}
              <span style={{ fontWeight: "600" }}>
                {selectedAppointment.assignedToFirstName}{" "}
                {selectedAppointment.assignedToLastName}
              </span>
            </p>
          </div>
          <div>
            <p className="text-darker m-0" style={{ fontWeight: "600" }}>
              {selectedAppointment.address && `${selectedAppointment.address} `}
              {`${selectedAppointment.city} `}
            </p>
            <p className="text-darker m-0 mb-3" style={{ fontWeight: "600" }}>
              {`${selectedAppointment.state}`}{" "}
              {selectedAppointment.zip && ` ${selectedAppointment.zip}`}
            </p>
          </div>
        </div>

        <div className="bg-secondary p-2 mb-2 mt-2 rounded">
          <p className="m-0 text-dark">note:</p>
          <p className="m-0 text-dark" style={{ fontWeight: 600 }}>
            {selectedAppointment.note || "nothing to show"}
          </p>
        </div>
        {selectedAppointment.status === "complete" && (
          <div className="bg-success p-2 mb-2 mt-2 rounded">
            <p className="m-0 text-white">follow up note:</p>
            <p className="m-0 text-white" style={{ fontWeight: 600 }}>
              {selectedAppointment.followUpNote || "nothing to show"}
            </p>
          </div>
        )}
        <hr className="mt-2 mb-2" />

        <div>
          <label className="text-dark font-weight-bold mb-0 mt-2">
            Assign Color
          </label>
          <br />
          <div className="mb-4" style={{ display: "flex", flexWrap: "wrap" }}>
            {Constants.CALENDAR_COLORS.map((c) => (
              <div
                key={c}
                onClick={() =>
                  updateAppointmentColor(selectedAppointment.id, c)
                }
                className="shadow--hover rounded m-2"
                style={{
                  background: c,
                  width: "75px",
                  height: "50px",
                  cursor: "pointer",
                }}
              >
                {c === selectedAppointment.color && (
                  <span className="btn-inner--icon mr-1 ml-3 text-white">
                    <i className="fa fa-check" />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="text-dark font-weight-bold mb-0">
            Add To Calendar
          </label>
          {calendars.map((c) => (
            <CalendarSelection
              className={`p-2 m-2 ${
                calendarAssignmentIds.includes(c.id)
                  ? "bg-primary"
                  : "bg-lighter"
              } rounded shadow--hover`}
              key={c.id}
              onClick={() => {
                toggleAssignment(selectedAppointment.id, c.id);
              }}
            >
              <p
                className={`m-0 p-0 ${
                  calendarAssignmentIds.includes(c.id)
                    ? "text-white"
                    : "text-dark"
                } `}
              >
                {c.title}
              </p>
              <span
                className={`btn-inner--icon mr-1 ml-3 ${
                  calendarAssignmentIds.includes(c.id)
                    ? "text-white"
                    : "text-dark"
                }`}
              >
                <i
                  className={
                    calendarAssignmentIds.includes(c.id)
                      ? "fa fa-minus"
                      : "fa fa-plus"
                  }
                />
              </span>
            </CalendarSelection>
          ))}
        </div>

        <UpdateAppointmentDetails
          isComplete={selectedAppointment.status === "complete"}
          selectedAppointment={selectedAppointment}
        />
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => {
  return {
    appointments: state.appointments.appointments,
  };
};

export default connect(mapStateToProps, {
  updateAppointmentColor,
  completeAppointment,
  deleteAppointment,
  toggleAssignment,
})(withRouter(AppointmentPopup));

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const InnerWrapper = styled.div`
  width: 95%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 75px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;

const CalendarSelection = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 600;
  }
`;
