import React from "react";

import currency from "currency.js";

export default function WorkOrderTotal({ orderItems }) {
  return (
    <div style={{ display: "flex" }} className="mt-2">
      <div style={{ width: "45%" }}></div>
      <div style={{ width: "10%" }}></div>
      <p className="m-0 p-0" style={{ width: "20%", fontWeight: "600" }}>
        Total
      </p>

      <p
        className="m-0 p-0 text-success"
        style={{ width: "25%", fontWeight: "600" }}
      >
        {currency(
          orderItems.reduce(
            (acc, cv) => (acc += cv.unitCost * cv.quantity),
            0.0
          ),
          { symbol: "$", precision: 2 }
        ).format()}
      </p>
    </div>
  );
}
