import React, { useState, useRef } from "react";
import { connect } from "react-redux";

import UploadedImage from "../../../utils/UploadedImage.js";
import Modal from "../../utils/Modal.js";
import Tagged from "./Tagged.js";

import { resendChatMessage } from "store/reducers/chat.js";

import { SystemMessage, UserMessage } from "./styled.js";

function Message(props, ref) {
  const container = useRef();
  const [showFull, setShowFull] = useState(false);
  const { m, user, author, resendChatMessage } = props;

  return m.status === "system" ? (
    <SystemMessage>
      <div />
      <p>{m.contents}</p>
    </SystemMessage>
  ) : (
    <UserMessage
      className="mt-2"
      ref={ref}
      isUser={m.userId === user.id}
      isCompany={m.companyId === user.companyId}
      isTagged={!!m.tags[user.id]}
      status={m.status}
      onClick={m.status === "error" ? resendChatMessage.bind(this, m) : noFunc}
    >
      <div className="bg-white rounded w-100" ref={container}>
        {author && (
          <div className="author">
            <p>{author}</p>
          </div>
        )}
        {m.contents && <p className="contents p-2">{m.contents}</p>}
        {m.attachment && (
          <UploadedImage
            style={{ cursor: "pointer" }}
            // width={container.current ? container.current.clientWidth : 0}
            // height="200"
            width="100%"
            height="200"
            ref={container}
            display="fill"
            src={m.attachment}
            alt="User uploaded file"
            onClick={setShowFull.bind(this, true)}
          />
        )}
        {m.attachment && (
          <Modal show={showFull} setShow={setShowFull}>
            <UploadedImage
              src={m.attachment}
              alt="User uploaded file"
              display="fit"
              width="90vw"
              height="90vh"
            />
          </Modal>
        )}
        <Tagged tags={m.tags} />
      </div>
    </UserMessage>
  );
}

function noFunc() {}

const mapStateToProps = (state) => ({ user: state.auth.user });
const mapDispatchToProps = { resendChatMessage };
const ownProps = null;
const options = { forwardRef: true };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  ownProps,
  options
)(React.forwardRef(Message));
