import React, { useState, useEffect, useCallback } from "react";

import { updateAppointmentDetails } from "store/reducers/appointments";
import { fetchCompanyUsers } from "store/reducers/company.js";
import { useHistory } from "react-router-dom";
import { Button, Input } from "reactstrap";
import { connect } from "react-redux";

function UpdateAppointmentDetails({
  updateAppointmentDetails,
  selectedAppointment,
  fetchCompanyUsers,
  isComplete,
  customers,
  employees,
  companyId,
}) {
  const history = useHistory();

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [note, setNote] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const fetchUsersCallback = useCallback(() => {
    if (companyId) fetchCompanyUsers(companyId);
  }, [companyId, fetchCompanyUsers]);

  useEffect(() => {
    fetchUsersCallback();
  }, [fetchUsersCallback]);

  useEffect(() => {
    if (selectedAppointment) {
      setSelectedCustomer(selectedAppointment.customerId);
      setSelectedEmployee(selectedAppointment.assignedToId);
      setNote(selectedAppointment.note || "");
      setAddress(selectedAppointment.address || "");
      setCity(selectedAppointment.city || "");
      setState(selectedAppointment.state || "");
      setZip(selectedAppointment.zip || "");
    }
  }, [selectedAppointment]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedAppointment = {
      assignedToId: selectedEmployee,
      customerId: selectedCustomer,
      address,
      state,
      note,
      city,
      zip,
    };

    updateAppointmentDetails(selectedAppointment.id, updatedAppointment);
  };

  return (
    <div>
      <p className="display-4 text-dark m-0">Edit Appointment</p>
      <form onSubmit={handleSubmit}>
        <label
          className="text-dark font-weight-bold mt-4"
          style={{ display: "flex", alignItems: "center" }}
        >
          *Customer{" "}
          <span
            className="text-default ml-2"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.8rem",
              fontWeight: 400,
            }}
            onClick={() => history.push("/app/jobs/customers")}
          >
            create new
          </span>
        </label>
        <select
          disabled={isComplete}
          onChange={(e) => {
            setSelectedCustomer(e.target.value);
          }}
          value={selectedCustomer}
          className="w-100"
          required
        >
          <option value=""></option>
          {customers.map((c) => {
            return (
              <option key={c.id} value={c.id}>
                {c.firstName} {c.lastName} {c.company && `- ${c.company}`}
              </option>
            );
          })}
        </select>
        <label className="text-dark font-weight-bold mt-4">*Employee</label>
        <select
          disabled={isComplete}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
          }}
          value={selectedEmployee}
          className="w-100"
          required
        >
          <option value=""></option>
          {employees.map((e) => {
            return (
              <option key={e.id} value={e.id}>
                {e.firstName} {e.lastName} - {e.position}
              </option>
            );
          })}
        </select>
        <label className="text-dark font-weight-bold mb-0 mt-4">address</label>
        <Input
          disabled={isComplete}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="text-dark border border-light mt-2"
          type="text"
        />
        <label className="text-dark font-weight-bold mb-0 mt-2">city</label>
        <Input
          disabled={isComplete}
          value={city}
          onChange={(e) => setCity(e.target.value)}
          className="text-dark border border-light mt-2"
          type="text"
        />
        <label className="text-dark font-weight-bold mb-0 mt-2">state</label>
        <Input
          disabled={isComplete}
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="text-dark border border-light mt-2"
          type="text"
        />
        <label className="text-dark font-weight-bold mb-0 mt-2">zip</label>
        <Input
          disabled={isComplete}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          className="text-dark border border-light mt-2"
          type="text"
        />
        <label className="text-dark font-weight-bold mt-4">
          Note (whats this meeting for?)
        </label>
        <Input
          disabled={isComplete}
          onChange={(e) => setNote(e.target.value)}
          className="text-dark border border-dark"
          value={note}
          type="textarea"
          maxLength="500"
        />

        <Button
          disabled={isComplete}
          className="mt-4"
          color="default"
          type="submit"
        >
          Update Appointment
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  customers: state.customers.customers,
  employees: state.company.employees,
  companyId: state.auth.user.companyId,
});

export default connect(mapStateToProps, {
  updateAppointmentDetails,
  fetchCompanyUsers,
})(UpdateAppointmentDetails);
