export function reducer(state, action) {
  switch (action.type) {
    case "ADD_FILE":
      // file => original file to send to bucket
      // preview => a preview-able image (null = still loading the image preview)
      // pending => is the upload to a bucket pending (false = local only, true = uploading, null = error uploading)
      return [
        ...state,
        {
          file: action.payload,
          preview: null,
          pending: false,
          width: 0,
          height: 0,
        },
      ];
    case "ADD_RENDER":
      return state.map((img, i) =>
        i === action.payload.index
          ? {
              ...img,
              preview: action.payload.preview,
              width: action.payload.width,
              height: action.payload.height,
            }
          : img
      );
    case "REMOVE_IMAGE":
      return state.filter((_, i) => action.payload !== i);
    case "SET_PENDING":
      return state.map((img, i) =>
        i === action.payload ? { ...img, pending: true } : img
      );
    case "SET_FAILED_REQUEST":
      return state.map((img, i) =>
        i === action.payload ? { ...img, pending: null } : img
      );
    case "CLEAR_STATE":
      return [];
    default:
      return state;
  }
}

export function getPreview(attachments, dispatch) {
  return () => {
    // Create a list of files that have not been rendered yet.
    // Each element in the array is an index in attachments
    const noPreview = attachments.reduce((acc, { preview }, i) => {
      if (!preview) {
        acc.push(i);
      }
      return acc;
    }, []);

    if (noPreview.length) {
      // If a file hasn't been rendered, start rendering
      noPreview.forEach((index) => {
        const file = attachments[index].file;
        // New instance of FileReader per loop
        const reader = new FileReader();

        reader.onload = (e) => {
          // Event listener for when the file is read
          // (save to state)
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            dispatch({
              type: "ADD_RENDER",
              payload: {
                index,
                preview: e.target.result,
                width: img.width,
                height: img.height,
              },
            });
          };
        };

        // Read file
        reader.readAsDataURL(file);
      });
    }
  };
}
