import React, { useState, useEffect } from "react";

import usePermissionScopes from "utils/usePermissionScopes";
import { Button, Input, FormGroup } from "reactstrap";
import { editJob } from "store/reducers/jobs";
import Constants from "utils/Constants";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function JobNumber({ jobNumber, companyType, userType, jobId, editJob }) {
  const permissionScope = usePermissionScopes(companyType, userType);
  const [isEditing, setIsEditing] = useState(false);
  const [curJobNumber, setCurJobNumber] = useState("");
  useEffect(() => {
    setCurJobNumber(jobNumber);
  }, [jobNumber]);

  const updateJobNumber = async () => {
    try {
      if (permissionScope !== Constants.ADMIN_CONTRACTOR) {
        return alert("Only admin contractors can update this value.");
      }
      await axios.post("/api/jobs/jobNumber/update", {
        jobNumber: curJobNumber,
        jobId,
      });
      editJob({ jobNumber: curJobNumber, jobId });
    } catch (err) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating your job item.",
      });
    } finally {
      setIsEditing(false);
    }
  };
  return (
    <div>
      {!isEditing ? (
        <p className="text-darker m-0">
          <span style={{ width: "110px", display: "inline-block" }}>
            Job #:
          </span>{" "}
          <span style={{ fontWeight: 600 }}>{jobNumber}</span>{" "}
          {permissionScope === Constants.ADMIN_CONTRACTOR && (
            <span
              onClick={() => setIsEditing(true)}
              className="text-default ml-2"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              edit
            </span>
          )}
        </p>
      ) : (
        <div>
          <FormGroup>
            <Input
              onChange={(e) => setCurJobNumber(e.target.value)}
              className="text-dark mb-2 mt-2"
              placeholder="Job Id"
              value={curJobNumber}
              name="Job Number"
              type="text"
              required
            />
            <Button onClick={updateJobNumber} size="sm" color="default">
              save
            </Button>
            <Button
              className="shadow-none"
              onClick={() => setIsEditing(false)}
              size="sm"
              color="white"
            >
              cancel
            </Button>
          </FormGroup>
        </div>
      )}
    </div>
  );
}

export default connect(null, { editJob })(JobNumber);
