import styled from "styled-components";

export const Wrapper = styled.div`
  width: 95%;
  max-width: 900px;
  height: 85vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 55px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;

export const Cover = styled.div`
  display: ${(props) => props.display};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
`;
