import React, { useState, useEffect } from "react";

import currency from "currency.js";
import Toggle from "react-toggle";
import { Input } from "reactstrap";

export default function Product({ product, selectedItems, setSelectedItems }) {
  const [isSelected, setIsSelected] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [item, setItem] = useState(null);

  useEffect(() => {
    let selectedItem = selectedItems.find(
      (item) => item.productId === product.id
    );

    if (selectedItem) {
      setIsSelected(true);
      setQuantity(selectedItem.quantity);
      setItem(selectedItem);
    } else {
      setIsSelected(false);
      setQuantity(0);
    }
  }, [selectedItems, product]);

  const handleToggleSelected = () => {
    setIsSelected((prevState) => {
      if (prevState) {
        // remove from selectedItems
        setQuantity(0);
        setItem(null);
        setSelectedItems((prevState) =>
          prevState.filter((i) => i.id !== item.id)
        );
        return false;
      } else {
        // add to selectedItems
        const newItem = {
          categoryId: product.categoryId,
          id: Math.random(),
          productId: product.id,
          quantity: 0,
          unitCost: product.unitCost,
        };

        setSelectedItems((prevState) => [...prevState, newItem]);

        return false;
      }
    });
  };

  const handleChangeQuantity = (value) => {
    setQuantity(parseFloat(value));
    setSelectedItems((prevState) =>
      prevState.map((i) => {
        if (i.id !== item.id) {
          return i;
        } else {
          return {
            ...i,
            quantity: parseFloat(value),
          };
        }
      })
    );
  };

  return (
    <div
      className="ml-4 mt-1 mb-1"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Toggle
        value={isSelected ? "on" : "off"}
        checked={isSelected}
        className="bg-toggle m-0"
        style={{ width: "20%" }}
        icons={{
          unchecked: null,
        }}
        onChange={() => handleToggleSelected()}
      />
      <div style={{ width: "30%" }}>
        <p className="m-0 p-0 text-dark">{product.name}</p>
      </div>
      <div style={{ width: "30%" }}>
        <p className="m-0 p-0 text-dark">
          {currency(product.unitCost, {
            symbol: "$",
            precision: 2,
          }).format()}
        </p>
      </div>
      <Input
        style={{ width: "20%" }}
        disabled={!isSelected}
        value={quantity}
        min={0}
        onChange={(e) => handleChangeQuantity(e.target.value)}
        placeholder="Quantity"
        className="text-dark"
        type="number"
      />
    </div>
  );
}
