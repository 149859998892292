import React, { useState } from "react";

import { Button, Input } from "reactstrap";
import { Cover, InnerWrapper } from "../StyledComponents";

export default function CreateCustomer({
  setIsCreatingCustomer,
  handleCreateCustomer,
  isCreatingCustomer,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsCreatingCustomer(false);
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ display: isCreatingCustomer ? "block" : "none" }}
    >
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded p-4 bg-white"
      >
        <p className="display-4 text-darker m-0 mb-2">Create Customer</p>
        <hr className="m-0 p-0" />
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "90%",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateCustomer({
              firstName,
              lastName,
              company,
              phone,
              address,
              city,
              state,
              zip,
            })
              .then((res) => {
                if (res) {
                  setFirstName("");
                  setLastName("");
                  setCompany("");
                  setPhone("");
                  setAddress("");
                  setCity("");
                  setState("");
                  setZip("");
                }
              })
              .catch((err) => console.log(err));
          }}
        >
          <div>
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *first name
            </label>
            <Input
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *last name
            </label>
            <Input
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *phone number (555-555-5555)
            </label>
            <Input
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="text-dark border border-light mt-2"
              type="tel"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              company
            </label>
            <Input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="text-dark border border-light mt-2 mb-4"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-4">
              address
            </label>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">city</label>
            <Input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              state
            </label>
            <Input
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">zip</label>
            <Input
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
            />
          </div>
          <Button className="mt-4 mb-4" color="default" type="submit" block>
            create
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}
