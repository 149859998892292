import React, { useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";
import { Button, Modal } from "reactstrap";
import Toggle from "react-toggle";
import Swal from "sweetalert2";
import axios from "axios";

const MAX_FILE_SIZE = 50000000;

export default function AddCompanyFile({ setFiles }) {
  const [isUploading, setIsUploading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0].size > MAX_FILE_SIZE) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "File size too large. Max file size 50MB.",
      });
    }
    setFileName(acceptedFiles[0].name);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const handleSubmit = async () => {
    setIsUploading(true);
    let data = new FormData();
    // --- Dosnt just take images, but multer expects "image" on the multi part form data to find the file
    data.append("image", file, file.name);
    try {
      const response = await axios.post(
        `/api/company/uploadFile/${fileName}/${isPublic}`,
        data
      );
      await Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `Successfully uploaded ${fileName}`,
      });
      setIsOpen(false);
      setFileName("");
      setFile(null);
      setIsPublic(false);
      setFiles((prevState) => [...prevState, response.data.companyFile]);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error while uploading your file.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        color="default"
        type="button"
        size="sm"
      >
        + add
      </Button>
      <Modal
        toggle={() => {
          !isUploading && setIsOpen((ps) => !ps);
        }}
        className="modal-dialog-centered"
        isOpen={isOpen}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Upload a File
          </h5>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              !isUploading && setIsOpen(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="pl-4 pr-4 mb-2 mt-2">
          <p
            className="m-0 p-0 text-dark"
            style={{ fontSize: "0.875rem", fontWeight: 600 }}
          >
            If set to "public", this file will be viewable by companies who you
            are connected with.
          </p>
        </div>

        <div className="w-100 pl-4 pr-4">
          <div
            style={{
              border: "3px dotted #8898aa",
              cursor: "pointer",
            }}
            className="w-100 p-4 rounded text-center bg-white"
            {...getRootProps()}
          >
            {isUploading || (
              <span className="text-gray">
                <i className="fa fa-upload fa-2x" />
              </span>
            )}
            <input accept="image/*" {...getInputProps()} />
            {isUploading ? (
              <div style={{ textAlign: "center" }}>
                <div
                  style={{ width: "3.5rem", height: "3.5rem" }}
                  className="spinner-border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <p
                  className="m-0 p-0 h4 text-gray"
                  style={{ fontWeight: "600" }}
                >
                  {isDragActive ? "Drop..." : "Upload File"}
                </p>
                <p
                  className="m-0 p-0 text-default"
                  style={{ fontSize: "0.875rem" }}
                >
                  {fileName}
                </p>
              </>
            )}
          </div>
          <p
            className="m-0 p-0 text-gray"
            style={{ fontSize: "0.875rem", textAlign: "right" }}
          >
            max file size: 50MB
          </p>
          <div
            className="mt-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Toggle
              checked={isPublic}
              value={isPublic ? "on" : "off"}
              className="bg-toggle m-0 mb-2"
              icons={{
                unchecked: null,
              }}
              onChange={() => setIsPublic((prevState) => !prevState)}
            />
            <span
              className={`ml-2 ${isPublic ? "text-default" : "text-dark"}`}
              style={{ textSize: "0.875rem", fontWeight: 600 }}
            >
              {isPublic ? "public" : "private"}
            </span>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              !isUploading && setIsOpen(false);
            }}
            disabled={isUploading}
          >
            Close
          </Button>
          <Button
            disabled={!file || isUploading}
            onClick={handleSubmit}
            color="default"
            type="button"
          >
            upload
          </Button>
        </div>
      </Modal>
    </>
  );
}
