import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  calendars: [],
  selectedCalendar: 0,
  currentView: "jobs", // ["jobs", "map", "appointments"];
  filters: {
    startDate: null,
    endDate: null,
  },
};

export default createReducer(initialState, {
  fetchCalendars: (state, { payload }) => {
    state.calendars = payload;
  },
  filterCalendar: (state, { payload }) => {
    state.calendars = state.calendars.filter((c) => c.id !== payload);
  },
  setSelectedCalendar: (state, { payload }) => {
    state.selectedCalendar = payload;
  },
  editFilterStartEnd: (state, { payload }) => {
    state.filters = payload;
  },
  setCurrentView: (state, { payload }) => {
    state.currentView = payload;
  },
});

export const setSelectedCalendar = createAction("setSelectedCalendar");
export const filterCalendar = createAction("filterCalendar");
export const editFilterStartEnd = createAction("editFilterStartEnd");
export const setCurrentView = createAction("setCurrentView");

export const fetchCalendars = (companyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/calendar/calendars/${companyId}`);
    dispatch({ type: "fetchCalendars", payload: data.calendars });
  } catch (error) {}
};
