import React from "react";
import { Container, Button } from "reactstrap";
import Logo from "imgs/logos/APP icon.png";

export default function ExplainerPage({ handleSetStep }) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: "1000000",
      }}
    >
      <Container className="p-3">
        <div style={{ margin: "50px auto 35px", width: "200px" }}>
          <img
            src={Logo}
            className="shadow"
            alt="crewly-logo text-center"
            style={{ width: "100%", height: "auto", borderRadius: "40px" }}
          />
        </div>
        <p
          style={{ fontWeight: 600 }}
          className="h1 text-default p-0 m-0 text-left"
        >
          Welcome to Crewly!
        </p>
        <p className="text-dark lead m-0 p-0 text-left">
          We're happy you're here. We just need a few things to get you all set
          up with your account. When you're ready to begin, press the "Get
          Started" button below!
        </p>
        <Button
          onClick={() => handleSetStep(1)}
          className="btn-icon mt-4"
          color="default"
          type="submit"
        >
          <span className="btn-inner--text">Get Started</span>
          <span className="btn-inner--icon">
            <i className="fa fa-arrow-circle-right fa-lg" />
          </span>
        </Button>
      </Container>
    </div>
  );
}
