import React from "react";
import styled from "styled-components";

export default function TagList({ tags, removeTag }) {
  return (
    !!tags.length && (
      <TagsContainer>
        <p>Tagged:</p>
        {tags.map(({ firstName, lastName, id }, i) => {
          return (
            <div key={id} onClick={removeTag.bind(this, id)}>
              <p>
                {firstName} {lastName}
                {i < tags.length - 1 && ","}
              </p>
            </div>
          );
        })}
      </TagsContainer>
    )
  );
}

const TagsContainer = styled.div`
  line-height: 18px;
  padding-left: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  p {
    font-size: 8px;
    margin-right: 5px;
    margin-bottom: 0;
  }

  & > div {
    margin-right: 3px;

    p {
      font-size: 8px;
      margin: 0;
      cursor: pointer;
      padding: 2px 0;

      &:hover {
        text-decoration: line-through;
      }
    }
  }
`;
