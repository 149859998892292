import React from "react";

import { UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";

export default function DailyAlert() {
  const history = useHistory();
  return (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        zIndex: "10000000000",
        width: "100%",
      }}
      className="p-2"
    >
      <UncontrolledAlert
        style={{ margin: "10px auto", maxWidth: "900px" }}
        color="default"
        className="shadow text-white"
      >
        <strong>Whats new?</strong> Click{" "}
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontWeight: 600,
          }}
          onClick={() => history.push("/app/latest")}
        >
          here
        </span>{" "}
        to view what Crewly is working on and let us now how we can better help
        your business.
      </UncontrolledAlert>
    </div>
  );
}
