import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

export default function ProfileNavTabs({
  selected,
  setSelected,
  shouldShowFilesTab,
}) {
  return (
    <TabWrapper className="bg-white rounded shadow-sm">
      <Tab
        onClick={() => setSelected("employees")}
        selected={selected === "employees"}
      >
        <p>Employees</p>
      </Tab>

      <Tab
        onClick={() => setSelected("qualifications")}
        selected={selected === "qualifications"}
      >
        <p>Qualifications</p>
      </Tab>
      {shouldShowFilesTab && (
        <Tab
          onClick={() => setSelected("files")}
          selected={selected === "files"}
        >
          <p>Files</p>
        </Tab>
      )}
    </TabWrapper>
  );
}

const TabWrapper = styled.div`
  position: sticky;
  top: 15px;
  z-index: 750;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Tab = styled.div`
  transition: 0.2s;
  cursor: pointer;
  text-align: left;
  padding: 10px 15px;
  padding-right: 45px;
  color: ${(props) => (props.selected ? "#5B81EE" : "#282937")};
  border-bottom: 1px solid #c9cccf;
  border-left: 5px solid ${(props) => (props.selected ? "#5B81EE" : "#fff")};
  p {
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 800px) {
    border-left: none;
    border-bottom: 5px solid ${(props) => (props.selected ? "#5B81EE" : "#fff")};
    padding: 10px 5px;
    width: 20%;
    p {
      text-align: center;
      word-break: break-all;
    }
  }
  @media (max-width: 600px) {
    p {
      font-size: 12px;
    }
  }
`;

ProfileNavTabs.propTypes = {
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};
