import React from "react";

import { Button, UncontrolledTooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function AssignSubcontractor({
  customCrewId,
  setCustomCrewId,
  customCrewMemberId,
  setCustomCrewMemberId,
  setSelectedConnection,
  updateSubcontractor,
  selectedConnection,
  setIsCustom,
  connections,
  companyId,
  isCustom,
  crews,
}) {
  const history = useHistory();
  return (
    <div>
      <label className="text-dark font-weight-bold mb-0 mt-2">
        Assign Subcontractor
      </label>
      <br />
      <TextToggle
        onClick={() => {
          setSelectedConnection(0);
          setIsCustom(!isCustom);
        }}
        className="text-primary m-0 p-0"
        data-placement="right"
        id="tooltip611234743"
      >
        {isCustom ? "choose from list" : "custom"}
      </TextToggle>
      {isCustom ? (
        <p
          className="m-0 p-0"
          style={{
            fontSize: "12px",
          }}
        >
          In the case that you're assgning this job to a third party who{" "}
          <b
            style={{
              fontWeight: 600,
            }}
          >
            does not have a Crewly account,{" "}
          </b>
          you may enter a crew name/crew lead below.
        </p>
      ) : (
        <p
          className="m-0 p-0"
          style={{
            fontSize: "12px",
          }}
        >
          Below is a list of{" "}
          <b
            style={{
              fontWeight: 600,
            }}
          >
            connected
          </b>{" "}
          subcontractors. Selecting them here will send them a notification
          allowing them to accept or deny your job request.
        </p>
      )}
      <UncontrolledTooltip
        delay={0}
        placement="right"
        target="tooltip611234743"
      >
        You may either choose from a list of your connections, or simply write
        out the name of a subcontractor who works for you if they do not have a
        Crewly account.
      </UncontrolledTooltip>
      {isCustom ? (
        <>
          <label className="text-dark font-weight-bold mb-0">Custom Crew</label>
          <select
            onChange={(e) => {
              setCustomCrewId(parseInt(e.target.value));
            }}
            value={customCrewId}
            className="w-100"
          >
            <option value="0"></option>
            {crews.map((crew) => (
              <option key={crew.id} value={crew.id}>
                {crew.name}
              </option>
            ))}
          </select>
          <label className="text-dark font-weight-bold mb-0">Assigned To</label>
          <select
            onChange={(e) => {
              setCustomCrewMemberId(parseInt(e.target.value));
            }}
            value={customCrewMemberId}
            className="w-100"
          >
            <option value="0"></option>
            {customCrewId !== 0 &&
              crews
                ?.find((c) => c.id === customCrewId)
                ?.crewMembers?.map((crewMember) => (
                  <option key={crewMember.id} value={crewMember.id}>
                    {crewMember.name}
                  </option>
                ))}
          </select>
          <div className="w-100 text-right">
            <Button
              onClick={() => history.push("/app/jobs/custom_crews")}
              size="sm"
              className="mt-2"
              color="default"
            >
              manage custom crews
            </Button>
          </div>
        </>
      ) : (
        <select
          onChange={(e) => {
            setSelectedConnection(parseInt(e.target.value));
          }}
          value={selectedConnection}
          className="w-100 mt-2"
        >
          <option value="0"></option>
          {connections
            .filter((conn) => conn.accepted)
            .map((conn) => {
              let val = null;
              if (conn.senderId === companyId) {
                val = conn.recipientId;
              } else {
                val = conn.senderId;
              }
              return (
                <option key={conn.id} value={val}>
                  {conn.companyDetails.name}
                </option>
              );
            })}
        </select>
      )}
      <Button
        onClick={updateSubcontractor}
        className="mt-3 w-100"
        color="default"
      >
        Update Subcontractor
      </Button>
    </div>
  );
}

const TextToggle = styled.p`
  display: inline;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;
