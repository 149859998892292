import React from "react";

import styled from "styled-components";

export default function CompanyFiles({ files }) {
  return (
    <Wrapper className="w-100 bg-white rounded shadow-sm p-2">
      <p
        className="text-dark m-0 h4 pb-1 mb-2 border-bottom border-light"
        style={{ fontWeight: "600" }}
      >
        Files
      </p>
      {!files.length && (
        <p className="m-0 p-0 text-dark" style={{ fontStyle: "italic" }}>
          nothing to show
        </p>
      )}
      {files.map(({ id, fileName, fileUrl }) => {
        return (
          <div key={id} className="w-100 mb-0">
            <p className="m-0 text-underline">
              <a href={fileUrl}>{fileName}</a>
            </p>
          </div>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-left: 1rem;
  min-height: 300px;
  @media (max-width: 800px) {
    margin-left: 0;
    margin-top: 1rem;
  }
  .evenNumberedQual {
    background: #fcfcfc;
  }
`;
