import styled from "styled-components";

export const CompanyCardWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0px 35px;
`;

export const HeadTopLevel = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;

  img {
    cursor: pointer;
    width: 160px;
    height: 160px;
  }

  @media (max-width: 600px) {
    div {
      button {
        position: inherit !important;
        margin: auto !important;
        left: 0 !important;
      }
    }
    .statistic {
      font-size: 14px;
    }
  }
`;

export const ImageUploadBoxWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  text-align: center;
  background: white;
  margin-top: -75px;
  width: 125px;
  height: 125px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 150px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;
