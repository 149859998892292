import React from "react";

import styled from "styled-components";
import { useAuth0 } from "../../react-auth0-spa";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

function MobileNavPopout({ display, history }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  return (
    <Wrapper display={display} className="shadow rounded">
      <InfoLinksWrapper>
        {/* <p>about</p>
        <p>pricing</p>
        <p>contact</p> */}
        <div className="mt-4">
          {!isAuthenticated && (
            <SignInBtn
              onClick={() => {
                loginWithRedirect({
                  redirect_uri:
                    process.env.REACT_APP_LOGIN_URL ||
                    "http://localhost:3000/app/company",
                });
              }}
            >
              SIGN IN
            </SignInBtn>
          )}
          {!isAuthenticated && (
            <SignUpBtn
              onClick={() => {
                loginWithRedirect({
                  screen_hint: "signup",
                  redirect_uri:
                    process.env.REACT_APP_LOGIN_URL ||
                    "http://localhost:3000/app/company",
                });
              }}
            >
              SIGN UP
            </SignUpBtn>
          )}
          {isAuthenticated && (
            <SignUpBtn
              onClick={() => {
                history.push("/app/company");
              }}
            >
              account
            </SignUpBtn>
          )}
        </div>
      </InfoLinksWrapper>
    </Wrapper>
  );
}

export default withRouter(MobileNavPopout);

MobileNavPopout.propTypes = {
  display: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  border-top: 1px solid #dee2e6;
  margin-top: 15px;
  display: ${(props) => props.display};
`;

const InfoLinksWrapper = styled.div`
  p {
    transition: 0.5s;
    margin: 0;
    padding: 0 15px;
    cursor: pointer;
    font-size: 20px;
    color: black;
    background: transparent;
    border: none;
    border-bottom: 3px solid white;
    font-weight: 400;

    :hover {
      color: black;
      border-bottom: 3px solid black;
    }
  }
`;

export const SignUpBtn = styled.button`
  margin: 0 0 0 15px;
  width: 110px;
  padding: 10px 0;
  color: white;
  border: 1px solid #2a49ff;
  border-radius: 5px;
  transition: 0.2s;
  font-weight: 800;
  background: #2a49ff;

  :hover {
    color: #2a49ff;
    border: 1px solid #2a49ff;
    background: transparent;
  }
`;

export const SignInBtn = styled.button`
  width: 110px;
  padding: 10px 0;
  transition: 0.2s;
  font-weight: 800;

  color: #2a49ff;
  background: transparent;
  border: none;
  border-bottom: 3px solid transparent;

  :hover {
    border-bottom: 3px solid #2a49ff;
  }
`;
