import React from "react";

import { useAuth0 } from "../../../../../react-auth0-spa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function AccountDropdown({ display }) {
  const { logout } = useAuth0();
  const history = useHistory();
  return (
    <Wrapper display={display} className="bg-white shadow rounded">
      <p onClick={() => history.push("/app/user/settings")}>settings</p>
      <p>help</p>
      <p onClick={() => history.push("/app/latest")}>polls & news</p>
      <p
        onClick={() => {
          logout({
            returnTo:
              process.env.REACT_APP_LOGOUT_URL || "http://localhost:3000",
          });
        }}
      >
        log out
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 5px 0 0;
  width: 150px;
  position: fixed;
  z-index: 1000000000;
  top: 50px;
  right: 5px;
  display: ${(props) => props.display || "block"};

  p {
    text-align: center;
    font-weight: 600;
    width: 100%;
    padding: 10px 0;
    color: black;
    :hover {
      background: #e8e8e8;
      cursor: pointer;
    }
  }
`;
