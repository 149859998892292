import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";

function SingleConnection({
  acceptConnection,
  conn,
  history,
  declineConnection,
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [btnStyle, setBtnStyle] = useState("");

  useEffect(() => {
    const { accepted, sent } = conn;
    if (accepted) {
      setIsButtonDisabled(true);
      setBtnStyle("white");
    } else if (sent && !accepted) {
      setIsButtonDisabled(true);
      setBtnStyle("light");
    } else if (!sent && !accepted) {
      setIsButtonDisabled(false);
      setBtnStyle("default");
    } else {
      setIsButtonDisabled(true);
      setBtnStyle("dark");
    }
  }, [conn]);

  const determineButtonText = () => {
    const { accepted, sent } = conn;
    if (accepted) {
      return "connected";
    } else if (sent && !accepted) {
      return "pending";
    } else if (!sent && !accepted) {
      return "accept";
    }
  };

  return (
    <Wrapper>
      <ImgWrapper className="mr-3">
        <img
          src={
            conn.companyDetails.logoUrl ||
            "https://via.placeholder.com/56x56?text=logo"
          }
          alt=""
        />
      </ImgWrapper>
      <InfoWrapper className=" w-100 pb-2 border-bottom border-light">
        <p
          onClick={() => {
            history.push(`/app/view/company/${conn.companyDetails.id}`);
          }}
          className="lead text-primary m-0"
        >
          <b style={{ fontWeight: 600, lineHeight: 1 }} className="name">
            {conn.companyDetails.name}
          </b>
          <span
            className="text-dark"
            style={{ fontSize: "16px", lineHeight: 1 }}
          >
            - {conn.companyDetails.location}
          </span>
        </p>
        <p
          className="text-dark m-0"
          style={{ fontSize: "16px", lineHeight: 1 }}
        >
          <span style={{ fontWeight: 600 }}>message: </span>
          {conn.message}
        </p>
        <Button
          onClick={() => {
            acceptConnection(conn.id);
          }}
          disabled={isButtonDisabled}
          color={btnStyle}
          className="mt-2"
          size="sm"
        >
          {determineButtonText()}
        </Button>

        <Button
          onClick={() => {
            declineConnection(conn.id);
          }}
          color="danger"
          className="mt-2"
          size="sm"
        >
          remove
        </Button>
      </InfoWrapper>
    </Wrapper>
  );
}

export default withRouter(SingleConnection);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  img {
    border-radius: 50%;
    width: 56px;
    height: 56px;
  }
`;

const InfoWrapper = styled.div`
  .name {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
