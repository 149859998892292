import React from "react";

import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button } from "reactstrap";

function JobsHeaderBar({
  history,
  curTab,
  setCurTab,
  shouldShowBtns,
  isAdmin,
}) {
  return (
    <Header className="bg-lighter pt-1 pl-2 pr-2 pb-0">
      <ul
        className="nav nav-tabs mr-2"
        style={{ display: "flex", flexWrap: "nowrap" }}
      >
        <li onClick={() => setCurTab("all")} className="nav-item">
          <span
            className={`nav-link ${curTab === "all" && "active text-default"}`}
          >
            All
          </span>
        </li>
        <li onClick={() => setCurTab("active")} className="nav-item">
          <span
            className={`nav-link ${
              curTab === "active" && "active text-default"
            }`}
          >
            Active
          </span>
        </li>
        <li onClick={() => setCurTab("complete")} className="nav-item">
          <span
            className={`nav-link ${
              curTab === "complete" && "active text-default"
            }`}
          >
            Complete
          </span>
        </li>
        <li onClick={() => setCurTab("unscheduled")} className="nav-item">
          <span
            className={`nav-link ${
              curTab === "unscheduled" && "active text-default"
            }`}
          >
            Unscheduled
          </span>
        </li>
      </ul>
      {shouldShowBtns && (
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <Button
            onClick={() => history.push("/app/jobs/customers")}
            className="btn-icon ml-2 shadow-none p-2 mb-1"
            style={{ whiteSpace: "nowrap" }}
            color="default"
            outline
            size="sm"
          >
            <span className="btn-inner--icon">
              <i className="fa fa-users fa-lg" />
            </span>
            <span className="btn-inner--text">Customers</span>
          </Button>
          {isAdmin && (
            <Button
              className="btn-icon ml-2 shadow-none p-2 mb-1"
              onClick={() => history.push("/app/jobs/new")}
              style={{ whiteSpace: "nowrap" }}
              color="default"
              size="sm"
            >
              <span className="btn-inner--icon">
                <i className="fa fa-plus fa-lg" />
              </span>
              <span className="btn-inner--text">New Job</span>
            </Button>
          )}
        </div>
      )}
    </Header>
  );
}

export default withRouter(JobsHeaderBar);

const Header = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  li {
    cursor: pointer;
  }
`;
