import React, { useState } from "react";

import LoadingCover from "components/app/utils/LoadingCover";
import { withRouter } from "react-router-dom";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import moment from "moment";

function JobsContent({ history, jobs, loading }) {
  const [searchText, setSearchText] = useState("");

  return (
    <Content className="bg-white">
      <LoadingCover rem="6" display={loading} />
      <MoreSettingsWrapper className="p-3">
        <Input
          onChange={(e) => setSearchText(e.target.value)}
          className="text-dark border border-light"
          placeholder="search jobs..."
          value={searchText}
          type="text"
          required
        />

        <Button
          className="btn-icon ml-4 rounded-circle"
          onClick={() => {
            history.push("/app/jobs/help");
          }}
          color="darker"
          size="sm"
          outline
        >
          <span className="btn-inner--icon">
            <i className="fa fa-question fa-lg" />
          </span>
        </Button>
      </MoreSettingsWrapper>

      <table className="table bg-white pl-2">
        <thead
          className="bg-white"
          style={{
            boxShadow:
              "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          }}
        >
          <tr style={{ borderTop: "hidden" }}>
            <th className="text-darker headerfont" scope="col">
              Title
            </th>
            <th className="text-darker headerfont" scope="col">
              Address
            </th>
            <th className="text-darker headerfont" scope="col">
              Crew
            </th>
            <th className="text-darker headerfont" scope="col">
              Crew Lead
            </th>
            <th className="text-darker headerfont" scope="col">
              Start Date
            </th>
            <th className="text-darker headerfont" scope="col">
              End Date
            </th>
          </tr>
        </thead>
        <tbody>
          {jobs
            .filter(
              (j) =>
                j.title.toLowerCase().includes(searchText.toLowerCase()) ||
                j.address.toLowerCase().includes(searchText.toLowerCase()) ||
                j.city.toLowerCase().includes(searchText.toLowerCase()) ||
                j.state.toLowerCase().includes(searchText.toLowerCase()) ||
                j.zip.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((job) => {
              const {
                id,
                title,
                address,
                startDate,
                endDate,
                customCrewMemberName,
                isAcceptedBySub,
                isCustom,
                assignedToUserInfo,
                city,
                state,
                color,
                status,
              } = job;

              let crew;
              if (job.isCustom) {
                crew = job.customSubcontractorName;
              } else {
                crew = job?.companyInfo?.name;
              }
              return (
                <TableRow
                  tagColor={status === "active" ? color : "#738394"}
                  key={id}
                  style={{
                    borderBottom: "2px solid #ededed",
                  }}
                  onClick={() => {
                    history.push(`/app/jobs/details/${id}`);
                  }}
                >
                  <th scope="row" className="text-dark">
                    {title}
                  </th>
                  <th scope="row" className="text-dark">
                    {address || city + ", " + state}
                  </th>
                  <th
                    scope="row"
                    className={
                      isCustom
                        ? "text-dark"
                        : isAcceptedBySub
                        ? "text-success"
                        : "text-warning"
                    }
                  >
                    {crew || "-"}
                  </th>
                  <th
                    scope="row"
                    className={
                      isCustom
                        ? "text-dark"
                        : isAcceptedBySub
                        ? "text-success"
                        : "text-warning"
                    }
                  >
                    {isCustom
                      ? customCrewMemberName || "-"
                      : Object.keys(assignedToUserInfo).length
                      ? `${assignedToUserInfo.firstName} ${assignedToUserInfo.lastName}`
                      : "-"}
                  </th>
                  <th scope="row" className="text-dark">
                    {startDate ? moment(startDate).format("MMM Do YYYY") : "-"}
                  </th>
                  <th scope="row" className="text-dark">
                    {endDate ? moment(endDate).format("MMM Do YYYY") : "-"}
                  </th>
                </TableRow>
              );
            })}
        </tbody>
      </table>
      {!jobs.length && (
        <p className="m-0 mt-2 p-0 text-center">No jobs to show</p>
      )}
    </Content>
  );
}

export default withRouter(JobsContent);

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  table {
    min-width: 1000px;
    overflow-y: scroll;
  }
  th {
    font-size: 14px;
    font-weight: 400;
  }
  .headerfont {
    font-weight: 600;
  }
`;

const MoreSettingsWrapper = styled.div`
  width: 100%;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    width: 250px;
    min-width: 250px;
    height: 32px;
    font-size: 14px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
  border-left: 15px solid ${(props) => props.tagColor || "black"};
  :hover {
    background: #f3f3f3;
  }
`;
