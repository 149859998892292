import { createReducer } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import axios from "axios";

const initialState = {
  connections: [],
  hasFetched: false,
};

export default createReducer(initialState, {
  socketsAddedConnections: (state, { payload }) => {
    payload.forEach((c) => {
      state.connections.push(c);
    });
  },
  socketsDeletedConnections: (state, { payload }) => {
    state.connections = state.connections.filter(({ id }) => !payload[id]);
  },
  socketsUpdatedConnections: (state, { payload }) => {
    state.connections = state.connections.map((c) => {
      if (payload[c.id]) {
        return { ...c, ...payload[c.id] };
      }
      return c;
    });
  },
  fetchedConnections: (state, { payload }) => {
    state.connections = payload;
  },
  acceptedConnection: (state, { payload }) => {
    const c = state.connections.find(({ id }) => id === payload.id) || {};
    c.accepted = true;
  },
  declineConnection: (state, { payload }) => {
    state.connections = state.connections.filter(({ id }) => id !== payload.id);
  },
  fetchingConnections: (state) => {
    state.hasFetched = true;
  },
  didNotFetchConnections: (state) => {
    state.hasFetched = false;
  },
});

export const fetchConnections = () => (dispatch, getState) => {
  dispatch({ type: "fetchingConnections" });

  const state = getState();
  const { companyId } = state.auth.user || {};

  if (companyId !== undefined) {
    axios
      .get(`/api/connections/connections/${companyId}`)
      .then(({ data }) => {
        dispatch({
          type: "fetchedConnections",
          payload: data.fullConnectionsDetails,
        });
      })
      .catch((err) => {
        console.log("ERROR FETCHING: ", err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "There was an error fetching your connections.",
        });
      });
  } else {
    // This typically happens when the user hasn't been fetched yet
    // So the flag is reset so next state change it can try again
    dispatch({ type: "didNotFetchConnections" });
  }
};

export const acceptConnection = (id) => (dispatch) => {
  axios
    .post("/api/connections/accept", { id })
    .then((_) => {
      dispatch({ type: "acceptedConnection", payload: { id } });
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error accepting this connections. Please wait and try again.",
      });
    });
};

export const declineConnection = (id) => (dispatch) => {
  axios
    .post("/api/connections/decline", { id })
    .then((_) => {
      dispatch({ type: "declineConnection", payload: { id } });
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error declining this connections. Please wait and try again.",
      });
    });
};
