import React, { useState, useEffect } from "react";

import { Cover, InnerWrapper } from "../StyledComponents";
import { withRouter } from "react-router-dom";
import { Button, Input } from "reactstrap";
import Constants from "utils/Constants";
import HelpModal from "utils/HelpModal";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function NewJob({ history, isContractor, userType, routeTo }) {
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    let fetch = true;
    if (fetch) {
      !isContractor && history.push(routeTo);
    }
    return () => (fetch = false);

    // eslint-disable-next-line
  }, [isContractor]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userType !== Constants.ADMIN) {
      return alert("Only admin accounts may create jobs");
    }
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/jobs/create", {
        address,
        city,
        state,
        zip,
        title,
      });

      Swal.fire({
        icon: "success",
        title: "Sweet!",
        text: `We've created your new job.`,
      });
      history.push(`${routeTo}/${data.id}`);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error creating your job. Please try again in a moment.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Cover
      onClick={() => {
        history.push(routeTo || "/app/jobs");
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="display-4 text-darker m-0">Create New Job</p>
          <HelpModal modalTitle="Job Creation Help">
            <p className="text-dark m-0">
              "Jobs" are the feature with which Crewly is built around. After
              creating a job it can be scheduled and assigned to crews with
              which you've connected with, allowing them to see their work on
              their own Crewly calendar.
            </p>
            <p className="text-dark m-0 mt-3">
              All of the information in this form can be edited later on in the
              case that the address changes or you'd like to change the title.
              Editing details can be done by admin accounts via the "edit" tab
              in the "job view". You'll be forwarded to this view after pressing
              the "submit" button, or by clicking on a job in your calendar or
              in the "jobs" tab.
            </p>
          </HelpModal>
        </div>
        <hr className="mt-2 mb-2" />
        <form onSubmit={handleSubmit}>
          <label className="text-dark font-weight-bold">*Title</label>
          <Input
            disabled={userType !== Constants.ADMIN}
            onChange={(e) => setTitle(e.target.value)}
            className="text-dark border border-light mb-4"
            value={title}
            type="text"
            required
          />
          <label className="text-dark font-weight-bold">Address</label>
          <Input
            disabled={userType !== Constants.ADMIN}
            onChange={(e) => setAddress(e.target.value)}
            className="text-dark border border-light"
            value={address}
            type="text"
          />
          <label className="text-dark font-weight-bold">*City</label>
          <Input
            disabled={userType !== Constants.ADMIN}
            onChange={(e) => setCity(e.target.value)}
            className="text-dark border border-light"
            value={city}
            type="text"
            required
          />
          <label className="text-dark font-weight-bold">*State</label>
          <Input
            disabled={userType !== Constants.ADMIN}
            onChange={(e) => setState(e.target.value)}
            className="text-dark border border-light"
            value={state}
            type="text"
            required
          />
          <label className="text-dark font-weight-bold">Zip</label>
          <Input
            disabled={userType !== Constants.ADMIN}
            onChange={(e) => setZip(e.target.value)}
            className="text-dark border border-light"
            value={zip}
            type="text"
          />
          <Button
            disabled={isLoading || userType !== Constants.ADMIN}
            className="mt-4"
            color="default"
            type="submit"
            block
          >
            Submit
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {})(withRouter(NewJob));
