import { createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import history from "utils/history.js";

const initialState = {
  rooms: {},
  hasFetched: false,
  activeRoom: null,
};

export default createReducer(initialState, {
  socketsAddedRooms: (state, { payload }) => {
    payload.forEach((room) => {
      const id = !isNaN(Number(room)) ? room : room.id;
      state.rooms[id] = getNewRoom({ roomId: id });
    });
  },
  socketsDeletedRooms: (state, { payload }) => {
    Object.keys(payload).forEach((id) => {
      delete state.rooms[id];
    });
  },
  socketsAddedMessages: (state, { payload }) => {
    payload.forEach((message) => {
      const { roomId, userId, contents } = message;
      if (state.rooms[roomId]) {
        const mIndex = state.rooms[roomId].messages.findIndex(
          (m) => m.userId === userId && m.contents === contents && m.status
        );
        if (mIndex > -1) {
          // Already in state -> user sent the message
          const message = state.rooms[roomId].messages[mIndex];
          state.rooms[roomId].messages[mIndex] = {
            ...message,
            status: "received",
          };
        } else {
          // New message from another user
          state.rooms[roomId].messages.unshift(message);
        }
      } else {
        state.rooms[roomId] = { messages: payload };
      }
    });
  },
  selectedRoom: (state, { payload }) => {
    state.activeRoom = payload;
  },
  fetchingRooms: (state, { payload }) => {
    if (payload) {
      state.rooms[payload] = getNewRoom({
        roomId: payload,
        hasFetchedRoom: true,
      });
    } else {
      state.hasFetched = true;
    }
  },
  fetchedRooms: (state, { payload }) => {
    const fetchedRooms = payload.reduce((acc, r) => {
      const { roomId, company, lastMessage, incomplete, ...room } = r;

      acc[roomId] = getNewRoom({
        roomId,
        messages: lastMessage,
        company,
        hasFetchedRoom: !incomplete,
        ...room,
      });

      return acc;
    }, {});

    state.rooms = { ...state.rooms, ...fetchedRooms };
  },
  didNotFetchRooms: (state, { payload }) => {
    if (!payload) {
      // Failed to fetch all rooms
      state.hasFetched = false;
      state.activeRoom = null;
    } else {
      // Failed to fetch a single room
      delete state.rooms[payload];
    }
  },
  submittedMessages: (state, { payload }) => {
    payload.status = "sending";
    state.rooms[payload.roomId].messages.unshift(payload);
  },
  resendMessage: (state, { payload }) => {
    payload.status = "sending";
    const { roomId, tempId } = payload;
    const index = state.rooms[roomId].messages.findIndex(
      (m) => m.tempId === tempId
    );

    if (index >= 0) {
      state.rooms[roomId].messages[index] = payload;
    }
  },
  confirmedMessageSent: (state, { payload }) => {
    const { tempId, ...updatedMessage } = payload;
    const { messages } = state.rooms[payload.roomId] || { messages: [] };

    const index = messages.findIndex((m) => m.tempId === tempId);
    if (index >= 0) {
      if (messages[index].status !== "received") {
        updatedMessage.status = "sent";
      }
      messages[index] = updatedMessage;
    }
  },
  messageDidNotSend: (state, { payload }) => {
    const { tempId, roomId } = payload;
    const room = state.rooms[roomId] || {};
    if (room.messages) {
      const msg = room.messages.find((r) => r.tempId === tempId);
      if (msg) {
        msg.status = "error";
      }
    }
  },
  fetchingMessages: (state, { payload }) => {
    state.rooms[payload].hasFetchedMessages = true;
  },
  fetchedMessages: (state, { payload }) => {
    const { roomId, messages } = payload;
    state.rooms[roomId].messages = messages;
  },
  didNotFetchMessages: (state, { payload }) => {
    if (state.rooms[payload]) {
      state.rooms[payload].hasFetchedMessages = false;
    }
  },
  sentTooManyMessages: (state, { payload }) => {
    if (state.rooms[payload] && state.rooms[payload].messages) {
      state.rooms[payload].messages.unshift({
        status: "system",
        contents: "You've sent too many messages, wait a minute and try again",
      });
    }
  },
});

function getNewRoom({
  messages,
  hasFetchedRoom = false,
  hasFetchedMessages = false,
  roomId,
  id,
  userlist = [],
  ...newRoom
}) {
  const msgs = messages
    ? Array.isArray(messages)
      ? messages
      : [messages]
    : [];
  return {
    ...newRoom,
    roomId: parseInt(roomId || id, 10),
    userlist,
    messages: msgs,
    hasFetchedRoom,
    hasFetchedMessages,
  };
}

export const setActiveRoom = (roomId) => ({
  type: "selectedRoom",
  payload: roomId,
});

export const fetchChatRooms = (roomId) => (dispatch) => {
  // If no roomId, fetch all rooms.  If roomId, fetch single room
  dispatch({ type: "fetchingRooms", payload: roomId });
  const url = roomId ? "api/chat/room/" + roomId : "api/chat/rooms";

  // Uncomment & add `dispatch, getState` to second function parameters
  // to remove current user from user list
  // const state = getState();
  // const { id: userId } = state.auth.user || {};

  axios
    .get(url)
    .then(({ data }) => {
      const rooms = roomId ? [data] : data;
      // Uncomment and pass variable to payload to remove current user
      // from the list
      // const removeUserFromUserlist = rooms.map((r) => {
      //   const userlist = r.userlist.filter(({ id }) => id !== userId);
      //   r.userlist = userlist;
      //   return r;
      // });
      dispatch({ type: "fetchedRooms", payload: rooms });
    })
    .catch((err) => {
      console.error("Fetching chat rooms encountered ", err);
      !roomId && history.push("/app/company");
      dispatch({ type: "didNotFetchRooms", payload: roomId });
    });
};

export const fetchMessagesForRoom = (roomId, lastMessageId) => (dispatch) => {
  dispatch({ type: "fetchingMessages", payload: roomId });

  axios
    .get(`/api/chat/messages/room/${roomId}`, { headers: { lastMessageId } })
    .then(({ data }) => {
      dispatch({
        type: "fetchedMessages",
        payload: { roomId, messages: data },
      });
    })
    .catch((err) => {
      console.error("Fetching messages for room ", roomId, ": ", err);
      dispatch({ type: "didNotFetchMessages", payload: roomId });
    });
};

export const sendChatMessage = (contents, tags) => (dispatch, getState) => {
  const state = getState();
  const roomId = state.chat.activeRoom;
  const { companyId, id: userId } = state.auth.user;
  const room = state.chat.rooms[roomId] || {};

  const message = { userId, roomId };
  message.contents = contents;

  if (tags) {
    message.tags = tags;
  }

  if (room.messages) {
    message.tempId = room.messages.length;
    dispatch({
      type: "submittedMessage",
      payload: { ...message, companyId },
    });

    axios
      .post("/api/chat/sendMessage", message)
      .then(({ data }) => {
        dispatch({ type: "confirmedMessageSent", payload: data });
      })
      .catch((err) => {
        dispatch({ type: "messageDidNotSend", payload: message });
        console.error(err);
        if (err && err.split && err.split(" ")[0] === "Duplicate") {
          dispatch({ type: "sentTooManyMessages", payload: message.roomId });
        }
      });
  } else {
    console.error("Cannot send a message to a room that doesn't exist.");
  }
};

export const sendAttachments = (attachments, tags) => (dispatch, getState) => {
  const state = getState();
  const roomId = state.chat.activeRoom;
  const { companyId, id: userId } = state.auth.user;
  const room = state.chat.rooms[roomId] || {};

  const postAttachments = attachments.map((a, i) => {
    const formData = new FormData();
    formData.append("image", a.file, a.file.name);
    tags && tags.length && formData.append("tags", JSON.stringify(tags));
    return axios.post(`/api/chat/sendAttachment/${roomId}`, formData);
  });

  const message = { userId, roomId };
  const { length } = room.messages;
  const newMessages = [];
  if (room.messages) {
    // Put 'unsent' message in state
    attachments.forEach((_, i) => {
      const payload = {
        ...message,
        companyId,
        attachment: true,
        tempId: length + i,
      };
      newMessages.push(payload);
      dispatch({ type: "submittedMessage", payload });
    });
  }

  if (tags) {
    message.tags = tags;
  }

  Promise.all(postAttachments)
    .then(({ data }) => {
      // add data to store
    })
    .catch((err) => {
      newMessages.forEach((message) => {
        dispatch({ type: "messageDidNotSend", payload: message });
      });
      console.error(err);
      if (err && err.split && err.split(" ")[0] === "Duplicate") {
        dispatch({ type: "sentTooManyMessages", payload: message.roomId });
      }
    });
};

export const resendChatMessage = (message) => (dispatch, getState) => {
  const { status, companyId, ...m } = message;
  const room = getState().chat.rooms[message.roomId] || {};
  if (room.messages) {
    dispatch({ type: "resendMessage", payload: { ...message } });

    axios
      .post("/api/chat/sendMessage", m)
      .then(({ data }) => {
        dispatch({ type: "confirmedMessageSent", payload: data });
      })
      .catch((err) => {
        dispatch({ type: "messageDidNotSend", payload: message });
        console.error(err);

        if (err && err.split && err.split(" ")[0] === "Duplicate") {
          dispatch({ type: "sentTooManyMessages", payload: message.roomId });
        }
      });
  } else {
    console.error("Cannot send a message to a room that doesn't exist.");
  }
};
