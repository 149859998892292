import React, { useState, useEffect } from "react";

import usePermissionScopes from "utils/usePermissionScopes";
import { Button, Input, FormGroup } from "reactstrap";
import { editJob } from "store/reducers/jobs";
import Constants from "utils/Constants";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function InspectionNotes({
  inspectionNote,
  companyType,
  userType,
  editJob,
  jobId,
}) {
  const permissionScope = usePermissionScopes(companyType, userType);

  const [newNote, setNewNote] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setNewNote(inspectionNote);
  }, [inspectionNote]);

  const update = async () => {
    try {
      await axios.post("/api/jobs/update/inspectionNote", {
        inspectionNote: newNote,
        jobId,
      });
      setIsEditing(false);
      editJob({
        inspectionNote: newNote,
        jobId,
      });
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue updating your job.",
      });
    }
  };

  return (
    <div className="mt-2 mb-2 p-2 bg-lighter">
      <label className="m-0 p-0" style={{ display: "inline-block" }}>
        inspection notes
      </label>
      {permissionScope === Constants.ADMIN_CONTRACTOR && !isEditing && (
        <span
          onClick={() => setIsEditing((prevState) => !prevState)}
          className="text-default ml-2"
          style={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          edit
        </span>
      )}
      {isEditing ||
        (inspectionNote.length ? (
          <p className="m-0 p-0 text-dark">{inspectionNote}</p>
        ) : (
          <p
            className="m-0 p-0 text-dark"
            style={{ fontSize: "12px", fontStyle: "italic" }}
          >
            nothing to show
          </p>
        ))}
      {isEditing && (
        <div>
          <FormGroup>
            <Input
              onChange={(e) => setNewNote(e.target.value)}
              className="text-dark mb-2 mt-2"
              placeholder="Inspection Note"
              name="Inspection note"
              value={newNote}
              maxLength="300"
              type="textarea"
              required
            />
            <Button onClick={update} size="sm" color="default">
              save
            </Button>
            <Button
              className="shadow-none"
              onClick={() => setIsEditing(false)}
              size="sm"
              color="white"
            >
              cancel
            </Button>
          </FormGroup>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, { editJob })(InspectionNotes);
