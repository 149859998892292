import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchMessagesForRoom, setActiveRoom } from "store/reducers/chat.js";
import { hasSeenNotifications } from "store/reducers/notifications.js";

function FetchChatRooms({
  rooms,
  fetchMessagesForRoom,
  setActiveRoom,
  hasSeenNotifications,
}) {
  const { roomId } = useParams();
  useEffect(() => {
    if (roomId) {
      // User navigates to different room, update the active one in redux
      setActiveRoom(roomId);
    }
  }, [roomId, setActiveRoom]);

  useEffect(() => {
    const room = rooms[roomId];
    if (room && !room.hasFetchedMessages) {
      // If we haven't fetched messages for a room yet, grab them now
      fetchMessagesForRoom(roomId);
    }

    // Whenever there is a change to rooms, mark notifications as seen
    hasSeenNotifications("messages");
  }, [roomId, rooms, fetchMessagesForRoom, hasSeenNotifications]);

  return null;
}

const mapStateToProps = (state) => ({
  rooms: state.chat.rooms,
});

const mapDispatchToProps = {
  fetchMessagesForRoom,
  setActiveRoom,
  hasSeenNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(FetchChatRooms);
