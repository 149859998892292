/**
 * After filling out and submitting an invitation to join a company via Invite.js,
 * a user is forwarded to this component via Auth0 redirect. This component pulls that
 * users state from local storage (does not include things like email/password, just name etc)
 * and submit the invitation with the now authenticated user.
 */
import React, { useEffect, useState } from "react";

import { useAuth0 } from "../../../react-auth0-spa";
import { withRouter } from "react-router-dom";
import Loading from "../../utils/Loading";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import axios from "axios";

function InviteRedirect(props) {
  const [newUserInfo, setNewUserInfo] = useState({});
  const { getTokenSilently, logout, user } = useAuth0();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("newUserSettings"));
    localStorage.removeItem("newUserSettings");
    if (userInfo) setNewUserInfo(userInfo);
    else props.history.push("/");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(newUserInfo).length) {
      completeInvitation();
    }
    // eslint-disable-next-line
  }, [newUserInfo]);

  const completeInvitation = async () => {
    try {
      localStorage.removeItem("token");
      const token = await getTokenSilently();
      window.localStorage.setItem("token", token);
      if (user.email !== newUserInfo.email) {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "You must sign up using the same email with which you received the invitation.",
        });
        logout({
          returnTo: process.env.REACT_APP_LOGOUT_URL || "http://localhost:3000",
        });
        return;
      }
      const res = await axios.post(
        "/api/company/invitation/submit",
        newUserInfo
      );
      if (res.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });
        props.history.push("/");
      } else {
        props.history.push("/app/company");
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There is already an account with those login credentials.",
      });
      logout({
        returnTo: process.env.REACT_APP_LOGOUT_URL || "http://localhost:3000",
      });
    }
  };
  return (
    <div>
      <Loading />
    </div>
  );
}

export default withRouter(InviteRedirect);

InviteRedirect.propTypes = {
  history: PropTypes.object.isRequired,
};
