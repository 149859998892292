import { useEffect } from "react";

import { fetchContractorVendors } from "store/reducers/contractorVendors";
import Constants from "utils/Constants";
import { connect } from "react-redux";

function ContractorVendors({ companyType, companyId, fetchContractorVendors }) {
  useEffect(() => {
    if (companyType === Constants.CONTRACTOR) {
      companyId && fetchContractorVendors();
    }
    // eslint-disable-next-line
  }, [companyId, companyType, Constants]);
  return null;
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
  companyType: state.company.company.companyType,
});

export default connect(mapStateToProps, { fetchContractorVendors })(
  ContractorVendors
);
