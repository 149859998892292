import React, { useState, useEffect } from "react";

import { unscheduleJob } from "store/reducers/jobs";
import PrintableJobCard from "./PrintableJobCard";
import { withRouter } from "react-router-dom";
import Constants from "utils/Constants";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Tags from "./Tags";

function JobEditPopup({
  unscheduleJob,
  companyType,
  calendars,
  history,
  allJobs,
  match,
}) {
  const [selectedJob, setSelectedJob] = useState({
    address: "",
    assignedToCompanyId: null,
    customCrewMemberName: null,
    assignedToUserId: null,
    assignedToUserInfo: {},
    calendarAssignments: [],
    city: "",
    color: "black",
    companyId: null,
    companyInfo: {},
    contractAmount: null,
    contractorCompanyName: "",
    contractorId: null,
    crewId: null,
    customSubcontractorName: null,
    dateInserted: "",
    endDate: "",
    id: null,
    isAcceptedBySub: false,
    inspectionNote: "",
    isCustom: true,
    jobCrewTableId: null,
    jobCustomer: null,
    jobNumber: "",
    latitude: null,
    longitude: null,
    reports: [],
    startDate: null,
    state: null,
    status: null,
    tags: [],
    title: null,
    zip: "",
  });
  const [calendarAssignmentIds, setCalendarAssignmentIds] = useState([]);

  const updateColor = async (color) => {
    if (companyType !== Constants.CONTRACTOR) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Only contractor accounts are able to change job colors.",
      });
    }
    try {
      await axios.post("/api/jobs/update/changeColor", {
        color,
        jobId: selectedJob.id,
      });
      setSelectedJob((prevState) => ({ ...prevState, color }));
    } catch (e) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error updating this job. Please try again in a moment.",
      });
    }
  };

  const toggleAssignment = async (jobId, calendarId) => {
    try {
      const assignment = await axios.post("/api/calendar/assign", {
        jobId,
        calendarId,
      });

      if (assignment.data.assignment.length) {
        setSelectedJob((prevState) => ({
          ...prevState,
          calendarAssignments: [
            ...prevState.calendarAssignments,
            ...assignment.data.assignment,
          ],
        }));
      } else {
        setSelectedJob((prevState) => ({
          ...prevState,
          calendarAssignments: prevState.calendarAssignments.filter(
            (ca) => ca.calendarId !== calendarId
          ),
        }));
      }
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error updating this job. Please try again in a moment.",
      });
    }
  };

  useEffect(() => {
    setCalendarAssignmentIds(
      selectedJob.calendarAssignments.map(({ calendarId }) => calendarId)
    );
  }, [selectedJob]);

  useEffect(() => {
    if (allJobs.length) {
      const j = allJobs.find((j) => j.id === parseInt(match.params.id));
      if (j) {
        setSelectedJob(j);
      } else {
        history.push("/app/calendar");
      }
    }
  }, [match.params.id, allJobs, history]);

  return (
    <Cover
      onClick={() => {
        history.push("app/calendar");
      }}
    >
      <InnerWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded shadow p-4 bg-white"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="display-4 text-darker m-0">{selectedJob.title}</p>
          <div>
            <Button
              color="default"
              size="sm"
              onClick={() =>
                history.push(`/app/calendar/job/details/${selectedJob.id}`)
              }
            >
              See Full Details
            </Button>
            {selectedJob.startDate && companyType === Constants.CONTRACTOR && (
              <Button
                color="dark"
                size="sm"
                onClick={() => {
                  if (selectedJob.status !== "active") {
                    return Swal.fire({
                      icon: "warning",
                      title: "Oops...",
                      text:
                        "You can not reschedule jobs which are marked as complete.",
                    });
                  }
                  unscheduleJob(selectedJob.id);
                }}
              >
                unschedule
              </Button>
            )}
          </div>
        </div>
        <p className="text-darker m-0">
          {selectedJob.address && `${selectedJob.address}, `}
          {`${selectedJob.city}, `}
        </p>
        <p className="text-darker m-0 mb-3">
          {`${selectedJob.state}`} {selectedJob.zip && `, ${selectedJob.zip}`}
        </p>
        <hr className="mt-2 mb-2" />

        <PrintableJobCard selectedJob={selectedJob} />

        <Tags jobId={selectedJob.id} tags={selectedJob.tags} />

        {companyType === Constants.CONTRACTOR && (
          <div>
            <label className="text-dark font-weight-bold mb-0 mt-2">
              Assign Color
            </label>
            <br />
            <div className="mb-4" style={{ display: "flex", flexWrap: "wrap" }}>
              {Constants.CALENDAR_COLORS.map((c) => (
                <div
                  key={c}
                  onClick={() => updateColor(c)}
                  className="shadow--hover rounded m-2"
                  style={{
                    background: c,
                    width: "75px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                >
                  {c === selectedJob.color && (
                    <span className="btn-inner--icon mr-1 ml-3 text-white">
                      <i className="fa fa-check" />
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <div>
          <label className="text-dark font-weight-bold mb-0 mt-2">
            Add To Calendar
          </label>
          {calendars.map((c) => (
            <CalendarSelection
              className={`p-2 m-2 ${
                calendarAssignmentIds.includes(c.id)
                  ? "bg-primary"
                  : "bg-lighter"
              } rounded shadow--hover`}
              key={c.id}
              onClick={() => {
                toggleAssignment(selectedJob.id, c.id);
              }}
            >
              <p
                className={`m-0 p-0 ${
                  calendarAssignmentIds.includes(c.id)
                    ? "text-white"
                    : "text-dark"
                } `}
              >
                {c.title}
              </p>
              <span
                className={`btn-inner--icon mr-1 ml-3 ${
                  calendarAssignmentIds.includes(c.id)
                    ? "text-white"
                    : "text-dark"
                }`}
              >
                <i
                  className={
                    calendarAssignmentIds.includes(c.id)
                      ? "fa fa-minus"
                      : "fa fa-plus"
                  }
                />
              </span>
            </CalendarSelection>
          ))}
        </div>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => {
  return {
    companyType: state.company.company.companyType,
    userType: state.auth.user.userType,
    companyId: state.auth.user.companyId,
    allJobs: state.jobs.jobs,
  };
};

export default connect(mapStateToProps, { unscheduleJob })(
  withRouter(JobEditPopup)
);

const Cover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const InnerWrapper = styled.div`
  width: 95%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: scroll;
  margin: auto;
  margin-top: 75px;
  cursor: initial;
  position: sticky;
  top: 50px;
`;

const CalendarSelection = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: 600;
  }
`;
