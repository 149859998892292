import React, { useState, useEffect } from "react";

import styled from "styled-components";

export default function DropdownMenu({
  clickedId,
  additionalXOffset = 0,
  additionalYOffset = 0,
  dropDownOpen,
  children,
}) {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const domNode = document.getElementById(clickedId);
    if (domNode) {
      setTop(
        domNode.getBoundingClientRect().top + additionalYOffset + window.scrollY
      );
      setLeft(domNode.getBoundingClientRect().left + additionalXOffset);
    }
  }, [clickedId, additionalXOffset, additionalYOffset]);

  return (
    <DropDownWrapper
      className="p-2 bg-white rounded shadow"
      display={dropDownOpen ? "block" : "none"}
      top={`${top}px`}
      left={`${left}px`}
    >
      {children}
    </DropDownWrapper>
  );
}

const DropDownWrapper = styled.div`
  display: ${(props) => props.display};
  width: 250px;
  position: absolute;
  z-index: 10000;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;
