import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const routeWithHistory = (history) => (route) => history.push(route);

function NotificationsDropdown({ display, notifications }) {
  const history = useHistory();
  const routeTo = routeWithHistory(history);
  return (
    <Wrapper display={display} className="bg-white shadow rounded">
      {notifications.map((n) => {
        const { notification } = n;
        const { id, title, content, route, image } = notification || {};
        return (
          <Notification key={id} onClick={routeTo.bind(this, route)}>
            <img src={image} alt="notification icon" />
            <div>
              <h2>{title}</h2>
              <p>{content}</p>
            </div>
          </Notification>
        );
      })}
      {!notifications.length && (
        <Notification>
          <h2>You're all caught up!</h2>
        </Notification>
      )}
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.bell,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDropdown);

const Wrapper = styled.div`
  padding: 5px 0;
  width: ${(props) => props.width || 150};
  position: fixed;
  z-index: 1000000000;
  top: 50px;
  right: 30px;
  display: ${(props) => props.display || "block"};
  }
`;

const Notification = styled.div`
  cursor: pointer;
  padding: 5px;
  display: flex;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  div {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  h2 {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    font-weight: 0;
    padding: 0px;
    text-align: left;
  }
`;
