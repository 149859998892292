import React, { useState, useEffect } from "react";

import DropdownMenu from "utils/DropdownMenu";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";

function AppointmentDropDown({
  setDropDownOpen,
  dropDownOpen,
  appointments,
  selectedEventId,
}) {
  const history = useHistory();
  const [appointment, setAppointment] = useState({});

  useEffect(() => {
    const eventIdArray = selectedEventId.split("-");
    const itemId = parseInt(eventIdArray[eventIdArray.length - 1]);

    const a = appointments.find((a) => a.id === itemId);
    if (a) {
      setAppointment(a);
    }
  }, [selectedEventId, appointments]);

  return (
    <DropdownMenu
      additionalYOffset={10}
      clickedId={selectedEventId}
      dropDownOpen={dropDownOpen}
      setDropDownOpen={setDropDownOpen}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontWeight: 600 }} className="p-0 m-0 text-dark">
          Appointments Actions
        </p>
        <p
          className="p-0 m-0 text-dark"
          style={{ cursor: "pointer", fontWeight: 600 }}
          onClick={() => setDropDownOpen(false)}
        >
          x
        </p>
      </div>
      <DropDownItem
        onClick={() => {
          appointment.id &&
            history.push(`/app/calendar/appointment_options/${appointment.id}`);
        }}
        className="p-2"
        style={{
          borderLeft: `5px solid ${
            appointment.status === "complete" ? "#738394" : appointment.color
          }`,
        }}
      >
        <p className="m-0 p-0">Appointment Details</p>
      </DropDownItem>
    </DropdownMenu>
  );
}

export default connect(null, {})(AppointmentDropDown);

const DropDownItem = styled.div`
  width: 100%;
  background: white;
  :hover {
    background: #f2f2f2;
    cursor: pointer;
  }
`;
