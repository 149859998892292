import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";

const initialState = {
  categories: [],
  products: [],
};

export default createReducer(initialState, {
  addCategory: (state, { payload }) => {
    state.categories = [...state.categories, payload];
  },
  addProduct: (state, { payload }) => {
    state.products = [...state.products, payload];
  },
  fetchCategories: (state, { payload }) => {
    state.categories = payload;
  },
  fetchProducts: (state, { payload }) => {
    state.products = payload;
  },
  deleteCategory: (state, { payload }) => {
    state.categories = state.categories.filter((c) => c.id !== payload);
  },
  deleteProduct: (state, { payload }) => {
    state.products = state.products.filter((c) => c.id !== payload);
  },
  updateCategoryTitle: (state, { payload }) => {
    state.categories = state.categories.map((c) =>
      c.id !== payload.categoryId ? c : { ...c, title: payload.title }
    );
  },
  updateProduct: (state, { payload }) => {
    state.products = state.products.map((c) =>
      c.id !== payload.id ? c : { ...c, ...payload }
    );
  },
});

export const addCategory = createAction("addCategory");

export const fetchCategories = (companyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/products/categories/${companyId}`);
    dispatch({ type: "fetchCategories", payload: data.categories });
  } catch (error) {}
};

export const updateCategoryTitle = (title, categoryId) => async (dispatch) => {
  try {
    await axios.put(`/api/products/categories`, { title, categoryId });
    dispatch({ type: "updateCategoryTitle", payload: { title, categoryId } });
  } catch (error) {}
};

export const deleteCategory = (categoryId) => async (dispatch) => {
  try {
    await axios.delete(`/api/products/categories/${categoryId}`);
    dispatch({ type: "deleteCategory", payload: categoryId });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        "There was an error removing this category. Please try again in a moment.",
    });
  }
};

export const addProduct = (name, unitCost, categoryId) => async (dispatch) => {
  try {
    const {
      data: { product },
    } = await axios.post("/api/products/add", {
      name,
      unitCost: parseFloat(unitCost),
      categoryId,
    });
    dispatch({ type: "addProduct", payload: product });
  } catch (error) {}
};

export const fetchProducts = (companyId) => async (dispatch) => {
  try {
    const {
      data: { products },
    } = await axios.get(`/api/products/products/${companyId}`);
    dispatch({ type: "fetchProducts", payload: products });
  } catch (error) {}
};

export const updateProduct = (details) => async (dispatch) => {
  try {
    await axios.put(`/api/products/products`, details);
    dispatch({ type: "updateProduct", payload: details });
  } catch (error) {}
};

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    await axios.delete(`/api/products/products/${productId}`);
    dispatch({ type: "deleteProduct", payload: productId });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        "There was an error removing this product. Please try again in a moment.",
    });
  }
};
