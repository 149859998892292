import { useEffect } from "react";
import { connect } from "react-redux";

import { fetchCustomers } from "store/reducers/customers.js";
import Constants from "utils/Constants";

function FetchCustomers({ fetchCustomers, companyType }) {
  useEffect(() => {
    companyType && companyType === Constants.CONTRACTOR && fetchCustomers();
    // eslint-disable-next-line
  }, [companyType]);
  return null;
}

const mapDispatchToProps = { fetchCustomers };

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
});

export default connect(mapStateToProps, mapDispatchToProps)(FetchCustomers);
