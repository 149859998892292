import React from "react";

import { Container } from "reactstrap";
import styled from "styled-components";
import { Input } from "reactstrap";
import ShingleImg from "../../../../imgs/shingleimg.jpeg";

const LandingHero = () => {
  return (
    <ContainerWrapper className="pt-0 pb-6 container-fluid bg-darker" fluid>
      <div className="innerWrapper">
        <InnerHeroWrapper>
          <h1
            className="text-white mb-0"
            style={{ fontSize: "60px", fontWeight: "800" }}
          >
            Keep your Shit Together
          </h1>
          <p
            className="mt-4 mb-4 text-white"
            style={{ fontWeight: "400", fontSize: "22px" }}
          >
            Crewly is a cross-company collaborative construction project
            management software. Track your jobs, assign them to your
            subcontractors, views on-the-job images, and get paid. All in one
            place.
          </p>
        </InnerHeroWrapper>
        <SignUpCard className="rounded mr-4 p-4 bg-dark SteppedBoxShadow">
          <h3 className="display-4 text-white">Find Out More</h3>
          <p
            className="text-white text-left mb-0"
            style={{ fontWeight: "600" }}
          >
            *Name
          </p>
          <Input className="text-dark mb-4" required type="text" name="name" />
          <p
            className="text-white text-left mb-0"
            style={{ fontWeight: "600" }}
          >
            *Email
          </p>
          <Input className="text-dark mb-4" required type="text" name="email" />
          <p
            className="text-white text-left mb-0"
            style={{ fontWeight: "600" }}
          >
            *What does your company do?
          </p>
          <Input
            className="text-dark mb-4"
            required
            type="text"
            name="companyType"
          />
          <p
            className="text-white text-left mb-0"
            style={{ fontWeight: "600" }}
          >
            Anything else you'd like us to know?
          </p>
          <Input className="text-dark mb-4" type="textarea" name="details" />
          <SubmitEmailButton className="bg-white text-dark mt-4">
            <span className="btn-inner--text">Join the Crew</span>
          </SubmitEmailButton>
        </SignUpCard>
      </div>
    </ContainerWrapper>
  );
};

export default LandingHero;

const ContainerWrapper = styled(Container)`
  // background-image: url(${ShingleImg});
  .innerWrapper {
    margin: auto;
    width: 100%;
    padding-top: 30px;
    max-width: 1300px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h1 {
      margin-top: 50px;
    }
    @media (max-width: 850px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

const SignUpCard = styled.div`
  z-index: 0;
  width: 45%;
  margin: 25px 0;
  min-width: 350px;
  @media (max-width: 850px) {
    width: 90%;
    margin: 0;
  }
`;

const InnerHeroWrapper = styled.div`
  width: 55%;
  height: 100%;
  min-height: 60vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  justify-content: flex-start;
  text-align: left;
  padding: 0 10px;
  z-index: 2;
  @media (max-width: 850px) {
    width: 95%;
  }
  p {
    max-width: 400px;
    font-size: 18px;
    @media (min-width: 1000px) {
      max-width: 550px;
      font-size: 20px;
    }
    @media (max-width: 850px) {
      width: 100%;
      max-width: 100%;
    }
  }
  h1 {
    z-index: 1;
    line-height: 65px;
    color: #32325d;
    font-size: 45px;
    max-width: 550px;
    @media (max-width: 850px) {
      width: 100%;
    }
  }
`;

const SubmitEmailButton = styled.button`
  margin: 15px 0;
  width: 100%;
  padding: 15px;
  transition: 0.2s;
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  border: none;
`;
