import React, { useState, useEffect } from "react";

import { Cover, InnerWrapper } from "../StyledComponents";
import { useHistory } from "react-router-dom";
import { Button, Input } from "reactstrap";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";

function ViewSingleCustomerDetails({
  handleUpdateCustomer,
  handleDeleteCustomer,
  setIsViewingCustomer,
  isViewingCustomer,
  appointments,
  customer,
  isAdmin,
  jobs,
}) {
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  useEffect(() => {
    setFirstName(customer.firstName || "");
    setLastName(customer.lastName || "");
    setCompany(customer.company || "");
    setPhone(customer.phone || "");
    setAddress(customer.address || "");
    setCity(customer.city || "");
    setState(customer.state || "");
    setZip(customer.zip || "");
  }, [customer]);

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsViewingCustomer(false);
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ display: isViewingCustomer ? "block" : "none" }}
    >
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="rounded p-4 bg-white"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="display-4 text-darker m-0 mb-2">
            {customer.firstName} {customer.lastName}{" "}
            {customer.company && "- " + customer.company}
          </p>
          <Button
            disabled={!isAdmin}
            onClick={() => handleDeleteCustomer(customer.id)}
            color="danger"
            size="sm"
          >
            delete
          </Button>
        </div>
        {/* ---- JOBS ---- */}
        <p
          className="text-darker m-0 mt-2"
          style={{ fontWeight: 600, fontSize: "1.2rem" }}
        >
          Jobs
        </p>
        {!jobs.filter((j) => j.jobCustomer === customer.id).length && (
          <p className="m-0 p-0 ml-2">nothing to show</p>
        )}
        {jobs
          .filter((j) => j.jobCustomer === customer.id)
          .map((j) => {
            return (
              <JobAndAppointmentRow
                key={j.id}
                onClick={() => history.push(`/app/jobs/details/${j.id}`)}
                className="text-default m-0 p-0 ml-2"
              >
                {j.title}
              </JobAndAppointmentRow>
            );
          })}
        {/* ---- APPOINTMENTS */}
        <p
          className="text-darker m-0 mt-2"
          style={{ fontWeight: 600, fontSize: "1.2rem" }}
        >
          Appointments
        </p>
        {appointments
          ?.filter((a) => a.customerId === customer.id)
          .map((a) => {
            return (
              <JobAndAppointmentRow
                key={a.id}
                onClick={() =>
                  history.push(`/app/calendar/appointment_options/${a.id}`)
                }
                className="text-default m-0 p-0 ml-2"
              >
                Meeting with {a.assignedToFirstName} {a.assignedToLastName}{" "}
                {moment(a.start).calendar()}
              </JobAndAppointmentRow>
            );
          })}
        {!appointments?.filter((a) => a.customerId === customer.id).length && (
          <p className="m-0 p-0 ml-2">nothing to show</p>
        )}
        {/* ---- DETAILS */}
        <p
          className="text-darker m-0 mt-2"
          style={{ fontWeight: 600, fontSize: "1.2rem" }}
        >
          Details
        </p>
        <form
          className="ml-2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "90%",
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateCustomer(customer.id, {
              firstName,
              lastName,
              company,
              phone,
              address,
              city,
              state,
              zip,
            });
          }}
        >
          <div>
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *first name
            </label>
            <Input
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *last name
            </label>
            <Input
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              *phone number (555-555-5555)
            </label>
            <Input
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="text-dark border border-light mt-2"
              type="tel"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              company
            </label>
            <Input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className="text-dark border border-light mt-2 mb-4"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-4">
              address
            </label>
            <Input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">city</label>
            <Input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">
              state
            </label>
            <Input
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
            <label className="text-dark font-weight-bold mb-0 mt-2">zip</label>
            <Input
              value={zip}
              onChange={(e) => setZip(e.target.value)}
              className="text-dark border border-light mt-2"
              type="text"
              disabled={!isAdmin}
            />
          </div>
          <Button
            disabled={!isAdmin}
            className="mt-4 mb-4"
            color="default"
            type="submit"
            block
          >
            update
          </Button>
        </form>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  jobs: state.jobs.jobs,
  appointments: state.appointments.appointments,
});

export default connect(mapStateToProps, {})(ViewSingleCustomerDetails);

const JobAndAppointmentRow = styled.p`
  cursor: pointer;
  font-weight: 600;
  :hover {
    text-decoration: underline;
  }
`;
