import React, { useState, useEffect } from "react";

import { unscheduleJob, editJob } from "store/reducers/jobs";
import DropdownMenu from "utils/DropdownMenu";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function JobDropDown({
  isAdminContractor,
  setDropDownOpen,
  selectedEventId,
  unscheduleJob,
  dropDownOpen,
  editJob,
  history,
  jobs,
}) {
  const [job, setJob] = useState({});
  const [isScheduled, setIsScheduled] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const toggleCompleteStatus = async () => {
    try {
      await axios.post("/api/jobs/complete", {
        jobId: job.id,
        isActive: !isComplete,
      });
      editJob({ jobId: job.id, status: isComplete ? "active" : "complete" });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text:
          "There was an error updating this job. Please try again in a moment.",
      });
    } finally {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    const eventIdArray = selectedEventId.split("-");
    const itemId = parseInt(eventIdArray[eventIdArray.length - 1]);

    const j = jobs.find((j) => j.id === itemId);
    if (j) {
      if (!j.startDate || !j.endDate) {
        setIsScheduled(false);
      } else {
        setIsScheduled(true);
      }
      setJob(j);
      setIsComplete(j.status !== "active");
    }
  }, [selectedEventId, jobs]);

  return (
    <DropdownMenu
      additionalYOffset={10}
      clickedId={selectedEventId}
      dropDownOpen={dropDownOpen}
      setDropDownOpen={setDropDownOpen}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontWeight: 600 }} className="p-0 m-0 text-dark">
          Job Actions
        </p>
        <p
          className="p-0 m-0 text-dark"
          style={{ cursor: "pointer", fontWeight: 600 }}
          onClick={() => setDropDownOpen(false)}
        >
          x
        </p>
      </div>
      <DropDownItem
        onClick={() => {
          job.id && history.push(`/app/calendar/job/details/${job.id}`);
        }}
        className="p-2"
      >
        <p className="m-0 p-0">Full Job Details</p>
      </DropDownItem>

      <DropDownItem
        onClick={() => {
          job.id && history.push(`/app/calendar/event_options/${job.id}`);
        }}
        className="p-2"
        style={{ borderLeft: "5px solid " + job.color }}
      >
        <p className="m-0 p-0">Card/Calendar Details</p>
      </DropDownItem>

      {isScheduled && isAdminContractor && (
        <DropDownItem
          onClick={() => {
            setDropDownOpen(false);
            if (job.status !== "active") {
              return Swal.fire({
                icon: "warning",
                title: "Oops...",
                text:
                  "You can not reschedule jobs which are marked as complete.",
              });
            }
            unscheduleJob(job.id);
          }}
          className="p-2"
        >
          <p className="m-0 p-0">Unschedule</p>
        </DropDownItem>
      )}

      {isAdminContractor && (
        <DropDownItem onClick={toggleCompleteStatus} className="p-2">
          <p className="m-0 p-0">
            Mark as {isComplete ? "Incomplete" : "Complete"}
          </p>
        </DropDownItem>
      )}
    </DropdownMenu>
  );
}

export default connect(null, { unscheduleJob, editJob })(JobDropDown);

const DropDownItem = styled.div`
  width: 100%;
  background: white;
  :hover {
    background: #f2f2f2;
    cursor: pointer;
  }
`;
