import React, { useState, useEffect } from "react";

import { Cover, InnerWrapper } from "../../StyledComponents";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import currency from "currency.js";
import Product from "./Product";

function ManageProductsForm({
  handleUpdatePurchaseOrderItems,
  setIsAssigningProducts,
  isAssigningProducts,
  purchaseOrder,
  categories,
  products,
}) {
  const history = useHistory();

  const [selectedItems, setSelectedItems] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSelectedItems(purchaseOrder?.items || []);
  }, [purchaseOrder]);

  const handleSaveChanges = async () => {
    try {
      setIsSaving(true);
      const rows = selectedItems.map(({ productId, quantity }) => ({
        productId,
        quantity,
        purchaseOrderId: purchaseOrder.id,
      }));
      await handleUpdatePurchaseOrderItems(purchaseOrder.id, rows);
      setIsSaving(false);
      setIsAssigningProducts(false);
    } catch (err) {
      setIsSaving(false);
    }
  };

  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        display: isAssigningProducts ? "block" : "none",
        cursor: "initial",
      }}
    >
      <InnerWrapper
        style={{ maxHeight: "75vh" }}
        className="rounded p-4 bg-white"
      >
        <p className="m-0 p-0 text-dark h4" style={{ fontWeight: 600 }}>
          <span>{purchaseOrder?.title || "hi"}</span>
        </p>
        {!categories.length && (
          <>
            <p className="m-0 mt-2 ml-0 p-0" style={{ fontStyle: "italic" }}>
              no product categories to show.
            </p>
            <Button
              color="default"
              className="mt-2"
              onClick={() => {
                history.push("/app/jobs/products");
              }}
            >
              manage products
            </Button>
          </>
        )}
        {categories.map((c) => {
          const p = products.filter((p) => p.categoryId === c.id);
          return (
            <div key={c.id} className="mt-2 mb-2">
              <div className="rounded-top p-2 bg-lighter">
                <p
                  className={`m-0 p-0 rounded text-dark h5`}
                  style={{ fontWeight: 600 }}
                >
                  {c.title}
                </p>
              </div>
              {!p.length && (
                <p
                  className="m-0 mt-2 ml-2 p-0"
                  style={{ fontStyle: "italic" }}
                >
                  nothing to show
                </p>
              )}
              {p.map((prod) => {
                return (
                  <Product
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    product={prod}
                    key={prod.id}
                  />
                );
              })}
            </div>
          );
        })}
      </InnerWrapper>
      <ToolBarBottom
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
        className="p-2 shadow-lg"
      >
        <div className="actions-btns-wrapper">
          <Button
            onClick={handleSaveChanges}
            disabled={isSaving}
            color="default"
          >
            save changes
          </Button>
          <Button
            disabled={isSaving}
            onClick={() => {
              setSelectedItems(purchaseOrder.items);
              setIsAssigningProducts(false);
            }}
            color="white"
            className="shadow-none"
          >
            cancel
          </Button>
        </div>
        <div className="total-wrapper mr-4">
          <span className="m-0 p-0 mr-2">Total</span>
          <span className="m-0 p-0 text-success">
            {currency(
              selectedItems.reduce(
                (acc, cv) => (acc += cv.unitCost * cv.quantity),
                0.0
              ),
              { symbol: "$", precision: 2 }
            ).format()}
          </span>
        </div>
      </ToolBarBottom>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  categories: state.products.categories,
  products: state.products.products,
});

export default connect(mapStateToProps, {})(ManageProductsForm);

const ToolBarBottom = styled.div`
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: initial;

  .actions-btns-wrapper .total-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-wrapper {
    span {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
`;
