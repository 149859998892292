import React, { useState, useEffect } from "react";

import DatePickerRangeFilter from "./DatePickerRangeFilter";
import { editFilters } from "store/reducers/jobs";
import styled from "styled-components";
import { connect } from "react-redux";

function CalendarSettingsBar({
  filterLocation,
  filterStatus,
  editFilters,
  currentView,
  jobs,
}) {
  const [locations, setLocations] = useState(new Set());

  useEffect(() => {
    const jobLocations = jobs.map((j) => {
      return j.city.toLowerCase();
    });
    setLocations(new Set(jobLocations));
  }, [jobs]);

  return (
    <div className="mb-4" style={{ height: "100%" }}>
      <SettingsBarWrapper>
        <p className="h5 text-dark mb-2">Settings & Filters</p>
        <label className="m-0 text-dark text-justify">status</label>
        <select
          value={filterStatus}
          onChange={(e) => {
            editFilters({
              filterStatus: e.target.value,
              filterLocation,
            });
          }}
          className="form-control mb-2 text-dark form-control-sm"
          style={{ width: "100%" }}
        >
          <option>all</option>
          <option>active</option>
          <option>completed</option>
        </select>
        {currentView === "appointments" || (
          <>
            <label className="m-0 text-dark text-justify">location</label>
            <select
              className="form-control mb-2 text-dark form-control-sm"
              style={{ width: "150px !important" }}
              value={filterLocation}
              onChange={(e) => {
                editFilters({
                  filterStatus,
                  filterLocation: e.target.value,
                });
              }}
            >
              <option>all locations</option>
              {Array.from(locations).map((l) => (
                <option key={l}>{l}</option>
              ))}
            </select>
          </>
        )}
        <DatePickerRangeFilter />
      </SettingsBarWrapper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filterLocation: state.jobs.filters.filterLocation,
  filterStatus: state.jobs.filters.filterStatus,
});

export default connect(mapStateToProps, { editFilters })(CalendarSettingsBar);

const SettingsBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 150px;
  position: sticky;
  top: 0;
  z-index: 750;
`;
