import React from "react";

import styled from "styled-components";
import { withRouter } from "react-router";

function SingleCompany({ company, history }) {
  return (
    <Wrapper className="mb-2">
      <ImgWrapper className="mr-3">
        <img
          src={company.logoUrl || "https://via.placeholder.com/56x56?text=logo"}
          alt=""
        />
      </ImgWrapper>
      <InfoWrapper className=" w-100 pb-2 border-bottom border-light">
        <p
          onClick={() => history.push(`/app/view/company/${company.id}`)}
          className="lead text-primary m-0"
        >
          <b style={{ fontWeight: 600, lineHeight: 1 }} className="name">
            {company.name}
          </b>
          <span
            className="text-dark"
            style={{ fontSize: "16px", lineHeight: 1 }}
          >
            - {company.location}
          </span>
        </p>
        <p
          className="text-dark m-0"
          style={{ fontSize: "16px", lineHeight: 1 }}
        >
          <span>{company.description}</span>
        </p>
      </InfoWrapper>
    </Wrapper>
  );
}

export default withRouter(SingleCompany);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  img {
    border-radius: 50%;
    width: 56px;
    height: 56px;
  }
`;

const InfoWrapper = styled.div`
  .name {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
