// company types
const CONTRACTOR = "contractor";
const SUBCONTRACTOR = "subcontractor";
// user types
const MEMBER = "member";
const ADMIN = "admin";
const SALESMAN = "salesman";
const FOREMAN = "foreman";
// permissions scopes
const ADMIN_CONTRACTOR = "admin_contractor";
const MEMBER_CONTRACTOR = "member_contractor";
const SALESMAN_CONTRACTOR = "salesman_contractor";
const FOREMAN_CONTRACTOR = "foreman_contractor";
const ADMIN_SUBCONTRACTOR = "admin_subcontractor";
const MEMBER_SUBCONTRACTOR = "member_subcontractor";

const CALENDAR_COLORS = [
  "#3966ea",
  "#9a5ce3",
  "#f3a4b5",
  "#f5365c",
  "#fb6340",
  "#ffd600",
  "#059361",
  "#2bffc6",
  "#5603ad",
  "#669E1E",
  "#EBDB21",
  "#EB5080",
  "#EDBF74",
  "#635585",
];

export default {
  CONTRACTOR,
  SUBCONTRACTOR,
  MEMBER,
  ADMIN,
  SALESMAN,
  FOREMAN,
  ADMIN_CONTRACTOR,
  MEMBER_CONTRACTOR,
  SALESMAN_CONTRACTOR,
  FOREMAN_CONTRACTOR,
  ADMIN_SUBCONTRACTOR,
  MEMBER_SUBCONTRACTOR,
  CALENDAR_COLORS,
};
