/**
 * PLEASE READ THIS BEFORE YOU WORK ON THIS COMPONENT
 *
 * SO there is one important note here...
 * FullCalendar (the calendar lib that we're using) has a concept of "all day"
 * events, which is what we use here. An "all day" event in their eyes has a
 * start date and an end date.
 * If the "start date" is the 26th and the "end date" is the 27th, youll only
 * get a bar over the 26th, as the even "ends" at 12am the 27th....
 * Because of this, i've had to do some finagling to make this work how we want..
 * When jobs come in, we format the dates via formatEndDateToDisplayCorrectly.
 * When we schedule/reschedule events, we ALSO need to to do some addition/subtraction
 * in order to correct everything to how people THINK their scheduling their jobs.
 * Because of this, we need to be pretty careful and test changes to this component
 * pretty well. Thanks!!
 */

import React, { useEffect, useState, useRef } from "react";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import LoadingCover from "components/app/utils/LoadingCover";
import AppointmentDropDown from "./AppointmentDropDown";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useHistory } from "react-router-dom";
import Unscheduled from "./Unscheduled";
import JobDropDown from "./JobDropDown";
import styled from "styled-components";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import jsPDF from "jspdf";
import {
  renderJobEventContent,
  renderAppointmentEventContent,
} from "./fullCalendarRenderContentFunctions";
import { updateAppointmentSchedule } from "store/reducers/appointments";
import Map from "./Map";

import usePermissionScopes from "utils/usePermissionScopes";
import Constants from "utils/Constants";
import LeftNav from "./LeftNav";

/**
 * CHECK HERE FOR GREAT REFERENCE: https://github.com/fullcalendar/fullcalendar-example-projects/blob/master/react/src/DemoApp.jsx
 */
function Calendar({
  updateAppointmentSchedule,
  setSelectedCalendar,
  filterByEndDate,
  filterByStartDate,
  filterByLocation,
  selectedCalendar,
  filterByStatus,
  shouldShowBtn,
  calendarsList,
  appointments,
  companyType,
  currentView,
  setDeleting,
  setLoading,
  validRange,
  fetchJob,
  userType,
  loading,
  jobs,
}) {
  const history = useHistory();
  const permissionScope = usePermissionScopes(companyType, userType);

  const jobsCalendarRef = useRef(null);
  const appointmentsCalendarRef = useRef(null);

  const [allJobs, setAllJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [inactiveJobs, setInactiveJobs] = useState([]);
  const [shouldRerunDrop, setShouldRerunDrop] = useState(true);
  const [selectedJobEventId, setSelectedJobEventId] = useState("");
  const [jobDropDownOpen, setJobDropDownOpen] = useState(false);
  const [appointmentsDropDownOpen, setAppointmentsDropDownOpen] = useState(
    false
  );
  const [selectedAppointmentEventId, setSelectedAppointmentEventId] = useState(
    ""
  );

  const [formattedAppointments, setFormattedAppointments] = useState([]);

  /**
   * formats jobs into usable objects for calendar
   */
  useEffect(() => {
    setAllJobs(
      jobs.map((job) => {
        const { startDate, endDate, title, id } = job;
        return {
          start: startDate ? startDate : null,
          end: endDate
            ? // read doc comment for why we do this
              formatEndDateToDisplayCorrectly(endDate)
            : null,
          extendedProps: job,
          allDay: true,
          title,
          id,
        };
      })
    );
  }, [jobs]);

  useEffect(() => {
    setFormattedAppointments(
      appointments.map((appointment) => {
        const {
          start,
          end,
          customerFirstName,
          customerLastName,
          id,
        } = appointment;
        return {
          start,
          end,
          extendedProps: appointment,
          allDay: false,
          title: `${customerFirstName} ${customerLastName}`,
          id,
        };
      })
    );
  }, [appointments]);

  /**
   * separates the ^^ formatted jobs into active and inactive
   */
  useEffect(() => {
    setActiveJobs(allJobs.filter((j) => j.start && j.end));
    setInactiveJobs(allJobs.filter((j) => !j.start || !j.end));
  }, [allJobs]);

  /**
   * finds draggable elements in dom and adds drag functionality
   */
  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".draggable",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        return {
          title: title,
          id: id,
          create: false,
        };
      },
    });
  }, [inactiveJobs, activeJobs]);

  /**
   * See doc comment for why
   */
  const formatEndDateToDisplayCorrectly = (endDate) => {
    // see doc comment for why this exists
    return moment(endDate).add(1, "days").format();
  };

  /**
   * handles dragging and dropping inside of calendar for already scheduled events
   */
  const handleEventDrop = ({ revert, event: { start, end, id } }) => {
    updateJobSchedule(start, end, id, revert);
  };

  /**
   * handles resizing an event
   */
  const handleJobEventResize = ({ revert, event: { start, end, id } }) => {
    updateJobSchedule(start, end, id, revert);
  };

  /**
   * handles dragging unscheduled job to calendar
   */
  const handleDragJobToCalendar = (start, id) => {
    setLoading(true);
    updateJobSchedule(
      moment(start).format(),
      moment(start).add(1, "days").format(),
      id,
      () => {}
    )
      .then(() => {
        setAllJobs(
          allJobs.map((j) => {
            if (j.id === parseInt(id)) {
              return {
                ...j,
                start,
                end: start,
              };
            }
            return j;
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Used all of the other methods, updates a job schedule,
   * then fetches that updated job and set it to redux store.
   * Would note that tuning this in the future may be worth it,
   * as this causes re-render of calendar, which may be annoying with thousands of jobs etc.
   * For now totally fine however.
   */
  const updateJobSchedule = (startDate, endDate, jobId, revert) => {
    startDate = moment(startDate).format();
    endDate = moment(endDate).subtract(1, "days").format(); // read doc comment for why we do this
    const job = jobs.find(({ id }) => id === parseInt(jobId));

    if (permissionScope !== Constants.ADMIN_CONTRACTOR) {
      setLoading(false);
      revert();
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text:
          'Only admin accounts are able to update schedules. If you need access to updating your schedule, ask to have your account be given "admin" status.',
      });
    }
    if (job?.status !== "active") {
      setLoading(false);
      revert();
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You may not reschedule events which are marked as complete.",
      });
    }
    if (startDate > endDate) {
      setLoading(false);
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Start date must come before end date.",
      });
    }
    const schedule = {
      startDate,
      endDate,
      jobId,
    };

    return axios
      .post("/api/jobs/update/schedule", schedule)
      .then(() => {
        fetchJob(jobId);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "There was an error updating this job. Please try again in a moment.",
        });
        revert();
      });
  };

  /**
   * We... really should make our own damn calendar sooner or later xD
   * Full calendar DOES technically provide a method to force a resize, but it
   * dose not work correct with the react version and im not really able to see why.
   *
   * The reason we need to do this is because if a bounding div changes size, the
   * calendar isnt responsive to it and will result in a weird layout both when printing
   * and when opening/closing the side bars.
   *
   * Doing this forces all stuck calendar dom nodes to resize back to 100% (it sets them to a set width),
   * as well as rerenders the events (doing this by "updating" current view, simplest thing i could find to force a rerender)
   */
  const forceUpdateCalendarSize = async () => {
    const harness = document.querySelector(".fc-view-harness");
    const calBody = document.querySelector("tbody");
    const calBody2 = document.querySelector(".fc-daygrid-body");
    const calBody3 = document.querySelector(".fc-scrollgrid-sync-table");
    const calHeader = document.querySelector(".fc-col-header");
    harness.style.width = "100%";
    calBody.style.width = "100%";
    calBody2.style.width = "100%";
    calBody3.style.width = "100%";
    calHeader.style.width = "100%";

    if (currentView === "appointments") {
      appointmentsCalendarRef.current
        .getApi()
        .changeView(
          appointmentsCalendarRef.current.getApi().currentDataManager.state
            .currentViewType
        );
    } else {
      jobsCalendarRef.current
        .getApi()
        .changeView(
          jobsCalendarRef.current.getApi().currentDataManager.state
            .currentViewType
        );
    }
  };
  /**
   * handles printing the current calendar view
   */
  const printCalendar = (type) => {
    // scroll to top to get a correct print
    window.scrollTo(0, 0);
    // turn off buttons
    const buttonGroups = document.getElementsByClassName("btn-group");
    for (let i = 0; i < buttonGroups.length; i++) {
      buttonGroups[i].style.display = "none";
    }

    //resize parent div
    document.getElementById(
      currentView === "appointments"
        ? "appointmentsCalendarCapture"
        : "calendarCapture"
    ).style.width = "1100px";
    // force a rerender on calendar so that it looks as it should
    forceUpdateCalendarSize();

    // So seems like the .changeView method called in forceUpdateCalendarSize
    // isnt synchronous in some way, so ignore the fact that im wrapping this bit of
    // code in setTimeout etc, just delaying this to make sure it runs after everything else
    // has completed. For sure possible that there are some unforseen bugs from this tho...
    const save = () => {
      setTimeout(() => {
        html2canvas(
          document.getElementById(
            currentView === "appointments"
              ? "appointmentsCalendarCapture"
              : "calendarCapture"
          )
        )
          .then((canvas) => {
            for (let i = 0; i < buttonGroups.length; i++) {
              buttonGroups[i].style.display = "block";
            }

            const imgData = canvas.toDataURL("image/png");

            if (type === "PDF") {
              let imgWidth = 210;
              let pageHeight = 295;
              let imgHeight = (canvas.height * imgWidth) / canvas.width;
              let heightLeft = imgHeight;
              let doc = new jsPDF("p", "mm");
              let position = 10; // give some top padding to first page

              doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;

              while (heightLeft >= 0) {
                position += heightLeft - imgHeight; // top padding for other pages
                doc.addPage();
                doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
              }
              doc.save(
                `${moment(Date.now()).format("MMM Do YY")}-calendar.pdf`
              );
            } else if (type === "PNG") {
              //  --- Or can just download the PNG at full scale ---
              var a = document.createElement("a");
              a.href = imgData.replace("image/png", "image/octet-stream");
              a.download = `${moment(Date.now()).format(
                "MMM Do YY"
              )}-calendar.png`;
              a.click();
            }
          })
          .finally(() => {
            document.getElementById(
              currentView === "appointments"
                ? "appointmentsCalendarCapture"
                : "calendarCapture"
            ).style.width = "100%";
            for (let i = 0; i < buttonGroups.length; i++) {
              buttonGroups[i].style.display = "block";
            }
          });
      }, 0);
    };

    save();
  };

  const handleJobEventClick = (jobId) => {
    const selectedElId = `CalendarPopoverItem-${jobId}`;

    if (selectedElId === selectedAppointmentEventId) {
      setJobDropDownOpen((prevState) => !prevState);
    } else {
      setJobDropDownOpen(true);
      setSelectedJobEventId(selectedElId);
    }
  };

  const handleAppointmentEventClick = (appointmentId) => {
    const selectedElId = `AppointmentPopoverItem-${appointmentId}`;

    if (selectedElId === selectedAppointmentEventId) {
      setAppointmentsDropDownOpen((prevState) => !prevState);
    } else {
      setAppointmentsDropDownOpen(true);
      setSelectedAppointmentEventId(selectedElId);
    }
  };

  const handleAppointmentReschedule = async ({
    revert,
    event: { start, end, id, extendedProps },
  }) => {
    if (userType !== Constants.ADMIN) {
      alert("You must be an admin to perform this action");
    }
    if (extendedProps.status === "complete") {
      revert();
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You can not reschedule events which are marked as complete.",
      });
    }
    updateAppointmentSchedule(
      parseInt(id),
      moment(start).format(),
      moment(end).format(),
      revert
    );
  };

  return (
    <div style={{ display: "flex", position: "relative" }} className="shadow">
      <JobDropDown
        isAdminContractor={permissionScope === Constants.ADMIN_CONTRACTOR}
        selectedEventId={selectedJobEventId}
        setDropDownOpen={setJobDropDownOpen}
        dropDownOpen={jobDropDownOpen}
        setAllJobs={setAllJobs}
        history={history}
        jobs={jobs}
      />
      <AppointmentDropDown
        setDropDownOpen={setAppointmentsDropDownOpen}
        selectedEventId={selectedAppointmentEventId}
        dropDownOpen={appointmentsDropDownOpen}
        appointments={appointments}
      />
      <LeftNav
        forceUpdateCalendarSize={forceUpdateCalendarSize}
        printCalendar={printCalendar}
        shouldShowBtn={shouldShowBtn}
        jobs={jobs}
        setSelectedCalendar={setSelectedCalendar}
        selectedCalendar={selectedCalendar}
        setDeleting={setDeleting}
        calendars={calendarsList}
      />

      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ----- Job Calendar ----- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      <OuterWrapper
        style={{ display: currentView === "jobs" ? "flex" : "none" }}
      >
        <Unscheduled
          history={history}
          shouldBeDraggable={permissionScope === Constants.ADMIN_CONTRACTOR}
          handleEventClick={handleJobEventClick}
          inactiveJobs={inactiveJobs
            .filter(filterByStatus)
            .filter(filterByLocation)
            .filter((j) => {
              if (!selectedCalendar) {
                return true;
              }
              return j.extendedProps.calendarAssignments
                .map((j) => j.calendarId)
                .includes(selectedCalendar);
            })}
        />
        <Wrapper className="p-4 bg-white rounded" id="calendarCapture">
          <LoadingCover display={loading} rem={10} />
          <FullCalendar
            validRange={validRange}
            headerToolbar={{
              left: "title",
              center: "dayGridMonth,dayGridWeek,dayGridDay,today",
              right: "prev,next",
            }}
            contentHeight={"auto"}
            plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin]}
            droppable={permissionScope === Constants.ADMIN_CONTRACTOR}
            editable={permissionScope === Constants.ADMIN_CONTRACTOR}
            ref={jobsCalendarRef}
            eventContent={renderJobEventContent}
            initialView="dayGridMonth"
            nextDayThreshold="00:00:00"
            eventResizableFromStart
            themeSystem="bootstrap"
            events={activeJobs
              .filter(filterByStatus)
              .filter(filterByLocation)
              .filter(filterByStartDate)
              .filter(filterByEndDate)
              .filter((j) => {
                if (!selectedCalendar) {
                  return true;
                }
                return j.extendedProps.calendarAssignments
                  .map((j) => j.calendarId)
                  .includes(selectedCalendar);
              })}
            // --- callbacks ---
            eventClick={(eventInfo) => {
              handleJobEventClick(eventInfo.event.extendedProps.id);
            }}
            eventDrop={(info) => {
              try {
                // I have these in try catch blocks because i've run into an issue
                // super sporadically where "id" getter from fullcalendar throws an
                // error.
                handleEventDrop(info);
              } catch (error) {
                return Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "There was an issue updating your job.",
                });
              }
            }}
            eventResize={(info) => {
              try {
                handleJobEventResize(info);
              } catch (error) {
                return Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "There was an issue updating your job.",
                });
              }
            }}
            drop={(info) => {
              try {
                // if the job is marked as complete, cant schedule it
                const status = inactiveJobs.find(
                  ({ id }) =>
                    id === parseInt(info.draggedEl.getAttribute("data"))
                ).extendedProps.status;
                if (status === "complete") {
                  return Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text:
                      "You may not reschedule events which are marked as complete.",
                  });
                }
                let start = info.date;
                let id = info.draggedEl.getAttribute("data");

                shouldRerunDrop && handleDragJobToCalendar(start, id);

                // yes... YES this is ugly... I know.. BUT I CAN EXPLAIN!
                // Theres an issue with FullCalendar where "drop" inadvertently is called
                // multiple times when dragging an unscheduled event into the calendar.
                // This little bit of ugliness just ensures that does not happen.
                // Feel free to look for another solution if you'd like xD
                setShouldRerunDrop(false);
                setTimeout(() => {
                  setShouldRerunDrop(true);
                }, 500);
              } catch (error) {
                return Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "There was an issue updating your job.",
                });
              }
            }}
          />
        </Wrapper>
      </OuterWrapper>

      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ----- Appointments Calendar ----- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      <OuterWrapper
        className="bg-white p-2"
        style={{ display: currentView === "appointments" ? "flex" : "none" }}
      >
        <Wrapper
          className="p-4 bg-white rounded"
          id="appointmentsCalendarCapture"
        >
          <LoadingCover display={loading} rem={10} />
          <FullCalendar
            validRange={validRange}
            headerToolbar={{
              left: "title",
              center: "dayGridMonth,timeGridWeek,timeGridDay,today",
              right: "prev,next",
            }}
            themeSystem="bootstrap"
            contentHeight={"auto"}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              bootstrapPlugin,
              interactionPlugin,
            ]}
            droppable={permissionScope === Constants.ADMIN_CONTRACTOR}
            editable={permissionScope === Constants.ADMIN_CONTRACTOR}
            eventContent={renderAppointmentEventContent}
            eventResize={handleAppointmentReschedule}
            eventDrop={handleAppointmentReschedule}
            events={formattedAppointments.filter(filterByStatus).filter((a) => {
              if (!selectedCalendar) {
                return true;
              }
              return a.extendedProps.appointmentCalendarAssignments
                .map((aca) => aca.calendarId)
                .includes(selectedCalendar);
            })}
            ref={appointmentsCalendarRef}
            initialView="timeGridWeek"
            allDaySlot={false}
            eventClick={(eventInfo) => {
              handleAppointmentEventClick(eventInfo.event.extendedProps.id);
            }}
          />
        </Wrapper>
      </OuterWrapper>
      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ----- Job Map ----- */}
      {/* ---------- */}
      {/* ---------- */}
      {/* ---------- */}
      {
        <OuterWrapper
          className="bg-white p-2"
          style={{ display: currentView === "map" ? "flex" : "none" }}
        >
          <Wrapper
            className="p-4 bg-white rounded"
            id="appointmentsCalendarCapture"
          >
            <LoadingCover display={loading} rem={10} />
            <Map
              shouldMountMapCanvas={currentView === "map"}
              jobs={jobs
                .filter(filterByLocation)
                .filter(filterByStatus)
                .filter(filterByStartDate)
                .filter(filterByEndDate)
                .filter((j) => {
                  return selectedCalendar === 0
                    ? true
                    : j.calendarAssignments.find(
                        ({ calendarId }) => calendarId === selectedCalendar
                      );
                })}
            />
          </Wrapper>
        </OuterWrapper>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  companyType: state.company.company.companyType,
  appointments: state.appointments.appointments,
  currentView: state.calendars.currentView,
  userType: state.auth.user.userType,
  jobs: state.jobs.jobs,
});

export default connect(mapStateToProps, { updateAppointmentSchedule })(
  Calendar
);

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  button {
    background: #8898aa !important;
    border-color: #8898aa !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    :hover {
      background: #738393 !important;
    }
    @media (max-width: 700px) {
      font-size: 10px !important;
    }
  }
  .fc-prev-button {
    @media (max-width: 700px) {
      margin-left: 5px;
    }
  }
  .fc-toolbar-title {
    @media (max-width: 700px) {
      font-size: 16px;
    }
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
