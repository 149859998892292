import React, { useState, useCallback, useEffect } from "react";

import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";

function Images({ jobId, fetchJobImages, jobImgs, setJobImgs, userId }) {
  const [dropZoneEnabled, setDropZoneEnabled] = useState(true);
  const [previewSrcs, setPreviewSrcs] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles.filter(({ type }) => type.includes("image")));
    acceptedFiles
      .filter(({ type }) => type.includes("image"))
      .forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          setPreviewSrcs((prevState) => [
            ...prevState,
            { src: e.target.result, name: file.name },
          ]);
        };
        reader.readAsDataURL(file);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    fetchJobImages(jobId);
    // eslint-disable-next-line
  }, [jobId]);

  const handleSubmit = async () => {
    try {
      // upload all of the new files
      setIsUploading(true);
      await Promise.all(
        files.map(async (file) => {
          let data = new FormData();
          data.append("image", file, file.name);
          try {
            const {
              data: { newEntry },
            } = await axios.post(`/api/jobs/uploadJobImage/${jobId}`, data);
            setJobImgs((prevState) => [...prevState, newEntry]);
          } catch (err) {}
        })
      );

      setFiles([]);
      setPreviewSrcs([]);
    } catch (err) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "There was an issue while attempting to upload your images.",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteImg = async (id) => {
    try {
      await axios.delete(`/api/jobs/deleteJobImg/${id}`);
      setJobImgs((prevState) => prevState.filter((job) => job.id !== id));
    } catch (err) {
      return Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You was an issue when deleting your job.",
      });
    }
  };

  return (
    <div className="mt-4">
      <div className="p-2 bg-secondary rounded">
        <p
          className="m-0 p-0 text-center text-default"
          onClick={() => setDropZoneEnabled((prevState) => !prevState)}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: 400,
          }}
        >
          {dropZoneEnabled ? "-" : "+"} upload images
        </p>
        <UploadDropZone display={dropZoneEnabled ? "block" : "none"}>
          <div
            {...getRootProps()}
            className="w-100 p-4 mt-2 rounded text-center bg-light"
            style={{ border: "3px dotted #8898aa", cursor: "pointer" }}
          >
            <span className="text-gray">
              <i className="fa fa-upload fa-3x" />
            </span>
            <input accept="image/*" {...getInputProps()} />
            <p className="m-0 p-0 h3 text-gray" style={{ fontWeight: "600" }}>
              {isDragActive ? "Drop..." : "Upload Files"}
            </p>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {previewSrcs.map(({ src, name }) => {
              return (
                <div
                  className="m-2"
                  key={src + name}
                  style={{
                    position: "relative",
                    width: "100px",
                    height: "auto",
                  }}
                >
                  <img
                    src={src}
                    alt={name}
                    style={{ width: "100px", height: "auto" }}
                  />
                  <p
                    className="m-0 p-0"
                    style={{ fontSize: "12px", fontStyle: "italic" }}
                  >
                    {name}
                  </p>

                  <div
                    className="m-0 rounded-circle bg-danger text-white shadow--hover"
                    style={{
                      position: "absolute",
                      top: -12,
                      right: -12,
                      fontWeight: 600,
                      width: "25px",
                      height: "25px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setPreviewSrcs((prevState) =>
                        prevState.filter((file) => file.name !== name)
                      );
                      setFiles((prevState) =>
                        prevState.filter((file) => file.name !== name)
                      );
                    }}
                  >
                    x
                  </div>
                </div>
              );
            })}
          </div>
          <Button
            disabled={!files.length || isUploading}
            onClick={handleSubmit}
            className="mt-2"
            color="default"
            block
          >
            {isUploading ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>upload {files.length} image(s)</>
            )}
          </Button>
        </UploadDropZone>
      </div>
      {jobImgs.map(
        (
          { id, imgUrl, postedBy, postedByFirstName, postedByLastName },
          idx
        ) => {
          return (
            <div key={id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
                className="mb-2"
              >
                <p
                  className="m-0 p-0"
                  style={{ fontStyle: "italic", fontSize: "12px" }}
                >
                  posted by:{" "}
                  {postedBy === userId
                    ? "you"
                    : `${postedByFirstName} ${postedByLastName}`}
                </p>
                {userId === postedBy && (
                  <Button
                    onClick={() => handleDeleteImg(id)}
                    color="danger"
                    size="sm"
                  >
                    delete
                  </Button>
                )}
              </div>

              <img
                key={id}
                alt={`job #${idx}`}
                src={imgUrl}
                style={{ width: "100%", height: "auto" }}
                className="mb-3 shadow--hover"
              />
            </div>
          );
        }
      )}
    </div>
  );
}

const mapStateToProps = (state) => () => ({
  userId: state.auth.user.id,
});

export default connect(mapStateToProps, {})(Images);

const UploadDropZone = styled.div`
  width: 100%;
  display: ${(props) => props.display};
`;
