import React, { useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

function NavBarIcon({ iconName, routeTo, history, isHomeTab }) {
  const [tabSelected, setTabSelected] = useState("");
  const { pathname } = history.location;
  useEffect(() => {
    // dont LOVE how this works in case anyone wants to do it a different way
    // the "isHomeTab" prop is just so that we can handle:
    // --- case: "/app"
    // as well as
    // case "/app/whatever"
    // Im not just doing "pathname === routeTo" for all of them so that
    // we can do things like "/app/whatever/:id"
    if (isHomeTab) {
      if (pathname === routeTo) {
        setTabSelected(pathname);
      }
    } else if (pathname.includes(routeTo)) {
      setTabSelected(pathname);
    }
  }, [pathname, routeTo, isHomeTab]);
  return (
    <IconWrapper
      onClick={() => {
        if (routeTo) {
          history.push(routeTo);
        }
      }}
      style={
        tabSelected
          ? { borderBottom: "2px solid white" }
          : { borderBottom: "2px solid #162b50" }
      }
      className="bg-darker"
    >
      <span className={`btn-inner--icon fa-sm text-white`}>
        <i className={iconName} />
      </span>
    </IconWrapper>
  );
}

export default withRouter(NavBarIcon);

NavBarIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  routeTo: PropTypes.string,
  isHomeTab: PropTypes.bool,
};

NavBarIcon.defaultProps = {
  isHomeTab: false,
};

export const IconWrapper = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
  @media (max-width: 850px) {
    min-width: 0px;
  }
  :hover {
    background: #20202b !important;
  }
`;
