import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

import { Container, UncontrolledTooltip, Progress, Spinner } from "reactstrap";
import { createNewAccountAndCompany } from "store/reducers/auth.js";
import FinishAccountConfig from "./FinishAccountConfig";
import ChooseAccountType from "./ChooseAccountType";
import { useAuth0 } from "../../../react-auth0-spa";
import BasicAccountInfo from "./BasicAccountInfo";
import BasicCompanyInfo from "./BasicCompanyInfo";
import { withRouter } from "react-router-dom";
import ExplainerPage from "./ExplainerPage";
import SubmitModal from "./SubmitModal";
import styled from "styled-components";

function ConfigureAccount({
  createNewAccountAndCompany,
  accountCreationComplete,
}) {
  const [progress, setProgress] = useState(20);
  const [curStep, setCurStep] = useState(0);
  const { user, logout } = useAuth0();
  const [formState, setFormState] = useState({
    email: "",
    location: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    language: "english",
    account_type: "",
    company_name: "",
    position: "",
    plan: "basic",
  });

  const [creationSuccessful, setCreationSuccessful] = useState(false);

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalBody: "",
    modalHeader: "",
  });

  const handleSetStep = (stepNumber) => {
    window.scrollTo(0, 0);
    setCurStep(stepNumber);
    setProgress(stepNumber * 25);
  };

  const handleModalButtonClick = () => {
    if (creationSuccessful) {
      window.location.pathname = "/app/company";
    } else {
      setModalState({
        isModalOpen: false,
        modalBody: "",
        modalHeader: "",
      });
      handleSetStep(1);
    }
  };

  const handleCreateAccountAndCompany = () => {
    createNewAccountAndCompany(formState)
      .then((res) => {
        if (res) {
          setCreationSuccessful(true);
          setModalState({
            isModalOpen: true,
            modalBody: "Click the button below to be get started.",
            modalHeader: "Success!",
          });
        } else {
          handleSetStep(1);
          setCreationSuccessful(false);
          setModalState({
            isModalOpen: true,
            modalBody:
              "It seems there was an issue creating your account. Plase go back and make sure that you've correctly filled out your information.",
            modalHeader: "Hmm...",
          });
        }
      })
      .catch(() => {
        handleSetStep(1);
        setCreationSuccessful(false);
        setModalState({
          isModalOpen: true,
          modalBody:
            "It seems there was an issue creating your account. Plase go back and make sure that you've correctly filled out your information.",
          modalHeader: "Hmm...",
        });
      });
  };

  useEffect(() => {
    setFormState({
      ...formState,
      email: user.email,
    });
    // eslint-disable-next-line
  }, [user]);

  if (accountCreationComplete) {
    return <Redirect to="/app/company" />;
  }
  return (
    <>
      {curStep !== 0 ? (
        <>
          <NavWrapper className="bg-darker p-2 shadow">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("token");
                logout({
                  returnTo:
                    process.env.REACT_APP_LOGOUT_URL || "http://localhost:3000",
                });
              }}
              className="btn-inner--icon"
              data-placement="right"
              id="tooltip611234743"
            >
              <i className="fa fa-sign-out fa-2x text-white" />
            </span>
            <UncontrolledTooltip
              delay={0}
              placement="right"
              target="tooltip611234743"
            >
              <p className="text-md p-0 m-0 font-weight-bold">log out</p>
            </UncontrolledTooltip>
          </NavWrapper>
          <Progress
            max="100"
            value={progress}
            color="default"
            className="m-0"
          />
        </>
      ) : null}
      <Container
        style={{ minHeight: "100vh" }}
        className={`pt-3 pb-8 text-white container-fluid ${
          curStep ? "bg-white" : "bg-lighter"
        }`}
        fluid
      >
        {curStep === 0 && <ExplainerPage handleSetStep={handleSetStep} />}
        {curStep === 1 && (
          <BasicAccountInfo
            handleSetStep={handleSetStep}
            formState={formState}
            setFormState={setFormState}
          />
        )}
        {curStep === 2 && (
          <ChooseAccountType
            setFormState={setFormState}
            handleSetStep={handleSetStep}
          />
        )}
        {curStep === 3 && (
          <BasicCompanyInfo
            formState={formState}
            setFormState={setFormState}
            handleSetStep={handleSetStep}
          />
        )}
        {curStep === 4 && (
          <FinishAccountConfig
            handleCreateAccountAndCompany={handleCreateAccountAndCompany}
            handleSetStep={handleSetStep}
          />
        )}
        {curStep === 5 && (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Spinner
              style={{ width: "12rem", height: "12rem" }}
              color="default"
              className="mt-6"
            />
          </div>
        )}
      </Container>
      <SubmitModal
        isModalOpen={modalState.isModalOpen}
        modalBody={modalState.modalBody}
        modalHeader={modalState.modalHeader}
        handleClick={handleModalButtonClick}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    accountCreationComplete: state.auth.user.accountCreationComplete,
  };
};

export default withRouter(
  connect(mapStateToProps, { createNewAccountAndCompany })(ConfigureAccount)
);

const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
