import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  messages: [],
  connections: [],
  bell: [],
  hasFetched: false,
};

export default createReducer(initialState, {
  socketsAddedNotifications: (state, { payload }) => {
    payload.forEach((un) => {
      const { destination } = un.notification;
      state[destination].unshift(un);
    });
  },
  sawNotifications: (state, { payload }) => {
    const { category, notifications } = payload;
    state[category] = state[category].map((n) =>
      notifications[n.id] ? { ...n, ...notifications[n.id] } : n
    );
  },
  fetchedNotifications: (state, { payload }) => {
    const newState = payload.reduce((acc, n) => {
      const { destination } = n.notification;
      if (!acc[destination]) {
        acc[destination] = [];
      }
      acc[destination].push(n);
      return acc;
    }, {});

    newState.hasFetched = true;
    return { ...state, ...newState };
  },
});

export const receivedNotification = createAction("receivedNotification");

export const hasSeenNotifications = (category) => (dispatch, getState) => {
  const state = getState();

  const ids = state.notifications[category]
    .filter(({ isNew }) => isNew)
    .map(({ id }) => id);

  if (ids.length) {
    axios
      .post("/api/notifications/seen", ids)
      .then(({ data }) => {
        dispatch({
          type: "sawNotifications",
          payload: { category, notifications: data },
        });
      })
      .catch((err) => console.log("Error spotting notifications: ", err));
  }
};

export const fetchNotifications = () => (dispatch) => {
  axios.get("/api/notifications/user").then(({ data }) => {
    if (data.notifications.length) {
      dispatch({ type: "fetchedNotifications", payload: data.notifications });
    }
  });
};
