import { createReducer, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: { language: "english", location: "" },
  hasFetchedUser: false,
  isFetchingUser: true,
  shouldLoadInitialUserState: true,
};

export default createReducer(initialState, {
  fetchedUser: (state, { payload }) => {
    state.user = payload;
    state.isFetchingUser = false;
    state.hasFetchedUser = true;
  },
  changeLanguage: (state, { payload }) => {
    state.user.language = payload;
  },
  fetchingUser: (state) => {
    state.isFetchingUser = true;
  },
  updateUserInfo: (state, { payload }) => {
    state.user = { ...state.user, ...payload };
  },
});

export const changeLanguage = createAction("changeLanguage");

// creates a new account and company
export const createNewAccountAndCompany = (companyAndUserInfo) => async (
  dispatch
) => {
  try {
    const results = await axios.post(
      "/api/auth/createNewAccountWithCompany",
      companyAndUserInfo
    );

    dispatch({
      type: "CREATE_ACCOUNT_AND_COMPANY_SUCCESS",
      payload: results.data,
    });

    return results.status === 201;
  } catch (err) {
    dispatch({ type: "CREATE_ACCOUNT_AND_COMPANY_FAIL", payload: err });
    return false;
  }
};

export const fetchUserInfo = (getTokenSilently, history) => async (
  dispatch
) => {
  dispatch({ type: "fetchingUser" });
  try {
    const token = await getTokenSilently();
    window.localStorage.setItem("token", token);
    const responseData = await axios.get("/api/auth/fetchProfile");
    dispatch({ type: "fetchedUser", payload: responseData.data.user });
    dispatch({
      type: "setCompanyInfoToStore",
      payload: responseData.data.company,
    });
  } catch (err) {
    history.push("/");
  }
};

export const updateUserInfo = (userInfo) => async (dispatch) => {
  try {
    await axios.post("/api/auth/user/update", userInfo);
    dispatch({ type: "updateUserInfo", payload: userInfo });
  } catch (err) {}
};
