import React from "react";
import styled from "styled-components";

const calendarSelectorClasses =
  "p-2 mb-2 bg-white rounded shadow-sm shadow--hover text-dark";
const selectedCalendarClasses =
  "p-2 mb-2 bg-default rounded shadow-sm shadow--hover text-white";

export default function CalendarSelect({
  setSelectedCalendar,
  selectedCalendar,
  setDeleting,
  calendars,
  history,
}) {
  return (
    <>
      <div className="mb-4">
        <p className="h5 text-dark">Choose a Calendar </p>
        <span
          className="text-default "
          style={{
            fontSize: "14px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push("/app/calendar/edit");
          }}
        >
          create{" "}
        </span>
        {selectedCalendar ? (
          <span
            className="text-warning ml-2"
            style={{
              fontSize: "14px",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              setDeleting(true);
            }}
          >
            delete{" "}
          </span>
        ) : (
          ""
        )}
      </div>
      <CalendarSelectorsWrapper
        className="w-100 mb-4"
        style={{ position: "sticky", top: 15 }}
      >
        <CalendarSelector
          onClick={() => setSelectedCalendar(0)}
          className={
            selectedCalendar === 0
              ? selectedCalendarClasses
              : calendarSelectorClasses
          }
        >
          <p className="m-0 p-0">All</p>
        </CalendarSelector>
        {calendars.map(({ title, id }) => (
          <CalendarSelector
            onClick={() => setSelectedCalendar(id)}
            key={id}
            className={
              selectedCalendar === id
                ? selectedCalendarClasses
                : calendarSelectorClasses
            }
          >
            <p className="m-0 p-0">{title}</p>
          </CalendarSelector>
        ))}
      </CalendarSelectorsWrapper>
    </>
  );
}

const CalendarSelectorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const CalendarSelector = styled.div`
  max-width: 250px;
  min-width: 150px;
  text-align: center;
  cursor: pointer;
  p {
    font-weight: 600;
    font-size: 14px;
  }
`;
