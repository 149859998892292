import { createReducer } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import axios from "axios";

const initialState = {
  appointments: [],
};

export default createReducer(initialState, {
  addAppointment: (state, { payload }) => {
    state.appointments.push(payload);
  },
  fetchAppointments: (state, { payload }) => {
    state.appointments = payload;
  },
  updateAppointment: (state, { payload }) => {
    state.appointments = state.appointments.map((apt) =>
      parseInt(apt.id) !== parseInt(payload.id) ? apt : { ...apt, ...payload }
    );
  },
  toggleAssignment: (state, { payload }) => {
    const { appointmentId, assignment, calendarId } = payload;
    const shouldRemove = !Object.keys(assignment).length;

    state.appointments = state.appointments.map((apt) => {
      if (apt.id !== appointmentId) {
        return apt;
      }

      const appointmentCalendarAssignments = shouldRemove
        ? apt.appointmentCalendarAssignments.filter(
            (aca) => aca.calendarId !== calendarId
          )
        : [...apt.appointmentCalendarAssignments, assignment];

      return {
        ...apt,
        appointmentCalendarAssignments,
      };
    });
  },
  completeAppointment: (state, { payload }) => {
    state.appointments = state.appointments.map((apt) =>
      parseInt(apt.id) !== parseInt(payload.id)
        ? apt
        : { ...apt, status: "complete", followUpNote: payload.followUpNote }
    );
  },
  deleteAppointment: (state, { payload }) => {
    state.appointments = state.appointments.filter(
      (apt) => parseInt(apt.id) !== parseInt(payload.id)
    );
  },
});

export const addAppointment = (appointment) => async (dispatch) => {
  try {
    const { data } = await axios.post("/api/appointments/create", appointment);

    dispatch({ type: "addAppointment", payload: data.appointment });
  } catch (error) {}
};

export const fetchAppointments = () => async (dispatch) => {
  try {
    const {
      data: { appointments },
    } = await axios.get("/api/appointments/company");
    dispatch({ type: "fetchAppointments", payload: appointments });
  } catch (error) {}
};

export const updateAppointmentSchedule = (id, start, end, revert) => async (
  dispatch
) => {
  try {
    await axios.put(`/api/appointments/schedule/${id}`, { start, end });

    dispatch({
      type: "updateAppointment",
      payload: { id, start, end },
    });
  } catch (error) {
    revert();
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an issue updating your appointment.",
    });
  }
};

export const updateAppointmentColor = (id, color) => async (dispatch) => {
  try {
    await axios.put(`/api/appointments/color/${id}`, { color });
    dispatch({
      type: "updateAppointment",
      payload: { id, color },
    });
  } catch (error) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an issue updating your appointment.",
    });
  }
};

export const deleteAppointment = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/appointments/delete/${id}`);
    dispatch({
      type: "deleteAppointment",
      payload: { id },
    });
  } catch (error) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an issue updating your appointment.",
    });
  }
};

export const completeAppointment = (id) => async (dispatch) => {
  try {
    const { value, isConfirmed } = await Swal.fire({
      title: "Optional follow up note",
      input: "textarea",
      inputValue: "",
      showCancelButton: true,
    });
    if (isConfirmed) {
      await axios.put(`/api/appointments/complete/${id}`, {
        followUpNote: value,
      });
      dispatch({
        type: "completeAppointment",
        payload: { id, followUpNote: value },
      });
    }
  } catch (error) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an issue updating your appointment.",
    });
  }
};

export const updateAppointmentDetails = (id, details) => async (dispatch) => {
  try {
    await axios.put(`/api/appointments/details/${id}`, details);
    dispatch({
      type: "updateAppointment",
      payload: { id, ...details },
    });
    Swal.fire({
      icon: "success",
      title: "Sweet!",
      text: `We've updated your appointment.`,
    });
  } catch (error) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "There was an issue updating your appointment.",
    });
  }
};

export const toggleAssignment = (appointmentId, calendarId) => async (
  dispatch
) => {
  try {
    const {
      data: { assignment },
    } = await axios.post("/api/appointments/assignToCalendar", {
      appointmentId,
      calendarId,
    });

    dispatch({
      type: "toggleAssignment",
      payload: { appointmentId, assignment, calendarId },
    });
  } catch (error) {
    return Swal.fire({
      icon: "error",
      title: "Oops...",
      text:
        "There was an error updating this appointment. Please try again in a moment.",
    });
  }
};
