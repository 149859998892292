import React from "react";

export default function NothingToShow({ subMsg, msg }) {
  return (
    <div className="p-2 bg-white rounded border border-light">
      <p
        className="m-0 p-0 lead"
        style={{ textAlign: "center", fontWeight: "600" }}
      >
        {msg}
      </p>
      <p className="m-0 p-0" style={{ textAlign: "center" }}>
        {subMsg}
      </p>
    </div>
  );
}
