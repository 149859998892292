import React from "react";

import UploadedImage from "../../../utils/UploadedImage";
import styled from "styled-components";
import currency from "currency.js";
import moment from "moment";

export default function JobCard({
  purchaseOrders,
  isContractor,
  previewSrc,
  workOrders,
  customer,
  vendors,
  job,
}) {
  return (
    <CardWrapper borderColor={job.color} className="bg-white p-4 shadow">
      <CardSection width={isContractor ? "33%" : "50%"} className="p-1">
        {previewSrc !== "" && (
          <UploadedImage
            src={previewSrc}
            alt="image which is representative of job"
            style={{
              width: "100%",
              height: "auto",
            }}
            background="white"
            width="150"
            height="150"
            display="fill"
            className="mb-2"
          />
        )}
        <p className="m-0 p-0 text-dark job-card-sub-heading">
          Customer Details
        </p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">
          {customer?.firstName} {customer?.lastName}{" "}
          {customer?.company && `(${customer?.company})`}
        </p>
        <p className="m-0 p-0 ml-2 mb-2 text-dark job-card-p-tag">
          phone: {customer?.phone}
        </p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">
          {job.address && `${job.address}, `}
        </p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag mb-2">
          {`${job.city}, `}
          {`${job.state}`} {job.zip && ` ${job.zip}`}
        </p>
        <p className="m-0 p-0 text-dark job-card-sub-heading">Job Details</p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">{job.title}</p>

        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">
          {job.startDate && moment(job.startDate).format("MMM Do YYYY")} -{" "}
          {job.endDate && moment(job.endDate).format("MMM Do YYYY")}
        </p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">
          Job #{job.jobNumber}
        </p>
        <p className="m-0 p-0  ml-2 text-dark job-card-p-tag mb-2">
          Contract Amount:{" "}
          {currency(job.contractAmount, {
            symbol: "$",
            precision: 2,
          }).format()}
        </p>
        <p className="m-0 p-0 text-dark job-card-sub-heading">
          Inspection Note
        </p>
        <p className="m-0 p-0  ml-2 text-dark job-card-p-tag mb-2">
          {job.inspectionNote}
        </p>
        <p className="m-0 p-0 text-dark job-card-sub-heading">Tags</p>
        <div
          className="ml-2 mb-2"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {job.tags.map(({ id, content }) => {
            return (
              <div
                key={id}
                style={{ fontSize: "10px" }}
                className="badge badge-light mr-1 mb-1"
              >
                {content}
              </div>
            );
          })}
        </div>
      </CardSection>

      <CardSection width={isContractor ? "33%" : "50%"} className="p-1">
        <p className="m-0 p-0 text-darker job-card-header">Work Order</p>
        <p className="m-0 p-0 mb-2 text-dark job-card-sub-heading">Crew Info</p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag">
          <span style={{ width: "150px", display: "inline-block" }}>
            Subcontractor Name:
          </span>
          <span style={{ fontWeight: 600 }}>
            {job.isCustom
              ? job.customSubcontractorName || "-"
              : job?.companyInfo?.name
              ? job.companyInfo.name
              : "-"}
          </span>
        </p>
        <p className="m-0 p-0 ml-2 text-dark job-card-p-tag mb-2">
          <span style={{ width: "150px", display: "inline-block" }}>
            Assigned To
          </span>
          <span style={{ fontWeight: 600 }}>
            {job.isCustom
              ? job.customCrewMemberName || "-"
              : Object.keys(job.assignedToUserInfo).length
              ? `${job.assignedToUserInfo.firstName} ${job.assignedToUserInfo.lastName}`
              : "-"}
          </span>
        </p>
        <p className="m-0 p-0 mb-2 text-dark job-card-sub-heading">
          Work Orders
        </p>
        {workOrders.map((order) => {
          return (
            <div key={order.id}>
              <p className="m-0 text-dark" style={{ fontWeight: 600 }}>
                {order.title}
              </p>
              {!order.orderItems.length && (
                <p
                  className="m-0 p-0 text-dark"
                  style={{ fontStyle: "italic" }}
                >
                  nothing to show
                </p>
              )}
              {order.orderItems.map((item, idx) => {
                return (
                  <div
                    key={item.id}
                    className={`p-1 rounded ${idx % 2 ? "bg-secondary" : ""}`}
                  >
                    <p
                      className="m-0 p-0 job-card-p-tag "
                      style={{ color: job.color, fontWeight: 600 }}
                    >
                      {item.title}
                    </p>
                    <p className="m-0 p-0 text-dark job-card-p-tag">
                      {item.description}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </CardSection>
      {isContractor && (
        <CardSection width={isContractor ? "33%" : "50%"} className="p-1">
          <p className="m-0 p-0 text-darker job-card-header">Purchase Order</p>
          {purchaseOrders.map((order) => {
            let ven = vendors.find((v) => v.id === order.vendorId);
            console.log(ven);

            return (
              <div key={order.id}>
                <p className="m-0 text-dark" style={{ fontWeight: 600 }}>
                  {order.title}
                </p>
                <p className="m-0 text-dark">
                  {ven.name} - {ven.accountNumber}
                </p>
                {!order.items.length && (
                  <p
                    className="m-0 p-0 text-dark"
                    style={{ fontStyle: "italic" }}
                  >
                    nothing to show
                  </p>
                )}
                {order.items.map((item, idx) => {
                  return (
                    <div
                      key={item.id}
                      className={`p-1 rounded ${idx % 2 ? "bg-secondary" : ""}`}
                    >
                      <p
                        className="m-0 p-0 job-card-p-tag "
                        style={{ color: job.color, fontWeight: 600 }}
                      >
                        {item.categoryTitle} - {item.productName}
                      </p>
                      <p className="m-0 p-0 text-dark job-card-p-tag">
                        quantity - {item.quantity}
                      </p>
                      <p className="m-0 p-0 text-dark job-card-p-tag">
                        price -{" "}
                        {currency(item.unitCost, {
                          symbol: "$",
                          precision: 2,
                        }).format()}
                      </p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </CardSection>
      )}
    </CardWrapper>
  );
}

const CardSection = styled.div`
  width: ${(props) => props.width || "50%"};
  height: 100%;
  .job-card-header {
    font-size: 32px;
    font-weight: 600;
  }
  .job-card-p-tag {
    font-size: 14px;
    line-height: 18px;
  }
  .job-card-sub-heading {
    font-weight: 600;
    font-size: 18px;
  }
`;

const CardWrapper = styled.div`
  max-width: 1069px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-left: 15px solid ${(props) => props.borderColor};

  @media print {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
