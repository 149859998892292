import React from "react";

import { Container } from "reactstrap";

export default function Footer() {
  return (
    <Container
      className="p-4 pt-6 pb-8 container-fluid bg-dark"
      style={{ position: "relative" }}
      fluid
    ></Container>
  );
}
