import React, { useEffect } from "react";

import { Cover, InnerWrapper } from "../StyledComponents";
import {
  addProduct,
  addCategory,
  updateProduct,
  deleteProduct,
  deleteCategory,
  updateCategoryTitle,
} from "store/reducers/products";
import CreateCategoryForm from "./CreateCategoryForm";
import ProductCategory from "./ProductCategory";
import { useHistory } from "react-router-dom";
import HelpModal from "utils/HelpModal";
import Constants from "utils/Constants";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function ProductsPage({
  products,
  userType,
  companyId,
  addProduct,
  categories,
  companyType,
  addCategory,
  deleteProduct,
  updateProduct,
  deleteCategory,
  updateCategoryTitle,
}) {
  const history = useHistory();

  const createCategory = async (title) => {
    try {
      const {
        data: { category },
      } = await axios.post("/api/products/categories/add", {
        title,
        companyId,
      });
      addCategory(category);
    } catch (error) {
      return Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an issue creating this category.",
      });
    }
  };

  useEffect(() => {
    if (companyType && companyType !== Constants.CONTRACTOR) {
      history.push(
        window.location.pathname.includes("/app/jobs")
          ? "/app/jobs"
          : "/app/calendar"
      );
    }
    // eslint-disable-next-line
  }, [companyType]);

  return (
    <Cover
      onClick={() => {
        history.push(
          window.location.pathname.includes("/app/jobs")
            ? "/app/jobs"
            : "/app/calendar"
        );
      }}
    >
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        className="rounded p-4 bg-white"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="display-4 text-darker m-0 mb-4">Manage Products</p>
          <HelpModal modalTitle="Products Help">
            <p className="text-dark m-0">
              This page is simply a way to manage products which can used in
              your purchase orders. Add categories, for instance "shingles", add
              products to those categories, price them, then head into any of
              your jobs to create a purchase order. In your purchase orders, you
              can include products listed to here.
            </p>
          </HelpModal>
        </div>
        {!categories.length && (
          <p className="m-0 mt-2 ml-0 p-0" style={{ fontStyle: "italic" }}>
            no product categories to show
          </p>
        )}
        {categories.map((c) => {
          return (
            <ProductCategory
              products={products.filter((p) => p.categoryId === c.id)}
              updateCategoryTitle={updateCategoryTitle}
              isAdmin={userType === Constants.ADMIN}
              deleteCategory={deleteCategory}
              deleteProduct={deleteProduct}
              updateProduct={updateProduct}
              addProduct={addProduct}
              category={c}
              key={c.id}
            />
          );
        })}
        {userType === Constants.ADMIN && (
          <CreateCategoryForm createCategory={createCategory} />
        )}
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  companyId: state.auth.user.companyId,
  userType: state.auth.user.userType,
  companyType: state.company.company.companyType,
  categories: state.products.categories,
  products: state.products.products,
});

export default connect(mapStateToProps, {
  addProduct,
  addCategory,
  updateProduct,
  deleteProduct,
  deleteCategory,
  updateCategoryTitle,
})(ProductsPage);
