import React from "react";

import { AttachmentsContainer, Attachment } from "./styled.js";

function RenderAttachments({ attachments, dispatch }) {
  if (!attachments.length) {
    return null;
  }

  const removeFile = (index) => {
    dispatch({ type: "REMOVE_IMAGE", payload: index });
  };
  return (
    <AttachmentsContainer>
      {attachments.map(({ preview, file, height, width }, i) => {
        if (preview) {
          return (
            <Attachment key={file.name} width={width} height={height}>
              <button type="button" onClick={removeFile.bind(this, i)}>
                X
              </button>
              <img src={preview} alt="Preview" />
            </Attachment>
          );
        }

        return null;
      })}
    </AttachmentsContainer>
  );
}

export default RenderAttachments;
