import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { Input, Button } from "reactstrap";
import { connect } from "react-redux";

import RenderAttachments from "./RenderAttachments.js";
import RoomList from "./RoomList.js";
import Message from "./Message.js";
import ChatButtons from "./Buttons.js";
import TagList from "./TagList.js";

import {
  sendChatMessage,
  sendAttachments,
  fetchMessagesForRoom,
} from "store/reducers/chat.js";
import { getAuthor } from "utils/chat.js";
import { reducer, getPreview } from "./manageAttachments.js";

import { Wrapper, Right, ChatLog, TextBox, TextLimit } from "./styled.js";

function Chat({
  rooms,
  user,
  sendChatMessage,
  sendAttachments,
  activeRoomId,
  hasFetchedRooms,
}) {
  const [contents, setContents] = useState("");
  const [attachments, dispatch] = useReducer(reducer, []);
  const [tags, setTags] = useState([]);
  const textInput = useRef();
  const chatTop = useRef(null);
  const chatBottom = useRef(null);

  const activeRoom = rooms[activeRoomId] || {};
  const { messages, hasFetchedRoom, hasFetchedMessages, userlist } = activeRoom;

  const addTag = useCallback(
    (u) => {
      !tags.find(({ id }) => id === u.id) && setTags([...tags, u]);
    },
    [tags]
  );

  const removeTag = useCallback(
    (id) => setTags(tags.filter((u) => u.id !== id)),
    [tags]
  );

  useEffect(getPreview(attachments, dispatch), [attachments]);

  useEffect(() => {
    if (messages && messages.length && chatBottom.current) {
      // If 'messages' changes, scroll to the bottom of the chat
      chatBottom.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  if (
    !hasFetchedRooms ||
    (activeRoomId && (!hasFetchedRoom || !hasFetchedMessages))
  ) {
    // Placeholder loading state
    return <div>Loading</div>;
  }

  if (!activeRoomId) {
    // Placeholder no chat rooms state (maybe disable chat button instead?)
    return <p>No chats to display. Make connections to start chatting!</p>;
  }

  const handleTextChange = (e) => {
    if (contents.length < 500) {
      setContents(e.target.value);
    }
  };

  const handleEmojiChange = (emoji) => {
    const e = { target: { value: contents + emoji } };
    handleTextChange(e);
    // textInput.current.focus(); // Currently doesn't work because of the reactstrap component
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const noTextContent = !contents.length;
    if (contents.length) {
      sendChatMessage(contents, tags);
      setContents("");
    }

    if (attachments.length) {
      sendAttachments(attachments, noTextContent && tags);
      dispatch({ type: "CLEAR_STATE" });
    }

    setTags([]);
  };

  const remainingCharacters = 500 - contents.length;
  return (
    <Wrapper>
      <RoomList />
      <Right>
        <ChatLog className="p-2 pl-4 pr-4" attachments={attachments}>
          {messages.map((m, i, arr) => {
            const key = m.id || "t" + m.tempId;
            const ref =
              i === 0 ? chatBottom : i === messages.length - 1 ? chatTop : null;
            const author = getAuthor(m, arr[i + 1]);
            return <Message m={m} key={key} ref={ref} author={author} />;
          })}
        </ChatLog>
        <RenderAttachments attachments={attachments} dispatch={dispatch} />
        <TagList tags={tags} removeTag={removeTag} />
        <TextBox className="pl-4">
          <form onSubmit={sendMessage}>
            <Input
              value={contents}
              onChange={handleTextChange}
              onKeyPress={handleKeyPress(sendMessage)}
              type="textarea"
              className="m-0 mb-1 p-2 form-control-alternative"
              rows="3"
              ref={textInput}
            />
            <Button
              color="lighter"
              disabled={!contents.length && !attachments.length}
              type="submit"
            >
              Send
            </Button>
            <TextLimit>
              {remainingCharacters < 100 && remainingCharacters}
            </TextLimit>
          </form>
          <ChatButtons
            handleEmojiChange={handleEmojiChange}
            attachments={attachments}
            dispatch={dispatch}
            addTag={addTag}
            userlist={userlist}
          />
        </TextBox>
      </Right>
    </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  rooms: state.chat.rooms,
  activeRoomId: state.chat.activeRoom,
  user: state.auth.user,
  hasFetchedRooms: state.chat.hasFetched,
});

const mapDispatchToProps = {
  sendChatMessage,
  fetchMessagesForRoom,
  sendAttachments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

function handleKeyPress(sendMessage) {
  return (e) => {
    if (e.shiftKey) {
      // Shift is press, let the default behavior happen
      return;
    }

    if (
      e.key === "Enter" ||
      e.keyCode === 13 ||
      e.which === 13 ||
      e.charCode === 13
    ) {
      sendMessage(e);
    }
  };
}
