import React from "react";

import { Cover, InnerWrapper } from "../../StyledComponents";
import AddVendorsForm from "./AddVendorsForm";
import HelpModal from "utils/HelpModal";
import { connect } from "react-redux";
import Vendor from "./Vendor";
import Constants from "utils/Constants";

function ManageVendors({
  setIsManagingVendors,
  isManagingVendors,
  setPurchaseOrders,
  vendorContacts,
  userType,
  vendors,
}) {
  return (
    <Cover
      onDoubleClick={(e) => {
        e.stopPropagation();
        setIsManagingVendors(false);
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ display: isManagingVendors ? "block" : "none" }}
    >
      <InnerWrapper
        onClick={(e) => e.stopPropagation()}
        className="rounded p-4 bg-white"
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="display-4 text-darker m-0 mb-2">Manage Vendors</p>

        {!vendors.length && (
          <p className="m-0 ml-2 text-dark" style={{ fontStyle: "italic" }}>
            nothing to show
          </p>
        )}
        {vendors.map((v) => (
          <Vendor
            vendorContacts={vendorContacts.filter((vc) => {
              return vc.vendorId === v.id;
            })}
            setPurchaseOrders={setPurchaseOrders}
            userType={userType}
            key={v.id}
            vendor={v}
          />
        ))}
        {userType === Constants.ADMIN && <AddVendorsForm />}
        <div className="mt-3">
          <HelpModal shouldShowHelpText modalTitle="Vendors Help">
            <p className="text-dark m-0">
              Vendors can be assigned to purchase orders. Vendor contacts will
              show up on the "send/print" page for purchase orders, giving you
              the ability to email them the purchase order directly from your
              Crewly account.
            </p>
          </HelpModal>
        </div>
      </InnerWrapper>
    </Cover>
  );
}

const mapStateToProps = (state) => ({
  vendors: state.contractorVendors.vendors,
  vendorContacts: state.contractorVendors.vendorContacts,
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps, {})(ManageVendors);
