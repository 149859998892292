import React, { useState, useEffect, createContext, useContext } from "react";

const windowContext = createContext();

export function WindowSizeProvider(props) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const resizeWindow = () => {
      setWindowSize(getWindowSize());
    };
    const removeListener = () =>
      window.removeEventListener("resize", resizeWindow);

    resizeWindow();
    removeListener();

    window.addEventListener("resize", resizeWindow);

    return removeListener;
    // eslint-disable-next-line
  }, []);

  const { Provider } = windowContext;
  return <Provider value={windowSize}>{props.children}</Provider>;
}

export default function useWindowSize() {
  return useContext(windowContext);
}

function getWindowSize() {
  return { width: window.innerWidth, height: window.innerHeight };
}
